import React, { createContext, useState, useEffect,useContext } from 'react';
import '../../tailwind.css';
import '../../assets/styles/styles.css';

export const ThemeContext = createContext(null);

export const ThemeWrapper = ({ children }) => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || "light");

  useEffect(() => {
    if (theme === 'dark') {
      // document.documentElement.classList.add('dark');
      document.body.classList.add('dark');

    } else {
      // document.documentElement.classList.remove('dark');
      document.body.classList.remove('dark');

    }
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = (value : string) => {
    setTheme(value);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
