// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.tab-button {
    font-size: 15px !important;
}

.tab-button {
    padding: 5px 10px;
    border: none;
    background-color: transparent;
    color: #5acce6;
    cursor: pointer;
    font-weight: bold;
    position: relative;
}

@media (min-width:640px){
    .tab-button {
        padding: 10px 20px;
        font-size: 16px;
    }
}

@media (max-width:375px){
    .tab-button {
        padding: 5px 10px;
        font-size: 11px;
    }
}

.tab-button:hover {
    /* opacity: 80; */
}

.tab-button.active {
    /* color: #fff; */
    border-bottom: 2px solid #000;
}

body.dark .tab-button.active {
    /* color: #fff; */
    border-bottom: 2px solid #fff;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/Analytics/style.css"],"names":[],"mappings":"AAAA;IACI,oBAAa;IAAb,oBAAa;IAAb,aAAa;IACb,wBAAuB;QAAvB,qBAAuB;YAAvB,uBAAuB;AAC3B;;AAEA;IAMI,0BAA0B;AAG9B;;AATA;IACI,iBAAiB;IACjB,YAAY;IACZ,6BAA6B;IAC7B,cAAc;IACd,eAAe;IAEf,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI;QACI,kBAAkB;QAClB,eAAe;IACnB;AACJ;;AACA;IACI;QACI,iBAAiB;QACjB,eAAe;IACnB;AACJ;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,6BAA6B;AACjC;;AACA;IACI,iBAAiB;IACjB,6BAA6B;AACjC","sourcesContent":[".tabs {\n    display: flex;\n    justify-content: center;\n}\n\n.tab-button {\n    padding: 5px 10px;\n    border: none;\n    background-color: transparent;\n    color: #5acce6;\n    cursor: pointer;\n    font-size: 15px !important;\n    font-weight: bold;\n    position: relative;\n}\n\n@media (min-width:640px){\n    .tab-button {\n        padding: 10px 20px;\n        font-size: 16px;\n    }\n}\n@media (max-width:375px){\n    .tab-button {\n        padding: 5px 10px;\n        font-size: 11px;\n    }\n}\n\n.tab-button:hover {\n    /* opacity: 80; */\n}\n\n.tab-button.active {\n    /* color: #fff; */\n    border-bottom: 2px solid #000;\n}\nbody.dark .tab-button.active {\n    /* color: #fff; */\n    border-bottom: 2px solid #fff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
