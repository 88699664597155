import React, { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import {createDisplaySettingsOptions,fetchDisplaySettingsOptions } from '../requestHandler';
import { updateDisplaySettingsOptions } from '../requestHandler/displaySettingsRequest';

export const DisplaySettingsContext = createContext(null);

export const DisplaySettingsProvider = ({ children, service, extension }) => {
  const [displaySettingsOptionsLists, setDisplaySettingsOptionsLists] = useState();
  const [worklistAutoRefreshTime,setWorklistAutoRefreshTime] = useState()


  useEffect(() => {
    fetchDisplaySettingsOptions()
      .then(data => setDisplaySettingsOptionsLists(data))
      .catch(error => console.error('Error fetching display settings details:', error))
  }, []);


  const value = useMemo(
    () => ({
      displaySettingsOptionsLists,
      setDisplaySettingsOptionsLists,
      createDisplaySettingsOptions,
      setWorklistAutoRefreshTime,
      worklistAutoRefreshTime,
      updateDisplaySettingsOptions
    }),
    [displaySettingsOptionsLists,worklistAutoRefreshTime]
  );

  return <DisplaySettingsContext.Provider value={value}>{children}</DisplaySettingsContext.Provider>;
};

export const useDisplaySettings = () => useContext(DisplaySettingsContext);
