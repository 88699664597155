import React, { useState } from 'react';
import Typography from '../Typography';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import classNames from 'classnames';
import { useAppConfig } from '@state';
import { Header, useUser } from '@ohif/ui';
import { SiStatuspal } from "react-icons/si";
import { BiSolidUserCheck, BiSolidReport, BiSolidMessageCheck } from "react-icons/bi";
import { FaChartLine, FaUpload } from "react-icons/fa";
import { MdRateReview } from "react-icons/md";
import { AiOutlineAudit } from "react-icons/ai";
import { FaFileInvoiceDollar, FaShareFromSquare } from 'react-icons/fa6';
import { HiDocumentDuplicate } from "react-icons/hi2";
import { PiUserListFill } from "react-icons/pi";


const Dashboard = ({ servicesManager, hotkeysManager, extensionManager, sortedStudies }) => {
  const navigate = useNavigate();

  const [appConfig] = useAppConfig();
  const location = useLocation();
  const { userAuthenticationService } = servicesManager.services;
  const user = userAuthenticationService.getUser();
  const {
    token
  } = useUser();

  const onClickReturnButton = () => {
    const { pathname } = location;
    const dataSourceIdx = pathname.indexOf('/', 1);
    const query = new URLSearchParams(window.location.search);
    const configUrl = query.get('configUrl');

    const dataSourceName = pathname.substring(dataSourceIdx + 1);
    const existingDataSource = extensionManager.getDataSources(dataSourceName);

    const searchQuery = new URLSearchParams();
    if (dataSourceIdx !== -1 && existingDataSource) {
      searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    }

    if (configUrl) {
      searchQuery.append('configUrl', configUrl);
    }

    navigate({
      pathname: '/',
      search: decodeURIComponent(searchQuery.toString()),
    });
  };


  // const permissions = user?.profile?.permission;

  // console.log(user);
  // console.log(permissions);

  // const hasAssignPermission =
  //   permissions?.includes('Access Dashboard') ||
  //   token?.realm_access?.roles?.includes('super-admin') ||
  //   token?.realm_access?.roles?.includes('deputy-admin');

  // const hasOfDashBoardPermission = user?.profile?.roleType?.includes('RadiologyGroupAdmin');

  // console.log(hasAssignPermission);

  // // Only include menu items based on combined permission checks
  // const menuItems = [
  //   // Only show Connectivity Status if the user has permission but is not restricted to RadiologyGroupAdmin-only options
  //   !hasOfDashBoardPermission && hasAssignPermission && {
  //     label: 'Connectivity Status',
  //     link: '/dashboard/connectivity-status',
  //     icon: <SiStatuspal />
  //   },
  //   // Show Users Status only if both permissions and role match
  //   hasAssignPermission && hasOfDashBoardPermission && {
  //     label: 'Users Status',
  //     link: '/dashboard/users-status',
  //     icon: <BiSolidUserCheck />
  //   },
  //   // Show Analytics only if both permissions and role match
  //   hasAssignPermission && hasOfDashBoardPermission && {
  //     label: 'Analytics',
  //     link: '/dashboard/analytics',
  //     icon: <FaChartLine />
  //   },
  //   // Show Audit Logs only if both permissions and role match
  //   hasAssignPermission && hasOfDashBoardPermission && {
  //     label: 'Audit Logs',
  //     link: '/dashboard/audit-logs',
  //     icon: <AiOutlineAudit />
  //   },
  //   // Only show Report Template if the user has permission but is not restricted to RadiologyGroupAdmin-only options
  //   !hasOfDashBoardPermission && hasAssignPermission && {
  //     label: 'Report Template',
  //     link: '/dashboard/report-template',
  //     icon: <HiDocumentDuplicate />
  //   }
  // ].filter(Boolean); // Filter out any undefined values

  const permissions = user?.profile?.permission;

  console.log(user)
  console.log(permissions);

  // Check if the user has the "Access Dashboard" permission or is a super-admin or deputy-admin
  const hasAssignPermission =
    permissions?.includes('Access Dashboard') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  // Check if the user is a RadiologyGroupAdmin
  const hasOfDashBoardPermission = user?.profile?.roleType?.includes('RadiologyGroupAdmin');

  console.log(hasAssignPermission);

  // Only include menu items based on combined permission checks
  const menuItems = [
    // Super-admin users have access to Connectivity Status
    hasAssignPermission && (token?.realm_access?.roles?.includes('super-admin') || token?.realm_access?.roles?.includes('deputy-admin')) && {
      label: 'Connectivity Status',
      link: '/dashboard/connectivity-status',
      icon: <SiStatuspal />
    },
    // Show Users Status only if the user has the required permission and role matches
    hasAssignPermission && (hasOfDashBoardPermission || token?.realm_access?.roles?.includes('super-admin') || token?.realm_access?.roles?.includes('deputy-admin')) && {
      label: 'Users Status',
      link: '/dashboard/users-status',
      icon: <BiSolidUserCheck />
    },
    // Show Analytics only if the user has the required permission and role matches
    hasAssignPermission && (hasOfDashBoardPermission || token?.realm_access?.roles?.includes('super-admin') || token?.realm_access?.roles?.includes('deputy-admin')) && {
      label: 'Analytics',
      link: '/dashboard/analytics',
      icon: <FaChartLine />
    },
    // Show Audit Logs only if the user has the required permission and role matches
    hasAssignPermission && (hasOfDashBoardPermission || token?.realm_access?.roles?.includes('super-admin') || token?.realm_access?.roles?.includes('deputy-admin')) && {
      label: 'Audit Logs',
      link: '/dashboard/audit-logs',
      icon: <AiOutlineAudit />
    },
    // Super-admin users have access to Report Template
    hasAssignPermission && (token?.realm_access?.roles?.includes('super-admin') || token?.realm_access?.roles?.includes('deputy-admin')) && {
      label: 'Report Template',
      link: '/dashboard/report-template',
      icon: <HiDocumentDuplicate />
    }
  ].filter(Boolean); // Filter out any undefined values

  const handleMenuItemClick = (link, label) => {
    navigate(link);
  };

  return (
    <>
      <Header
        isReturnEnabled={!!appConfig.showStudyList}
        onClickReturnButton={onClickReturnButton}
        WhiteLabeling={appConfig.whiteLabeling}
        loggedInUserDetails={user}
        sortedStudies={sortedStudies}
        hotkeysManager={hotkeysManager}
      />

      <section className="mr-5 flex w-60">
        <ul
          className="bg-primary-dark top-0 w-60 text-white"
          style={{
            height: 'calc(100vh - 53px)',
          }}
        >
          {menuItems.map(menuItem => (
            <li
              key={menuItem.label}
              className={classNames(
                'border-b border-b-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer p-2 transition duration-300 flex items-center gap-2 group'
              )}
              onClick={() => handleMenuItemClick(menuItem.link, menuItem.label)}
            >
              <span
                className='text-blue-400 bg-[#183153] text-2xl p-1 duration-300 group-hover:bg-blue-400 group-hover:text-white transition-all rounded-sm'
                style={{
                  boxShadow:
                    'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
                }}
              >
                {menuItem.icon}
              </span>
              {menuItem.label}
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default Dashboard;
