export const SendtoML = async (modelUrl: string) => {
    const page_url = window.location.href;
    const uuid_value = extractUuidFromUrl(page_url);

    if (uuid_value) {
        try {
            const modelLabel = getModelLabelFromUrl(modelUrl);

            const response = await fetch(modelUrl, {  // Use modelUrl directly
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ uuid: uuid_value }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            return { data: result, model: modelLabel };
        } catch (error) {
            console.error('Error:', error);
            return { results: [], model: null }; // Return an empty result on error
        }
    } else {
        console.error('UUID not found in the URL.');
        return { results: [], model: null }; // Return an empty result if UUID is not found
    }
};

const extractUuidFromUrl = (url: string) => {
    const query = new URL(url).search;
    const params = new URLSearchParams(query);
    return params.get('UUID');
};


/**
 * Get the model label based on the model URL.
 * @param {string} modelUrl - The model URL.
 * @returns {string} - The corresponding model label.
 */
const getModelLabelFromUrl = (modelUrl: string): string => {
    // Extract the part after "/api/" from the URL (e.g., chestm1, breastcancerm1, etc.)
    const path = modelUrl.split('/api/')[1];

    // Map the extracted model path to the corresponding model label
    switch (path) {
        case 'chestm1':
            return 'modelCXR';
        case 'breast-cancer-m1':
            return 'modelBreastCancer';
        case 'spine-m1':
            return 'modelSpineAI';
        case 'stroke-detection-m1':
            return 'modelBrainStroke';
        case 'bone-fracture':
            return 'modelBoneFracture';
        default:
            return 'unknownModel';  // Default if no match is found
    }
};