import React, { useEffect, useRef, useState } from 'react';
import Label from '../Label';
import Input from '../Input';
import Button from '../Button';
import Icon from '../Icon';
// import './Physician.css'
import InputMultiSelect from '../InputMultiSelect';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useModal, useReportEditor, useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import Select from '../Select';
import CheckBox from '../CheckBox';
import PermissionDropdown from '../CommanDropdown/PermissionDropdown';
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  GetState,
  GetCity
} from "react-country-state-city";
import PhoneInput from 'react-phone-input-2';



const RadiologistUsersCreateModel = ({
  createUser,
  updateUser,
  fetchUsers,
  setUsersList,
  user,
  editradiologistUserData,
  hidden,
  radiologyGroupList,
  addRadiologyGroupMember,
  deleteRadiologyGroupMember,
  uploadImage,
  defaultReportTemplates,
  countriesList
}) => {
  const { show: showSnackbar } = useSnackbar();


  const initialValues = {
    firstName: editradiologistUserData?.firstName || '',
    lastName: editradiologistUserData?.lastName || '',
    username: editradiologistUserData?.username || '',
    email: editradiologistUserData?.email || '',
    password: editradiologistUserData?.password || '',
    confirmPassword: editradiologistUserData?.password || '',
    // mobileNumber: editradiologistUserData?.attributes?.mobileNumber || '',
    radiologyGroup: editradiologistUserData?.attributes?.radiologyGroup || [],
    title: editradiologistUserData?.attributes?.title[0] || '',
    registrationNo: editradiologistUserData?.attributes?.registrationNo[0] || '',
    qualification: editradiologistUserData?.attributes?.qualification[0] || '',
    // uploadSignature: editradiologistUserData?.attributes?.uploadSignature || '',
    // templates: editradiologistUserData?.attributes?.templates || [],
    permission: editradiologistUserData?.attributes?.permission || '',
    street: editradiologistUserData?.attributes?.userStreet || '',
    district: editradiologistUserData?.attributes?.userDistrict || '',
    cityName: (editradiologistUserData?.attributes?.userCityid || [0]).map(Number) || '',
    stateName: (editradiologistUserData?.attributes?.userStateid || [0]).map(Number) || '',
    countryName: (editradiologistUserData?.attributes?.userCountryid || [0]).map(Number) || '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    username: Yup.string().required('User Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: !editradiologistUserData
      ? Yup.string().required('Password is required')
      : Yup.string(),
    confirmPassword: !editradiologistUserData
      ? Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
      : Yup.string(),
    // mobileNumber: Yup.number().required('Mobile Number is required'),
    title: Yup.string().required('Title is required'),
    // registrationNo: Yup.string().required('Registration No is required'),
    qualification: Yup.string().required('Qualification is required'),
    templates: Yup.array().min(1, 'Selection of templates is required'),
    // street: Yup.string().required('street is required'),
    // district: Yup.string().required('district is required'),
    // radiologyGroup: Yup.array().min(1).required('Select at least one Radiology Group is required'),
  });

  const options = [
    { label: 'Default', value: 'Default' },
    ...radiologyGroupList.map(group => ({
      label: group.name,
      value: group.name,
    }))
  ];

  const uniqueTemplateGroups = [
    ...Array.from(
      new Set(
        defaultReportTemplates
          .map(data => data.templategroup)
          .filter(templategroup => templategroup !== null && templategroup !== undefined)
      )
    )
  ];

  const optionsForTemplete = [
    { label: 'Select All', value: 'Select All' },
    ...uniqueTemplateGroups.map(templete => ({
      label: templete,
      value: templete,
    })),
    ...defaultReportTemplates.map(templete => ({
      label: templete.name,
      value: templete.name,
    }))
  ];
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    const countryid = (editradiologistUserData?.attributes?.userCountryid || [0]).map(Number);
    const stateid = (editradiologistUserData?.attributes?.userStateid || [0]).map(Number);

    GetState(countryid[0]).then((result) => {
      setStateList(result);
    });
    GetCity(countryid[0], stateid[0]).then((result) => {
      setCityList(result);
    });
  }, [])


  const initialSelectedOptions = editradiologistUserData?.attributes?.radiologyGroup || [];
  const [selectedOptions, setSelectedOptions] = useState(
    initialSelectedOptions.flatMap(option => option)
  );
  const initialSelectedCountry = (editradiologistUserData?.attributes?.userCountryid || [0]).map(Number);
  const initialSelectedState = (editradiologistUserData?.attributes?.userStateid || [0]).map(Number);
  const initialSelectedCities = (editradiologistUserData?.attributes?.userCityid || [0]).map(Number);
  // const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [signaturePrompt, setSignaturePrompt] = useState(false);

  const initialSelectedTemplates = editradiologistUserData?.attributes?.templates || []
  const initialSelectedPermission = editradiologistUserData?.attributes?.permission || []
  const [selectedTemplates, setSelectedTemplates] = useState(initialSelectedTemplates.flatMap(option => option.split(', ')));
  const [passwordeye, setpasswordeye] = useState(false);
  const [confirmPassword, setConfirmPasswordeye] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [files, setFiles] = useState(undefined);
  const [signature, setSignature] = useState(undefined);
  const [country, setCountry] = useState(initialSelectedCountry);
  const [state, setstate] = useState(initialSelectedState);
  const [city, setcity] = useState(initialSelectedCities);

  const [phoneNuber, setPhoneNuber] = useState(editradiologistUserData?.attributes?.mobileNumber[0] || '')
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAiSubmenu, setShowAiSubmenu] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState(initialSelectedPermission?.flatMap(option => option?.split(', ')));
  const DEFAULT_TEMPLATE_NAME = 'Default';

  const options2 = [
    {
      label: 'AI Assistance',
      value: 'ai-assistance',
      subOptions: [
        { value: 'Breast cancer', label: 'Breast cancer' },
        { value: 'Spine', label: 'Spine' },
        { value: 'Bone fracture', label: 'Bone fracture' },
        { value: 'Brain Stroke', label: 'Brain Stroke' },
        { value: 'Chest X-ray', label: 'Chest X-ray' },
      ],
    },
  ];


  const handleSelectionChange = selected => {
    setSelectedOptions(selected);
  };

  const handleTempletesSelectionChange = selected => {
    if (selected.includes('Select All')) {
      setSelectedTemplates(['Select All']);
    } else {
      setSelectedTemplates(selected);
    }
  };

  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);

  const hadlerUploadImageChange = (e) => {
    setSignaturePrompt(false)
    const file = e.target.files[0];

    setFiles(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }
  }
  const hadlerRemoveImage = () => {
    setFiles(null);
    setPreview(null);
    setSignature(null);
    setSuccess(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Clear the file input
    }
  }

  // Handle main option selection
  const handleSelectPermission = permission => {
    if (permission?.value === 'ai-assistance') {
      // Toggle AI Assistance submenu
      setShowAiSubmenu(!showAiSubmenu);
      const allAiSelected = options2?.find(opt => opt.value === 'ai-assistance')?.subOptions?.every(opt => selectedPermissions?.includes(opt.value));

      if (allAiSelected) {
        // Deselect all AI modules if currently selected
        setSelectedPermissions(
          selectedPermissions?.filter(p => !permission?.subOptions?.some(opt => opt?.value === p))
        );
      } else {
        // Select all AI modules
        setSelectedPermissions([
          ...new Set([
            ...selectedPermissions,
            ...(permission?.subOptions?.map(opt => opt?.value) || [])
          ])
        ]);
      }
    } else {
      // Toggle single permission
      setSelectedPermissions(prev => {
        if (prev.includes(permission.value)) {
          return prev.filter(p => p !== permission.value);
        }
        return [...prev, permission.value];
      });
    }
  };

  // Handle sub-option selection for AI modules
  const handleSelectAiModule = module => {
    setSelectedPermissions(prev => {
      if (prev.includes(module.value)) {
        return prev.filter(m => m !== module.value);
      }
      return [...prev, module.value];
    });
  };

  const transformUrl = (url) => {
    if (url.includes('prod-telerapp-attachments.s3.us-east-2.amazonaws.com')) {
      return url.replace(
        'https://prod-telerapp-attachments.s3.us-east-2.amazonaws.com',
        'https://documents.telerapp.com'
      );
    }
    return url;
  };

  const handlerImageUpload = async () => {
    try {
      if (files) {

        let imageUpload;
        if (editradiologistUserData) {
          const oldSignature = editradiologistUserData?.attributes?.uploadSignature[0];

          const removeImageName = oldSignature.split('/').pop();
          imageUpload = await uploadImage(files, removeImageName);

        } else {
          imageUpload = await uploadImage(files);
        }

        if (imageUpload.error) {
          setError(`Error uploading images:- ${imageUpload?.error}`);
        } else {
          setSignature(imageUpload);
          setSuccess("Signature Uploaded Successfully");
        }

      }
    } catch (error) {
      console.error("Error uploading images:", error);
      setError("Failed to upload images");
    }
  }


  const handleSubmit = async (values, { setSubmitting }) => {

    try {
      if (editradiologistUserData) {
        const data = {
          username: values.username,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          attributes: {
            radiologyGroup: selectedOptions.label || selectedOptions[0],
            roleType: ['Radiologist'],
            mobileNumber: phoneNuber,
            permission: selectedPermissions,
            registrationNo: values.registrationNo,
            title: values.title,
            qualification: values.qualification,
            uploadSignature: signature,
            templates: selectedTemplates,
            userStreet: values.street === initialValues.street ? values.street : [values.street],
            userDistrict: values.district === initialValues.district ? values.district : [values.district],
            userCityid: city[0]?.id ? [city[0]?.id] : [city[0]],
            userStateid: state[0]?.id ? [state[0]?.id] : [state[0]],
            userCountryid: country[0]?.id ? [country[0]?.id] : [country[0]],
            userCityName: city[0]?.name ? [city[0]?.name] : editradiologistUserData?.attributes?.userCityName,
            userStateName: state[0]?.name ? [state[0]?.name] : editradiologistUserData?.attributes?.userStateName,
            userCountryName: country[0]?.name ? [country[0]?.name] : editradiologistUserData?.attributes?.userCountryName,
          },
        };
        let response;
        if (signature) {
          data.attributes.uploadSignature = signature;
          response = await updateUser(editradiologistUserData.id, data, user.access_token);
        } else {
          data.attributes.uploadSignature = editradiologistUserData.attributes.uploadSignature;
          response = await updateUser(editradiologistUserData.id, data, user.access_token);
        }
        if (response.status === 204) {
          await fetchUsers(user.access_token)
            .then(data => setUsersList(data))
            .catch(error => console.error('Error fetching users:', error));
          showSnackbar({
            title: 'Success!',
            message: 'Successfully Radiologist User Update',
            type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
            position: 'topRight',
          });
        }
        const findGroup = radiologyGroupList.find(
          group => group.name === initialValues.radiologyGroup[0]
        );
        const findCurrentGroup = radiologyGroupList.find(
          group => group.name === selectedOptions.label
        );

        if (findCurrentGroup && findGroup.id !== findCurrentGroup.id) {
          addRadiologyGroupMember(
            editradiologistUserData.id,
            findCurrentGroup.id,
            user.access_token
          );
          deleteRadiologyGroupMember(editradiologistUserData.id, findGroup.id, user.access_token);
        }
        hidden();
      } else {
        const data = {
          username: values.username,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          attributes: {
            radiologyGroup: selectedOptions.label,
            roleType: ['Radiologist'],
            mobileNumber: phoneNuber,
            permission: selectedPermissions,
            registrationNo: values.registrationNo,
            title: values.title,
            qualification: values.qualification,
            uploadSignature: '',
            templates: selectedTemplates,
            userStreet: values.street === initialValues.street ? values.street : [values.street],
            userDistrict: values.district === initialValues.district ? values.district : [values.district],
            userCityid: city[0]?.id && [city[0]?.id],
            userStateid: state[0]?.id && [state[0]?.id],
            userCountryid: country[0]?.id && [country[0]?.id],
            userCityName: city[0]?.name && [city[0]?.name],
            userStateName: state[0]?.name && [state[0]?.name],
            userCountryName: country[0]?.name && [country[0]?.name],
          },
          credentials: [{ type: 'password', temporary: true, value: values.password }],
          enabled: true,
          emailVerified: true,
        };
        let response;
        if (signature) {
          data.attributes.uploadSignature = signature;
          response = await createUser(data, user.access_token);
          if (response.status === 201) {
            await fetchUsers(user.access_token)
              .then(async data => {
                setUsersList(data);
                const findeUser = data.find(items => items.email === values.email);
                const findGroup = radiologyGroupList.find(
                  group => group.name === selectedOptions.label
                );

                await addRadiologyGroupMember(findeUser.id, findGroup.id, user.access_token);
              })
              .catch(error => console.error('Error fetching users:', error));
            showSnackbar({
              title: 'Success!',
              message: 'Successfully Radiologist User Created',
              type: SnackbarTypes.SUCCESS,
              position: 'topRight',
            });
            hidden();
          } else {
            const errorMessage = await response.json();
            setError(errorMessage.errorMessage);
          }
        } else {
          setSignaturePrompt(true); // Set the state variable to show the prompt
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const setFieldValue = (e, value, name) => {

    if (name === 'mobileNumber') {
      let dialCode = value?.dialCode || '';
      let phoneNumber = e;
      let moblieNumber

      if (phoneNumber.startsWith(dialCode)) {
        // Remove the dial code from the phone number
        phoneNumber = phoneNumber.substring(dialCode.length);
        moblieNumber = `+${dialCode}${phoneNumber}`
      }

      setPhoneNuber(moblieNumber);
    }
  }


  return (
    <section className="w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2">
              <div>
                <Label className="my-2 block" text="">First Name</Label>
                <Field
                  type="text"
                  name="firstName"
                  as={Input}
                  placeholder="Please enter your first name"
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text="">Last Name</Label>
                <Field
                  type="text"
                  name="lastName"
                  as={Input}
                  placeholder="Please enter your last name"
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text="">User Name</Label>
                <Field
                  type="text"
                  name="username"
                  as={Input}
                  placeholder="Please enter your user name"
                  disabled={editradiologistUserData ? true : false}
                />
                <ErrorMessage
                  name="username"
                  component="div"
                  className="text-red-600"
                />
              </div>
              {/* <div>
                <Label className="my-2 block" text="">Mobile Number</Label>
                <Field
                  type="text"
                  name="mobileNumber"
                  as={Input}
                  placeholder="Please enter your mobile number"
                />
                <ErrorMessage
                  name="mobileNumber"
                  component="div"
                  className="text-red-600"
                />
              </div> */}
              <div className=' w-full'>
                <Label className='my-2 block' text=''>Mobile Number</Label>
                <PhoneInput
                  country={"us"}
                  value={editradiologistUserData?.attributes?.mobileNumber[0]}
                  onChange={(e, phone) => setFieldValue(e, phone, "mobileNumber")}
                  inputStyle={{ width: '100%', marginTop: '0.5rem', fontSize: '1.125rem', color: 'white', backgroundColor: 'black', borderColor: `rgb(58 63 153)` }}
                  containerStyle={{ width: '100%' }}
                />
              </div>
              <div>
                <Label className="my-2 block" text="">Email</Label>
                <Field
                  type="text"
                  name="email"
                  as={Input}
                  placeholder="Please enter your email name"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="passworld_box">
                <Label className="my-2 block" text="">Password</Label>
                <Field
                  type={passwordeye ? 'text' : 'password'}
                  name="password"
                  as={Input}
                  placeholder="Please enter your password"
                />
                <Icon
                  onClick={() => setpasswordeye(!passwordeye)}
                  name={passwordeye ? 'eye-visible' : 'eye-hidden'}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="passworld_box">
                <Label className="my-2 block" text="">Confirm Password</Label>
                <Field
                  type={confirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  as={Input}
                  placeholder="Please enter your Confirm Password "
                />
                <Icon
                  onClick={() => setConfirmPasswordeye(!confirmPassword)}
                  name={confirmPassword ? 'eye-visible' : 'eye-hidden'}
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text="">Title</Label>
                <Field
                  type="text"
                  name="title"
                  as={Input}
                  placeholder="Please enter your title"
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text="">Registration No</Label>
                <Field
                  type="text"
                  name="registrationNo"
                  as={Input}
                  placeholder="Please enter your registration no"
                />
                <ErrorMessage
                  name="registrationNo"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text="">Qualification</Label>
                <Field
                  type="text"
                  name="qualification"
                  as={Input}
                  placeholder="Please enter your qualification"
                />
                <ErrorMessage
                  name="qualification"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text="">Upload Signature</Label>
                <div className=' flex justify-between items-center'>
                  <input type="file" className='dark:bg-primary-dark bg-primary-light mr-5 shadow transition duration-300 appearance-none border border-secondary-dark dark:border-primary-main focus:border-inputfield-focus focus:outline-none disabled:border-inputfield-disabled rounded w-full py-2 px-3 text-sm dark:text-white text-black placeholder-inputfield-placeholder leading-tight' name='uploadSignature' accept='image/*' onChange={hadlerUploadImageChange} ref={fileInputRef}
                  />

                  <button type="button" className=' bg-primary-main box-content inline-flex flex-row items-center text-lg justify-center gap-[5px] justify center p-[8px] py-[5px] outline-none rounded font-semibold' onClick={handlerImageUpload}>Upload</button>
                </div>
                {editradiologistUserData && editradiologistUserData?.attributes?.uploadSignature[0] && <a target="_blank" className="dark:text-white text-black mb-2" href={transformUrl(`${editradiologistUserData.attributes.uploadSignature[0]}`)} >Preview</a>}
                <Label className={`my-2 mt-2 block h-3 pl-3 text-green-700 mb-2 ${success ? 'block' : 'hidden'}	`} text=''>{success ? success : ''}</Label>
                {signaturePrompt && (
                  <Label className="my-2 mt-2 block h-3 pl-3 text-red-600" text=''>
                    Please Upload Signature First
                  </Label>
                )}
                {
                  preview && (
                    <div className=' flex gap-4'>
                      <img
                        src={preview}
                        alt="Preview"
                        style={{ marginTop: '10px', maxWidth: '100%', height: '100px' }}
                      />
                      <div className=' flex items-center'>
                        <button type="button" onClick={hadlerRemoveImage} className=' bg-primary-main box-content inline-flex flex-row items-center text-sm justify-center gap-[5px] justify center p-[8px] py-[5px] outline-none rounded font-semibold' >Remove</button>
                      </div>
                    </div>
                  )
                }
                <ErrorMessage name="uploadSignature" component="div" className="text-red-600" />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block" text=""> Radiology Group</Label>
                <Field
                  name="radiologyGroup"
                  as={Select}
                  placeholder="Select Radiology Group"
                  options={options}
                  value={
                    selectedOptions
                  }
                  onChange={handleSelectionChange}
                />
                <ErrorMessage
                  name="radiologyGroup"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text=''> Select Templete</Label>
                <Field
                  name="templates"
                  as={InputMultiSelect}
                  placeholder="Select Report Templete"
                  options={optionsForTemplete}
                  value={
                    selectedTemplates
                  }
                  onChange={handleTempletesSelectionChange}
                />
              </div>
              <div className="form-dropdown">
                <label className="my-2 block">Permission</label>
                <Field
                  name="permission"
                  component={({ field }) => (
                    <PermissionDropdown
                      showDropdown={showDropdown}
                      setShowDropdown={setShowDropdown}
                      showAiSubmenu={showAiSubmenu}
                      setShowAiSubmenu={setShowAiSubmenu}
                      selectedPermissions={selectedPermissions}
                      setSelectedPermissions={setSelectedPermissions}
                      handleSelectPermission={handleSelectPermission}
                      handleSelectAiModule={handleSelectAiModule}
                      options={options2}
                      defaultPermission={editradiologistUserData?.attributes?.permission}
                    />
                  )}
                />
                <ErrorMessage
                  name="permission"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block" text=""> Country</Label>
                <CountrySelect
                  onChange={(e) => {
                    setCountry([e]);
                  }}
                  defaultValue={country[0]?.id ? country[0] : countriesList.find((item) => item.id === initialSelectedCountry[0])}
                  placeHolder="Select Country"
                />
                <ErrorMessage
                  name="countryName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block" text=""> State</Label>
                <StateSelect
                  countryid={country[0]?.id ? country[0].id : country[0]}
                  onChange={(e) => {
                    setstate([e]);
                  }}
                  defaultValue={state[0].id ? state[0] : stateList?.find((item) => item.id === initialSelectedState[0])}
                  placeHolder="Select State"
                />
                <ErrorMessage
                  name="stateName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block" text=""> City</Label>
                <CitySelect
                  stateid={state[0].id ? state[0].id : state[0]}
                  countryid={country[0]?.id ? country[0].id : country[0]}
                  onChange={(e) => {
                    setcity([e])
                  }}
                  defaultValue={city[0].id ? city[0] : cityList?.find((item) => item.id === initialSelectedCities[0])}
                  placeHolder="Select City"
                />
                <ErrorMessage
                  name="stateName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text={''}>District</Label>
                <Field type="text" name="district" as={Input} placeholder="Please enter your district" />
                <ErrorMessage name="district" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block" text={''}>Street</Label>
                <Field type="text" name="street" as={Input} placeholder="Please enter your street" />
                <ErrorMessage name="street" component="div" className="text-red-600" />
              </div>
            </div>
            <Button
              className="mt-8"
              disabled={isSubmitting}
            >
              {editradiologistUserData ? 'Update Radiologist User' : 'Create Radiologist User'}
            </Button>

            <div className=' text-center'>
              <Label className="my-2 mt-2 block h-3 pl-3 text-red-600	" text=''>{error}</Label>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default RadiologistUsersCreateModel;
