import React from 'react';

type Annotation = {
    class: string;
    confidence: number;
    bbox: number[]; // Including bounding box info if needed for future use
};

interface ChestAiInterfaceProps {
    fetchedData?: {
        image: string;
        normal_count: number;
        abnormal_count: number;
        annotations: Annotation[];
    };
}

// A mapping of annotation classes to their respective descriptions
const annotationDescriptions: { [key: string]: string } = {
    "Aortic enlargement": "Aorta is enlarged, suggesting possible aortic dilatation, aneurysm or unfolding.",
    "Cardiomegaly": "Cardiothoracic ratio is enlarged, consistent with cardiomegaly.",
    "Atelectasis": "Segmental/ sub segmental atelectasis is noted in the [right/left] lung, in the [upper/mid/lower] zone [with/without] reduced lung volume.",
    "Calcification": "[Few/Multiple] calcification/s identified in the [right/left] lung, in the [upper/mid/lower] zone, probably old granulomatous disease or prior infection.",
    "Consolidation": "[Focal /multifocal] air space opacity / consolidation [with/without] air bronchograms observed in the [right/left] lung, involving the [upper/mid/lower] zone, possibly indicative of pneumonia or other infiltrative process.",
    "Interstitial Lung Disease (ILD)": "Bilateral symmetric reticular/ reticulonodular opacities / honeycombing [with/without] basal predominance, suggestive of interstitial lung disease. No evidence of pneumothorax.",
    "Infiltration": "[Patchy /multifocal] infiltrates seen, in the upper/ mid/ lower zone probably due to infection, inflammation, or neoplastic etiology.",
    "Lung Opacity": "[Ill-defined/well defined] lung opacity with [smooth/irregular/ spiculated] margins and [with/without] calcifications noted in the [right/left] lung, in the [upper/mid/lower] zone, which may represent consolidation, mass, or other abnormality. Recommended CT for further workup.",
    "Nodule/Mass": "A solitary pulmonary nodule/mass with [smooth/irregular/ spiculated] margins and [with/without] calcifications is noted in the [right/left] lung, in the [upper/mid/lower] zone. Further evaluation with CT recommended for characterization.",
    "Other Lesion": "[Other lesion] identified in the [right/left] lung, in the [upper/mid/lower] zone, warranting further investigation with CT chest to determine etiology.",
    "Pleural Effusion": "[Right/ left / bilateral] Costophrenic angle blunting/ lower lobe opacity is noted, suggestive of minimal/ moderate/ massive pleural effusion. [Right/ left / bilateral] costophrenic angle blunting with homogenous opacity, suggestive of [moderate / large] pleural effusion",
    "Pleural Thickening": "[Smooth/irregular] [focal/diffuse] Pleural thickening [with/without] calcifications is observed, possibly related to chronic inflammation or fibrosis or asbestos exposure.",
    "Pneumothorax": "[Minimal/Moderate/Large/Tension] Pneumothorax is noted on [right/left/both] side [with/without] mediastinal shift and lung collapse. Follow up/ urgent intervention is warranted.",
    "Pulmonary Fibrosis": "[Right/left/both] lungs show reticular opacities and interstitial thickening [with/without] architectural distortion / volume loss, suggestive of pulmonary fibrosis."
};

const ChestAiInterface: React.FC<ChestAiInterfaceProps> = ({ fetchedData }) => {
    if (!fetchedData) return null; // Return nothing if no fetchedData

    const handleCopyImageDetails = () => {
        if (fetchedData.image) {
            const imageSrc = `data:image/png;base64,${fetchedData.image}`;

            // Create a hidden link element
            const link = document.createElement('a');
            link.href = imageSrc;
            link.download = 'result_image.png';  // Name of the file to download

            // Trigger the download by simulating a click on the link
            link.click();

            alert("Image download initiated!");
        } else {
            alert('No image to copy');
        }
    };


    // Function to copy findings to clipboard
    const handleCopyFindings = () => {
        const findingsText = generateFindingsTemplate(fetchedData.annotations); // Use the generateFindingsTemplate function

        // Remove <strong> and </strong> tags
        let cleanText = findingsText.replace(/<strong>/g, '').replace(/<\/strong>/g, '');

        // Replace multiple spaces (more than 2) with a single space
        // cleanText = cleanText.replace(/\s{2,}/g, ' ');

        // Copy the cleaned findings text to clipboard
        navigator.clipboard.writeText(cleanText).then(() => {
            alert("Findings copied to clipboard!");
        });
    };


    // Default Findings Map
    const defaultFindings = {
        "Aortic enlargement": "Negative for aorta.",
        "Cardiomegaly": "Negative for Cardiomegaly.",
        "Atelectasis": "",
        "Calcification": "",
        "Consolidation": "",
        "Interstitial Lung Disease (ILD)": "",
        "Infiltration": "",
        "Lung Opacity": "",
        "Nodule/Mass": "",
        "Other Lesion": "",
        "Pleural Effusion": "",
        "Pleural Thickening": "",
        "Pneumothorax": "",
        "Pulmonary Fibrosis": ""
        // "Atelectasis": "Negative for atelectasis.",
        // "Calcification": "Negative for calcification.",
        // "Consolidation": "No evidence of consolidation.",
        // "Interstitial Lung Disease (ILD)": "Negative for interstitial lung disease.",
        // "Infiltration": "Negative for infiltration.",
        // "Lung Opacity": "Negative for lung opacity.",
        // "Nodule/Mass": "Negative for nodule or mass.",
        // "Other Lesion": "No other lesions identified.",
        // "Pleural Effusion": "Pleural space with no evidence of fluid effusion.",
        // "Pleural Thickening": "Negative for pleural thickening.",
        // "Pneumothorax": "Negative for pneumothorax.",
        // "Pulmonary Fibrosis": "Negative for pulmonary fibrosis."
    };

    const impressionData: string[] = [];

    const generateDynamicDescription = (annotations: Annotation[]) => {
        if (!annotations || annotations.length === 0) {
            return { findings: {}, impression: [] };
        }

        const imageSize = 512; // Assuming a 512x512 image for bounding box calculation.

        // Helper functions for determining zones
        const getVerticalZone = (bbox: number[]) => {
            const yCenter = (bbox[1] + bbox[3]) / 2; // Center of the bounding box (Y-axis)
            if (yCenter <= imageSize / 3) return "upper";
            if (yCenter <= (2 * imageSize) / 3) return "mid";
            return "lower";
        };

        const getHorizontalZone = (bbox: number[]) => {
            const xCenter = (bbox[0] + bbox[2]) / 2; // Center of the bounding box (X-axis)
            return xCenter <= imageSize / 2 ? "left" : "right";
        };

        const findings: { [key: string]: string } = {};

        // This will hold the highest severity for each class
        const classSeverities: { [key: string]: string } = {
            severe: "severe",
            moderate: "Moderate",
            mild: "mild"
        };

        const highestSeverities: { [key: string]: string } = {};

        annotations.forEach((annotation) => {
            const cls = annotation.class;
            const descriptionTemplate = annotationDescriptions[cls] || "Description not available.";

            const zone = annotation.bbox
                ? `${getVerticalZone(annotation.bbox)} ${getHorizontalZone(annotation.bbox)}`
                : "zone not specified";

            const severity = annotation.confidence >= 0.85 ? "severe" : annotation.confidence >= 0.45 ? "moderate" : "mild";

            const severityGrade = classSeverities[severity];

            // Update the highest severity for the class
            if (!highestSeverities[cls] || highestSeverities[cls] === "mild" || severity === "severe") {
                highestSeverities[cls] = severityGrade;
            } else if (severity === "moderate" && highestSeverities[cls] !== "severe") {
                highestSeverities[cls] = "moderate"; // Update if the current severity is moderate but the class isn't already high
            }

            // Generate description using the template
            const description = descriptionTemplate
                .replace(/\[.*?right\/left.*?\]/, zone.includes("right") ? "right" : "left")
                .replace(/\[.*?upper\/mid\/lower.*?\]/, zone.includes("upper") ? "upper" : zone.includes("mid") ? "mid" : "lower")
                .replace(/\[.*?mild\/moderate\/severe.*?\]/, severity)
                .replace(/\[.*?present\/absent.*?\]/, annotation.confidence > 0.4 ? "present" : "absent");

            // Add description to findings
            findings[cls] = `${description}`;

            // Only add the class with its highest severity once
            if (impressionData.indexOf(`${highestSeverities[cls]} ${cls}`) === -1) {
                impressionData.push(`${highestSeverities[cls]} ${cls}`);
            }
        });

        return findings;
    };

    const generateFindingsTemplate = (annotations: Annotation[]) => {
        // Clear the impressionData before generating new ones
        impressionData.length = 0;

        const dynamicDescriptions = generateDynamicDescription(annotations);

        const findings = Object.keys(defaultFindings)
            .map((cls) => {
                // If dynamicDescriptions has a value, use it, else check defaultFindings value
                const description = dynamicDescriptions[cls] || defaultFindings[cls];

                // Only return the description if it's not an empty string
                return description.trim() !== "" ? description : null; // Return null if it's empty
            })
            .filter(Boolean) // This filters out any null (empty) values from the array
            .join("\n");

        const impressionText = impressionData.length > 0
            ? Array.from(new Map(impressionData.map(item => [item, item])).values()).join(", ")
            : "No significant abnormality detected.";

        const capitalizedImpressionText = impressionText.charAt(0).toUpperCase() + impressionText.slice(1);
        return `
            <strong>FINDINGS:</strong>
Radiographically clear lungs.
No evidence of consolidation.
Pleural space with no evidence of fluid effusion.
Negative for pneumothorax.
<strong>${findings || "No findings detected."}</strong>
Mediastinum and hila are unremarkable.
Soft tissues are unremarkable.
No evidence of free air.
Negative for radiodense foreign body.\n
<strong>IMPRESSION:</strong>
${capitalizedImpressionText || "No significant abnormality detected."}.
        `.trim();
    };


    return (
        <div className="chest-ai-interface">
            {/* Display Image Section */}
            {fetchedData.image && (
                <div style={{ marginBottom: '20px', position: 'relative' }}>
                    <img
                        src={`data:image/png;base64,${fetchedData.image}`}
                        alt="Chest AI Result"
                        style={{ width: '100%', maxHeight: '500px', objectFit: 'contain' }}
                    />
                    <button
                        title='Download image'
                        onClick={handleCopyImageDetails}
                        style={{
                            position: 'absolute',
                            top: '10px', // Positioned at the bottom right
                            right: '10px',  // Positioned at the right
                            padding: '5px 10px', // Smaller padding
                            backgroundColor: '#808080', // Gray background
                            color: '#FFFFFF',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            fontSize: '10px', // Very small font size
                            fontWeight: 'bold', // Bold to make it stand out slightly
                        }}
                    >
                        ⇩
                    </button>
                </div>
            )}

            {/* Display Annotations Section */}
            {fetchedData.annotations?.length > 0 && (
                <div className="annotations" style={{ position: 'relative' }}>
                    {/* Display Findings Template */}
                    <div style={{ marginTop: '20px' }}>
                        <pre style={{
                            whiteSpace: 'pre-wrap',  // Keeps the line breaks and spaces
                            background: '#f8f9fa',   // Light gray background
                            padding: '10px',         // Adjust padding for better look
                            borderRadius: '5px',     // Rounded corners
                            fontSize: '13px',        // Smaller font size
                            textAlign: 'left',       // Ensures left alignment
                            lineHeight: '1.4',
                            fontFamily: 'Arial, sans-serif',
                        }} dangerouslySetInnerHTML={{ __html: generateFindingsTemplate(fetchedData.annotations) }} />
                    </div>

                    {/* Copy Findings Button */}
                    <button
                        title='Copy results'
                        onClick={handleCopyFindings}
                        style={{
                            position: 'absolute',
                            top: '10px', // Positioned at the bottom right
                            right: '10px',  // Positioned at the right
                            padding: '5px 10px', // Smaller padding
                            backgroundColor: '#808080', // Gray background
                            color: '#FFFFFF',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            fontSize: '10px', // Very small font size
                            fontWeight: 'bold', // Bold to make it stand out slightly
                        }}
                    >
                        ❏
                    </button>

                </div>
            )}
        </div>
    );
};

export default ChestAiInterface;
