import React, { useEffect, useState } from 'react';
import Label from '../Label';
import Input from '../Input';
import Button from '../Button';
import Icon from '../Icon';
import InputMultiSelect from '../InputMultiSelect';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  GetState,
  GetCity
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import Select from '../Select';

const RadiologistGroupModel = ({ user, data, createRadiologyGroup, fetchRadiologyGroup, updateRadiologyGroup, setRadiologyGroupList, fetchSingleRadiologyGroup, singleRadiologyGroupList, setSingleRadiologyGroupList, groupIds, radiologyGroupList, hide, countriesList, }) => {
  const { show } = useSnackbar();

  const initialValues = {
    radiologyGroupName: data.name || '',
    email: data?.attributes?.groupEmail || '',
    mobileNumber: data?.attributes?.groupMobileNumber || '',
    aeTitle: data?.attributes?.groupAETitle || '',
    ip: data?.attributes?.groupIP || '',
    portNumber: data?.attributes?.groupPortNumber || '',
    // address: data?.attributes?.groupAddress || '',
    street: data?.attributes?.groupStreet || '',
    district: data?.attributes?.groupDistrict || '',
    cityName: (data?.attributes?.groupCityid || [0]).map(Number) || '',
    stateName: (data?.attributes?.groupStateid || [0]).map(Number)|| '',
    countryName: (data?.attributes?.groupCountryid || [0]).map(Number) || '',
  };

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    const countryid =  (data?.attributes?.groupCountryid || [0]).map(Number);
    const stateid =  (data?.attributes?.groupStateid || [0]).map(Number);

    GetState(countryid[0]).then((result) => {
      setStateList(result);
    });
    GetCity(countryid[0], stateid[0]).then((result) => {
      setCityList(result);
    });
  },[])

  // const validationSchema = Yup.object().shape({
  //   radiologyGroupName: Yup.string().required('Radiology group name is required'),
  //   email: Yup.string().email('Invalid email').required('Email is required'),
  //   mobileNumber: Yup.number().required('Mobile number is required'),
  //   aeTitle: Yup.array().required('AE Title is required'),
  //   ip: Yup.array().required('IP address is required'),
  //   portNumber: Yup.array().required('Port number is required'),
  //   address: Yup.array().required('Address is required'),
  // });

  const initialSelectedCountry = (data?.attributes?.groupCountryid || [0]).map(Number);
  const initialSelectedState = (data?.attributes?.groupStateid || [0]).map(Number);
  const initialSelectedCities = (data?.attributes?.groupCityid || [0]).map(Number);
  const [error, setError] = useState(null);

  const [country, setCountry] = useState(initialSelectedCountry);
  const [state, setstate] = useState(initialSelectedState);
  const [city, setcity] = useState(initialSelectedCities);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {

      if (data) {

        const dataDetails = {
          name: values.radiologyGroupName,
          attributes: {
            groupEmail: values.email === initialValues.email ? values.email : [values.email],
            groupMobileNumber:
              values.mobileNumber === initialValues.mobileNumber
                ? values.mobileNumber
                : [values.mobileNumber],
            groupAETitle:
              values.aeTitle === initialValues.aeTitle ? values.aeTitle : [values.aeTitle],
            // groupAddress:
            //   values.address === initialValues.address ? values.address : [values.address],
            groupPortNumber:
              values.portNumber === initialValues.portNumber
                ? values.portNumber
                : [values.portNumber],
            groupIP: values.ip === initialValues.ip ? values.ip : [values.ip],
            groupStreet: values.street === initialValues.street ? values.street : [values.street],
            groupDistrict: values.district === initialValues.district ? values.district : [values.district],
            groupCityid: city[0]?.id ? [city[0]?.id] : [city[0]],
            groupStateid: state[0]?.id ? [state[0]?.id] : [state[0]],
            groupCountryid: country[0]?.id ? [country[0]?.id] : [country[0]],
            groupCityName: city[0]?.name ? [city[0]?.name] : data?.attributes?.groupCityName,
            groupStateName: state[0]?.name ? [state[0]?.name] : data?.attributes?.groupStateName,
            groupCountryName: country[0]?.name ? [country[0]?.name] : data?.attributes?.groupCountryName,
          },
        }
        const response = await updateRadiologyGroup(data.id, dataDetails, user.access_token)
        if (response.status === 204) {
          await fetchSingleRadiologyGroup(user.access_token, groupIds)
            .then(data => setSingleRadiologyGroupList(data))
            .catch(error => console.error('Error fetching users:', error));

          await fetchRadiologyGroup(user.access_token)
            .then(data => setRadiologyGroupList(data))
            .catch(error => console.error('Error fetching users:', error));
          show({
            title: "Success!",
            message: "Successfully Radiology Group Update",
            type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
            position: "topRight"
          });
        }
        hide()
      } else {
        const dataDetails = {
          name: values.radiologyGroupName,
          attributes: {
            groupEmail: values.email === initialValues.email ? values.email : [values.email],
            groupMobileNumber:
              values.mobileNumber === initialValues.mobileNumber
                ? values.mobileNumber
                : [values.mobileNumber],
            groupAETitle:
              values.aeTitle === initialValues.aeTitle ? values.aeTitle : [values.aeTitle],
            // groupAddress:
            //   values.address === initialValues.address ? values.address : [values.address],
            groupPortNumber:
              values.portNumber === initialValues.portNumber
                ? values.portNumber
                : [values.portNumber],
            groupIP: values.ip === initialValues.ip ? values.ip : [values.ip],
            groupStreet: values.street === initialValues.street ? values.street : [values.street],
            groupDistrict: values.district === initialValues.district ? values.district : [values.district],
            groupCityid: city[0]?.id && [city[0]?.id],
            groupStateid: state[0]?.id && [state[0]?.id],
            groupCountryid: country[0]?.id&& [country[0]?.id],
            groupCityName: city[0]?.name && [city[0]?.name],
            groupStateName: state[0]?.name && [state[0]?.name],
            groupCountryName: country[0]?.name && [country[0]?.name],
          },
        }
        const response = await createRadiologyGroup(dataDetails, user.access_token)
        if (response.status === 201) {
          let filteredGroupIds
          await fetchRadiologyGroup(user.access_token)
            .then((data) => {
              filteredGroupIds = data
                .filter(group => !group.error)
                .map(group => group.id);
              setRadiologyGroupList(data)
            })
            .catch(error => console.error('Error fetching users:', error));

          await fetchSingleRadiologyGroup(user.access_token, filteredGroupIds)
            .then(data => setSingleRadiologyGroupList(data))
            .catch(error => console.error('Error fetching users:', error));
          show({
            title: "Success!",
            message: "Successfully Radiology Group Created",
            type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
            position: "topRight"
          });
        }
        hide()
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };


  return (
    <section className="w-full">

      <Formik initialValues={initialValues}  onSubmit={handleSubmit} >
        {({ isSubmitting }) => (
          <Form>
            <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2">
              <div>
                <Label className="my-2 block" text={''}>Radiology Group Name</Label>
                <Field type="text" name="radiologyGroupName" as={Input} placeholder="Please enter your radiology group name" />
                <ErrorMessage name="radiologyGroupName" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block" text={''}>Email</Label>
                <Field type="text" name="email" as={Input} placeholder="Please enter email" />
                <ErrorMessage name="email" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block" text={''}>Mobile Number</Label>
                <Field type="text" name="mobileNumber" as={Input} placeholder="Please enter mobile number" />
                <ErrorMessage name="mobileNumber" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block" text={''}>AE Title</Label>
                <Field type="text" name="aeTitle" as={Input} placeholder="Please enter your AE title" />
                <ErrorMessage name="aeTitle" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block" text={''}>IP</Label>
                <Field type="text" name="ip" as={Input} placeholder="Please enter your IP" />
                <ErrorMessage name="ip" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block" text={''}>Port Number</Label>
                <Field type="text" name="portNumber" as={Input} placeholder="Please enter your port number" />
                <ErrorMessage name="portNumber" component="div" className="text-red-600" />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block" text=""> Country</Label>
                <CountrySelect
                  onChange={(e) => {
                    setCountry([e]);
                  }}
                  defaultValue={country[0]?.id ? country[0] : countriesList.find((item) => item.id === initialSelectedCountry[0])}
                  placeHolder="Select Country"
                />
                <ErrorMessage
                  name="countryName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block" text=""> State</Label>
                <StateSelect
                  countryid={country[0]?.id ? country[0].id : country[0]}
                  onChange={(e) => {
                    setstate([e]);
                  }}
                  defaultValue={state[0].id ? state[0] : stateList?.find((item) => item.id === initialSelectedState[0])}
                  placeHolder="Select State"
                />
                <ErrorMessage
                  name="stateName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block" text=""> City</Label>
                <CitySelect
                  stateid={state[0].id ? state[0].id : state[0]}
                  countryid={country[0]?.id ? country[0].id : country[0]}
                  onChange={(e) => {
                    setcity([e])
                  }}
                  defaultValue={city[0].id ? city[0] : cityList?.find((item) => item.id === initialSelectedCities[0])}
                  placeHolder="Select City"
                />
                <ErrorMessage
                  name="stateName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text={''}>District</Label>
                <Field type="text" name="district" as={Input} placeholder="Please enter your district" />
                <ErrorMessage name="district" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block" text={''}>Street</Label>
                <Field type="text" name="street" as={Input} placeholder="Please enter your street" />
                <ErrorMessage name="street" component="div" className="text-red-600" />
              </div>
            </div>
            <div className="flex">
              <Button className="mt-8" disabled={isSubmitting}>
                {data ? 'Update Radiology  Group' : 'Create Radiology Group'}
              </Button>
              <Label className="my-2 block h-3 text-red-600 mt-9 pl-3	" text={''}>{error}</Label>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default RadiologistGroupModel;
