import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import getGridWidthClass from '../../utils/getGridWidthClass';
import { Accordion, Button, ButtonGroup } from 'rsuite';
import 'rsuite/Accordion/styles/index.css';
import './inputGroup.css';
import '../Select/Select.css';
import InputText from '../InputText';
import InputDateRange from '../InputDateRange';
import InputMultiSelect from '../InputMultiSelect';
import InputLabelWrapper from '../InputLabelWrapper';
import Select from '../Select';
import Typography from '../Typography';
import { useTranslation } from 'react-i18next';
import LegacyButton from '../LegacyButton';
import Icon from '../Icon';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useModal, useRadiologyGroup, useUser } from '../../contextProviders';
import UploadStudyModel from '../UploadStudy/UploadStudyModel';
import InputSelect from '../InputSelect';
import { BsPersonFillSlash } from 'react-icons/bs';
import { BsSearch } from 'react-icons/bs';
import AnonymizeStudyModel from '../AnonymizeStudy/AnonymizeStudyModel';
import { FaCheck, FaClock, FaPlus } from 'react-icons/fa';
import { LuFilterX } from 'react-icons/lu';
import Tooltip from '../Tooltip';
import { GrClearOption } from 'react-icons/gr';
import { CountrySelect, StateSelect } from 'react-country-state-city';
import 'react-country-state-city/dist/react-country-state-city.css';

const InputGroup = ({
  inputMeta,
  values,
  onValuesChange,
  sorting,
  onSortingChange,
  handleFilterStudies,
  displaySetList,
  isFiltering,
  clearFilters,
  otherData,
  isCriticalData,
  handleStudiesDelete,
  patientReportsDetails,
  selectedRows,
  token,
  user,
  setstateid,
  countryid,
  setCountryid,
  stateid,
}) => {
  const { show, hide } = useModal();
  const { sortBy, sortDirection } = sorting;
  const { t } = useTranslation('StudyList');
  const { radiologyGroupList } = useRadiologyGroup();

  const [selectedWorklistFilter, setSelectedWorklistFilter] = useState(displaySetList[1].value);
  const [studyFilterHeight, setStudyFilterHeight] = useState(false);
  const isWideScreen = window.innerWidth >= 640;
  const isLGWideScreen = window.innerWidth >= 1024;

  const {
    uploadStudy,
    setAllStudyList,
    setIsPostData,
    anonymizeStudies,
    anonymizeStudiesDeleteOrigin,
  } = useUser();

  useEffect(() => {
    handleFilterStudies(selectedWorklistFilter);
  }, [
    selectedWorklistFilter,
    handleFilterStudies,
    otherData,
    isCriticalData,
    patientReportsDetails,
  ]);

  const [activeKey, setActiveKey] = useState(0);

  const permissions = user?.profile?.permission;

  const hasDeleteStudyPermission =
    permissions?.includes('Delete Study') ||
    token?.realm_access?.roles.includes('super-admin') ||
    token?.realm_access?.roles.includes('deputy-admin');

  const hasUploadStudyPermission =
    permissions?.includes('Upload Study') ||
    token?.realm_access?.roles.includes('super-admin') ||
    token?.realm_access?.roles.includes('deputy-admin');

  const hasInstitutionFilter =
    (token?.roleType?.includes('Radiologist') && token?.radiologyGroup === 'Default') ||
    // token?.roleType?.includes('QaUsers') ||
    token?.roleType?.includes('TelerappManager') ||
    token?.realm_access?.roles.includes('super-admin') ||
    token?.realm_access?.roles.includes('deputy-admin') ||
    user?.profile?.roleType === 'DeputyAdmin';

  const handleFilterChange = e => {
    setSelectedWorklistFilter(e.value);
  };

  const handleFilterLabelClick = name => {
    let _sortDirection = 'descending';
    if (sortBy === name) {
      if (sortDirection === 'ascending') {
        _sortDirection = 'descending';
      } else if (sortDirection === 'descending') {
        _sortDirection = 'ascending';
      }
    }

    onSortingChange({
      sortBy: _sortDirection !== 'none' ? name : '',
      sortDirection: _sortDirection,
    });
  };

  const renderFieldInputComponent = ({ name, displayName, inputProps, isSortable, inputType }) => {
    const _isSortable = isSortable;
    const _sortDirection = sortBy !== name ? 'none' : sortDirection;

    // if (radiologyGroupList.length > 0 && inputProps?.options?.length === 0) {
    //   let allOption = {
    //     label: 'All Institutions',
    //     value: 'All Institutions',
    //   };

    //   inputProps?.options.push(
    //     allOption,
    //     ...(radiologyGroupList.length > 0 && radiologyGroupList?.map(group => ({
    //       label: group.name,
    //       value: group.name,
    //     })))
    //   );
    // }

    const onLabelClick = () => {
      handleFilterLabelClick(name);
    };

    const handleFieldChange = newValue => {
      onValuesChange({
        ...values,
        [name]: newValue,
      });
    };

    const handleSelectionChange = (event, inputMetaName) => {
      const formattedValue = event?.value;
      // ? event.value.split(/[\s,]+/)[0]  // Split by space or comma and take the first part
      // : '';
      const updatedValues = {
        ...values,
        [inputMetaName]: formattedValue ? [formattedValue] : [],
      };
      onValuesChange(updatedValues);
    };

    const handleDateRangeFieldChange = ({ startDate, endDate }) => {
      onValuesChange({
        ...values,
        [name]: {
          startDate: startDate,
          endDate: endDate,
        },
      });
    };

    switch (inputType) {
      case 'Text':
        return (
          <InputText
            id={name}
            key={name}
            label={displayName}
            isSortable={_isSortable}
            sortDirection={_sortDirection}
            onLabelClick={onLabelClick}
            value={values[name]}
            onChange={handleFieldChange}
          />
        );
      case 'MultiSelect':
        return (
          <InputMultiSelect
            id={name}
            key={name}
            label={displayName}
            isSortable={_isSortable}
            sortDirection={_sortDirection}
            onLabelClick={onLabelClick}
            value={values[name]}
            onChange={handleFieldChange}
            options={inputProps.options}
          />
        );
      case 'Select':
        return (
          <InputSelect
            id={name}
            key={name}
            label={
              inputProps?.options[0]?.value === 'All Institutions'
                ? inputProps?.options[0]?.value
                : displayName
            }
            isSortable={_isSortable}
            sortDirection={_sortDirection}
            onLabelClick={onLabelClick}
            value={values[name]}
            onChange={handleSelectionChange}
            options={inputProps.options}
            inputMetaname={name}
          />
        );
      case 'DateRange':
        return (
          <InputDateRange
            id={name}
            key={name}
            label={displayName}
            isSortable={_isSortable}
            sortDirection={_sortDirection}
            onLabelClick={onLabelClick}
            value={values[name]}
            onChange={handleDateRangeFieldChange}
            setStudyFilterHeight={setStudyFilterHeight}
          />
        );
      // case 'None':
      //   return (
      //     <InputLabelWrapper
      //       key={name}
      //       label={displayName}
      //       isSortable={_isSortable}
      //       sortDirection={_sortDirection}
      //       onLabelClick={onLabelClick}
      //     />
      //   );
      default:
        break;
    }
  };

  const order = [
    'studyDate',
    'modality',
    'priority',
    'patientName',
    'sex',
    'reportstatus',
    'accession',
    'mrn',
    'uid',
    'description',
    'institutionname',
    'general',
  ];

  const sortedInputMeta = inputMeta.sort((a, b) => {
    const aIndex = order.indexOf(a.name);
    const bIndex = order.indexOf(b.name);

    if (aIndex === -1 && bIndex === -1) return 0;
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;

    return aIndex - bIndex;
  });

  const handleChange = (event, inputMetaName) => {
    const { name, checked } = event.target;
    let updatedCheckboxes = [...values[inputMetaName]];

    // Define the "Others" modalities list
    const othersModalities = [
      'AR',
      'ASMT',
      'AU',
      'BDUS',
      'BI',
      'BMD',
      'CTPROTOCOL',
      'DG',
      'DOC',
      'ECG',
      'EPS',
      'ES',
      'FID',
      'GM',
      'HC',
      'HD',
      'IO',
      'IOL',
      'IVOCT',
      'IVUS',
      'KER',
      'KO',
      'LEN',
      'LS',
      'M3D',
      'OAM',
      'OCT',
      'OP',
      'OPM',
      'OPT',
      'OPTBSV',
      'OPTENF',
      'OPV',
      'OSS',
      'OT',
      'PLAN',
      'PR',
      'PX',
      'REG',
      'RESP',
      'RF',
      'RG',
      'RTDOSE',
      'RTIMAGE',
      'RTINTENT',
      'RTPLAN',
      'RTRAD',
      'RTRECORD',
      'RTSEGANN',
      'RTSTRUCT',
      'RWV',
      'SEG',
      'SM',
      'SMR',
      'SR',
      'SRF',
      'STAIN',
      'TEXTUREMAP',
      'TG',
      'VA',
      'XC',
    ];

    if (checked) {
      if (name === 'Others') {
        // If "Others" is checked, add all items in the "Others" list if not already present
        updatedCheckboxes = Array.from(
          new Set([...updatedCheckboxes, ...othersModalities, 'Others'])
        );
      } else {
        // Add the selected modality if it is not "Others"
        updatedCheckboxes.push(name);
      }
    } else {
      if (name === 'Others') {
        // If "Others" is unchecked, remove "Others" and all its associated modalities
        updatedCheckboxes = updatedCheckboxes.filter(
          item => !othersModalities.includes(item) && item !== 'Others'
        );
      } else {
        // Remove the unchecked modality
        updatedCheckboxes = updatedCheckboxes.filter(item => item !== name);
      }
    }
    // Update state with the modified checkboxes array
    onValuesChange({
      ...values,
      [inputMetaName]: updatedCheckboxes,
    });
  };

  const modelForUploadStudy = () => {
    show({
      content: UploadStudyModel,
      title: 'Upload To Worklist',
      contentProps: {
        hidden: hide,
        uploadStudy,
        setAllStudyList,
        setIsPostData,
      },
    });
  };

  const modelForAnonymizeStudy = () => {
    show({
      content: AnonymizeStudyModel,
      title: '',
      contentProps: {
        hidden: hide,
        setAllStudyList,
        anonymizeStudies,
        anonymizeStudiesDeleteOrigin,
        selectedRows,
        setIsPostData,
      },
    });
  };

  const getCountryDropDown = useMemo(() => {
    return (
      <CountrySelect
        // id={`countrySelected+${countryid?.id}`}
        key={`countrySelected+${countryid?.id}`}
        onChange={e => {
          setCountryid(e);
          // onCountryStateChange(e, 'countryid');
        }}
        // value={countryid}
        defaultValue={countryid ? countryid : ''}
        placeHolder="Select Country"
      />
    );
  }, [countryid]);
  const getStateDropDown = useMemo(() => {
    return (
      <StateSelect
        // id="stateSelected"
        key={`stateSelected+${stateid?.id}`}
        countryid={countryid.id}
        onChange={e => {
          setstateid(e);
          // onCountryStateChange(e, 'stateid');
        }}
        defaultValue={stateid ? stateid : ''}
        placeHolder="Select State"
      />
    );
  }, [stateid, countryid]);
  return (
    <div>
      <div className="mb-2 flex flex-wrap items-center justify-between">
        <div className="flex w-full flex-wrap items-center justify-between gap-4 lg:w-auto">
          {/* <Typography
            variant="h6"
            className="pointer text-white "
          >
            {t('WorkList')}
          </Typography> */}

          <div className="flex w-full flex-wrap items-center justify-between sm:gap-4 lg:w-auto">
            <div className="w-full sm:w-auto">
              <Select
                id="select_study"
                placeholder={'Worklist'}
                isClearable={false}
                onChange={handleFilterChange}
                options={displaySetList}
                value={[selectedWorklistFilter]}
                className="w-auto"
              />
            </div>
            {hasInstitutionFilter && (
              <div
                className={` ${sortedInputMeta.some(inputMeta => inputMeta.name === 'institutionname') ? 'w-full sm:w-[200px]' : ''}`}
              >
                {sortedInputMeta.map(inputMeta => {
                  return (
                    <div
                      key={inputMeta.name}
                      className={classnames('pb-2', {})}
                      style={{
                        width: inputMeta.name === 'institutionname' ? '100%' : '',
                        display: inputMeta.name === 'institutionname' ? 'block' : 'none',
                      }}
                    >
                      {renderFieldInputComponent(inputMeta)}
                    </div>
                  );
                })}
              </div>
            )}
            <div className="relative w-full sm:w-[200px]">
              <div className="pointer-events-none absolute top-5 left-0 flex items-center pl-3">
                <BsSearch className="h-3 w-3 text-black dark:text-white" />
              </div>
              <div>
                {sortedInputMeta.map(inputMeta => (
                  <div
                    id={inputMeta.name}
                    key={inputMeta.name}
                    className={classnames(`pb-2`, {})}
                    // className={classnames(`pb-2 ${isWideScreen ? 'min-[431px]:pr-5' : ''}`, {})}
                    style={{
                      width: inputMeta.name === 'general' ? '100%' : '',
                      display: inputMeta.name === 'general' ? 'block' : 'none',
                    }}
                  >
                    {renderFieldInputComponent(inputMeta)}
                  </div>
                ))}
              </div>
            </div>
            <div className="relative w-full max-sm:mt-2 sm:w-[200px]">{getCountryDropDown}</div>
            <div className="relative w-full max-sm:mt-4 sm:w-[200px]">{getStateDropDown}</div>
          </div>
        </div>
        <div className="my-2 flex w-full items-center gap-4 min-[431px]:justify-start lg:my-0 lg:w-auto lg:justify-end">
          <button
            className="dark:border-primary-main border-secondary-dark bg-secondary-light dark:bg-secondary-dark inline-flex items-center rounded-md border py-1 px-4 font-semibold text-black transition-all hover:opacity-60 dark:text-white"
            onClick={() => {
              clearFilters();
              setActiveKey(0);
              setCountryid('');
              setstateid('');
            }}
          >
            {/* {t('ClearFilters')} */}
            <Tooltip
              content={'Clear Filters'}
              position="bottom"
              style={{ padding: '8px', fontWeight: 'normal' }}
            >
              <GrClearOption className="text-xl" />
            </Tooltip>
          </button>

          {hasDeleteStudyPermission && (
            <button
              className={`hover:opacity-8 dark:border-primary-main border-secondary-dark bg-secondary-light dark:bg-secondary-dark inline-flex cursor-pointer items-center rounded-md border py-1 px-4 font-semibold text-black transition-all dark:text-white ${
                selectedRows.length === 0
                  ? 'cursor-not-allowed disabled:opacity-75'
                  : 'bg-secondary-light dark:bg-secondary-dark hover:opacity-60'
              }`}
              onClick={handleStudiesDelete}
              // disabled={headerCheckboxRef.current ? false : true}
              disabled={selectedRows.length === 0}
            >
              <Tooltip
                content={'Delete'}
                position="bottom"
                style={{ padding: '8px', fontWeight: 'normal' }}
              >
                <RiDeleteBin6Line className="text-xl" />
              </Tooltip>
              {/* Delete */}
            </button>
          )}
          <button
            className={`hover:opacity-8 dark:border-primary-main border-secondary-dark bg-secondary-light dark:bg-secondary-dark inline-flex cursor-pointer items-center rounded-md border py-1 px-4 font-semibold text-black transition-all dark:text-white ${
              selectedRows.length === 0
                ? 'cursor-not-allowed disabled:opacity-75'
                : 'bg-secondary-light dark:bg-secondary-dark hover:opacity-60'
            }`}
            onClick={modelForAnonymizeStudy}
            disabled={selectedRows.length === 0}
          >
            <Tooltip
              content={'Anonymize'}
              position="bottom"
              style={{ padding: '8px', fontWeight: 'normal' }}
            >
              <BsPersonFillSlash className="text-xl" />
            </Tooltip>
            {/* Anonymize */}
          </button>
          {hasUploadStudyPermission && (
            <button
              className="dark:border-primary-main border-secondary-dark bg-secondary-light dark:bg-secondary-dark inline-flex items-center rounded-md border py-1 px-4 font-semibold text-black transition-all hover:opacity-60 dark:text-white"
              onClick={modelForUploadStudy}
            >
              <Tooltip
                content={'Upload Study'}
                position="left"
                style={{ padding: '8px', fontWeight: 'normal' }}
              >
                <FaCloudUploadAlt className="text-xl" />
              </Tooltip>
              {/* Upload Study */}
            </button>
          )}
        </div>
      </div>

      <ButtonGroup
        className={`grid grid-flow-row grid-cols-3 gap-5 ${activeKey !== 0 ? 'mb-2' : ''}`}
      >
        {[1, 2, 3].map((key: any) => (
          <Button
            key={key}
            active={key === activeKey}
            onClick={() => (key === activeKey ? setActiveKey(0) : setActiveKey(key))}
            className="text-white"
          >
            <div className="rs-panel-group border-inputfield-main border">
              <div className="rs-panel-header rs-heading rs-panel-btn">
                {key === 1 ? (
                  <div className="rs-panel-btn">
                    <span
                      className="rs-panel-title text-sm sm:text-base"
                      style={{ fontWeight: 'normal' }}
                    >
                      Patient Filters
                    </span>
                    <svg
                      height="1em"
                      width="1em"
                      viewBox="0 0 21 32"
                      fill="currentColor"
                      aria-hidden="true"
                      focusable="false"
                      className="rs-panel-btn-icon rs-icon rs-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        transform: activeKey === 1 ? 'rotate(180deg)' : '',
                      }}
                    >
                      <path d="M19.196 13.143a.612.612 0 01-.179.411l-8.321 8.321c-.107.107-.268.179-.411.179s-.304-.071-.411-.179l-8.321-8.321c-.107-.107-.179-.268-.179-.411s.071-.304.179-.411l.893-.893a.582.582 0 01.411-.179c.143 0 .304.071.411.179l7.018 7.018 7.018-7.018c.107-.107.268-.179.411-.179s.304.071.411.179l.893.893a.617.617 0 01.179.411z" />
                    </svg>
                  </div>
                ) : key === 2 ? (
                  <div className="rs-panel-btn">
                    <span
                      className="rs-panel-title text-sm sm:text-base"
                      style={{ fontWeight: 'normal' }}
                    >
                      Study Filters
                    </span>
                    <svg
                      height="1em"
                      width="1em"
                      viewBox="0 0 21 32"
                      fill="currentColor"
                      aria-hidden="true"
                      focusable="false"
                      aria-label="angle down"
                      data-category="legacy"
                      data-testid="caret icon"
                      className="rs-panel-btn-icon rs-icon rs-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        transform: activeKey === 2 ? 'rotate(180deg)' : '',
                      }}
                    >
                      <path d="M19.196 13.143a.612.612 0 01-.179.411l-8.321 8.321c-.107.107-.268.179-.411.179s-.304-.071-.411-.179l-8.321-8.321c-.107-.107-.179-.268-.179-.411s.071-.304.179-.411l.893-.893a.582.582 0 01.411-.179c.143 0 .304.071.411.179l7.018 7.018 7.018-7.018c.107-.107.268-.179.411-.179s.304.071.411.179l.893.893a.617.617 0 01.179.411z" />
                    </svg>
                  </div>
                ) : (
                  <div className="rs-panel-btn">
                    <span
                      className="rs-panel-title text-sm sm:text-base"
                      style={{ fontWeight: 'normal' }}
                    >
                      Modality Filters
                    </span>
                    <svg
                      height="1em"
                      width="1em"
                      viewBox="0 0 21 32"
                      fill="currentColor"
                      aria-hidden="true"
                      focusable="false"
                      className="rs-panel-btn-icon rs-icon rs-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        transform: activeKey === 3 ? 'rotate(180deg)' : '',
                      }}
                    >
                      <path d="M19.196 13.143a.612.612 0 01-.179.411l-8.321 8.321c-.107.107-.268.179-.411.179s-.304-.071-.411-.179l-8.321-8.321c-.107-.107-.179-.268-.179-.411s.071-.304.179-.411l.893-.893a.582.582 0 01.411-.179c.143 0 .304.071.411.179l7.018 7.018 7.018-7.018c.107-.107.268-.179.411-.179s.304.071.411.179l.893.893a.617.617 0 01.179.411z" />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </Button>
        ))}
      </ButtonGroup>

      <div className="rcv-render">
        <Accordion
          activeKey={activeKey}
          bordered
          onSelect={(eventKey, event) => {
            if (typeof eventKey === 'number') {
              setActiveKey(eventKey);
            }
          }}
        >
          <Accordion.Panel eventKey={1}>
            <div className="grid grid-cols-2 gap-3 sm:grid-cols-3">
              {sortedInputMeta.map(inputMeta => (
                <div
                  key={inputMeta.name}
                  className={classnames('', {})}
                  style={{
                    width:
                      inputMeta.name === 'patientName' ||
                      inputMeta.name === 'sex' ||
                      inputMeta.name === 'uid'
                        ? '100%'
                        : '',
                    display:
                      inputMeta.name === 'patientName' ||
                      inputMeta.name === 'sex' ||
                      inputMeta.name === 'uid'
                        ? 'block'
                        : 'none',
                  }}
                >
                  {renderFieldInputComponent(inputMeta)}
                </div>
              ))}
            </div>
          </Accordion.Panel>

          <Accordion.Panel
            eventKey={2}
            className={`${studyFilterHeight ? 'custome-panel' : ''}`}
          >
            <div className="grid grid-cols-2 gap-3 sm:grid-cols-4">
              {sortedInputMeta.map(inputMeta => (
                <div
                  key={inputMeta.name}
                  className={classnames('', {})}
                  style={{
                    width: [
                      'studyDate',
                      'accession',
                      'description',
                      'priority',
                      'reportstatus',
                      'mrn',
                      'assign',
                    ].includes(inputMeta.name)
                      ? '100%'
                      : '',
                    display: [
                      'studyDate',
                      'accession',
                      'description',
                      'priority',
                      'reportstatus',
                      'mrn',
                      'assign',
                    ].includes(inputMeta.name)
                      ? 'block'
                      : 'none',
                  }}
                >
                  {renderFieldInputComponent(inputMeta)}
                </div>
              ))}
            </div>
          </Accordion.Panel>

          <Accordion.Panel eventKey={3}>
            <div className="flex justify-between">
              {sortedInputMeta.some(inputMeta => inputMeta.name === 'modality') ? (
                sortedInputMeta.map(inputMeta =>
                  inputMeta.name === 'modality' ? (
                    <div key={inputMeta.name}>
                      <div
                        className="grid"
                        style={{
                          gridTemplateColumns: isLGWideScreen
                            ? 'repeat(17, 1fr)'
                            : isWideScreen
                              ? 'repeat(11, 1fr)'
                              : 'repeat(4, 1fr)',
                        }}
                      >
                        {inputMeta?.inputProps?.options?.map(option => (
                          <div key={option.value}>
                            <input
                              type="checkbox"
                              id={option.value}
                              name={option.value}
                              checked={values[inputMeta.name]?.includes(option.value)}
                              onChange={e => handleChange(e, inputMeta.name)}
                              className="mx-1"
                            />
                            <label
                              className="line-clamp-1 text-sm text-black dark:text-white"
                              htmlFor={option.value}
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null
                )
              ) : (
                <span className="w-full text-center">Modality not selected</span>
              )}
            </div>
          </Accordion.Panel>
        </Accordion>
      </div>
    </div>
  );
};

InputGroup.propTypes = {
  inputMeta: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      inputType: PropTypes.oneOf(['Text', 'MultiSelect', 'DateRange', 'None']).isRequired,
      isSortable: PropTypes.bool.isRequired,
      gridCol: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]).isRequired,
      option: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  values: PropTypes.object.isRequired,
  onValuesChange: PropTypes.func.isRequired,
  sorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortDirection: PropTypes.oneOf(['ascending', 'descending', 'none']),
  }).isRequired,
  onSortingChange: PropTypes.func.isRequired,
  isSortingEnabled: PropTypes.bool.isRequired,
  setstateid: PropTypes.number,
  countryid: PropTypes.object,
  stateid: PropTypes.object,
  setCountryid: PropTypes.number,
};

export default InputGroup;
