// Reports.js
import React, { useContext, useEffect, useState } from 'react';
import { useAnalytics, useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Pie, PieChart, Cell, Legend, LineChart, Line, AreaChart, Area } from 'recharts';
import InputDateRange from '../InputDateRange';
import Label from '../Label';
import moment from 'moment';
import { ThemeContext } from '../ThemeWrapper/ThemeWrapper';


const Reports = ({ servicesManager, extensionManager, hotkeysManager }) => {
    const {
        fetchStudyCount, studyCount,
        fetchReportsByPriority, reportsByPriority,
        fetchReportsByModality, reportsByModality,
        fetchReportsByDocumentStatus, reportsByDocumentStatus,
        fetchApprovedReportsByModality, approvedReportsByModality,
        fetchReportsByDate, reportsByDate,
        fetchReportsByWeek, reportsByWeek,
        fetchModalityWiseAverageTATs, modalityWiseAverageTATs,
        setStartDate, setEndDate,
        startDate, endDate,
    } = useAnalytics();
    const { userAuthenticationService } = servicesManager.services;
    const user = userAuthenticationService.getUser();

    const currentuser = user?.profile?.roleType?.includes('RadiologyGroupAdmin')
    const radiologyGroupName = user?.profile?.radiologyGroup;

    // const radiologyGroupName = 'Utkarsh scan';
    const { show } = useSnackbar();
    const [error, setError] = useState(null);
    // const [selectedDateRange, setSelectedDateRange] = useState<any>({ startDate, endDate });
    const [selectedDateRange, setSelectedDateRange] = useState<any>({
        startDate,
        endDate,
        ...(currentuser && { radiologyGroupName })
    });
    const isMobileLayout = window.innerWidth < 640;
    const { theme } = useContext(ThemeContext);
    const [studyFilterHeight, setStudyFilterHeight] = useState(false);

    const handleDateRangeFieldChange = ({ startDate, endDate }) => {
        const start_date = moment(startDate).format('YYYY-MM-DD');
        const end_date = moment(endDate).format('YYYY-MM-DD');
        setSelectedDateRange({ start_date, end_date });
        setStartDate(start_date);
        setEndDate(end_date);
    };

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

    const fetchData = (startDate, endDate, radiologyGroupName) => {
        const reportsByPriorityParams = currentuser ? [startDate, endDate, radiologyGroupName] : [startDate, endDate];
        Promise.all([
            fetchStudyCount(),
            // fetchReportsByPriority(startDate, endDate),
            fetchReportsByPriority(...reportsByPriorityParams),
            // fetchReportsByModality(startDate, endDate),
            fetchReportsByModality(...reportsByPriorityParams),
            // fetchReportsByDocumentStatus(startDate, endDate),
            fetchReportsByDocumentStatus(...reportsByPriorityParams),
            // fetchApprovedReportsByModality(startDate, endDate),
            fetchApprovedReportsByModality(...reportsByPriorityParams),
            // fetchReportsByDate(startDate, endDate),
            fetchReportsByDate(...reportsByPriorityParams),
            // fetchReportsByWeek(startDate, endDate),
            fetchReportsByWeek(...reportsByPriorityParams),
            // fetchModalityWiseAverageTATs(startDate, endDate),
            fetchModalityWiseAverageTATs(...reportsByPriorityParams),
        ]).catch(error => setError(error));
    };

    const getLast7Days = () => {
        const today = new Date();
        const priorDate = new Date().setDate(today.getDate() - 7);

        return {
            startDate: new Date(priorDate).toISOString().split('T')[0],
            endDate: today.toISOString().split('T')[0],
        };
    };

    useEffect(() => {
        const { startDate, endDate } = getLast7Days();
        setSelectedDateRange({ startDate, endDate })
        setStartDate(startDate);
        setEndDate(endDate);
        fetchData(startDate, endDate, radiologyGroupName);
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            fetchData(startDate, endDate, radiologyGroupName);
            setSelectedDateRange({ startDate, endDate });
        }
    }, [startDate, endDate, radiologyGroupName]);

    useEffect(() => {
        if (error) {
            show({ message: error.message, type: SnackbarTypes.ERROR });
        }
    }, [error, show]);

    const transformData = (dataObj) => {
        return dataObj
            ? Object.keys(dataObj).map(key => ({
                name: key || 'Unknown',   // Handle null or undefined keys
                value: parseInt(dataObj[key], 10),  // Convert count to number
            }))
            : [];
    };

    const reportsByPriorityData = transformData(reportsByPriority);
    const reportsByModalityData = transformData(reportsByModality);
    const reportsByDocumentStatusData = transformData(reportsByDocumentStatus);
    const approvedReportsByModalityData = transformData(approvedReportsByModality);
    const reportsByDateData = transformData(reportsByDate);
    const reportsByWeekData = transformData(reportsByWeek);
    const modalityWiseAverageTATsData = transformData(modalityWiseAverageTATs);

    const CustomTooltip = ({ payload, label }) => {
        if (payload.length > 0) {
            return (
                <div className="custom-tooltip dark:text-white text-black" >
                    <p className="label" > {`Date: ${label}`
                    } </p>
                    < p className="value" > {`Value: ${payload[0].value}`
                    }</p>
                </div>
            );
        }
        return null;
    };

    const CustomTooltip2 = ({ payload }) => {
        if (payload.length > 0) {
            return (
                <div className="custom-tooltip dark:text-white text-black" >
                    <p className="value" > {`${payload[0].name}: ${payload[0].value}`
                    } </p>
                </div>
            );
        }
        return null;
    };

    return (

        <div className=" container mx-auto min-h-[500px] max-h-[550px] overflow-y-auto overflow-x-auto telerapp-scrollbar"
            style={{
                maxHeight: 'calc(100vh - 115px)',
                // scrollbarColor: '#173239 transparent',
            }}>
            <div className=' flex items-center lg:justify-between justify-start my-5 gap-5 max-lg:flex-col'>
                <div className=' flex items-center gap-3 w-full'>
                    <Label className="mt-2 max-sm:text-[16px] max-[375px]:text-[13px] dark:text-white text-black" text={''}>Date Range : </Label>
                    <InputDateRange
                        id={'report-analutic'}
                        value={selectedDateRange}
                        onChange={handleDateRangeFieldChange}
                        setStudyFilterHeight={setStudyFilterHeight}
                    />
                </div>
                < div className=' w-full lg:text-end text-start'>
                    <p
                        className="inline-flex items-center rounded border dark:border-primary-main border-secondary-dark bg-secondary-light dark:bg-secondary-dark py-2 px-4 font-semibold dark:text-white text-black transition-all opacity-80 mr-3 max-sm:text-[16px] max-[375px]:text-[13px]"
                    >
                        Study Count: {studyCount?.count || 0}
                    </p>

                </div>
            </div>


            < div className=' flex justify-between gap-5 mb-5 max-lg:flex-col'>
                <div className=' flex-1 bg-secondary-light dark:bg-secondary-dark border-secondary-dark dark:border-primary-main border p-4 rounded-md'>
                    <p className=' dark:text-[#5acce6] text-black max-sm:text-[16px] max-[375px]:text-[13px] '>Study by Priority </p>
                    {
                        <ResponsiveContainer width="100%" height={400}>
                            {reportsByPriorityData.length > 0 ? (
                                <BarChart data={reportsByPriorityData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                    <CartesianGrid strokeDasharray="3 3" stroke='#3a3f99 ' />
                                    <XAxis dataKey="name" stroke={theme === 'dark' ? "#fff" : "#000"} fontSize={isMobileLayout ? '12px' : '16px'} />
                                    <YAxis stroke={theme === 'dark' ? "#fff" : "#000"} allowDecimals={false} domain={[0, 'auto']} fontSize={isMobileLayout ? '12px' : '16px'} />
                                    <Tooltip content={CustomTooltip2} />
                                    <Bar dataKey="value" fill="#0088FEcd" />
                                </BarChart>
                            ) : (
                                <div className=' h-full flex justify-center items-center max-sm:text-[16px] max-[375px]:text-[13px] dark:text-white text-black'>Data not available.</div>
                            )}
                        </ResponsiveContainer>
                    }
                </div>
                < div className=' flex-1 bg-secondary-light dark:bg-secondary-dark border-secondary-dark dark:border-primary-main border p-4 rounded-md lg:mr-3'>
                    <p className=' dark:text-[#5acce6] text-black max-sm:text-[16px] max-[375px]:text-[13px]'>Approved Study by Modality </p>

                    <ResponsiveContainer width="100%" height={400} >
                        {approvedReportsByModalityData.length > 0 ? (
                            <BarChart data={approvedReportsByModalityData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" stroke='#3a3f99 ' />
                                <XAxis dataKey="name" stroke={theme === 'dark' ? "#fff" : "#000"} fontSize={isMobileLayout ? '12px' : '16px'} />
                                <YAxis stroke={theme === 'dark' ? "#fff" : "#000"} allowDecimals={false} domain={[0, 'auto']} fontSize={isMobileLayout ? '12px' : '16px'} />
                                < Tooltip content={CustomTooltip2} />
                                <Bar dataKey="value" fill="#00C49Fcd" />
                            </BarChart>
                        ) : (
                            <div className=' h-full flex justify-center items-center max-sm:text-[16px] max-[375px]:text-[13px] dark:text-white text-black'>Data not available.</div>
                        )}
                    </ResponsiveContainer>
                </div>
            </div>

            < div className=' flex justify-between gap-5 mb-5 max-lg:flex-col'>
                < div className=' flex-1 bg-secondary-light dark:bg-secondary-dark border-secondary-dark dark:border-primary-main border p-4 rounded-md'>
                    <p className=' dark:text-[#5acce6] text-black max-sm:text-[16px] max-[375px]:text-[13px]'>Study by Modality </p>

                    <ResponsiveContainer width="100%" height={400} >
                        {reportsByModalityData.length > 0 ? (
                            <PieChart>
                                <Pie
                                    data={reportsByModalityData}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={isMobileLayout ? 120 : 160}
                                    innerRadius={isMobileLayout ? 40 : 60}
                                // label={(entry) => `${entry.name}: ${entry.value}`
                                // }
                                >
                                    {
                                        reportsByModalityData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                </Pie>
                                < Tooltip content={CustomTooltip2} />
                                <Legend
                                    layout="horizontal"
                                    align="center"
                                    verticalAlign="bottom"
                                    wrapperStyle={{ bottom: 0, left: 0, right: 0, padding: 10, fontSize: `${isMobileLayout ? '12px' : '16px'}`}}
                                    payload={
                                        reportsByModalityData.map((entry, index) => ({
                                            id: entry.name,
                                            type: 'square',
                                            value: `${entry.name}: ${entry.value}`,
                                            color: COLORS[index % COLORS.length],
                                        }))
                                    }
                                />
                            </PieChart>
                        ) : (
                            <div className=' h-full flex justify-center items-center max-sm:text-[16px] max-[375px]:text-[13px] dark:text-white text-black'>Data not available.</div>
                        )}
                    </ResponsiveContainer>
                </div>
                <div className=' flex-1 bg-secondary-light dark:bg-secondary-dark border-secondary-dark dark:border-primary-main border p-4 rounded-md lg:mr-3'>
                    <p className=' dark:text-[#5acce6] text-black max-sm:text-[16px] max-[375px]:text-[13px]'>Study by Status </p>

                    <ResponsiveContainer width="100%" height={400} >
                        {reportsByDocumentStatusData.length > 0 ? (
                            <PieChart>
                                <Pie
                                    data={reportsByDocumentStatusData}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={isMobileLayout ? 120 : 160}
                                    innerRadius={isMobileLayout ? 40 : 60}
                                // label={(entry) => `${entry.name}: ${entry.value}`
                                // }
                                >
                                    {
                                        reportsByDocumentStatusData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                </Pie>
                                < Tooltip content={CustomTooltip2} />
                                <Legend
                                    layout="horizontal"
                                    align="center"
                                    verticalAlign="bottom"
                                    wrapperStyle={{ bottom: 0, left: 0, right: 0, padding: 10, fontSize: `${isMobileLayout ? '12px' : '16px'}` }}
                                    payload={
                                        reportsByDocumentStatusData.map((entry, index) => ({
                                            id: entry.name,
                                            type: 'square',
                                            value: `${entry.name}: ${entry.value}`,
                                            color: COLORS[index % COLORS.length],
                                        }))
                                    }
                                />
                            </PieChart>
                        ) : (
                            <div className=' h-full flex justify-center items-center max-sm:text-[16px] max-[375px]:text-[13px] dark:text-white text-black'>Data not available.</div>
                        )}
                    </ResponsiveContainer>
                </div>
            </div>

            < div className=' flex justify-between gap-5 mb-5'>
                <div className=' flex-1 bg-secondary-light dark:bg-secondary-dark border-secondary-dark dark:border-primary-main border p-4 rounded-md lg:mr-3'>
                    <p className=' dark:text-[#5acce6] text-black max-sm:text-[16px] max-[375px]:text-[13px]'>Study by Date </p>

                    <ResponsiveContainer width="100%" height={400} >
                        {reportsByDateData.length > 0 ? (
                            <AreaChart data={reportsByDateData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }
                            }>
                                <CartesianGrid strokeDasharray="3 3" stroke='#3a3f99 ' />
                                <XAxis dataKey="name" stroke={theme === 'dark' ? "#fff" : "#000"} fontSize={isMobileLayout ? '12px' : '16px'} />
                                <YAxis stroke={theme === 'dark' ? "#fff" : "#000"} allowDecimals={false} domain={[0, 'auto']} fontSize={isMobileLayout ? '12px' : '16px'} />
                                < Tooltip content={CustomTooltip} />
                                <Line type="monotone" dataKey="value" stroke="#5acce6" />
                                <Area type="monotone" dataKey="value" stroke="#5acce6" fill="#FFBB28" />
                            </AreaChart>
                        ) : (
                            <div className=' h-full flex justify-center items-center max-sm:text-[16px] max-[375px]:text-[13px] dark:text-white text-black'>Data not available.</div>
                        )}
                    </ResponsiveContainer>
                </div>
            </div>


            < div className=' flex justify-between gap-5 mb-5 max-lg:flex-col'>
                <div className=' flex-1 bg-secondary-light dark:bg-secondary-dark border-secondary-dark dark:border-primary-main border p-4 rounded-md'>
                    <p className=' dark:text-[#5acce6] text-black max-sm:text-[16px] max-[375px]:text-[13px]'>Study by Week </p>

                    <ResponsiveContainer width="100%" height={400} >
                        {reportsByWeekData.length > 0 ? (
                            <BarChart data={reportsByWeekData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }
                            }>
                                <CartesianGrid strokeDasharray="3 3" stroke='#3a3f99 ' />
                                <XAxis dataKey="name" stroke={theme === 'dark' ? "#fff" : "#000"} fontSize={isMobileLayout ? '12px' : '16px'} />
                                <YAxis stroke={theme === 'dark' ? "#fff" : "#000"} allowDecimals={false} domain={[0, 'auto']} fontSize={isMobileLayout ? '12px' : '16px'} />
                                < Tooltip content={CustomTooltip} />
                                <Bar dataKey="value" fill="#0088FEcd" />
                            </BarChart>
                        ) : (
                            <div className=' h-full flex justify-center items-center max-sm:text-[16px] max-[375px]:text-[13px] dark:text-white text-black'>Data not available.</div>
                        )}
                    </ResponsiveContainer>
                </div>

                < div className=' flex-1 bg-secondary-light dark:bg-secondary-dark border-secondary-dark dark:border-primary-main border p-4 rounded-md lg:mr-3'>
                    <p className=' dark:text-[#5acce6] text-black max-sm:text-[16px] max-[375px]:text-[13px]'>Modality Wise Average TAT (minutes) </p>

                    <ResponsiveContainer width="100%" height={400} >
                        {modalityWiseAverageTATsData.length > 0 ? (
                            <BarChart data={modalityWiseAverageTATsData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }
                            }>
                                <CartesianGrid strokeDasharray="3 3" stroke='#3a3f99 ' />
                                <XAxis dataKey="name" stroke={theme === 'dark' ? "#fff" : "#000"} fontSize={isMobileLayout ? '12px' : '16px'} />
                                <YAxis stroke={theme === 'dark' ? "#fff" : "#000"} allowDecimals={false} domain={[0, 'auto']} fontSize={isMobileLayout ? '12px' : '16px'} />
                                < Tooltip content={CustomTooltip2} />
                                <Bar dataKey="value" fill="#00C49Fcd" />
                            </BarChart>
                        ) : (
                            <div className=' h-full flex justify-center items-center max-sm:text-[16px] max-[375px]:text-[13px] dark:text-white text-black'>Data not available.</div>
                        )}
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};

export default Reports;
