import React, { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import { fetchDefaultTemplates, createPatientReports, fetchPatientReports, generateReportPdf, updatePatientReports, generateReportWord, deletePatientReport, fetchReportSetting, createReportSetting, updateReportSetting, deleteReportSetting, createDefaultTemplates, deleteDefaultTemplates, updateDefaultTemplates } from '../requestHandler';
import { fetchDefaultReportTemplates } from '../requestHandler/reportTemplateRequest';

export const ReportEditorContext = createContext(null);

export const ReportEditorProvider = ({ children, service, extension }) => {
  const [defaultTemplates, setDefaultTemplates] = useState('');
  const [defaultReportTemplates, setDefaultReportTemplates] = useState('');
  const [patientReportsDetails, setPatientReportsDetails] = useState('');
  const [reportSettingDetails, setReportSettingDetails] = useState('');
  const [reportEditorTemplate, setReportEditorTemplate] = useState('<p>Hello</p><p>World</p>');
  const [toggleDisplayReportEditor, setToggleDisplayReportEditor] = useState(false);

  const activeDataSource = extension.activeDataSource;
  const dataSource = extension.dataSourceMap[activeDataSource][0];
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalTemplates, setTotalTemplates] = useState(0)

  useEffect(() => {
    fetchDefaultTemplates(currentPage, resultsPerPage, searchTerm)
      .then(data => {
        setDefaultTemplates(data)
        setTotalTemplates(data.totalTemplates)
        setTotalPages(data.totalPages);
      })
      .catch(error => console.error('Error fetching default templates:', error));
  }, [currentPage, resultsPerPage, totalPages, searchTerm]);

  useEffect(() => {
    fetchDefaultReportTemplates()
      .then(data => setDefaultReportTemplates(data))
      .catch(error => console.error('Error fetching default templates:', error));

    fetchPatientReports()
      .then(data => setPatientReportsDetails(data))
      .catch(error => console.error('Error fetching patient details:', error));

    fetchReportSetting()
      .then(data => setReportSettingDetails(data))
      .catch(error => console.error('Error fetching report setting details:', error));
  }, []);

  const getCurrentPatient = useCallback(async (params, query, studyInstanceUid) => {
    if (params.pathname.includes('report-editor')) {
      dataSource.initialize({ params, query });
    }

    return await dataSource.query.studies.search({ studyInstanceUid });
  }, []);


  const value = useMemo(
    () => ({
      defaultTemplates,
      currentPage, setCurrentPage,
      resultsPerPage, setResultsPerPage,
      totalPages,
      setTotalTemplates,
      setDefaultTemplates,
      reportEditorTemplate,
      setReportEditorTemplate,
      toggleDisplayReportEditor,
      setToggleDisplayReportEditor,
      getCurrentPatient,
      dataSource,
      createPatientReports,
      patientReportsDetails,
      generateReportPdf,
      updatePatientReports,
      setPatientReportsDetails,
      generateReportWord,
      deletePatientReport,
      reportSettingDetails,
      setReportSettingDetails,
      createReportSetting,
      updateReportSetting,
      deleteReportSetting,
      createDefaultTemplates,
      fetchDefaultTemplates,
      searchTerm,
      setSearchTerm,
      totalTemplates,
      deleteDefaultTemplates,
      updateDefaultTemplates,
      defaultReportTemplates,
      setDefaultReportTemplates
    }),
    [reportEditorTemplate, toggleDisplayReportEditor, defaultTemplates, patientReportsDetails, reportSettingDetails, defaultReportTemplates]
  );

  return <ReportEditorContext.Provider value={value}>{children}</ReportEditorContext.Provider>;
};

export const useReportEditor = () => useContext(ReportEditorContext);
