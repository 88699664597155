import React, { useEffect, useRef, useState } from 'react';
import { useSnackbar } from '../../contextProviders';
import { GoDotFill } from 'react-icons/go';
import { Loader } from 'rsuite';
import 'rsuite/Loader/styles/index.css';
import { RxCross2 } from 'react-icons/rx';


const RadiologistUserActiveDetailsModel = ({ radiologistData, fetchSessionsRadiologistUser, user, radiologistUser, togglePopup }) => {


  const [session, setSession] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSessionsRadiologistUser(user.access_token, radiologistUser)
      .then(data => setSession(data))
      .catch(error => console.error('Error fetching radiology user session:', error));

    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const activeUsernames = Array.from(new Set(session.flat().map(user => user.username)));
  // Convert Set to array

  const activeRadiologistData = activeUsernames.map(username => {
    // Get the modalities data for the username or an empty object if not found
    const modalities = radiologistData[username]?.modalities || {};

    // Combine modality names into a single string
    const combinedModality = Object.keys(modalities).join(', ');

    // Calculate total approved and pending counts using reduce
    const totalApproved = Object.values(modalities).reduce((sum, modality) => sum + modality.approved, 0);
    const totalPending = Object.values(modalities).reduce((sum, modality) => sum + modality.pending, 0);


    return {
      username,
      modality: combinedModality,
      approved: totalApproved,
      pending: totalPending,
    };
  });


  const tableHeaders = {
    liveStatus: 'Status',
    radiologist: 'Radiologist',
    modality: 'Modality',
    approved: 'Approved',
    pending: 'Pending'
  };

  const keys = Object.keys(tableHeaders);
  const values = Object.values(tableHeaders);


  return (
    <div
      className="fixed right-10 top-80 bg-primary-dark p-6 rounded-lg shadow-lg z-50 border border-secondary-light"
      style={{ minWidth: '400px', height: '500px', overflowY: 'auto' }}
    // Fixed height and enable scrolling
    >
      <header className=" bg-primary-dark flex items-center rounded-tl rounded-tr pb-2">
        <h6 className="  text-primary-light m-0 flex grow text-xl leading-tight !leading-[1.2]">
          Online Radiologists
        </h6>
        <RxCross2 onClick={togglePopup} className=' text-primary-light text-xl' />
      </header>

      {activeRadiologistData.length > 0 ? (
        <table className="container m-auto mt-2 table-auto text-white w-full">
          <thead className="bg-primary-dark border-secondary-light border">
            <tr>
              {values.map(tableHead => (
                <th className="p-2 text-[13px]" key={tableHead}>
                  {tableHead}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {activeRadiologistData.map((user, userIndex) => (
              <tr className="bg-primary-dark border-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer border text-center transition duration-300">
                {keys.map(key => (<td className="p-2 text-[13px]"
                  key={key}>
                  {key === 'liveStatus' ? (
                    <span>
                      <GoDotFill
                        fontSize={37}
                        style={{ color: 'lime' }}
                        className="ml-5"
                      />
                    </span>
                  ) : key === 'radiologist' ? (
                    <span className="cursor-pointer">
                      {user?.username}
                    </span>
                  ) : key === 'modality' ? (
                    <span className="text-wrap flex w-[45px] cursor-pointer flex-wrap justify-center break-all">
                      {user?.modality?.split(',')?.map((item, index) => (
                        <span key={index}>
                          {item},
                          <br />
                        </span>
                      ))}
                    </span>
                  ) : key === 'approved' ? (
                    <span className="cursor-pointer">
                      {user.approved}
                    </span>
                  ) : key === 'pending' ? (
                    <span className="cursor-pointer">
                      {user.pending}
                    </span>
                  ) : (
                    user[key]
                  )}
                </td>))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className=' w-full h-48 bg-primary-dark border-secondary-light flex items-center justify-center border p-4  text-white'>
          {loading ? (
            <Loader size="sm" color='#fff' content="loading..." />
          ) : (
            'User not available'
          )}
        </div>
      )
      }

    </div>
  );
};

export default RadiologistUserActiveDetailsModel;
