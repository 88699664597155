import React, { ReactNode, useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import { hotkeys } from '@ohif/core';
import { useReportEditor, useUser, useModal } from '../../contextProviders';

import NavBar from '../NavBar';
import Svg from '../Svg';
import Icon from '../Icon';
import IconButton from '../IconButton';
import Dropdown from '../Dropdown';
import { BiSolidDashboard, BiSolidUserCheck } from "react-icons/bi";
import { FaClipboardList, FaUserCog, FaUserTie, FaUserMd, FaPowerOff, FaChartLine } from "react-icons/fa";
import { HiDocumentDuplicate, HiUserGroup, HiUsers } from "react-icons/hi2";
import { FaChalkboardUser, FaUsersGear, FaXRay } from "react-icons/fa6";
import UploadModal from '../AiIntegration/AiTool';
import { SendtoML } from '../AiIntegration/SendtoML';
import { getModels, filterModels } from '../AiIntegration/modelUtils';
import { IoMdSettings } from 'react-icons/io';
import WorklistModel from '../WorklistModel';
import { IoInformationCircle } from 'react-icons/io5';
import AboutModal from '../AboutModal';
import UserPreferences from '../UserPreferences';
import { useAppConfig } from '@state';
import i18n from '@ohif/i18n';
import filtersMeta from '../../../../app/src/routes/WorkList/filtersMeta';
import { RiArrowDropDownLine, RiFileSettingsFill } from 'react-icons/ri';
import { MdAccountBalance, MdChangeCircle, MdDarkMode, MdKeyboardArrowDown, MdKeyboardArrowUp, MdLightMode, MdOutlineFullscreen, MdOutlineFullscreenExit, MdOutlineSettingsBrightness, MdSummarize } from 'react-icons/md';
import { SiStatuspal } from 'react-icons/si';
import { AiOutlineAudit } from 'react-icons/ai';
import './aiButtonStyle.css';
import { ThemeContext } from '../ThemeWrapper/ThemeWrapper';

import HeaderPatientInfo from '../HeaderPatientInfo';
import { PatientInfoVisibility } from '../../types/PatientInfoVisibility';
import Tooltip from '../Tooltip';

function Header({
  children,
  menuOptions,
  isReturnEnabled = true,
  onClickReturnButton,
  isSticky = false,
  WhiteLabeling,
  showPatientInfo = PatientInfoVisibility.VISIBLE_COLLAPSED,
  loggedInUserDetails,
  isReportEnabled,
  sortedStudies,
  servicesManager,
  hotkeysManager,
  headerOpen,
  Secondary,
  ...props
}: withAppTypes): ReactNode {
  const { hotkeyDefinitions, hotkeyDefaults } = hotkeysManager;
  const { t } = useTranslation('Header');
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { setToggleDisplayReportEditor, setModelUrl, setIsModelOpen, isModelOpen } = useReportEditor();
  const [appConfig] = useAppConfig();
  const { show: showModal, hide } = useModal();
  const [showModelMenu, setShowModelMenu] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef(null);
  const { availableLanguages, defaultLanguage, currentLanguage } = i18n;
  const {
    userToken,
    token,
    setToken,
  } = useUser();

  const subOptions = [
    { value: 'Breast cancer', label: 'Breast cancer' },
    { value: 'Spine', label: 'Spine' },
    { value: 'Bone fracture', label: 'Bone fracture' },
    { value: 'Brain Stroke', label: 'Brain Stroke' },
    { value: 'Chest X-ray', label: 'Chest X-ray' },
  ]
  const filteredValues = subOptions
    ?.map(option => option?.value) // Get only the values from subOptions
    ?.filter(value => token?.permission?.includes(value));
  const versionNumber = process.env.VERSION_NUMBER;
  const commitHash = process.env.COMMIT_HASH;

  const location = useLocation();
  const isWorklist = location.pathname !== '/viewer';
  const { theme, toggleTheme } = useContext(ThemeContext);

  const [isVisible, setIsVisible] = useState(window.innerWidth > 1400);

  useEffect(() => {
    const handleResize = () => {
      setIsVisible(window.innerWidth > 1400);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const getToken = async () => {
      try {
        const data = {
          token: loggedInUserDetails.access_token,
        };
        const response = await userToken(data);
        setToken(response);
      } catch (error) {
        console.log(error);
      }
    };
    getToken();
  }, []);

  // TODO: this should be passed in as a prop instead and the react-router-dom
  // dependency should be dropped
  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      onClickReturnButton();
      setToggleDisplayReportEditor((show: boolean) => !show);
    }
  };

  const toggleDisplayReportEditorView = () => {
    setToggleDisplayReportEditor((show: boolean) => !show);
    setIsModelOpen(false)
  }


  const permissions = loggedInUserDetails?.profile?.permission;
  const hasAssignPermission =
    permissions?.includes('Access Dashboard') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin')

  const hasPermissonOfConnetivity = permissions?.includes('Access Dashboard') && loggedInUserDetails?.profile?.roleType?.includes('RadiologyGroupAdmin')

  const hasAddRadiologistPermission =
    permissions?.includes('Add Radiologist') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const hasCreateRadiologyGroupPermission =
    permissions?.includes('Create Radiology Group') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const hasCreateRadiologyGroupAdminPermission =
    permissions?.includes('Add Radiology Group Admin') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin') ||
    loggedInUserDetails?.profile?.roleType?.includes('RadiologyGroupAdmin');

  const hasAddTelerappManagerPermission =
    permissions?.includes('Add Telerapp Manager') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const hasAddTechnologistPermission =
    permissions?.includes('Add Radiology Group Technologist') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin') ||
    loggedInUserDetails?.profile?.roleType?.includes('RadiologyGroupAdmin');

  const hasQaUserPermission =
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const hasPhysicianPermission =
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin') ||
    loggedInUserDetails?.profile?.roleType?.includes('RadiologyGroupAdmin');

  const hasAllUsersPermission =
    permissions?.includes('All Users Tab Access') ||
    loggedInUserDetails?.profile?.roleType?.includes('RadiologyGroupAdmin') ||
    token?.realm_access?.roles?.includes('TelerappManager') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const hasDeputyAdminPermission = token?.realm_access?.roles?.includes('super-admin');

  const hasUserPermission =
    permissions?.includes('Add Telerapp Manager') ||
    permissions?.includes('All Users Tab Access') ||
    permissions?.includes('Add Radiology Group Technologist') ||
    permissions?.includes('Create Radiology Group') ||
    permissions?.includes('Add Radiologist') ||
    permissions?.includes('Access Dashboard') ||
    loggedInUserDetails?.profile?.roleType?.includes('RadiologyGroupAdmin') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  // const hasAdminAndDeputyAdminPermission =
  //   token?.realm_access?.roles.includes('super-admin') ||
  //   loggedInUserDetails?.profile?.roleType.includes('DeputyAdmin') ||
  //   token?.realm_access?.roles.includes('deputy-admin');

  const hasAdminAndDeputyAdminPermission =
    Array.isArray(token?.realm_access?.roles) && token.realm_access.roles.includes('super-admin') ||
    (loggedInUserDetails?.profile?.roleType?.includes && loggedInUserDetails.profile.roleType.includes('DeputyAdmin')) ||
    Array.isArray(token?.realm_access?.roles) && token.realm_access.roles.includes('deputy-admin');


  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [isDashboardDropdownOpen, setIsDashboardDropdownOpen] = useState(false);
  const [isSettingDropdownOpen, setIsSettingDropdownOpen] = useState(false);
  const [isRadiologyDropdownOpen, setIsRadiologyDropdownOpen] = useState(false);

  // Function to handle click for the User menu
  const handleUserClick = () => {
    setIsDashboardDropdownOpen(false); // Close Dashboard dropdown
    setIsSettingDropdownOpen(false); // Close Setting dropdown
    setIsRadiologyDropdownOpen(false); // Close radiology dropdown
    setIsUserDropdownOpen((prev) => !prev); // Toggle User dropdown
  };

  // Function to handle click for the Dashboard menu
  const handleDashboardClick = () => {
    setIsUserDropdownOpen(false); // Close User dropdown
    setIsSettingDropdownOpen(false); // Close Setting dropdown
    setIsRadiologyDropdownOpen(false); // Close radiology dropdown
    setIsDashboardDropdownOpen((prev) => !prev); // Toggle Dashboard dropdown
  };

  // Function to handle click for the Setting menu
  const handleSettingClick = () => {
    setIsUserDropdownOpen(false); // Close User dropdown
    setIsDashboardDropdownOpen(false); // Close Dashboard dropdown
    setIsRadiologyDropdownOpen(false); // Close radiology dropdown
    setIsSettingDropdownOpen((prev) => !prev); // Toggle Setting dropdown
  };

  const handleRadiologyClick = () => {
    setIsSettingDropdownOpen(false); // Close setting dropdown
    setIsDashboardDropdownOpen(false); // Close Dashboard dropdown
    setIsRadiologyDropdownOpen((prev) => !prev); // Toggle radiology dropdown
  };

  const toggleUserDropdown = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
  };

  const dashboardItems = [
    hasAssignPermission && {
      label: 'Dashboard',
      icon: <BiSolidDashboard />,
      submenu: [
        !hasPermissonOfConnetivity && {
          label: 'Connectivity Status',
          link: '/dashboard/connectivity-status',
          icon: <SiStatuspal />
        },
        {
          label: 'Users Status',
          link: '/dashboard/users-status',
          icon: <BiSolidUserCheck />
        },
        // {
        //   label: 'Upload Status',
        //   link: '/dashboard/upload-status',
        //   icon: <FaUpload />
        // },
        {
          label: 'Analytics',
          link: '/dashboard/analytics',
          icon: <FaChartLine />
        },
        // {
        //   label: 'Message Status',
        //   link: '/dashboard/message-status',
        //   icon: <BiSolidMessageCheck />
        // },
        {
          label: 'Audit Logs',
          link: '/dashboard/audit-logs',
          icon: <AiOutlineAudit />
        },
        {
          label: 'Report Template',
          link: '/dashboard/report-template',
          icon: <HiDocumentDuplicate />
        },
        // {
        //   label: 'Invoice',
        //   link: '/dashboard/invoice',
        //   icon: <FaFileInvoiceDollar />
        // },
      ].filter(Boolean),
    },
  ].filter(Boolean);


  const menuItems = [
    // loggedInUserDetails?.profile?.roleType?.includes('RadiologyGroupAdmin') ?
    //   hasAssignPermission && {
    //     label: 'Dashboard',
    //     link: '/dashboard/users-status',
    //     icon: <BiSolidDashboard />,
    //   } :
    //   hasAssignPermission && !hasPermissonOfConnetivity && {
    //     label: 'Dashboard',
    //     link: '/dashboard/connectivity-status',
    //     icon: <BiSolidDashboard />,
    //   },
    // hasAssignPermission && {
    //   label: 'Dashboard',
    //   link: '/dashboard/connectivity-status',
    //   icon: <BiSolidDashboard />,
    // },
    {
      label: 'WorkList',
      link: '/',
      icon: <FaClipboardList />,
    },
    hasUserPermission && {
      label: 'Users',
      icon: <FaUserCog />,
      submenu: [
        hasAllUsersPermission && {
          label: 'All Users',
          link: '/users/all-users',
          icon: <HiUsers />
        },
        hasDeputyAdminPermission && {
          label: 'Deputy Admin',
          link: '/users/deputy-admin',
          icon: <FaUserCog />,
        },
        hasAddTelerappManagerPermission && {
          label: 'Telerapp Manager',
          link: '/users/telerapp-manager',
          icon: <FaUsersGear />,
        },
        hasAddRadiologistPermission && {
          label: 'Radiologist',
          link: '/users/radiologist-users',
          icon: <FaXRay />,
        },
        hasQaUserPermission && {
          label: 'QA User',
          link: '/users/qa-user',
          icon: <FaUserTie />,
        },
        {
          label: 'Radiology Group',
          icon: <HiUserGroup />,
          submenu: [
            hasCreateRadiologyGroupPermission && {
              label: 'Institutions',
              link: '/users/radiology-group-user',
              icon: <HiUserGroup />,
            },
            hasCreateRadiologyGroupAdminPermission && {
              label: 'Admin',
              link: '/users/radiology-group-admin',
              icon: <FaUsersGear />,
            },
            hasAddTechnologistPermission && {
              label: 'Technologist',
              link: '/users/technologist-user',
              icon: <FaChalkboardUser />,
            },
            hasPhysicianPermission && {
              label: 'Physician',
              link: '/users/physician',
              icon: <FaUserMd />,
            },
          ].filter(Boolean),
        },
      ].filter(Boolean), // Filter out any falsy values
    },
  ].filter(Boolean);

  const userItems = [
    {
      label: 'Worklist Options',
      icon: <FaClipboardList />,
      link: '/worklist-option'
    },
    {
      label: 'Settings',
      icon: <IoMdSettings />,
      // onClick: () => {
      //   setIsOpen(false);
      //   navigate('/setting/my-account');
      // },
      //link: '/setting/my-account'
      submenu: [
        {
          label: 'My Account',
          link: '/setting/my-account',
          icon: <MdAccountBalance />
        },
        {
          label: 'Display Settings',
          link: '/setting/display-setting',
          icon: <MdOutlineSettingsBrightness />
        },
        hasAdminAndDeputyAdminPermission && {
          label: 'Radiology Group Settings',
          link: '/setting/radiologist-group-setting',
          icon: <FaUsersGear />
        },
        hasAdminAndDeputyAdminPermission && {
          label: 'Change Radiology  Group',
          link: '/setting/change-radiologist',
          icon: <MdChangeCircle />
        },
        hasAdminAndDeputyAdminPermission && {
          label: 'Report Setting',
          link: '/setting/report-setting',
          icon: <RiFileSettingsFill />
        },
        hasAdminAndDeputyAdminPermission && {
          label: 'Report Summary',
          link: '/setting/report-summary',
          icon: <MdSummarize />
        },
      ].filter(Boolean),
    },
  ].filter(Boolean)


  const settingItems = [
    {
      label: 'About',
      icon: <IoInformationCircle />,
      onClick: () => {
        setIsOpen(false);
        showModal({
          content: AboutModal,
          title: t('AboutModal:About Telerapp Viewer'),
          contentProps: { versionNumber, commitHash },
        });
      },
    },
    {
      label: 'Preferences',
      icon: <FaUserCog />,
      onClick: () => {
        setIsOpen(false);
        showModal({
          title: t('UserPreferencesModal:User preferences'),
          content: UserPreferences,
          contentProps: {
            hotkeyDefaults: hotkeysManager.getValidHotkeyDefinitions(hotkeyDefaults),
            hotkeyDefinitions,
            onCancel: hide,
            currentLanguage: currentLanguage(),
            availableLanguages,
            defaultLanguage,
            onSubmit: state => {
              if (state.language.value !== currentLanguage().value) {
                i18n.changeLanguage(state.language.value);
              }
              hotkeysManager.setHotkeys(state.hotkeyDefinitions);
              hide();
            },
            onReset: () => hotkeysManager.restoreDefaultBindings(),
            hotkeysModule: hotkeys,
          },
        });
      },
    },
  ].filter(Boolean);

  if (appConfig.oidc) {
    settingItems.push({
      // icon: 'power-off',
      icon: <FaPowerOff />,
      label: 'Logout',
      onClick: () => {
        navigate(`/logout?redirect_uri=${encodeURIComponent(window.location.href)}`);
      },
    });
  }

  const renderRadiologySubmenu = (submenu) => (
    <ul className="dark:bg-secondary-dark text-white border-b border-b-secondary-dark dark:border-b-primary-main">
      {submenu.map((item) => (
        <li
          key={item.label}
          className="dark:bg-secondary-dark pl-10 hover:border-secondary-dark dark:hover:border-secondary-light hover:bg-primary-light dark:hover:bg-primary-dark bg-secondary-light dark:text-white text-black cursor-pointer p-2 transition duration-300 flex items-center gap-2 group"
          onClick={() => handleMenuItemClick(item.link)}
        >
          <span className='dark:text-secondary-dark text-secondary-light bg-secondary-dark dark:bg-secondary-light p-1 text-2xl duration-300 dark:group-hover:bg-secondary-dark dark:group-hover:text-secondary-light group-hover:bg-secondary-light group-hover:text-secondary-dark transition-all rounded-sm'
            style={{
              boxShadow:
                'box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);',
            }}
          >
            {item.icon}
          </span>
          {item.label}
        </li>
      ))}
    </ul>
  );

  const renderSubmenu = (submenu) => (
    <ul className="dark:bg-secondary-dark text-white border-b border-b-secondary-dark dark:border-b-primary-main">
      {submenu?.map((item) => (
        <li
          key={item.label}
          className="dark:bg-secondary-dark pl-5 hover:border-secondary-dark dark:hover:border-secondary-light hover:bg-primary-light dark:hover:bg-primary-dark bg-secondary-light dark:text-white text-black cursor-pointer p-2 transition duration-300 flex items-center gap-2 group"
          onClick={() => {
            if (item.label === 'Radiology Group') {
              handleRadiologyClick()
            } else {
              handleMenuItemClick(item.link)
            }
          }}
        >

          <div className='flex items-center gap-2'>
            <span className='dark:text-secondary-dark text-secondary-light bg-secondary-dark dark:bg-secondary-light p-1 text-2xl duration-300 dark:group-hover:bg-secondary-dark dark:group-hover:text-secondary-light group-hover:bg-secondary-light group-hover:text-secondary-dark transition-all rounded-sm'
              style={{
                boxShadow:
                  'box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);',
              }}
            >
              {item.icon}
            </span>
            {item.label}
          </div>
          {item.submenu && (
            <span className="dark:text-white text-black group-hover:text-black dark:group-hover:text-white transition duration-300">
              {isRadiologyDropdownOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
            </span>
          )}
        </li>
      ))}
    </ul>
  );

  const handleMenuItemClick = (link, onClick) => {
    if (onClick) {
      onClick(); // Trigger modal for Worklist Option
    } else if (link) {
      navigate(link); // Handle regular link navigation
    }
  };

  const handleModelMenuClick = () => {
    // setShowModelMenu(!showModelMenu);
    if (hasDeputyAdminPermission || filteredModelsValues?.length > 0) {
      setIsModelOpen(!isModelOpen)
      setToggleDisplayReportEditor((show: boolean) => !show)
    }

  };

  const handleModelSelection = (model: { value: string; label: string }) => {
    setShowModelMenu(false);
    setIsModelOpen(false)
    // setToggleDisplayReportEditor(false)
    openUploadModal(model.value);
    if (model?.value) {
      setToggleDisplayReportEditor((show: boolean) => !show)
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const openUploadModal = (modelUrl: string) => {
    if (modelUrl) {
      // setModelUrl(modelUrl);
      setIsModelOpen(true);
      // setToggleDisplayReportEditor(true)
      // showModal({
      //   content: UploadModal,
      //   contentProps: {
      //     onSend: SendtoML,
      //     // hide: () => setShowModelMenu(false),
      //     modelUrl1: modelUrl,
      //     send: true,
      //   },
      //   title: 'AI Predictions',
      //   isOpen: true,
      // });
    }
  };

  const models = getModels();
  const filteredModels = filterModels(models, searchQuery);
  const filteredModelsValues = hasDeputyAdminPermission ? filteredModels : filteredModels?.filter(model => filteredValues?.includes(model.label))

  useEffect(() => {
    setIsOpen(headerOpen);
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowModelMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      // Request fullscreen on the document (or any element)
      document.documentElement.requestFullscreen().catch((err) => {
        console.error("Error attempting to enable fullscreen mode:", err);
      });
      setIsFullscreen(true);
    } else {
      // Exit fullscreen
      document.exitFullscreen().catch((err) => {
        console.error("Error attempting to exit fullscreen mode:", err);
      });
      setIsFullscreen(false);
    }
  };

  return (
    <NavBar
      className="relative justify-between border-b-4 border-black"
      isSticky={isSticky}
      {...props}
    >
      <div className="flex flex-1 justify-between">
        <div className="flex items-center">
          {/* // TODO: Should preserve filter/sort
              // Either injected service? Or context (like react router's `useLocation`?) */}
          <div
            className={classNames(
              'sm:mr-3 inline-flex items-center',
              isReturnEnabled && 'cursor-pointer'
            )}
            data-cy="return-to-work-list"
          >
            {isReturnEnabled && (
              <Icon
                onClick={onClickReturn}
                name="chevron-left"
                className="dark:text-white text w-8"
              />
            )}
            <div className="sm:ml-4 ml-2 flex items-center">
              <svg
                className="mr-4 block h-4 w-4 cursor-pointer border-white fill-current dark:text-white "
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setIsOpen(!isOpen)}
              >
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
              </svg>

              { !isReportEnabled &&
                <h6 className="m-0 text-xl leading-tight dark:text-white max-sm:text-[16px] max-[375px]:text-[13px] sm:w-[200px]">
                  Hello, {loggedInUserDetails?.profile?.name || 'User'}
                </h6>
              }
            </div>
          </div>
        </div>
        {/* <div className="flex items-center">{children}</div> */}
        {/* <div className="lg:flex xl:flex justify-center items-center gap-2 md:w-[400px] xl:w-full lg:w-full xl:!overflow-x-none lg:!overflow-x-none md:overflow-x-auto sm:w-[176px] sm:overflow-x-auto w-[176px] overflow-x-auto"
        style={{display: '-webkit-box',}}
        >{Secondary}</div> */}
         {isReportEnabled && isVisible && (
            <div
              className={`xl:!overflow-x-none lg:!overflow-x-none w-[176px] items-center justify-center gap-2 overflow-x-auto sm:w-[176px] sm:overflow-x-auto md:w-[400px] md:overflow-x-auto lg:flex lg:w-full xl:flex xl:w-full`}
              style={{ display: isVisible ? '-webkit-box' : 'none' }}
            >
              {Secondary}
            </div>
          )}
        <div className='flex items-center'>
          {/* dark mode & light mode icon */}
          {isWorklist && (
            <>
              <button onClick={toggleFullscreen}>
                {isFullscreen ? (
                  <Tooltip
                    content={"Exit Full Screen"}
                    position="bottom"
                    style={{ padding: '8px', fontWeight: 'normal' }}
                  >
                    <MdOutlineFullscreenExit className="cursor-pointer text-3xl max-sm:text-2xl" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    content={"Full Screen"}
                    position="bottom"
                    style={{ padding: '8px', fontWeight: 'normal' }}
                  >
                    <MdOutlineFullscreen className="cursor-pointer text-3xl max-sm:text-2xl" />
                  </Tooltip>
                )}
              </button>
              <div className="mr-2">
                {' '}
                <button
                  onClick={() =>
                    toggleTheme(theme === 'dark' ? 'light' : theme === 'light' ? 'dark' : 'light')
                  }
                  className="inline-flex w-full justify-center rounded-md px-2 py-2 text-sm font-medium text-black dark:text-white"
                >
                  {theme === 'dark' ? (
                  <Tooltip
                    content={"Dark Mode"}
                    position="bottom"
                    style={{ padding: '8px', fontWeight: 'normal' }}
                  >
                    <MdDarkMode className="cursor-pointer text-3xl" />
                  </Tooltip>
                  ) : (
                  <Tooltip
                    content={"Light Mode"}
                    position="bottom"
                    style={{ padding: '8px', fontWeight: 'normal' }}
                  >
                    <MdLightMode className="cursor-pointer text-3xl" />
                  </Tooltip>
                  )}
                </button>
              </div>
            </>
          )}
          {isWorklist && (
            <Icon
              name="quantum-logo1"
              className="h-10 w-28 text-white ml-auto mr-1"
            />
          )}
        </div>
       {isReportEnabled && <div className="flex items-center border-l border-l-secondary-dark dark:border-l-primary-main">
          {/* dark mode & light mode icon */}
          <button onClick={toggleFullscreen}>
              {isFullscreen ? (
                <Tooltip
                  content={"Exit Full Screen"}
                  position="bottom"
                  style={{ padding: '8px', fontWeight: 'normal' }}
                >
                  <MdOutlineFullscreenExit className="cursor-pointer text-3xl max-sm:text-2xl" />
                </Tooltip>
              ) : (
                <Tooltip
                  content={"Full Screen"}
                  position="bottom"
                  style={{ padding: '8px', fontWeight: 'normal' }}
                >
                  <MdOutlineFullscreen className="cursor-pointer text-3xl max-sm:text-2xl" />
                </Tooltip>
              )}
          </button>
          <div className="mr-2">
            {' '}
            <button
              onClick={() =>
                toggleTheme(theme === 'dark' ? 'light' : theme === 'light' ? 'dark' : 'light')
              }
              className="inline-flex w-full justify-center rounded-md px-2 py-2 text-sm font-medium text-black  dark:text-white"
            >
              {theme === 'dark' ? (
                <Tooltip
                  content={"Dark Mode"}
                  position="bottom"
                  style={{ padding: '8px', fontWeight: 'normal', }}
                >
                  <MdDarkMode className="text-3xl max-sm:text-2xl cursor-pointer" />
                </Tooltip>
              ) : (
                <Tooltip
                  content={"Dark Mode"}
                  position="bottom"
                  style={{ padding: '8px', fontWeight: 'normal', }}
                >
                  <MdLightMode className="text-3xl max-sm:text-2xl cursor-pointer" />
                </Tooltip>
              )}
            </button>
          </div>
          {isReportEnabled && (
            <div
              className="relative"
              style={{ marginTop: '10px', marginRight: '10px' }}
            >
              <IconButton
                id={'submit-report'}
                variant="text"
                color="inherit"
                size="initial"
                className="dark:text-white text-black font-bold"
                onClick={
                  loggedInUserDetails?.profile?.roleType !== 'Physician' &&
                    loggedInUserDetails?.profile?.roleType !== 'Technologist' &&
                    loggedInUserDetails?.profile?.roleType !== 'RadiologyGroupAdmin'
                    ? toggleDisplayReportEditorView
                    : loggedInUserDetails?.profile?.roleType === 'RadiologyGroupAdmin' &&
                      permissions?.includes('Edit Report')
                      ? toggleDisplayReportEditorView
                      : null
                }
                disabled={
                  loggedInUserDetails?.profile?.roleType === 'Physician' ||
                  loggedInUserDetails?.profile?.roleType === 'Technologist' ||
                  (loggedInUserDetails?.profile?.roleType === 'RadiologyGroupAdmin' &&
                    !permissions?.includes('Edit Report'))
                }
              >
                <Tooltip
                  content={"Report Editor"}
                  position="bottom"
                  style={{ padding: '8px', fontWeight: 'normal' }}
                >
                  <Icon name="tab-patient-info" />
                </Tooltip>
              </IconButton>
            </div>
          )}
          {isReportEnabled && (
            <div className="relative " ref={dropdownRef}>
              <IconButton
                id={'run-script-button'}
                variant="text"
                color="inherit"
                size="initial"
                className="dark:text-white text-blac neon-border-button"
                onClick={
                  hasDeputyAdminPermission
                    ? handleModelMenuClick
                    : filteredModelsValues?.length > 0
                      ? handleModelMenuClick
                      : null
                }
                disabled={
                  hasDeputyAdminPermission ? false : !filteredModelsValues?.length > 0
                }
              >
                <span className="neon-text">
                  <Tooltip
                    content={"AI assistant"}
                    position="bottom"
                    style={{ padding: '8px', fontWeight: 'normal' }}
                  >
                      AI
                  </Tooltip>
                </span>
              </IconButton>

              {showModelMenu && (
                <div
                  className="absolute right-0 mt-2 w-48 shadow-lg rounded-lg"
                  style={{ backgroundColor: '#041c4a' }}
                >
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="p-2 w-full"
                    style={{
                      backgroundColor: '#041c4a',
                      color: '#caccd1',
                      borderRadius: '5px',
                      outline: 'none',
                    }}
                  />
                  <ul
                    className="list-none p-2 m-0 max-h-[30px] overflow-y-auto"
                    style={{
                      maxHeight: '130px',
                      overflowY: 'auto',
                      backgroundColor: '#041c4a',
                      borderRadius: '5px',
                      scrollbarColor: '#caccd1 #041c4a',
                      scrollbarWidth: 'thin',
                    }}
                  >
                    {filteredModelsValues?.map((model) => (
                      <li
                        key={model.value}
                        onClick={() => handleModelSelection(model)}
                        className="cursor-pointer p-2"
                        style={{
                          color: '#caccd1',
                          backgroundColor: '#041c4a',
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = '#090c29';
                          e.currentTarget.style.color = '#255fb2';
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = '#041c4a';
                          e.currentTarget.style.color = '#caccd1';
                        }}
                      >
                        {model.label}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
          <Icon
            name="quantum-logo1"
            className="h-10 w-28 text-white ml-auto sm:pr-1"
          />
        </div>}
      </div>
      <ul
        className={classNames(
          'dark:bg-secondary-dark pr-0 bg-secondary-light absolute top-12 w-60 dark:text-white transition duration-300 flex flex-col justify-between overflow-y-auto telerapp-scrollbar',
          {
            'left-0': isOpen,
          },
          {
            'left-[-1000px]': !isOpen,
          }
        )}
        style={{
          height: 'calc(100vh - 48px)', // Full height minus header height
          // scrollbarColor: '#173239 transparent',
        }}
      >
        <div className="flex-grow">
          {dashboardItems.map((menuItem) => (
            <li
              key={menuItem.label}
              className="dark:bg-secondary-dark border-b border-b-secondary-dark dark:border-b-primary-main hover:border-secondary-dark bg-secondary-light dark:text-white hover:bg-primary-light dark:hover:bg-primary-dark cursor-pointer p-2 transition duration-300 flex items-center gap-2 group justify-between"
              onClick={() => {
                if (menuItem.label === 'Users') {
                  handleUserClick();
                } else if (menuItem.label === 'Dashboard') {
                  handleDashboardClick();
                } else if (menuItem.label === 'Settings') {
                  handleSettingClick()
                } else {
                  handleMenuItemClick(menuItem.link, menuItem.onClick);
                }
              }}
            >
              <div className="flex items-center gap-2">
                <span
                  className="dark:text-secondary-dark text-secondary-light bg-secondary-dark dark:bg-secondary-light p-1 text-2xl duration-300 dark:group-hover:bg-secondary-dark dark:group-hover:text-secondary-light group-hover:bg-secondary-light group-hover:text-secondary-dark transition-all rounded-sm"
                  style={{
                    boxShadow:
                      'box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);',
                  }}
                >
                  {menuItem.icon}
                </span>
                {menuItem.label}
              </div>

              {/* Conditionally render the dropdown icon if there is a submenu */}
              {menuItem.submenu && (
                <span className="dark:text-white text-black group-hover:text-black dark:group-hover:text-white transition duration-300">
                  {isDashboardDropdownOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                </span>
              )}
            </li>
          ))}

          {isDashboardDropdownOpen &&
            renderSubmenu(dashboardItems.find(item => item.label === 'Dashboard').submenu)}

          {menuItems.map((menuItem) => (
            <li
              key={menuItem.label}
              className={`dark:bg-secondary-dark border-b border-b-secondary-dark dark:border-b-primary-main hover:border-secondary-dark hover:bg-primary-light dark:hover:bg-primary-dark bg-secondary-light dark:text-white cursor-pointer p-2 transition duration-300 flex items-center gap-2 group justify-between ${menuItem.label === 'Physician' ? 'border-b border-b-secondary-dark dark:border-b-primary-main hover:border-secondary-dark  mt-6' : ''
                }`}
              onClick={() => {
                if (menuItem.label === 'Users') {
                  handleUserClick();
                } else if (menuItem.label === 'Dashboard') {
                  handleDashboardClick();
                } else if (menuItem.label === 'Settings') {
                  handleSettingClick()
                } else {
                  handleMenuItemClick(menuItem.link, menuItem.onClick);
                }
              }}
            >
              <div className="flex items-center gap-2">
                <span
                  className="dark:text-secondary-dark text-secondary-light bg-secondary-dark dark:bg-secondary-light p-1 text-2xl duration-300 dark:group-hover:bg-secondary-dark dark:group-hover:text-secondary-light group-hover:bg-secondary-light group-hover:text-secondary-dark transition-all rounded-sm"
                  style={{
                    boxShadow:
                      'box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);',
                  }}
                >
                  {menuItem.icon}
                </span>
                {menuItem.label}
              </div>
              {/* Conditionally render the dropdown icon if there is a submenu */}
              {menuItem.submenu && (
                <span className="dark:text-white text-black group-hover:text-black dark:group-hover:text-white transition duration-300">
                  {isUserDropdownOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                </span>
              )}
            </li>
          ))}

          {isUserDropdownOpen &&
            renderSubmenu(menuItems.find(item => item.label === 'Users').submenu)}

          {isRadiologyDropdownOpen &&
            renderRadiologySubmenu(
              menuItems
                .find(item => item.label === 'Users') // Find the 'Users' menu
                ?.submenu?.find(subItem => subItem.label === 'Radiology Group')?.submenu // Find the 'Radiology Group' submenu
            )
          }
          {userItems.map((menuItem) => (
            <li
              key={menuItem.label}
              className="dark:bg-secondary-dark border-b border-b-secondary-dark dark:border-b-primary-main hover:border-secondary-dark hover:bg-primary-light dark:hover:bg-primary-dark bg-secondary-light dark:text-white cursor-pointer p-2 transition duration-300 flex items-center gap-2 group justify-between"
              onClick={() => {
                if (menuItem.label === 'Users') {
                  handleUserClick();
                } else if (menuItem.label === 'Dashboard') {
                  handleDashboardClick();
                } else if (menuItem.label === 'Settings') {
                  handleSettingClick()
                } else {
                  handleMenuItemClick(menuItem.link, menuItem.onClick);
                }
              }}
            >
              <div className="flex items-center gap-2">
                <span
                  className="dark:text-secondary-dark text-secondary-light bg-secondary-dark dark:bg-secondary-light p-1 text-2xl duration-300 dark:group-hover:bg-secondary-dark dark:group-hover:text-secondary-light group-hover:bg-secondary-light group-hover:text-secondary-dark transition-all rounded-sm"
                  style={{
                    boxShadow:
                      'box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);',
                  }}
                >
                  {menuItem.icon}
                </span>
                {menuItem.label}
              </div>

              {menuItem.submenu && (
                <span className="dark:text-white text-black group-hover:text-black dark:group-hover:text-white transition duration-300">
                  {isSettingDropdownOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                </span>
              )}
            </li>
          ))}

          {isSettingDropdownOpen &&
            renderSubmenu(userItems.find(item => item.label === 'Settings').submenu)}
        </div>
        <div>
          {settingItems.map((settingItem) => (
            <li
              key={settingItem.label}
              className={`dark:bg-secondary-dark border-b border-b-secondary-dark dark:border-b-primary-main hover:border-secondary-dark hover:bg-primary-light dark:hover:bg-primary-dark bg-secondary-light dark:text-white cursor-pointer p-2 transition duration-300 flex items-center gap-2 groupRadiology group TAT Setting ${settingItem.label === 'About' ? 'border-t border-t-secondary-dark dark:border-t-primary-main mt-6' : ''
                }`}
              onClick={settingItem.onClick}
            >
              <span
                className="dark:text-secondary-dark text-secondary-light bg-secondary-dark dark:bg-secondary-light p-1 text-2xl duration-300 dark:group-hover:bg-secondary-dark dark:group-hover:text-secondary-light group-hover:bg-secondary-light group-hover:text-secondary-dark transition-all rounded-sm"
                style={{
                  boxShadow:
                    'box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);',
                }}
              >
                {settingItem.icon}
              </span>
              {settingItem.label}
            </li>
          ))}
        </div>
      </ul>

    </NavBar>
  );
}

Header.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isReturnEnabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  onClickReturnButton: PropTypes.func,
  WhiteLabeling: PropTypes.object,
  showPatientInfo: PropTypes.string,
  servicesManager: PropTypes.object,
  loggedInUserDetails: PropTypes.object,
};

export default Header;
