import React, { useState } from 'react';
import { useLogViewer } from '../../contextProviders/LogViewerProvider'; // Adjust the import path as needed
import Typography from '../Typography';
import LegacyButtonGroup from '../LegacyButtonGroup';
import LegacyButton from '../LegacyButton';

const LogList = ({ onSelectLog, searchTerm }) => {
    const { logs } = useLogViewer();
    const [currentPage, setCurrentPage] = useState(1);
    const logsPerPage = 50; // Display 50 logs per page

    let logEntries = [];

    // Helper function to format the log date and time in India/Mumbai time zone
    const formatDateTime = (timestamp) => {
        const dateTime = new Date(timestamp);

        const options = {
            timeZone: 'Asia/Kolkata',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        };

        const formattedDateTime = new Intl.DateTimeFormat('en-IN', options).formatToParts(dateTime);
        const date = `${formattedDateTime[4].value}-${formattedDateTime[2].value}-${formattedDateTime[0].value}`;
        const time = `${formattedDateTime[6].value}:${formattedDateTime[8].value}:${formattedDateTime[10].value} ${formattedDateTime[12].value}`;
        return { date, time };
    };

    // Helper function to extract the Accession, Patient ID, Activity, and User from log
    const extractLogDetails = (log) => {
        const { accession, patientID, action, userName, timestamp, UID } = log; // Added UID
        const dateTime = formatDateTime(timestamp);
        return {
            accession: accession || 'N/A',
            patientId: patientID || 'N/A',
            activity: action || 'Activity not found',
            user: userName || 'Unknown User',
            UID: UID || 'N/A', // Added UID to the returned object
            ...dateTime,
        };
    };

    // Parse log content based on whether it's a date range or a single log response
    const parseLogs = (logContent) => {
        const logLines = logContent.split('\n').filter((entry) => entry.trim() !== '');
        return logLines.map((line) => {
            try {
                const log = JSON.parse(line);
                return extractLogDetails(log);
            } catch (error) {
                console.error('Failed to parse log line:', line, error);
                return null;
            }
        }).filter(entry => entry !== null);
    };

    // Handle both single date and date range responses
    if (logs?.logs) {
        logs.logs.forEach((log) => {
            logEntries = [...logEntries, ...parseLogs(log.logContent)];
        });
    } else if (logs?.logContent) {
        logEntries = parseLogs(logs.logContent);
    }

    // Filter logs based on the search term
    const filteredLogs = logEntries
        .filter(log => Object.values(log).some(value => String(value).toLowerCase().includes(searchTerm.toLowerCase())))
        .reverse(); // Reverse to show the latest logs first

    // Pagination
    const totalPages = Math.ceil(filteredLogs.length / logsPerPage);
    const currentLogs = filteredLogs.slice((currentPage - 1) * logsPerPage, currentPage * logsPerPage);

    const handlePageChange = (direction) => {
        if (direction === 'next' && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        } else if (direction === 'prev' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    if (!currentLogs.length) {
        return <p>No logs available.</p>;
    }

    return (
        <div>
            <div
                className="overflow-y-auto container m-auto mt-2"
                style={{
                    maxHeight: '480px', // Adjust height as needed
                    overflowX: 'auto',
                    scrollbarColor: '#173239 transparent',
                }}
            >
                <table className="w-full table-auto text-white">
                    <thead className="bg-primary-dark border-secondary-light border sticky top-0 right-0 left-0" style={{ backgroundColor: '#041c4a' }}>
                        <tr>
                            <th className="p-2">Date</th>
                            <th className="p-2">Time</th>
                            <th className="p-2">UID</th>
                            <th className="p-2">Accession</th>
                            <th className="p-2">Patient ID</th>
                            <th className="p-2">Activity</th>
                            <th className="p-2">User</th>
                        </tr>
                    </thead>
                    <tbody className="mt-1">
                        {currentLogs && currentLogs.length > 0 ? (
                            currentLogs.map((log, index) => (
                                <tr
                                    key={index}
                                    className="bg-primary-dark border-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer border text-center transition duration-300"
                                    onClick={() => onSelectLog(log)}
                                >
                                    <td className="p-2">{log.date}</td>
                                    <td className="p-2">{log.time}</td>
                                    <td className="p-2">{log.UID}</td>
                                    <td className="p-2">{log.accession}</td>
                                    <td className="p-2">{log.patientId}</td>
                                    <td className="p-2">{log.activity}</td>
                                    <td className="p-2">{log.user}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={7} className="py-20 text-center">
                                    No logs found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {/* Total rows and pagination */}
            <div className="flex justify-between items-center mt-4">
                {/* Total number of rows at the bottom left */}
                <Typography className="text-base opacity-60">
                    Total Rows: {filteredLogs.length}
                </Typography>

                {/* Pagination at the bottom right */}
                <div className="flex items-center">
                    <Typography className="mr-4 text-base opacity-60">
                        Page {currentPage} of {totalPages}
                    </Typography>
                    <LegacyButtonGroup>
                        <LegacyButton
                            size="initial"
                            className="px-4 py-2 text-base"
                            color="translucent"
                            border="primary"
                            variant="outlined"
                            onClick={() => handlePageChange('prev')}
                            disabled={currentPage <= 1} // Disable on the first page
                        >
                            {'< Back'}
                        </LegacyButton>
                        <LegacyButton
                            size="initial"
                            className="px-4 py-2 text-base"
                            color="translucent"
                            border="primary"
                            variant="outlined"
                            onClick={() => handlePageChange('next')}
                            disabled={currentPage >= totalPages} // Disable on the last page
                        >
                            {'Next >'}
                        </LegacyButton>
                    </LegacyButtonGroup>
                </div>
            </div>
        </div>

    );
};

export default LogList;
