import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  Dashboard,
  ErrorBoundary,
  ReportEditor,
  Users,
  RadiologistUsers,
  RadiologyGroupAdmin,
  Physician,
  RadiologyGroupUser,
  DeputyAdmin,
  QAUsers,
  Technologist,
  SettingsLeftPanel,
  MyAccount,
  DisplaySetting,
  ChangeRadiologistMember,
  RadiologyGroupSettings,
  ReportSetting,
  ConnectivityStatus,
  UsersStatus,
  // UploadStatus,
  Invoice,
  MessageStatus,
  ReportTemplate,
  ReportSummary,
  ManagerUser,
  Analytics,
  LogViewer,
  WorklistModel,
} from '@ohif/ui';

// Route Components
import DataSourceWrapper from './DataSourceWrapper';
import WorkList from './WorkList';
import Local from './Local';
import Debug from './Debug';
import NotFound from './NotFound';
import buildModeRoutes from './buildModeRoutes';
import PrivateRoute from './PrivateRoute';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NotFoundServer = ({
  message = 'Unable to query for studies at this time. Check your data source configuration or network connection',
}) => {
  return (
    <div className="absolute flex h-full w-full items-center justify-center text-white">
      <div>
        <h4>{message}</h4>
      </div>
    </div>
  );
};

NotFoundServer.propTypes = {
  message: PropTypes.string,
};

const NotFoundStudy = () => {
  return (
    <div className="absolute flex h-full w-full items-center justify-center text-white">
      <div>
        <h4>
          One or more of the requested studies are not available at this time. Return to the{' '}
          <Link
            className="text-primary-light"
            to={'/'}
          >
            study list
          </Link>{' '}
          to select a different study to view.
        </h4>
      </div>
    </div>
  );
};

NotFoundStudy.propTypes = {
  message: PropTypes.string,
};

// TODO: Include "routes" debug route if dev build
const bakedInRoutes = [
  {
    path: '/notfoundserver',
    children: NotFoundServer,
  },
  {
    path: '/notfoundstudy',
    children: NotFoundStudy,
  },
  {
    path: '/debug',
    children: Debug,
  },
  {
    path: '/local',
    children: Local.bind(null, { modePath: '' }), // navigate to the worklist
  },
  {
    path: '/localbasic',
    children: Local.bind(null, { modePath: 'viewer/dicomlocal' }),
  },
];

// NOT FOUND (404)
const notFoundRoute = { component: NotFound };

const createRoutes = ({
  modes,
  dataSources,
  extensionManager,
  servicesManager,
  commandsManager,
  hotkeysManager,
  routerBasename,
  showStudyList,
}) => {
  const routes =
    buildModeRoutes({
      modes,
      dataSources,
      extensionManager,
      servicesManager,
      commandsManager,
      hotkeysManager,
    }) || [];

  const { customizationService } = servicesManager.services;

  const WorkListRoute = {
    path: '/',
    children: DataSourceWrapper,
    private: true,
    props: { children: WorkList, servicesManager, extensionManager },
  };

  const WorkListOptionRoute = {
    path: '/worklist-option',
    children: DataSourceWrapper,
    private: true,
    props: { children: WorklistModel, servicesManager, extensionManager, hotkeysManager },
  };

  const ReportEditorRoute = {
    path: '/report-editor/:StudyInstanceUIDs',
    children: ReportEditor,
    private: true,
  };

  const DashboardRoute = {
    path: '/dashboard',
    children: DataSourceWrapper,
    accessRoles: ['super-admin', 'deputy-admin', 'Access Dashboard'],
    private: true,
    props: { children: Dashboard, servicesManager, extensionManager, hotkeysManager },
  };

  const ConnectivityStatusRoute = {
    path: '/dashboard/connectivity-status',
    children: DataSourceWrapper,
    accessRoles: ['super-admin', 'deputy-admin', 'Access Dashboard'],
    private: true,
    props: { children: ConnectivityStatus, servicesManager, extensionManager, hotkeysManager },
  };

  const UsersStatusRoute = {
    path: '/dashboard/users-status',
    children: DataSourceWrapper,
    accessRoles: ['super-admin', 'deputy-admin', 'Access Dashboard'],
    private: true,
    props: { children: UsersStatus, servicesManager, extensionManager, hotkeysManager },
  };

  // const UploadStatusRoute = {
  //   path: '/dashboard/upload-status',
  //   children: DataSourceWrapper,
  // accessRoles: ['super-admin','deputy-admin', 'Access Dashboard'],
  //   private: true,
  //   props: { children: UploadStatus, servicesManager, extensionManager, hotkeysManager },
  // };

  const MonitorStudiesRoute = {
    path: '/dashboard/analytics',
    children: DataSourceWrapper,
    accessRoles: ['super-admin', 'deputy-admin', 'Access Dashboard'],
    private: true,
    props: { children: Analytics, servicesManager, extensionManager, hotkeysManager },
  };

  const MessageStatusRoute = {
    path: '/dashboard/message-status',
    children: DataSourceWrapper,
    accessRoles: ['super-admin', 'deputy-admin', 'Access Dashboard'],
    private: true,
    props: { children: MessageStatus, servicesManager, extensionManager, hotkeysManager },
  };

  const AuditLogsRoute = {
    path: '/dashboard/audit-logs',
    children: DataSourceWrapper,
    accessRoles: ['super-admin', 'deputy-admin', 'Access Dashboard'],
    private: true,
    props: { children: LogViewer, servicesManager, extensionManager, hotkeysManager },
  };

  const ReportTemplateRoute = {
    path: '/dashboard/report-template',
    children: DataSourceWrapper,
    accessRoles: ['super-admin', 'deputy-admin', 'Access Dashboard'],
    private: true,
    props: { children: ReportTemplate, servicesManager, extensionManager, hotkeysManager },
  };

  const InvoiceRoute = {
    path: '/dashboard/invoice',
    children: DataSourceWrapper,
    accessRoles: ['super-admin', 'deputy-admin', 'Access Dashboard'],
    private: true,
    props: { children: Invoice, servicesManager, extensionManager, hotkeysManager },
  };

  const UsersRoute = {
    path: '/users/all-users',
    children: DataSourceWrapper,
    accessRoles: ['super-admin', 'deputy-admin', 'All Users Tab Access', 'RadiologyGroupAdmin', 'TelerappManager'],
    private: true,
    props: { children: Users, servicesManager, extensionManager, hotkeysManager },
  };

  const PhysicianRoute = {
    path: '/users/physician',
    children: DataSourceWrapper,
    private: true,
    props: { children: Physician, servicesManager, extensionManager, hotkeysManager },
  };

  const DeputyAdminRoute = {
    path: '/users/deputy-admin',
    children: DataSourceWrapper,
    accessRoles: ['super-admin'],
    private: true,
    props: { children: DeputyAdmin, servicesManager, extensionManager, hotkeysManager },
  };

  const QaUser = {
    path: '/users/qa-user',
    children: DataSourceWrapper,
    accessRoles: ['super-admin', 'deputy-admin'],
    private: true,
    props: { children: QAUsers, servicesManager, extensionManager, hotkeysManager },
  };

  const RadiologistUsersRoute = {
    path: '/users/radiologist-users',
    children: DataSourceWrapper,
    accessRoles: ['super-admin', 'deputy-admin', 'Add Radiologist'],
    private: true,
    props: { children: RadiologistUsers, servicesManager, extensionManager, hotkeysManager },
  };

  const RadiologyGroupAdminRoute = {
    path: '/users/radiology-group-admin',
    children: DataSourceWrapper,
    private: true,
    props: { children: RadiologyGroupAdmin, servicesManager, extensionManager, hotkeysManager },
  };

  const RadiologyGroupUserRoute = {
    path: '/users/radiology-group-user',
    children: DataSourceWrapper,
    accessRoles: ['super-admin', 'deputy-admin', 'Create Radiology Group'],
    private: true,
    props: { children: RadiologyGroupUser, servicesManager, extensionManager, hotkeysManager },
  };

  const TechnologistUserRoute = {
    path: '/users/technologist-user',
    children: DataSourceWrapper,
    accessRoles: ['super-admin', 'deputy-admin', 'Add Radiology Group Technologist'],
    private: true,
    props: { children: Technologist, servicesManager, extensionManager, hotkeysManager },
  };

  const ManagerUserRoute = {
    path: '/users/telerapp-manager',
    children: DataSourceWrapper,
    accessRoles: ['super-admin', 'deputy-admin', 'Add Telerapp Manager'],
    private: true,
    props: { children: ManagerUser, servicesManager, extensionManager, hotkeysManager },
  };

  const SettingUserRoute = {
    path: '/setting',
    children: DataSourceWrapper,
    accessRoles: ['super-admin', 'deputy-admin'],
    private: true,
    props: { children: SettingsLeftPanel, servicesManager, extensionManager, hotkeysManager },
  };

  const MyAccountRoute = {
    path: '/setting/my-account',
    children: DataSourceWrapper,
    private: true,
    props: { children: MyAccount, servicesManager, extensionManager, hotkeysManager },
  };

  const DisplaySettingRoute = {
    path: '/setting/display-setting',
    children: DataSourceWrapper,
    private: true,
    props: { children: DisplaySetting, servicesManager, extensionManager, hotkeysManager },
  };

  const ChangeRadiologistMemberRoute = {
    path: '/setting/change-radiologist',
    children: DataSourceWrapper,
    accessRoles: ['super-admin', 'deputy-admin'],
    private: true,
    props: { children: ChangeRadiologistMember, servicesManager, extensionManager, hotkeysManager },
  };

  const RadiologyGroupSettingsRoute = {
    path: '/setting/radiologist-group-setting',
    children: DataSourceWrapper,
    accessRoles: ['super-admin', 'deputy-admin'],
    private: true,
    props: { children: RadiologyGroupSettings, servicesManager, extensionManager, hotkeysManager },
  };

  const ReportSettingsRoute = {
    path: '/setting/report-setting',
    children: DataSourceWrapper,
    accessRoles: ['super-admin', 'deputy-admin'],
    private: true,
    props: { children: ReportSetting, servicesManager, extensionManager, hotkeysManager },
  };

  const ReportSummaryRoute = {
    path: '/setting/report-summary',
    children: DataSourceWrapper,
    accessRoles: ['super-admin', 'deputy-admin'],
    private: true,
    props: { children: ReportSummary, servicesManager, extensionManager, hotkeysManager },
  };

  const NotFoundServerRoute = {
    path: '/found',
    children: DataSourceWrapper,
    private: true,
    props: { children: NotFoundServer, servicesManager, extensionManager },
  };

  const customRoutes = customizationService.getGlobalCustomization('customRoutes');
  const allRoutes = [
    ...routes,
    ...(showStudyList ? [WorkListRoute] : []),
    ...(customRoutes?.routes || []),
    ...bakedInRoutes,
    customRoutes?.notFoundRoute || notFoundRoute,
    ...[ReportEditorRoute],
    DashboardRoute,
    WorkListOptionRoute,
    UsersRoute,
    PhysicianRoute,
    RadiologistUsersRoute,
    RadiologyGroupAdminRoute,
    RadiologyGroupUserRoute,
    DeputyAdminRoute,
    QaUser,
    TechnologistUserRoute,
    SettingUserRoute,
    MyAccountRoute,
    DisplaySettingRoute,
    ChangeRadiologistMemberRoute,
    RadiologyGroupSettingsRoute,
    ReportSettingsRoute,
    ReportSummaryRoute,
    ConnectivityStatusRoute,
    UsersStatusRoute,
    // UploadStatusRoute,
    MonitorStudiesRoute,
    MessageStatusRoute,
    ReportTemplateRoute,
    InvoiceRoute,
    AuditLogsRoute,
    ManagerUserRoute,
    NotFoundServerRoute
  ];

  function RouteWithErrorBoundary({ route, ...rest }) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return (
      <ErrorBoundary
        context={`Route ${route.path}`}
        fallbackRoute="/"
      >
        <route.children
          {...rest}
          {...route.props}
          route={route}
          servicesManager={servicesManager}
          extensionManager={extensionManager}
          hotkeysManager={hotkeysManager}
        />
      </ErrorBoundary>
    );
  }

  const { userAuthenticationService } = servicesManager.services;

  // Note: PrivateRoutes in react-router-dom 6.x should be defined within
  // a Route element
  return (
    <Routes>
      {allRoutes.map((route, i) => {
        return route.private === true ? (
          <>
            <Route
              key={i}
              exact
              path={route.path}
              element={
                <PrivateRoute
                  handleUnauthenticated={userAuthenticationService.handleUnauthenticated}
                  accessRoles={route.accessRoles}
                >
                  <RouteWithErrorBoundary route={route} />
                </PrivateRoute>
              }
            ></Route>
          </>
        ) : (
          <Route
            key={i}
            path={route.path}
            element={<RouteWithErrorBoundary route={route} />}
          />
        );
      })}
    </Routes>
  );
};

export default createRoutes;
