import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LegacyButton from '../LegacyButton';
import LegacyButtonGroup from '../LegacyButtonGroup';
import Typography from '../Typography';
import Select from '../Select';
import Button from '../Button';
import { useAnalytics, useModal, useReportEditor, useUser } from '../../contextProviders';
import RadiologistUserActiveDetailsModel from './RadiologistUserActiveDetailsModel';
import { RxCross2 } from 'react-icons/rx';
import { Loader } from 'rsuite';
import { GoDotFill } from 'react-icons/go';
import { FaChalkboardUser } from 'react-icons/fa6';
import { FaUserMd } from 'react-icons/fa';
import Tooltip from '../Tooltip';

const StudyListPagination = ({ servicesManager, onChangePage, currentPage, perPage, numOfStudies, onChangePerPage, token, }) => {
  const { t } = useTranslation('StudyList');
  const { show, hide } = useModal();
  const { radiologistData } = useAnalytics();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [pageInput, setPageInput] = useState(null);

  // const togglePopup = () => {
  //   setIsPopupOpen(!isPopupOpen);
  // };

  const togglePopup = (e) => {
    e.stopPropagation(); // Stop the click event from propagating
    setIsPopupOpen(prev => !prev); // Toggle the popup state
  };

  const { userAuthenticationService } = servicesManager.services;
  const user = userAuthenticationService.getUser();

  const currentUserradiologyGroup = user?.profile?.radiologyGroup;
  const permissions = user?.profile?.permission

  const hasActiveRadiologyUserList =
    permissions?.includes('Access Dashboard') ||
    token?.realm_access?.roles.includes('super-admin') ||
    token?.realm_access?.roles.includes('deputy-admin');

  const {
    usersList,
    fetchSessionsRadiologistUser,
  } = useUser();

  const radiologistUserLists =
    usersList &&
    usersList?.filter(user => user?.attributes?.roleType?.includes('Radiologist'));

  const radiologistUser = user?.profile?.roleType.includes('RadiologyGroupAdmin')
    ? radiologistUserLists &&
    radiologistUserLists
      ?.filter(data => data?.attributes?.radiologyGroup?.includes(currentUserradiologyGroup))
      .map(data => data.id)
    : radiologistUserLists && radiologistUserLists?.map(data => data.id);


  const navigateToPage = page => {
    const toPage = page < 1 ? 1 : page;
    onChangePage(toPage);
    setPageInput('');
  };

  const handlePageInputChange = e => {
    const value = e.target.value;
    setPageInput(value ? parseInt(value) : ''); // Allow blank input
  };

  const handlePageInputKeyPress = (e) => {
    if (e.key === 'Enter' && pageInput && pageInput > 0) {
      navigateToPage(pageInput);
    }
  };

  const inputRef = useRef(null); // Create a ref for the input element

  const handlePageTextClick = () => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input when "Page" is clicked
    }
  };

  const ranges = [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
  ];

  const [selectedRange, setSelectedRange] = useState({
    value: '25',
    label: '25'
  });

  const onSelectedRange = selectedRange => {
    setSelectedRange(selectedRange);
    onChangePerPage(selectedRange.value);
  };

  const handlemodelShow = () => {
    show({
      content: RadiologistUserActiveDetailsModel,
      title: `Radiologist List`,
      contentProps: {
        fetchSessionsRadiologistUser,
        radiologistData,
        user,
        radiologistUser,
      },
    });
  };

  return (
    <div className="bg-black py-3">
      <div className="px-5 relative">
        {/* Ensure the popup can position relative to this container */}
        <div className="flex justify-between">
          <div className="flex items-center gap-4">
            <div className="flex items-center">
              <Tooltip
                content={'Results per page'}
                position="top"
                style={{ padding: '8px', fontWeight: 'normal' }}
              >
                <Select
                  id="rows-per-page"
                  className="border-primary-main relative w-24 mr-2"
                  options={ranges}
                  value={selectedRange}
                  isMulti={false}
                  isClearable={false}
                  isSearchable={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  onChange={onSelectedRange}
                />
              </Tooltip>

              <Typography variant="h8">
                Quantum Powered by Telerapp
              </Typography>
            </div>
          </div>

          <div className="">
            <div className="flex items-center">
              <Typography variant="h8" className="text-primary-light">
                {`${t('Study count')}: `}
              </Typography>
              <Typography variant="h8" className="mr-2" data-cy={'num-studies'}>
                {numOfStudies}
              </Typography>

              <LegacyButtonGroup>
                <LegacyButton
                  size="initial"
                  className="px-2 py-1 text-base flex items-center"
                  color="translucent"
                  border="primary"
                  variant="outlined"
                >
                  <span onClick={handlePageTextClick} className="cursor-pointer">
                    Page
                  </span>
                  <input
                    ref={inputRef}
                    type="number"
                    className="w-5 text-center bg-transparent outline-none"
                    value={pageInput}
                    onChange={handlePageInputChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handlePageInputKeyPress(e); // Call your function here when Enter is pressed
                      }
                    }}
                    placeholder={currentPage}
                  // min="1"
                  />
                </LegacyButton>
                <LegacyButton
                  size="initial"
                  className="py-2 px-4 text-base w-5"
                  color="translucent"
                  border="primary"
                  variant="outlined"
                  onClick={() => navigateToPage(currentPage - 1)}
                >
                  {t('<')}
                </LegacyButton>
                <LegacyButton
                  size="initial"
                  className="py-2 px-4 text-base w-5"
                  color="translucent"
                  border="primary"
                  variant="outlined"
                  onClick={() => navigateToPage(currentPage + 1)}
                  disabled={numOfStudies <= perPage * currentPage}
                >
                  {t('>')}
                </LegacyButton>
              </LegacyButtonGroup>


              {hasActiveRadiologyUserList && <div className="ml-2">
                <Button onClick={togglePopup} className=" rounded-md">

                  <Tooltip
                    content={'Radiologists'}
                    position="top"
                    style={{ padding: '8px', fontWeight: 'normal' }}
                  ><FaUserMd className=" text-xl" /></Tooltip>
                </Button>
              </div>}

              {isPopupOpen && (
                < RadiologistUserActiveDetailsModel fetchSessionsRadiologistUser={fetchSessionsRadiologistUser}
                  radiologistData={radiologistData}
                  user={user}
                  radiologistUser={radiologistUser}
                  togglePopup={togglePopup}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StudyListPagination.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  onChangePerPage: PropTypes.func.isRequired,
  token: PropTypes.object.isRequired,
};

export default StudyListPagination;
