import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { fetchLogs, fetchDateRangeLogs } from '../requestHandler';

export const LogViewerContext = createContext(null);

export const LogViewerProvider = ({ children }) => {
    const [logs, setLogs] = useState([]);
    const [selectedDate, setSelectedDate] = useState(''); // State for selected date
    const [selectedDateRange, setSelectedDateRange] = useState({ startDate: '', endDate: '' });
    const [selectedLogFileName, setSelectedLogFileName] = useState(''); // State for selected log file name

    // Effect to fetch logs when selectedDate is provided (but not when date range is provided)
    useEffect(() => {
        const { startDate, endDate } = selectedDateRange;

        // Fetch logs for single date only if date range is not provided
        if (selectedDate && selectedLogFileName && !(startDate && endDate)) {
            fetchLogs(selectedDate, selectedLogFileName)
                .then(data => setLogs(data))
                .catch(error => console.error('Error fetching logs:', error));
        }
    }, [selectedDate, selectedLogFileName, selectedDateRange]);

    // Effect to fetch logs for a date range
    useEffect(() => {
        const { startDate, endDate } = selectedDateRange;
        if (startDate && endDate && selectedLogFileName) {
            fetchDateRangeLogs(startDate, endDate, selectedLogFileName)
                .then(data => setLogs(data))
                .catch(error => console.error('Error fetching logs:', error));
        }
    }, [selectedDateRange, selectedLogFileName]);

    const value = useMemo(() => ({
        logs,
        setSelectedDate, // Provide the setter function for date
        setSelectedDateRange,
        setSelectedLogFileName, // Provide the setter function for log file name
        selectedDateRange,
    }), [logs]);

    return <LogViewerContext.Provider value={value}>{children}</LogViewerContext.Provider>;
};

export const useLogViewer = () => useContext(LogViewerContext);
