import React, { useEffect, useMemo, useState } from 'react';
import { Tooltip, useViewportGrid } from '@ohif/ui';
import classnames from 'classnames';
import { useToolbar } from '@ohif/core';

export function Toolbar({ servicesManager, buttonSection = 'primary' }) {
  const { toolbarButtons, onInteraction } = useToolbar({
    servicesManager,
    buttonSection,
  });

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 640);
  const toolbarService = useMemo(() => servicesManager.services.toolbarService, [servicesManager]);

  const hiddenButtons = [
    'StackScroll',
    'Cine',
    'Angle',
    'Magnify',
    'ImageSliceSync',
    'EllipticalROI',
    'RectangleROI',
    'Reset',
    'PlanarFreehandROI',
    'Length',
    'TagBrowser',
    'Probe'
  ];

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 640;
      setIsMobileView(isMobile);

      if (toolbarService.resetMenu) {
        toolbarService.resetMenu(isMobile);
      }
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [toolbarService]);

  return (
    <>
      {toolbarButtons.map(toolDef => {
        const { id, Component, componentProps } = toolDef;

        // Conditionally hide buttons in mobile view
        if (isMobileView && hiddenButtons.includes(id)) {
          return null;
        }

        return (
          <div key={id}>
            <Component
              id={id}
              {...componentProps}
              onInteraction={onInteraction}
              servicesManager={servicesManager}
            />
          </div>
        );
      })}
    </>
  );
}
