// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.namebox{
    background-color: #090c29;
    -webkit-box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin-bottom: 10px;
    padding: 7px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: pointer;
}
.namebox:hover{
    -webkit-box-shadow: rgba(60, 64, 67, 0.3) 0px 2px 4px, rgba(60, 64, 67, 0.3) 0px 7px 13px -3px, rgba(60, 64, 67, 0.3) 0px -3px 0px inset;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 2px 4px, rgba(60, 64, 67, 0.3) 0px 7px 13px -3px, rgba(60, 64, 67, 0.3) 0px -3px 0px inset;
}
.hedingdesign{
    margin: 0 0 18px 50px;
    font-size: 20px;
}
.namebox input{
    width: 20px;
    height: 20px;
    margin-right: 18px;
}
.namebox span{
    font-size: 14px;
    text-transform: capitalize;
}
.headingMaindesign{
    text-align: center;
    font-size: 28px;
    margin: 0 0 20px 0;
}`, "",{"version":3,"sources":["webpack://./../../ui/src/components/WorklistModel/WorklistModel.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iGAAyF;YAAzF,yFAAyF;IACzF,mBAAmB;IACnB,iBAAiB;IACjB,oBAAa;IAAb,oBAAa;IAAb,aAAa;IACb,yBAAmB;QAAnB,sBAAmB;YAAnB,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,wIAAgI;YAAhI,gIAAgI;AACpI;AACA;IACI,qBAAqB;IACrB,eAAe;AACnB;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,0BAA0B;AAC9B;AACA;IACI,kBAAkB;IAClB,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".namebox{\n    background-color: #090c29;\n    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;\n    margin-bottom: 10px;\n    padding: 7px 15px;\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n}\n.namebox:hover{\n    box-shadow: rgba(60, 64, 67, 0.3) 0px 2px 4px, rgba(60, 64, 67, 0.3) 0px 7px 13px -3px, rgba(60, 64, 67, 0.3) 0px -3px 0px inset;\n}\n.hedingdesign{\n    margin: 0 0 18px 50px;\n    font-size: 20px;\n}\n\n.namebox input{\n    width: 20px;\n    height: 20px;\n    margin-right: 18px;\n}\n.namebox span{\n    font-size: 14px;\n    text-transform: capitalize;\n}\n.headingMaindesign{\n    text-align: center;\n    font-size: 28px;\n    margin: 0 0 20px 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
