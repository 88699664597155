import React, { useEffect, useState } from 'react';
import {
  Button,
  Dashboard,
  InputDateRange,
  Label,
  Select,
  Typography,
  useRadiologyGroup,
  useSnackbar,
  useUser,
} from '@ohif/ui';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import { useAnalytics } from '../../contextProviders';

const StudyStatus = ({ servicesManager, extensionManager, hotkeysManager }) => {
  const { userAuthenticationService } = servicesManager.services;
  const { show } = useSnackbar();

  const user = userAuthenticationService.getUser();
  const { radiologyGroupList } = useRadiologyGroup();
  const { t } = useTranslation('UserPreferencesModal');
  const { fetchUsers, usersList } = useUser();
  const {
    studyCount,
    fetchStudyStatusByPriorityAndDocument,
    studyStatusByPriorityAndDocument,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
  } = useAnalytics();

  const [selectedGroupName, setSelectedGroupName] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState<any>({ startDate, endDate });
  const [radiologistUserLists, setRadiologistUserLists] = useState([]);
  const [error, setError] = useState(null);

  // const optionGroup = radiologyGroupList.map(group => ({
  //   label: group.name,
  //   value: group.name,
  // }));

  // const optionGroup = Object.keys(studyStatusByPriorityAndDocument).flatMap(group => {
  //   return {
  //     label: group,
  //     value: group,
  //   };
  // });

  const optionGroup = [
    { label: 'All Institutions', value: 'All Institutions' },
    ...Object.keys(studyStatusByPriorityAndDocument).flatMap(group => {
      return {
        label: group,
        value: group,
      };
    }),
  ];

  // const optionRadiologistUser = Object.keys(studyStatusByPriorityAndDocument)
  // .filter(group => selectedGroupName === "All Institutions" || group === selectedGroupName)
  //   .flatMap(group => {
  //     const users = studyStatusByPriorityAndDocument[group];
  //     return Object.keys(users).map(user => {
  //       return {
  //         label: user,
  //         value: user,
  //       };
  //     });
  //   });

  const uniqueUsers = new Set();

  const optionRadiologistUser = [
    { label: 'All Users', value: 'All Users' },
    ...Object.keys(studyStatusByPriorityAndDocument)
      .filter(group => selectedGroupName === 'All Institutions' || group === selectedGroupName)
      .flatMap(group => {
        const users = studyStatusByPriorityAndDocument[group];
        return Object.keys(users)
          .filter(user => {
            if (!uniqueUsers.has(user)) {
              uniqueUsers.add(user);
              return true;
            }
            return false;
          })
          .map(user => ({
            label: user,
            value: user,
          }));
      }),
  ];

  const handleSelectionGroupNameChange = selected => {
    setSelectedGroupName(selected.value);
  };

  const handleSelectionRadiologistUser = selected => {
    setSelectedUser(selected.value);
  };

  const handleDateRangeFieldChange = ({ startDate, endDate }) => {
    const start_date = moment(startDate).format('YYYY-MM-DD');
    const end_date = moment(endDate).format('YYYY-MM-DD');
    setSelectedDateRange({ start_date, end_date });
    setStartDate(start_date);
    setEndDate(end_date);
  };

  const fetchData = (startDate, endDate) => {
    fetchStudyStatusByPriorityAndDocument(startDate, endDate).catch(error => setError(error));
  };

  const getLast7Days = () => {
    const today = new Date();
    const priorDate = new Date().setDate(today.getDate() - 7);

    return {
      startDate: new Date(priorDate).toISOString().split('T')[0],
      endDate: today.toISOString().split('T')[0],
    };
  };

  useEffect(() => {
    const { startDate, endDate } = getLast7Days();
    setSelectedDateRange({ startDate, endDate });
    setStartDate(startDate);
    setEndDate(endDate);
    fetchData(startDate, endDate);
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      fetchData(startDate, endDate);
      setSelectedDateRange({ startDate, endDate });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (startDate && endDate) {
      fetchData(startDate, endDate);
      setSelectedDateRange({ startDate, endDate });
    }
  }, [startDate, endDate, selectedGroupName, selectedUser]);

  useEffect(() => {
    if (error) {
      show({ message: error.message, type: SnackbarTypes.ERROR });
    }
  }, [error, show]);

  const headers = ['group', 'user', 'Routine', 'STAT', 'ASAP', 'Completed', 'Pending'];

  const handleSearch: any = ({ startDate, endDate }) => {
    const startTimestamp = moment(startDate).format('MM/DD/YYYY');
    const endTimestamp = moment(endDate).format('MM/DD/YYYY');

    const filteredUsers = Object.keys(studyStatusByPriorityAndDocument)
      .filter(group => selectedGroupName === 'All Institutions' || group === selectedGroupName)
      .flatMap(group => {
        const users = studyStatusByPriorityAndDocument[group];
        return Object.keys(users)
          .filter(user => selectedUser === 'All Users' || user === selectedUser)
          .map(user => {
            const statusCounts = users[user];
            const userTimestamp = moment(statusCounts.createdTimestamp).format('MM/DD/YYYY');

            // Filter based on date range
            if (userTimestamp >= startTimestamp && userTimestamp <= endTimestamp) {
              return {
                group,
                user,
                ASAP: statusCounts.ASAP || 0,
                Completed: statusCounts.completed || 0,
                STAT: statusCounts.STAT || 0,
                Pending: statusCounts.pending || 0,
                Null: statusCounts.null || 0,
                Routine: statusCounts.Routine || 0,
              };
            }
            return null;
          })
          .filter(Boolean); // Remove null entries
      });

    setRadiologistUserLists(filteredUsers);

    if (filteredUsers.length === 0) {
      show({
        title: 'Error!',
        message: 'No Radiologist Users Exiting',
        type: SnackbarTypes.ERROR,
        position: 'topRight',
      });
    }
  };

  return (
    <>
      <div className="min-h-[500px] max-h-[550px] overflow-y-auto overflow-x-auto mr-2"
        style={{
          scrollbarColor: '#173239 transparent',
        }}>
        <div className=" my-5 flex items-center justify-between gap-5">
          <div className=" flex items-center gap-3">
            <Label
              className="mt-2"
              text={''}
            >
              Date Range :{' '}
            </Label>
            <InputDateRange
              id={'report-analutic'}
              value={selectedDateRange}
              onChange={handleDateRangeFieldChange}
              setStudyFilterHeight={''}
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <p className="inline-flex items-center rounded border border-[#3a3f99] bg-blue-400 py-2 px-4 font-semibold text-black opacity-80 transition-all mr-3">
              Study Count: {studyCount?.count || 0}
            </p>
          </div>
        </div>

        <div className={` mb-5 `}>
          <p className=" text-[#5acce6]">Study Status </p>

          <div className="flex items-center justify-between gap-4">
            <div className="flex items-center justify-between">
              <Label
                className="my-2 block"
                text={''}
              >
                Institution Name :{' '}
              </Label>
              <Select
                id="report-status-group"
                className={`ml-3 ${selectedGroupName ? 'w-52' : ''}`}
                placeholder={'Select Groups Name'}
                onChange={handleSelectionGroupNameChange}
                options={optionGroup}
                value={[selectedGroupName]}
              />
            </div>
            <div className="flex items-center justify-between">
              <Label
                className="my-2 block"
                text={''}
              >
                Radiologist User Name :
              </Label>
              <Select
                id="group"
                className={`ml-3 ${selectedUser ? 'w-52' : ''}`}
                placeholder={'Select Radiologist User Name'}
                onChange={handleSelectionRadiologistUser}
                options={optionRadiologistUser}
                value={[selectedUser]}
              />
              <Button
                className="my-2 py-0.5 px-5 ml-3"
                onClick={() => handleSearch(startDate, endDate)}
              >
                Search
              </Button>
            </div>
            <div className="flex items-center justify-end ">

            </div>
          </div>
          <div className={` mb-5 ${radiologistUserLists.length === 0 ? 'h-48 ' : ''} mr-3`}>
            {radiologistUserLists.length > 0 ? (
              <table className=" bg-primary-dark border-secondary-light  mt-2 w-full table-auto rounded-md border p-4 text-white ">
                <thead className="bg-primary-dark border-secondary-light border" style={{ backgroundColor: '#041c4a' }}>
                  <tr>
                    {headers.map(header => (
                      <th
                        className="p-2"
                        key={header}
                      >
                        {header === 'group'
                          ? 'Institution Group'
                          : header === 'user'
                            ? 'Radiologist User'
                            : header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="mt-1">
                  {radiologistUserLists.map((row, index) => (
                    <tr
                      key={index}
                      className="bg-primary-dark border-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer border text-center transition duration-300"
                    >
                      {headers.map(header => (
                        <td
                          className="p-2"
                          key={header}
                        >
                          {row[header]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className=" bg-primary-dark border-secondary-light mt-2 flex h-full  items-center justify-center border  p-4">
                Data not available.
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StudyStatus;
