import React, { useRef, useState } from 'react';
import Label from '../Label';
import Input from '../Input';
import Button from '../Button';
import Icon from '../Icon';
// import './Physician.css'
import InputMultiSelect from '../InputMultiSelect';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useModal, useReportEditor, useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import Select from '../Select';
import CheckBox from '../CheckBox';



const RadiologistUsersCreateModel = ({
  createUser,
  updateUser,
  fetchUsers,
  setUsersList,
  user,
  editradiologistUserData,
  hidden,
  radiologyGroupList,
  addRadiologyGroupMember,
  deleteRadiologyGroupMember,
  uploadImage,
  defaultReportTemplates
}) => {
  const { show: showSnackbar } = useSnackbar();


  const initialValues = {
    firstName: editradiologistUserData?.firstName || '',
    lastName: editradiologistUserData?.lastName || '',
    username: editradiologistUserData?.username || '',
    email: editradiologistUserData?.email || '',
    password: editradiologistUserData?.password || '',
    confirmPassword: editradiologistUserData?.password || '',
    mobileNumber: editradiologistUserData?.attributes?.mobileNumber || '',
    radiologyGroup: editradiologistUserData?.attributes?.radiologyGroup || [],
    title: editradiologistUserData?.attributes?.title[0] || '',
    registrationNo: editradiologistUserData?.attributes?.registrationNo[0] || '',
    qualification: editradiologistUserData?.attributes?.qualification[0] || '',
    // uploadSignature: editradiologistUserData?.attributes?.uploadSignature || '',
    // templates: editradiologistUserData?.attributes?.templates || [],
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    username: Yup.string().required('User Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: !editradiologistUserData
      ? Yup.string().required('Password is required')
      : Yup.string(),
    confirmPassword: !editradiologistUserData
      ? Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
      : Yup.string(),
    mobileNumber: Yup.number().required('Mobile Number is required'),
    title: Yup.string().required('Title is required'),
    registrationNo: Yup.string().required('Registration No is required'),
    qualification: Yup.string().required('Qualification is required'),
    templates: Yup.array().min(1, 'Selection of templates is required')
    // radiologyGroup: Yup.array().min(1).required('Select at least one Radiology Group is required'),
  });

  const options = [
    { label: 'Default', value: 'Default' },
    ...radiologyGroupList.map(group => ({
      label: group.name,
      value: group.name,
    }))
  ];

  const optionsForTemplete = [
    { label: 'Default', value: 'Default' },
    ...defaultReportTemplates.map(templete => ({
      label: templete.name,
      value: templete.name,
    }))
  ];

  const initialSelectedOptions = editradiologistUserData?.attributes?.radiologyGroup || [];
  const [selectedOptions, setSelectedOptions] = useState(
    initialSelectedOptions.flatMap(option => option)
  );
  // const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [signaturePrompt, setSignaturePrompt] = useState(false);

  const initialSelectedTemplates = editradiologistUserData?.attributes?.templates || []
  const [selectedTemplates, setSelectedTemplates] = useState(initialSelectedTemplates.flatMap(option => option.split(', ')));
  const [passwordeye, setpasswordeye] = useState(false);
  const [confirmPassword, setConfirmPasswordeye] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [files, setFiles] = useState(undefined);
  const [signature, setSignature] = useState(undefined);

  const DEFAULT_TEMPLATE_NAME = 'Default';



  const handleSelectionChange = selected => {
    setSelectedOptions(selected);
  };

  const handleTempletesSelectionChange = selected => {
    if (selected.includes('Default')) {
      setSelectedTemplates(['Default']);
    } else {
      setSelectedTemplates(selected);
    }
  };

  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);

  const hadlerUploadImageChange = (e) => {
    setSignaturePrompt(false)
    const file = e.target.files[0];

    setFiles(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }
  }
  const hadlerRemoveImage = () => {
    setFiles(null);
    setPreview(null);
    setSignature(null);
    setSuccess(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Clear the file input
    }
  }


  const handlerImageUpload = async () => {
    try {
      if (files) {

        let imageUpload;
        if (editradiologistUserData) {
          const oldSignature = editradiologistUserData?.attributes?.uploadSignature[0];

          const removeImageName = oldSignature.split('/').pop();
          imageUpload = await uploadImage(files, removeImageName);

        } else {
          imageUpload = await uploadImage(files);
        }

        if (imageUpload.error) {
          setError(`Error uploading images:- ${imageUpload?.error}`);
        } else {
          setSignature(imageUpload);
          setSuccess("Signature Uploaded Successfully");
        }

      }
    } catch (error) {
      console.error("Error uploading images:", error);
      setError("Failed to upload images");
    }
  }


  const handleSubmit = async (values, { setSubmitting }) => {

    try {
      if (editradiologistUserData) {
        const data = {
          username: values.username,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          attributes: {
            radiologyGroup: selectedOptions.label || selectedOptions[0],
            roleType: ['Radiologist'],
            mobileNumber: values.mobileNumber,
            registrationNo: values.registrationNo,
            title: values.title,
            qualification: values.qualification,
            uploadSignature: signature,
            templates: selectedTemplates
          },
        };
        let response;
        if (signature) {
          data.attributes.uploadSignature = signature;
          response = await updateUser(editradiologistUserData.id, data, user.access_token);
        } else {
          data.attributes.uploadSignature = editradiologistUserData.attributes.uploadSignature;
          response = await updateUser(editradiologistUserData.id, data, user.access_token);
        }
        if (response.status === 204) {
          await fetchUsers(user.access_token)
            .then(data => setUsersList(data))
            .catch(error => console.error('Error fetching users:', error));
          showSnackbar({
            title: 'Success!',
            message: 'Successfully Radiologist User Update',
            type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
            position: 'topRight',
          });
        }
        const findGroup = radiologyGroupList.find(
          group => group.name === initialValues.radiologyGroup[0]
        );
        const findCurrentGroup = radiologyGroupList.find(
          group => group.name === selectedOptions.label
        );

        if (findCurrentGroup && findGroup.id !== findCurrentGroup.id) {
          addRadiologyGroupMember(
            editradiologistUserData.id,
            findCurrentGroup.id,
            user.access_token
          );
          deleteRadiologyGroupMember(editradiologistUserData.id, findGroup.id, user.access_token);
        }
        hidden();
      } else {
        const data = {
          username: values.username,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          attributes: {
            radiologyGroup: selectedOptions.label,
            roleType: ['Radiologist'],
            mobileNumber: values.mobileNumber,
            registrationNo: values.registrationNo,
            title: values.title,
            qualification: values.qualification,
            uploadSignature: '',
            templates: selectedTemplates,
          },
          credentials: [{ type: 'password', temporary: true, value: values.password }],
          enabled: true,
          emailVerified: true,
        };
        let response;
        if (signature) {
          data.attributes.uploadSignature = signature;
          response = await createUser(data, user.access_token);
          if (response.status === 201) {
            await fetchUsers(user.access_token)
              .then(async data => {
                setUsersList(data);
                const findeUser = data.find(items => items.email === values.email);
                const findGroup = radiologyGroupList.find(
                  group => group.name === selectedOptions.label
                );

                await addRadiologyGroupMember(findeUser.id, findGroup.id, user.access_token);
              })
              .catch(error => console.error('Error fetching users:', error));
            showSnackbar({
              title: 'Success!',
              message: 'Successfully Radiologist User Created',
              type: SnackbarTypes.SUCCESS,
              position: 'topRight',
            });
            hidden();
          } else {
            const errorMessage = await response.json();
            setError(errorMessage.errorMessage);
          }
        } else {
          setSignaturePrompt(true); // Set the state variable to show the prompt
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };


  return (
    <section className="w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label className="my-2 block" text="">First Name</Label>
                <Field
                  type="text"
                  name="firstName"
                  as={Input}
                  placeholder="Please enter your first name"
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text="">Last Name</Label>
                <Field
                  type="text"
                  name="lastName"
                  as={Input}
                  placeholder="Please enter your last name"
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text="">User Name</Label>
                <Field
                  type="text"
                  name="username"
                  as={Input}
                  placeholder="Please enter your user name"
                  disabled={editradiologistUserData ? true : false}
                />
                <ErrorMessage
                  name="username"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text="">Mobile Number</Label>
                <Field
                  type="text"
                  name="mobileNumber"
                  as={Input}
                  placeholder="Please enter your mobile number"
                />
                <ErrorMessage
                  name="mobileNumber"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text="">Email</Label>
                <Field
                  type="text"
                  name="email"
                  as={Input}
                  placeholder="Please enter your email name"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="passworld_box">
                <Label className="my-2 block" text="">Password</Label>
                <Field
                  type={passwordeye ? 'text' : 'password'}
                  name="password"
                  as={Input}
                  placeholder="Please enter your password"
                />
                <Icon
                  onClick={() => setpasswordeye(!passwordeye)}
                  name={passwordeye ? 'eye-visible' : 'eye-hidden'}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="passworld_box">
                <Label className="my-2 block" text="">Confirm Password</Label>
                <Field
                  type={confirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  as={Input}
                  placeholder="Please enter your Confirm Password "
                />
                <Icon
                  onClick={() => setConfirmPasswordeye(!confirmPassword)}
                  name={confirmPassword ? 'eye-visible' : 'eye-hidden'}
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text="">Title</Label>
                <Field
                  type="text"
                  name="title"
                  as={Input}
                  placeholder="Please enter your title"
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text="">Registration No</Label>
                <Field
                  type="text"
                  name="registrationNo"
                  as={Input}
                  placeholder="Please enter your registration no"
                />
                <ErrorMessage
                  name="registrationNo"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text="">Qualification</Label>
                <Field
                  type="text"
                  name="qualification"
                  as={Input}
                  placeholder="Please enter your qualification"
                />
                <ErrorMessage
                  name="qualification"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text="">Upload Signature</Label>
                <div className=' flex justify-between items-center'>
                  <input type="file" className=' bg-black mr-5 shadow transition duration-300 appearance-none border border-inputfield-main focus:border-inputfield-focus focus:outline-none disabled:border-inputfield-disabled rounded w-full py-2 px-3 text-sm text-white placeholder-inputfield-placeholder leading-tight' name='uploadSignature' accept='image/*' onChange={hadlerUploadImageChange} ref={fileInputRef}
                  />

                  <button type="button" className=' bg-primary-main box-content inline-flex flex-row items-center text-lg justify-center gap-[5px] justify center p-[8px] py-[5px] outline-none rounded font-semibold' onClick={handlerImageUpload}>Upload</button>
                </div>
                {editradiologistUserData && editradiologistUserData?.attributes?.uploadSignature[0] && <a target="_blank" className="text-green-600 mb-2" href={editradiologistUserData.attributes.uploadSignature[0]} >Preview</a>}
                <Label className={`my-2 mt-2 block h-3 pl-3 text-green-700 mb-2 ${success ? 'block' : 'hidden'}	`} text=''>{success ? success : ''}</Label>
                {signaturePrompt && (
                  <Label className="my-2 mt-2 block h-3 pl-3 text-red-600" text=''>
                    Please Upload Signature First
                  </Label>
                )}
                {
                  preview && (
                    <div className=' flex gap-4'>
                      <img
                        src={preview}
                        alt="Preview"
                        style={{ marginTop: '10px', maxWidth: '100%', height: '100px' }}
                      />
                      <div className=' flex items-center'>
                        <button type="button" onClick={hadlerRemoveImage} className=' bg-primary-main box-content inline-flex flex-row items-center text-sm justify-center gap-[5px] justify center p-[8px] py-[5px] outline-none rounded font-semibold' >Remove</button>
                      </div>
                    </div>
                  )
                }
                <ErrorMessage name="uploadSignature" component="div" className="text-red-600" />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block" text=""> Radiology Group</Label>
                <Field
                  name="radiologyGroup"
                  as={Select}
                  placeholder="Select Radiology Group"
                  options={options}
                  value={
                    selectedOptions
                  }
                  onChange={handleSelectionChange}
                />
                <ErrorMessage
                  name="radiologyGroup"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text=''> Select Templete</Label>
                <Field
                  name="templates"
                  as={InputMultiSelect}
                  placeholder="Select Report Templete"
                  options={optionsForTemplete}
                  value={
                    selectedTemplates
                  }
                  onChange={handleTempletesSelectionChange}
                />
              </div>
            </div>
            <Button
              className="mt-8"
              disabled={isSubmitting}
            >
              {editradiologistUserData ? 'Update Radiologist User' : 'Create Radiologist User'}
            </Button>

            <div className=' text-center'>
              <Label className="my-2 mt-2 block h-3 pl-3 text-red-600	" text=''>{error}</Label>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default RadiologistUsersCreateModel;
