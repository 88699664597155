import React from 'react';

interface SpineAiInterfaceProps {
    fetchedData?: {
        image: string;
        grades: Array<{ [key: string]: number }>;
    };
}

const SpineAiInterface: React.FC<SpineAiInterfaceProps> = ({ fetchedData }) => {
    if (!fetchedData) return null;

    const { image, grades } = fetchedData;

    // Function to handle image download
    const handleDownloadImage = () => {
        if (image) {
            const imageSrc = `data:image/png;base64,${image}`;
            const link = document.createElement("a");
            link.href = imageSrc;
            link.download = "spine_result_image.png";
            link.click();
            alert("Image download initiated!");
        } else {
            alert("No image to download");
        }
    };

    return (
        <div className="spine-ai-interface" style={{ padding: "20px" }}>
            {/* Display Image Section */}
            {image && (
                <div style={{ marginBottom: "20px", position: "relative" }}>
                    <img
                        src={`data:image/png;base64,${image}`}
                        alt="Spine AI Result"
                        style={{ width: "100%", maxHeight: "500px", objectFit: "contain" }}
                    />
                    <button
                        title="Download image"
                        onClick={handleDownloadImage}
                        style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            padding: "5px 10px",
                            backgroundColor: "#808080",
                            color: "#FFFFFF",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontWeight: "bold",
                        }}
                    >
                        ⇩
                    </button>
                </div>
            )}

            {/* Grades Table Section */}
            {grades && grades.length > 0 ? (
                <div>
                    <strong>Grades:</strong>
                    <table
                        style={{
                            width: "100%",
                            borderCollapse: "collapse",
                            marginTop: "20px",
                        }}
                    >
                        <thead>
                            <tr style={{ backgroundColor: "#f5f5f5", textAlign: "left" }}>
                                {/* Dynamically generate column headers based on grade keys */}
                                {Object.keys(grades[0]).map((key) => (
                                    <th
                                        key={key}
                                        style={{
                                            padding: "10px",
                                            border: "1px solid #ddd",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {key}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {grades.map((grade, rowIndex) => (
                                <tr key={rowIndex}>
                                    {/* Populate rows with grade values */}
                                    {Object.values(grade).map((value, colIndex) => (
                                        <td
                                            key={colIndex}
                                            style={{
                                                padding: "10px",
                                                border: "1px solid #ddd",
                                                textAlign: "center",
                                            }}
                                        >
                                            {value}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p>No grades available.</p>
            )}
        </div>
    );
};

export default SpineAiInterface;
