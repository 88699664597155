import React, { useState, useEffect } from 'react';
import { useModal, useReportEditor, useUser } from '../../contextProviders';
import PreviewTemplate from './PreviewTemplate';
import { Typography, Button } from '@ohif/ui';
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { BsSearch } from 'react-icons/bs';
import Input from '../Input';
import Select from '../Select';
import LegacyButtonGroup from '../LegacyButtonGroup';
import LegacyButton from '../LegacyButton';
import Tooltip from '../Tooltip';

// const ranges = [2, 5, 10, 20, 50];
const ranges = [
  { value: '5', label: '5' },
  { value: '10', label: '10' },
  { value: '20', label: '20' },
  { value: '50', label: '50' },
  { value: '100', label: '100' },
];// Options for items per page

const ReportTemplateList = ({ handleClick, handleDeleteTemplate, setDataTemplate, setImageCapture, servicesManager }) => {
  const { usersList, token } = useUser();
  const { t } = useTranslation('TemplateList');
  const { defaultTemplates, currentPage, setCurrentPage,
    resultsPerPage, setResultsPerPage, searchTerm, totalPages,
    setSearchTerm, totalTemplates } = useReportEditor();

  const allTemaplateAccess = token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const { userAuthenticationService } = servicesManager.services;
  const user = userAuthenticationService.getUser();

  const loginUserData = usersList?.filter(data => data.id === user?.profile?.sub) || [];
  const isMobile = window.innerWidth < 770;

  const loginUseremplateName = loginUserData?.map(data => data?.attributes?.templates || []).flat()

  const loginUserTemplateOption = defaultTemplates?.templates?.filter(data => loginUseremplateName?.some(dat => dat === data.name))

  const templateOptions = loginUseremplateName?.includes('Select All') || allTemaplateAccess ? defaultTemplates?.templates || [] : loginUserTemplateOption || [];

  const { show } = useModal();
  const tableHeaders = {
    name: 'Name',
    modality: 'Modality',
    bodyparts: 'Body Parts',
    templategroup: 'Template Group',
    preview: 'Templates',
    update: 'Update',
    delete: 'Delete',
  };

  const keys = Object.keys(tableHeaders);

  const modelShow = templates => {
    show({
      content: PreviewTemplate,
      title: `RadiologistUsersCreateModel: Preview Template`,
      contentProps: { templates },
    });
  };

  const handleUpdated = (id, data) => {
    handleClick(true);
    setImageCapture(data.isCapture);
    setDataTemplate(data);
  };

  const [filteredTemplates, setFilteredTemplates] = useState(templateOptions);
  const [selectedRange, setSelectedRange] = useState({
    value: '5',
    label: '5'
  });


  useEffect(() => {
    setFilteredTemplates(templateOptions);
  }, [templateOptions]);

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const onSelectedRange = selectedRange => {
    setSelectedRange(selectedRange);
    setResultsPerPage(Number(selectedRange.value))
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };


  return (
    <>
      <div className="container relative mx-auto flex w-full flex-col pt-5">
        <div className="md:mb-5 mb-2 flex flex-row justify-between max-md:flex-col">
          <div className="flex md:gap-4 gap-2 max-md:flex-col">
            <div className="flex min-w-[1px] shrink flex-row items-center md:gap-6 gap-2">
              <Typography
                variant="h6"
                className="dark:text-white text-black max-sm:text-[16px]"
                component={'h6'}
              >
                Report Templates
              </Typography>
            </div>
            <div className="relative max-md:hidden">
              <div className="pointer-events-none absolute top-2 left-3 flex items-center">
                <BsSearch className="h-4 w-4 dark:text-white text-black" />
              </div>

              <Formik initialValues={{ searchTerm: '' }}>
                {({ values, setFieldValue }) => (
                  <Form>
                    <Field
                      name="searchTerm"
                      type="text"
                      as={Input}
                      placeholder="Search template..."
                      className="pl-10"
                      value={values.searchTerm}
                      onChange={(e) => {
                        setFieldValue('searchTerm', e.target.value);
                        setSearchTerm(e.target.value)
                        setCurrentPage(1);
                      }}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="flex sm:flex-row sm:items-center sm:justify-between max-md:gap-3 mt-2 max-sm:flex-col-reverse">
            <div className="relative md:hidden">
              <div className="pointer-events-none absolute top-2 left-3 flex items-center">
                <BsSearch className="h-4 w-4 dark:text-white text-black" />
              </div>

              <Formik initialValues={{ searchTerm: '' }}>
                {({ values, setFieldValue }) => (
                  <Form>
                    <Field
                      name="searchTerm"
                      type="text"
                      as={Input}
                      placeholder="Search template..."
                      className="pl-10"
                      value={values.searchTerm}
                      onChange={(e) => {
                        setFieldValue('searchTerm', e.target.value);
                        setSearchTerm(e.target.value)
                        setCurrentPage(1);
                      }}
                    />
                  </Form>
                )}
              </Formik>
            </div>
            <Typography
              variant="h6"
              className="dark:text-primary-light text-black cursor-pointer max-sm:text-[16px]"
              component={'h6'}
              onClick={() => handleClick(true)}
            >
              Create New Report Template
            </Typography>
          </div>
        </div>
      </div>

      <div
        className="container mx-auto mt-2 text-white"
        style={{ maxHeight: isMobile ? "calc(100vh - 282px)" : "calc(100vh - 200px)", overflowX: 'auto', scrollbarColor: '#173239 transparent' }}
      >
        <table className="w-full table-auto text-white">
          <thead className="bg-[#d1d1d1] dark:bg-primary-dark dark:border-primary-main border-secondary-dark sticky top-0 right-0 left-0" >
            <tr className=' max-sm:text-base '>
              {keys.map(key => (
                <th
                  className="p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black"
                  key={key}
                >
                  {tableHeaders[key]}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="mt-1">
            {filteredTemplates && filteredTemplates?.length > 0 ? (
              filteredTemplates?.map(instance => (
                <tr
                  key={instance.id}
                  className="template-row bg-secondary-light dark:bg-secondary-dark dark:border-primary-main border-secondary-dark hover:border-secondary-dark dark:hover:bg-[#414141] hover:bg-primary-light cursor-pointer border text-center transition duration-300 max-sm:text-sm"
                >
                  {keys.map(key => (
                    <td
                      className='p-2 dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black'
                      key={`${instance.id}-${key}`}
                    >
                      {key === 'delete' ? (
                        <div
                          className={` cursor-pointer transition-all flex justify-center items-center hover:text-red-400 text-xl text-[#ff0000]`}
                          onClick={() => handleDeleteTemplate(instance.id)}
                        >
                          <RiDeleteBin6Fill />{' '}
                        </div>
                      ) : key === 'preview' ? (
                        <span
                          className=" cursor-pointer text-green-600"
                          onClick={() => modelShow(instance.templates)}
                        >
                          Preview Template
                        </span>
                      ) : key === 'update' ? (
                        <div
                          className={` flex justify-center items-center cursor-pointer text-xl hover:opacity-60 transition-all`}
                          onClick={() => handleUpdated(instance.id, instance)}
                        >
                          <FaEdit />
                        </div>
                      ) : (
                        instance[key]
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={keys?.length} className="py-20 text-center dark:text-white text-black">No Templates Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination controls */}
      <div className="container relative mx-auto pt-5">
        <div className="flex justify-between">
          <div className="flex items-center gap-4">
            <div className="flex items-center">
              <Tooltip
                content={'Results per page'}
                position="top"
                style={{ padding: '8px', fontWeight: 'normal' }}
              >
                <Select
                  id="rows-per-page"
                  className="border-primary-main relative mr-3 w-24"
                  options={ranges}
                  value={selectedRange}
                  isMulti={false}
                  isClearable={false}
                  isSearchable={false}
                  closeMenuOnSelect={false}
                  menuPlacement="top"
                  // hideSelectedOptions={true}
                  onChange={onSelectedRange}
                />
              </Tooltip>
              {/* <Typography className="text-base opacity-60">Results per page</Typography> */}
              <div className="flex items-center max-md:hidden">
                <Typography variant="h6" className="dark:text-primary-light text-black">
                  {`${t('Number of report templates')}: `}
                </Typography>
                <Typography variant="h6" className="mr-2 dark:text-primary-light text-black" data-cy={'num-studies'}>
                  {totalTemplates}
                </Typography>
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <Typography className="mr-4 text-base opacity-60 dark:text-primary-light text-black">
                Page {currentPage}
              </Typography>
              <LegacyButtonGroup>
                <LegacyButton
                  size="initial"
                  className="px-4 py-2 text-base dark:text-primary-light text-black"
                  color="translucent"
                  border="primary"
                  variant="outlined"
                  onClick={handleFirstPage}
                >
                  {'<<'}
                </LegacyButton>
                <LegacyButton
                  size="initial"
                  className="py-2 px-2 text-base dark:text-primary-light text-black"
                  color="translucent"
                  border="primary"
                  variant="outlined"
                  // onClick={handlePreviousPage}
                  onClick={() => handlePageChange(currentPage - 1)}
                  // disabled={currentPage === 1}
                  disabled={currentPage === 1}
                >
                 {t('<')}
                </LegacyButton>
                <LegacyButton
                  size="initial"
                  className="py-2 px-4 text-base dark:text-primary-light text-black"
                  color="translucent"
                  border="primary"
                  variant="outlined"
                  // onClick={handleNextPage}
                  // disabled={currentPage * resultsPerPage >= filteredTemplates.length}
                  onClick={() => handlePageChange(currentPage + 1)}
                  // disabled={filteredTemplates?.length < resultsPerPage}
                  disabled={currentPage === totalPages}
                >
                 {t('>')}
                </LegacyButton>
              </LegacyButtonGroup>
            </div>
          </div>
        </div>
        <div className='flex md:hidden mt-2 '>
          <Typography variant="h6" className="dark:text-primary-light text-black max-sm:text-[16px]">
            {`${t('Number of report templates')}: `}
          </Typography>
          <Typography variant="h6" className="mr-2 max-sm:text-[16px] dark:text-primary-light text-black" data-cy={'num-studies'}>
            {totalTemplates}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default ReportTemplateList;
