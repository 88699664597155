import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

import Typography from '../Typography';
import { useModal, useRadiologyGroup, useUser } from '../../contextProviders';
import { Header } from '@ohif/ui';
import { useAppConfig } from '@state';
import TechnologistCreateModel from './TechnologistCreateModel';
import { ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { GetCountries } from "react-country-state-city";

const Technologist = ({ servicesManager, extensionManager, hotkeysManager }) => {
  const { usersList, createUser, updateUser, fetchUsers, setUsersList, deleteUser, token, userToken, setToken } = useUser();

  const { radiologyGroupList, addRadiologyGroupMember, deleteRadiologyGroupMember } =
    useRadiologyGroup();
  const hasRadiologistAdmin = token.roleType !== "RadiologyGroupAdmin";
  // const tableHeaders = {
  //   firstName: 'First Name',
  //   lastName: 'Last Name',
  //   mobileNumber: 'Mobile Number',
  //   email: 'Email',
  //   username: 'Username',
  //   title: 'Title',
  //   permission: 'Permission',
  //   country: 'Country',
  //   state: 'State',
  //   address: 'Address',
  //   radiologyGroup: 'Radiology Group',
  //   createdTimestamp: 'Added',
  //   delete: 'Delete',
  // };

  const tableHeadersBase = {
    firstName: 'First Name',
    lastName: 'Last Name',
    mobileNumber: 'Mobile Number',
    email: 'Email',
    username: 'Username',
    title: 'Title',
    permission: 'Permission',
    country: 'Country',
    state: 'State',
    address: 'Address',
    radiologyGroup: 'Radiology Group',
    createdTimestamp: 'Added',
    delete: 'Delete',
  };

  // const tableHeaders = {
  //   ...tableHeadersBase,
  //   ...(token.roleType !== "RadiologyGroupAdmin" && { update: 'Update' }),
  // };
  let tableHeaders;

  if (token.roleType !== "RadiologyGroupAdmin") {
    tableHeaders = {
      ...Object.fromEntries(
        Object.entries(tableHeadersBase).slice(0, Object.keys(tableHeadersBase).indexOf('delete'))
      ),
      update: 'Update', // Insert "update" here
      delete: tableHeadersBase.delete, // Append the original "delete"
    };
  } else {
    tableHeaders = tableHeadersBase;
  }


  const keys = Object.keys(tableHeaders);
  const values = Object.values(tableHeaders);
  const { userAuthenticationService } = servicesManager.services;
  const { show, hide } = useModal();
  const user = userAuthenticationService.getUser();
  const [expandedPermissions, setExpandedPermissions] = useState({});
  const [showMore, setShowMore] = useState({});
  const [countriesList, setCountriesList] = useState([]);

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, [])

  const toggleShowMore = (id) => {
    setShowMore((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // Toggle function to expand or collapse the permission text
  const togglePermissions = (id) => {
    setExpandedPermissions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const limitWords = (text, wordLimit) => {
    const words = text.split(' ');
    return words.length > wordLimit
      ? `${words.slice(0, wordLimit).join(' ')}...`
      : text;
  };

  useEffect(() => {
    const getToken = async () => {
      try {
        const data = {
          token: user.access_token,
        };
        const response = await userToken(data);
        setToken(response);
      } catch (error) {
        console.log(error);
      }
    };
    getToken();
  }, []);

  const handleDelete = async id => {
    Swal.fire({
      // title: 'Are you sure?',
      text: 'Are you sure to delete this user!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then(async result => {
      if (result.isConfirmed) {
        // If the user confirms deletion, proceed with the deletion
        await deleteUser(id, user.access_token)
          .then(async res => {
            Swal.fire({
              title: 'Deleted',
              text: 'User has been Delete Successfully',
              icon: 'success',
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
            });
            if (res.status === 204) {
              await fetchUsers(user.access_token)
                .then(data => setUsersList(data))
                .catch(error => console.error('Error fetching users:', error));
            }
          })
          .catch(error => {
            console.error('Error deleting user:', error);
            Swal.fire({
              title: 'Error!',
              text: 'Failed to delete user. Please try again later.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          // title: 'Cancelled',
          text: 'User Delete Cancel.',
          icon: 'info',
          confirmButtonText: 'OK',
        });
      }
    });
  };

  const [appConfig] = useAppConfig();
  const navigate = useNavigate();
  const location = useLocation();

  const onClickReturnButton = () => {
    const { pathname } = location;
    const dataSourceIdx = pathname.indexOf('/', 1);
    const query = new URLSearchParams(window.location.search);
    const configUrl = query.get('configUrl');

    const dataSourceName = pathname.substring(dataSourceIdx + 1);
    const existingDataSource = extensionManager.getDataSources(dataSourceName);

    const searchQuery = new URLSearchParams();
    if (dataSourceIdx !== -1 && existingDataSource) {
      searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    }

    if (configUrl) {
      searchQuery.append('configUrl', configUrl);
    }

    navigate({
      pathname: '/',
      search: decodeURIComponent(searchQuery.toString()),
    });
  };

  const modelShow = technologistUserData => {
    show({
      content: TechnologistCreateModel,
      title: `TechnologistCreateModel: ${technologistUserData.username ? 'Update Technologist User' : 'Create New Technologist User'} `,
      contentProps: {
        user,
        hide,
        technologistUserData: technologistUserData.username ? technologistUserData : '',
        radiologyGroupList,
        addRadiologyGroupMember,
        deleteRadiologyGroupMember,
        createUser,
        updateUser,
        fetchUsers,
        setUsersList,
        countriesList,
      },
    });
  };

  const technologistUserLists =
    token?.roleType?.includes('RadiologyGroupAdmin') && usersList ?
      usersList?.filter(
        user =>
          user.attributes &&
          user.attributes.roleType &&
          user.attributes.roleType.includes('Technologist') &&
          user?.attributes?.radiologyGroup.includes(token?.radiologyGroup)
      ) :
      usersList &&
      usersList?.filter(
        user =>
          user.attributes &&
          user.attributes.roleType &&
          user.attributes.roleType.includes('Technologist')
      );

  // if (token.roleType !== "RadiologyGroupAdmin") {
  //   tableHeaders.update = 'Update';
  // }

  const safeJoin = (value, delimiter = ', ') => (Array.isArray(value) ? value.join(delimiter) : '');

  return (
    <section className="">
      <Header
        isReturnEnabled={!!appConfig.showStudyList}
        onClickReturnButton={onClickReturnButton}
        WhiteLabeling={appConfig.whiteLabeling}
        loggedInUserDetails={user}
        loggedInUserTokenDetails={token}
        hotkeysManager={hotkeysManager}
        headerOpen={true}
      />
      <ToastContainer />
      <div className='max-sm:px-3'>
        <div className="container relative mx-auto flex flex-col pt-5">
          <div className="mb-5 flex flex-row justify-between">
            <div className="flex min-w-[1px] shrink flex-row items-center gap-6">
              <Typography
                variant="h6"
                className="dark:text-white text-black max-sm:text-[16px]"
                component={'h6'}
              >
                Technologist Users
              </Typography>
            </div>
            {
              hasRadiologistAdmin &&
              <div className="flex h-[34px] flex-row items-center">
                <Typography
                  variant="h6"
                  className="dark:text-white text-black cursor-pointer max-sm:text-[16px]"
                  component={'h6'}
                  onClick={modelShow}
                >
                  Add Technologist
                </Typography>
              </div>
            }
          </div>
        </div>
        <div
          className=" overflow-y-auto container m-auto mt-2 telerapp-scrollbar"
          style={{
            maxHeight: 'calc(100vh - 201px)', // Full height minus header height
            // scrollbarColor: '#173239 transparent',
          }}
        >
          <table className="w-full table-auto text-white">
            <thead className="bg-[#d1d1d1] dark:bg-primary-dark dark:border-primary-main border-secondary-dark border sticky top-0 right-0 left-0">
              <tr className=' max-sm:text-base'>
                {values.map(tableHead => (
                  <th
                    className="p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black"
                    key={'tableHead'}
                  >
                    {tableHead}
                  </th>
                ))}
              </tr>
            </thead>

            {technologistUserLists && technologistUserLists.length <= 0 ? (
              <span className="absolute  flex h-96 w-full items-center justify-center dark:text-white text-black">
                No Technologist User Exiting
              </span>
            ) : (
              <>
                <tbody className="mt-1 ">
                  {technologistUserLists &&
                    technologistUserLists?.map(instance => (
                      <tr className="bg-secondary-light dark:bg-secondary-dark dark:border-primary-main border-secondary-dark hover:border-secondary-dark dark:hover:bg-[#414141] hover:bg-primary-light cursor-pointer border text-center transition duration-300 max-sm:text-sm">
                        {keys.map(key => (
                          <td
                            className="p-2 dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black"
                            key={key}
                          >
                            {key === 'delete' ? (
                              <div
                                className="cursor-pointer transition-all hover:text-red-400 text-xl text-[#ff0000] flex justify-center items-center"
                                onClick={() => handleDelete(instance.id)}
                              >
                                <RiDeleteBin6Fill className=' max-sm:text-lg' />
                              </div>
                            ) : key === 'title' ? (
                              <span className="cursor-pointer">
                                {safeJoin(instance?.attributes?.title)}
                              </span>
                            ) : key === 'update' ? (
                              <div
                                className="cursor-pointer transition-all hover:opacity-10 text-xl flex justify-center items-center"
                                onClick={() => modelShow(instance)}
                              >
                                <FaEdit className=' max-sm:text-lg' />
                              </div>
                            ) : key === 'radiologyGroup' ? (
                              <span
                                className="cursor-pointer"
                                onClick={() => modelShow(instance)}
                              >
                                {safeJoin(instance?.attributes?.radiologyGroup)}
                              </span>
                            ) : key === 'permission' ? (
                              <span className="cursor-pointer">
                                {expandedPermissions[instance.id]
                                  ? safeJoin(instance.attributes.permission)
                                  : limitWords(safeJoin(instance.attributes.permission), 2)}
                                <button
                                  onClick={() => togglePermissions(instance.id)}
                                  className="text-blue-500 ml-2 sm:text-sm text-xs"
                                >
                                  {expandedPermissions[instance.id] ? 'See Less' : 'See More'}
                                </button>
                              </span>
                            ) : key === "country" ? (
                              <span className="cursor-pointer">
                                {instance?.attributes?.userCountryName || ''}
                              </span>
                            ) : key === "state" ? (
                              <span className='cursor-pointer'>
                                {instance?.attributes?.userStateName || ''}
                              </span>
                            ) : key === "address" ? (
                              <span>
                                <span className="cursor-pointer">
                                  {instance?.attributes?.userCityName}{`${showMore[instance.id] ? ',' : ',...'}`}
                                  {showMore[instance.id] && (
                                    <>
                                      <br />
                                      {instance?.attributes?.userDistrict}, <br />
                                      {instance?.attributes?.userStreet} <br />
                                    </>
                                  )}
                                </span>
                                <button
                                  className="text-blue-500 ml-2 sm:text-sm text-xs cursor-pointer"
                                  onClick={() => toggleShowMore(instance.id)}
                                >
                                  {showMore[instance.id] ? "See Less" : "See More"}
                                </button>
                              </span>
                            ) : key === 'mobileNumber' ? (
                              <span className="cursor-pointer">
                                {safeJoin(instance?.attributes?.mobileNumber)}
                              </span>
                            ) : key === 'createdTimestamp' ? (
                              <span className="cursor-pointer">
                                {moment(instance.createdTimestamp).format('MM/DD/YYYY hh:mm:ss A')}
                              </span>
                            ) : (
                              instance[key]
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </>
            )}
          </table>
        </div>
      </div>
    </section>
  );
};

export default Technologist;
