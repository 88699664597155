import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary, useImageViewer } from '@ohif/ui';
import { Toolbar } from '../../../../../extensions/default/src/Toolbar/Toolbar';

import Icon from '../Icon';
import Tooltip from '../Tooltip';

type StyleMap = {
  open: {
    left: { marginLeft: string };
    right: { marginRight: string };
  };
  closed: {
    left: { marginLeft: string };
    right: { marginRight: string };
  };
};
const borderSize = 4;
const collapsedWidth = 25;
const closeIconWidth = 30;
const gridHorizontalPadding = 10;
const tabSpacerWidth = 2;

const baseClasses =
  'transition-all duration-300 ease-in-out border-black justify-start box-content flex flex-col';

const classesMap = {
  open: {
    left: `mr-1`,
    right: `ml-1`,
  },
  closed: {
    left: `mr-2 items-end`,
    right: `ml-2 items-start`,
  },
};
let isMobileLayout;
const openStateIconName = {
  left: 'side-panel-close-left',
  right: 'side-panel-close-right',
};

const getTabWidth = (numTabs: number) => {
  if (numTabs < 3) {
    return 68;
  } else {
    return 40;
  }
};

const getGridWidth = (numTabs: number, gridAvailableWidth: number) => {
  const spacersWidth = (numTabs - 1) * tabSpacerWidth;
  const tabsWidth = getTabWidth(numTabs) * numTabs;

  if (gridAvailableWidth > tabsWidth + spacersWidth) {
    return tabsWidth + spacersWidth;
  }

  return gridAvailableWidth;
};

const getNumGridColumns = (numTabs: number, gridWidth: number) => {
  if (numTabs === 1) {
    return 1;
  }

  // Start by calculating the number of tabs assuming each tab was accompanied by a spacer.
  const tabWidth = getTabWidth(numTabs);
  const numTabsWithOneSpacerEach = Math.floor(gridWidth / (tabWidth + tabSpacerWidth));

  // But there is always one less spacer than tabs, so now check if an extra tab with one less spacer fits.
  if (
    (numTabsWithOneSpacerEach + 1) * tabWidth + numTabsWithOneSpacerEach * tabSpacerWidth <=
    gridWidth
  ) {
    return numTabsWithOneSpacerEach + 1;
  }

  return numTabsWithOneSpacerEach;
};

const getGridStyle = (
  side: string,
  numTabs: number = 0,
  gridWidth: number,
  expandedWidth: number
): CSSProperties => {
  const relativePosition = Math.max(0, Math.floor(expandedWidth - gridWidth) / 2 - closeIconWidth);
  return {
    position: 'relative',
    ...(side === 'left' ? { right: `${relativePosition}px` } : { left: `${relativePosition}px` }),
    width: `${gridWidth}px`,
  };
};

const getTabClassNames = (
  numColumns: number,
  numTabs: number,
  tabIndex: number,
  isActiveTab: boolean,
  isTabDisabled: boolean
) =>
  classnames('h-[28px] mb-[2px] cursor-pointer text-white bg-black', {
    'hover:text-primary-active': !isActiveTab && !isTabDisabled,
    'rounded-l': tabIndex % numColumns === 0,
    'rounded-r': (tabIndex + 1) % numColumns === 0 || tabIndex === numTabs - 1,
  });

const getTabStyle = (numTabs: number) => {
  return {
    width: `${getTabWidth(numTabs)}px`,
  };
};

const getTabIconClassNames = (numTabs: number, isActiveTab: boolean) => {
  return classnames('h-full w-full flex items-center justify-center', {
    'dark:bg-primary-dark bg-primary-light': isActiveTab,
    rounded: isActiveTab,
  });
};
const createStyleMap = (
  expandedWidth: number,
  borderSize: number,
  collapsedWidth: number
): StyleMap => {
  const collapsedHideWidth = expandedWidth - collapsedWidth - borderSize;

  return {
    open: {
      left: { marginLeft: '0px' },
      right: { marginRight: '0px' },
    },
    closed: {
      left: { marginLeft: `${isMobileLayout ? '-180px' : `-${collapsedHideWidth}px`}` },
      right: { marginRight: `-${collapsedHideWidth}px` },
    },
  };
};
let isLeftPanelOpen ;


const getToolTipContent = (label: string, disabled: boolean) => {
  return (
    <>
      <div>{label}</div>
      {disabled && <div className="text-white">{'Not available based on current context'}</div>}
    </>
  );
};

const createBaseStyle = (expandedWidth: number, side: string) => {
  return {
    maxWidth: `${side === 'right' ? '263px' : `${isMobileLayout ? '228px' : '273px'}`}`,
    width: `${side === 'right' ? '263px' : `${isMobileLayout ? '228px' : '273px'}`}`,
    // maxWidth: `${expandedWidth}px`,
    // width: `${expandedWidth}px`,
    // To align the top of the side panel with the top of the viewport grid, use position relative and offset the
    // top by the same top offset as the viewport grid. Also adjust the height so that there is no overflow.
    position: window.innerWidth < 640 ? 'absolute' : 'relative',
    top: '0.2%',
    height: '99.8%',
  };
};
const SidePanel = ({
  side,
  className,
  activeTabIndex: activeTabIndexProp = null,
  tabs,
  onOpen,
  expandedWidth = 248,
  onActiveTabIndexChange,
  servicesManager,
}) => {
  const { t } = useTranslation('SidePanel');
  isMobileLayout = window.innerWidth < 1040;
  const [panelOpen, setPanelOpen] = useState(activeTabIndexProp !== null);
  const [activeTabIndex, setActiveTabIndex] = useState(activeTabIndexProp !== null ? activeTabIndexProp : 0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 640);
  const { isThumbnail, setIsThumbnail } = useImageViewer();
  // Set up a listener to track screen width changes
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Close the panel when the screen width is less than 640px
  useEffect(() => {

    const handleResize = () => {
      if (isSmallScreen) {
        isLeftPanelOpen = false
        setPanelOpen(false);
      }
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (isThumbnail) {
        isLeftPanelOpen = false
        setPanelOpen(false);
      }
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [isThumbnail]);

  const styleMap = createStyleMap(expandedWidth, borderSize, collapsedWidth);
  const baseStyle = createBaseStyle(expandedWidth, side);
  const gridAvailableWidth = expandedWidth - closeIconWidth - gridHorizontalPadding;
  const gridWidth = getGridWidth(tabs.length, gridAvailableWidth);
  const openStatus = panelOpen ? 'open' : 'closed';
  const style = Object.assign({}, styleMap[openStatus][side], baseStyle);

  const ActiveComponent = tabs[activeTabIndex]?.content;

  const updatePanelOpen = useCallback((panelOpen: boolean) => {
    setIsThumbnail(false);
    isLeftPanelOpen = !isLeftPanelOpen
    setPanelOpen(panelOpen);
    if (panelOpen) {
      onOpen?.();
    }
  }, []);

  // const updateActiveTabIndex = useCallback(
  //   (activeTabIndex: number) => {
  //     if (activeTabIndex === null) {
  //       updatePanelOpen(false);
  //       return;
  //     }

  //     setActiveTabIndex(activeTabIndex);
  //     updatePanelOpen(true);
  //   },
  //   [updatePanelOpen]
  // );

  const updateActiveTabIndex = useCallback(
    (activeTabIndex) => {
      if (activeTabIndex !== null) {
        setActiveTabIndex(activeTabIndex);
        updatePanelOpen(true);
      }
    },
    [updatePanelOpen]
  );

  useEffect(() => {
    updateActiveTabIndex(activeTabIndexProp);
  }, [activeTabIndexProp, updateActiveTabIndex]);

  const getViewerIconsComponent = () => {
    const _childComponents = Array.isArray(tabs) ? tabs : [tabs];
    return (
      <>
        <div className={classnames('dark:bg-secondary-dark bg-secondary-light flex flex-col space-y-3 justify-center items-center h-full text-center ')}
          // style={{
          //   // background: 'linear-gradient(to bottom, #090c29 8.9%, #3a3f99 0.1%, #041c4a 9%)',
          //   background: '#090c29',
          //   // height: '100vh', // Ensure the gradient fills the full viewport height
          // }}>
          >
          {_childComponents.map((childComponent, index) => (
            <div>
              <ErrorBoundary context="Primary Toolbar">
                <div className="relative flex flex-col justify-end gap-1">
                  <Toolbar servicesManager={servicesManager} />
                </div>
              </ErrorBoundary>
            </div>

          ))}
        </div>
      </>
    );
  };
  const getCloseStateComponent = () => {
    const _childComponents = Array.isArray(tabs) ? tabs : [tabs];
    return (
      <>
        <div
          className={classnames(
            'bg-secondary-dark flex h-[28px] w-full cursor-pointer items-center rounded-md',
            side === 'left' ? 'justify-end pr-2' : 'justify-start pl-2'
          )}
          onClick={() => {
            updatePanelOpen(prev => !prev);
          }}
          data-cy={`side-panel-header-${side}`}
        >
          <Icon
            name={'navigation-panel-right-reveal'}
            className={classnames('dark:text-white text-black', side === 'left' && 'rotate-180 transform')}
          />
        </div>
        <div className={classnames(' dark:bg-black bg-secondary-light max-sm:mt-3 flex flex-col space-y-3 justify-center items-center h-full text-center w-[16%]')}>
          {_childComponents.map((childComponent, index) => (
            <div>
              <Tooltip
                position={side === 'left' ? 'right' : 'left'}
                key={index}
                content={`${childComponent.label}`}
                className={classnames(
                  'flex items-center mb-2',
                  side === 'left' ? 'justify-center ' : 'justify-start '
                )}
              >
                <div
                  id={`${childComponent.name}-btn`}
                  data-cy={`${childComponent.name}-btn`}
                  className="dark:text-white text-black hover:cursor-pointer"
                  onClick={() => {
                    updateActiveTabIndex(index);
                  }}
                >
                  <Icon
                    name={childComponent.iconName}
                    className="dark:text-white text-black"
                    style={{
                      width: '22px',
                      height: '22px',
                    }}
                  />
                </div>
              </Tooltip>

              {side === 'left' && (
                <ErrorBoundary context="Primary Toolbar">
                  <div className="relative flex flex-col justify-end gap-1">
                    <Toolbar servicesManager={servicesManager} />
                  </div>
                </ErrorBoundary>
              )}
            </div>

          ))}
        </div>
      </>
    );
  };

  const getCloseIcon = () => {
    return (
      <div
        className={classnames(
          'flex h-[28px] cursor-pointer items-center justify-center',
          side === 'left' ? 'order-last' : 'order-first'
        )}
        style={{ width: `${closeIconWidth}px` }}
        onClick={() => {
          updatePanelOpen(prev => !prev);
        }}
        data-cy={`side-panel-header-${side}`}
      >
        <Icon
          name={openStateIconName[side]}
          className="dark:text-white text-black"
        />
      </div>
    );
  };

  const getTabGridComponent = () => {
    const numCols = getNumGridColumns(tabs.length, gridWidth);

    return (
      <div className={classnames('flex grow ', side === 'right' ? 'justify-start' : 'justify-end')}>
        <div
          className={classnames(' text-white flex flex-wrap')}
          style={getGridStyle(side, tabs.length, gridWidth, expandedWidth)}
        >
          {tabs.map((tab, tabIndex) => {
            const { disabled } = tab;
            return (
              <React.Fragment key={tabIndex}>
                {tabIndex % numCols !== 0 && (
                  <div
                    className={classnames(
                      'flex h-[28px] w-[2px] items-center bg-black',
                      tabSpacerWidth
                    )}
                  >
                    <div className="bg-primary-dark h-[20px] w-full"></div>
                  </div>
                )}
                <Tooltip
                  position={'bottom'}
                  key={tabIndex}
                  content={getToolTipContent(tab.label, disabled)}
                >
                  <div
                    className={getTabClassNames(
                      numCols,
                      tabs.length,
                      tabIndex,
                      tabIndex === activeTabIndex,
                      disabled
                    )}
                    style={getTabStyle(tabs.length)}
                    onClick={() => {
                      return disabled ? null : updateActiveTabIndex(tabIndex);
                    }}
                    data-cy={`${tab.name}-btn`}
                  >
                    <div className={getTabIconClassNames(tabs.length, tabIndex === activeTabIndex)}>
                      <Icon
                        className="text-white"
                        name={tab.iconName}
                        className={`${tab.disabled && 'ohif-disabled'}`}
                        style={{
                          width: '22px',
                          height: '22px',
                        }}
                      ></Icon>
                    </div>
                  </div>
                </Tooltip>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };

  const getOneTabComponent = () => {
    return (
      <div
        className={classnames(
          'dark:text-white text-black flex grow cursor-pointer select-none justify-center self-center text-[13px]'
        )}
        style={{
          ...(side === 'left'
            ? { marginLeft: `${closeIconWidth}px` }
            : { marginRight: `${closeIconWidth}px` }),
        }}
        data-cy={`${tabs[0].name}-btn`}
        onClick={() => updatePanelOpen(prev => !prev)}
      >
        <span>{tabs[0].label}</span>
      </div>
    );
  };

  const getOpenStateComponent = () => {
    return (
      <div className="dark:bg-secondary-dark bg-secondary-light flex rounded-t pt-1.5 pb-[2px]	">
        {getCloseIcon()}
        {tabs.length === 1 ? getOneTabComponent() : getTabGridComponent()}
      </div>
    );
  };

  return (
    <div
      className={classnames(className, baseClasses, classesMap[openStatus][side], {
        'max-sm:absolute max-sm:top-0 max-sm:left-0 max-sm:bottom-0 max-sm:z-10': isSmallScreen && side === 'left',
        'max-sm:absolute max-sm:top-0 max-sm:right-0 max-sm:bottom-0 max-sm:z-10': isSmallScreen && side === 'right',
      })}
      style={style}
    >
      {panelOpen ? (
        <>
          {getOpenStateComponent()}
          <div className=' flex flex-row max-sm:mt-1' style={{ maxHeight: 'calc(100vh - 91px)', minHeight: 'calc(100vh - 91px)', }}>
            {side === 'left' && (
              <div>
                {getViewerIconsComponent()}
              </div>
            )}
            <div className=' w-full'>
              <ActiveComponent />
            </div>
          </div>
        </>
      ) : (
        <React.Fragment>{getCloseStateComponent()}</React.Fragment>
      )}
    </div>
  );
};

SidePanel.defaultProps = {
  defaultComponentOpen: null,
  activeTabIndex: null, // the default is to close the side panel
};

SidePanel.propTypes = {
  side: PropTypes.oneOf(['left', 'right']).isRequired,
  className: PropTypes.string,
  activeTabIndex: PropTypes.number,
  tabs: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        iconName: PropTypes.string.isRequired,
        iconLabel: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        content: PropTypes.func, // TODO: Should be node, but it keeps complaining?
      })
    ),
  ]),
  onOpen: PropTypes.func,
  onActiveTabIndexChange: PropTypes.func,
  expandedWidth: PropTypes.number,
};

export default SidePanel;
