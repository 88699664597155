// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.template-checkbox {
  -webkit-box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
          box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  margin-bottom: 10px;
  padding: 7px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.template-checkbox:hover {
  -webkit-box-shadow:
    rgba(60, 64, 67, 0.3) 0px 2px 4px,
    rgba(60, 64, 67, 0.3) 0px 7px 13px -3px,
    rgba(60, 64, 67, 0.3) 0px -3px 0px inset;
          box-shadow:
    rgba(60, 64, 67, 0.3) 0px 2px 4px,
    rgba(60, 64, 67, 0.3) 0px 7px 13px -3px,
    rgba(60, 64, 67, 0.3) 0px -3px 0px inset;
}
.hedingdesign {
  margin: 0 0 18px 50px;
  font-size: 20px;
}
.template-checkbox input {
  width: 20px;
  height: 20px;
  margin-right: 18px;
}
.template-checkbox span {
  font-size: 14px;
  text-transform: capitalize;
}
.headingMaindesign {
  text-align: center;
  font-size: 28px;
  margin: 0 0 20px 0;
}
.ck .ck-widget.ck-widget_selected > .ck-widget__type-around > .ck-widget__type-around__button,
.ck .ck-widget:hover > .ck-widget__type-around > .ck-widget__type-around__button {
  display: none;
}
.ck
  .ck-widget.ck-widget_with-selection-handle.ck-widget_selected:hover
  > .ck-widget__selection-handle,
.ck .ck-widget.ck-widget_with-selection-handle.ck-widget_selected > .ck-widget__selection-handle {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/ReportTemplate/ReportTemplate.css"],"names":[],"mappings":"AAAA;EACE;;0CAEwC;UAFxC;;0CAEwC;EACxC,mBAAmB;EACnB,iBAAiB;EACjB,oBAAa;EAAb,oBAAa;EAAb,aAAa;EACb,yBAAmB;MAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE;;;4CAG0C;UAH1C;;;4CAG0C;AAC5C;AACA;EACE,qBAAqB;EACrB,eAAe;AACjB;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,0BAA0B;AAC5B;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,kBAAkB;AACpB;AAEA;;EAEE,aAAa;AACf;AAEA;;;;EAIE,aAAa;AACf","sourcesContent":[".template-checkbox {\n  box-shadow:\n    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,\n    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;\n  margin-bottom: 10px;\n  padding: 7px 15px;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n.template-checkbox:hover {\n  box-shadow:\n    rgba(60, 64, 67, 0.3) 0px 2px 4px,\n    rgba(60, 64, 67, 0.3) 0px 7px 13px -3px,\n    rgba(60, 64, 67, 0.3) 0px -3px 0px inset;\n}\n.hedingdesign {\n  margin: 0 0 18px 50px;\n  font-size: 20px;\n}\n\n.template-checkbox input {\n  width: 20px;\n  height: 20px;\n  margin-right: 18px;\n}\n.template-checkbox span {\n  font-size: 14px;\n  text-transform: capitalize;\n}\n.headingMaindesign {\n  text-align: center;\n  font-size: 28px;\n  margin: 0 0 20px 0;\n}\n\n.ck .ck-widget.ck-widget_selected > .ck-widget__type-around > .ck-widget__type-around__button,\n.ck .ck-widget:hover > .ck-widget__type-around > .ck-widget__type-around__button {\n  display: none;\n}\n\n.ck\n  .ck-widget.ck-widget_with-selection-handle.ck-widget_selected:hover\n  > .ck-widget__selection-handle,\n.ck .ck-widget.ck-widget_with-selection-handle.ck-widget_selected > .ck-widget__selection-handle {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
