import React, { useState } from 'react';
import { Dashboard } from '@ohif/ui';
import Reports from './Reports';
import Users from './Users';
import Institutions from './Institutions';
import './style.css';
import StudyStatus from './StudyStatus';

const Analytics = ({ servicesManager, extensionManager, hotkeysManager, data: studies }) => {
    const [activeTab, setActiveTab] = useState('Reports');

    const renderContent = () => {
        switch (activeTab) {
            case 'Reports':
                return <Reports />;
            case 'Users':
                return <Users />;
            case 'Institutions':
                return <Institutions />;
            case 'StudyStatus':
                return (
                    <StudyStatus
                        servicesManager={servicesManager}
                        extensionManager={extensionManager}
                        hotkeysManager={hotkeysManager}
                    />
                );
            default:
                return <Reports />;
        }
    };

    return (
        <section className="relative w-full text-white">
            <Dashboard
                servicesManager={servicesManager}
                extensionManager={extensionManager}
                hotkeysManager={hotkeysManager}
                sortedStudies={studies}
            />
            <div className="absolute top-16 left-[250px] w-10/12">
                <div className="tabs" style={{ display: 'flex', justifyContent: 'center' }} >
                    <button
                        className={`tab-button hover:opacity-80 ${activeTab === 'Reports' ? 'active' : ''}`}
                        onClick={() => setActiveTab('Reports')}
                    >
                        Reports
                    </button>
                    <button
                        className={`tab-button hover:opacity-80 ${activeTab === 'Users' ? 'active' : ''}`}
                        onClick={() => setActiveTab('Users')}
                    >
                        Users
                    </button>
                    <button
                        className={`tab-button hover:opacity-80 ${activeTab === 'Institutions' ? 'active' : ''}`}
                        onClick={() => setActiveTab('Institutions')}
                    >
                        Institutions
                    </button>
                    <button
                        className={`tab-button hover:opacity-80 ${activeTab === 'StudyStatus' ? 'active' : ''}`}
                        onClick={() => setActiveTab('StudyStatus')}
                    >
                        Study status
                    </button>
                </div>
                {renderContent()}
            </div>
        </section>
    );
};

export default Analytics;
