// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Input fields (Search, Date) */
.custom-input {
    -webkit-appearance: none;
               -moz-appearance: none;
            appearance: none;
    /* Remove default appearance */
    background-color: black;
    /* Black background */
    color: lightgray;
    /* Light gray text */
    border: 2px solid rgb(65, 65, 176);
    /* Blue border */
    border-radius: 5px;
    /* Rounded corners */
    padding: 6px 10px;
    /* Slim padding */
    font-size: 13px;
    /* Font size */
    -webkit-transition: border-color 0.3s ease;
    transition: border-color 0.3s ease;
    /* Smooth transition on focus */
    width: 180px;
    /* Full width */
    height: 25px;
    /* Ensuring consistent height */
    line-height: 1.2;
    /* Align text better within the input */
}
.custom-input.search-box {
    width: 225px;
    /* Larger width for the search box */
}`, "",{"version":3,"sources":["webpack://./../../ui/src/components/UsersStatus/UsersStatus.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;IACI,wBAAgB;eAAhB,qBAAgB;YAAhB,gBAAgB;IAChB,8BAA8B;IAC9B,uBAAuB;IACvB,qBAAqB;IACrB,gBAAgB;IAChB,oBAAoB;IACpB,kCAAkC;IAClC,gBAAgB;IAChB,kBAAkB;IAClB,oBAAoB;IACpB,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;IACf,cAAc;IACd,0CAAkC;IAAlC,kCAAkC;IAClC,+BAA+B;IAC/B,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,+BAA+B;IAC/B,gBAAgB;IAChB,uCAAuC;AAC3C;AAGA;IACI,YAAY;IACZ,oCAAoC;AACxC","sourcesContent":["/* Input fields (Search, Date) */\n.custom-input {\n    appearance: none;\n    /* Remove default appearance */\n    background-color: black;\n    /* Black background */\n    color: lightgray;\n    /* Light gray text */\n    border: 2px solid rgb(65, 65, 176);\n    /* Blue border */\n    border-radius: 5px;\n    /* Rounded corners */\n    padding: 6px 10px;\n    /* Slim padding */\n    font-size: 13px;\n    /* Font size */\n    transition: border-color 0.3s ease;\n    /* Smooth transition on focus */\n    width: 180px;\n    /* Full width */\n    height: 25px;\n    /* Ensuring consistent height */\n    line-height: 1.2;\n    /* Align text better within the input */\n}\n\n\n.custom-input.search-box {\n    width: 225px;\n    /* Larger width for the search box */\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
