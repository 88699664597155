import React, { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import {
    fetchStudyCount,
    fetchRadiologistActiveListData,
    fetchReportsByPriorityAndDateRange,
    fetchReportsByModalityAndDateRange,
    fetchReportsByDocumentStatusAndDateRange,
    fetchApprovedReportsByModalityAndDateRange,
    fetchReportsByDateAnalysis,
    fetchReportsByModalityAndInstitute,
    fetchStudyStatusByPriorityAndDocumentStatus,
    fetchReportsByWeekAnlyt,
    fetchReportsByUserAndModality,
    fetchUserWeeklyPerformance,
    fetchModalityWiseAverageTAT,
    fetchInstituteWeeklyPerformanceData
} from '../requestHandler';
import { useUser } from './UserProvider';

export const AnalyticsContext = createContext(null);

export const AnalyticsProvider = ({ children }) => {
    const [studyCount, setStudyCount] = useState(0);
    const [radiologistData, setRadiologistData] = useState()
    const [reportsByPriority, setReportsByPriority] = useState([]);
    const [reportsByModality, setReportsByModality] = useState([]);
    const [reportsByDocumentStatus, setReportsByDocumentStatus] = useState([]);
    const [approvedReportsByModality, setApprovedReportsByModality] = useState([]);
    const [reportsByDate, setReportsByDate] = useState([]);
    const [reportsByModalityAndInst, setReportsByModalityAndInst] = useState([]);
    const [studyStatusByPriorityAndDocument, setStudyStatusByPriorityAndDocument] = useState([]);
    const [reportsByWeek, setReportsByWeek] = useState([]);
    const [reportsByUserAndModal, setReportsByUserAndModal] = useState([]);
    const [userWeeklyPerform, setUserWeeklyPerform] = useState([]);
    const [modalityWiseAverageTATs, setModalityWiseAverageTATs] = useState([]);
    const [instituteWeeklyPerform, setInstituteWeeklyPerform] = useState([]);


    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const username = "";
    const instituteName = "";

    const { token } = useUser()
    const currentuser = token?.roleType?.includes('RadiologyGroupAdmin')
    const radiologyGroupName = token.radiologyGroup
    // const radiologyGroupName = 'Utkarsh scan'

    useEffect(() => {
        fetchStudyCount()
            .then(data => setStudyCount(data))
            .catch(error => console.error('Error fetching study count:', error));
    }, []);

    useEffect(() => {
        fetchRadiologistActiveListData()
            .then(data => setRadiologistData(data))
            .catch(error => console.error('Error fetching study count:', error));
    }, []);

    useEffect(() => {
        const params = currentuser ? [startDate, endDate, radiologyGroupName] : [startDate, endDate];
        if (startDate && endDate) {
            fetchReportsByPriorityAndDateRange(...params);
            // fetchReportsByPriorityAndDateRange(startDate, endDate);
            fetchReportsByModalityAndDateRange(...params)
            // fetchReportsByModalityAndDateRange(startDate, endDate)
            fetchReportsByDocumentStatusAndDateRange(...params)
            // fetchReportsByDocumentStatusAndDateRange(startDate, endDate)
            fetchApprovedReportsByModalityAndDateRange(...params)
            // fetchApprovedReportsByModalityAndDateRange(startDate, endDate)
            fetchReportsByDateAnalysis(...params)
            // fetchReportsByDateAnalysis(startDate, endDate)
            fetchReportsByModalityAndInstitute(...params)
            // fetchReportsByModalityAndInstitute(startDate, endDate)
            fetchStudyStatusByPriorityAndDocumentStatus(...params)
            // fetchStudyStatusByPriorityAndDocumentStatus(startDate, endDate)
            fetchReportsByWeekAnlyt(...params)
            // fetchReportsByWeekAnlyt(startDate, endDate)
            fetchReportsByUserAndModality(...params)
            // fetchReportsByUserAndModality(startDate, endDate)
            username && fetchUserWeeklyPerformance(username, startDate, endDate)
            fetchModalityWiseAverageTAT(...params)
            // fetchModalityWiseAverageTAT(startDate, endDate)
            instituteName && fetchInstituteWeeklyPerformanceData(instituteName, startDate, endDate)
        }
    }, [startDate, endDate, username, instituteName, radiologyGroupName, currentuser]);

    const fetchReportsByPriority = useCallback(async (startDate, endDate, radiologyGroupName) => {

        try {
            const params = currentuser ? [startDate, endDate, radiologyGroupName] : [startDate, endDate];
            const data = await fetchReportsByPriorityAndDateRange(...params);
            setReportsByPriority(data);
        } catch (error) {
            console.error('Error fetching reports by priority:', error);
        }
    }, [currentuser]);

    const fetchReportsByModality = useCallback(async (startDate, endDate, radiologyGroupName) => {
        try {
            const params = currentuser ? [startDate, endDate, radiologyGroupName] : [startDate, endDate];
            const data = await fetchReportsByModalityAndDateRange(...params);
            setReportsByModality(data);
        } catch (error) {
            console.error('Error fetching reports by modality:', error);
        }
    }, [currentuser]);

    const fetchReportsByDocumentStatus = useCallback(async (startDate, endDate, radiologyGroupName) => {
        try {
            const params = currentuser ? [startDate, endDate, radiologyGroupName] : [startDate, endDate];
            const data = await fetchReportsByDocumentStatusAndDateRange(...params);
            setReportsByDocumentStatus(data);
        } catch (error) {
            console.error('Error fetching reports by document status:', error);
        }
    }, [currentuser]);

    const fetchApprovedReportsByModality = useCallback(async (startDate, endDate, radiologyGroupName) => {
        try {
            const params = currentuser ? [startDate, endDate, radiologyGroupName] : [startDate, endDate];
            const data = await fetchApprovedReportsByModalityAndDateRange(...params);
            setApprovedReportsByModality(data);
        } catch (error) {
            console.error('Error fetching approved reports by modality:', error);
        }
    }, [currentuser]);

    const fetchReportsByDate = useCallback(async (startDate, endDate, radiologyGroupName) => {
        try {
            const params = currentuser ? [startDate, endDate, radiologyGroupName] : [startDate, endDate];
            const data = await fetchReportsByDateAnalysis(...params);
            setReportsByDate(data);
        } catch (error) {
            console.error('Error fetching reports by date analysis:', error);
        }
    }, [currentuser]);

    const fetchReportsByModalityAndInst = useCallback(async (startDate, endDate, radiologyGroupName) => {
        try {
            const params = currentuser ? [startDate, endDate, radiologyGroupName] : [startDate, endDate];
            const data = await fetchReportsByModalityAndInstitute(...params);
            setReportsByModalityAndInst(data);
        } catch (error) {
            console.error('Error fetching reports by modality and institute:', error);
        }
    }, [currentuser]);

    const fetchStudyStatusByPriorityAndDocument = useCallback(async (startDate, endDate, radiologyGroupName) => {
        try {
            const params = currentuser ? [startDate, endDate, radiologyGroupName] : [startDate, endDate];
            const data = await fetchStudyStatusByPriorityAndDocumentStatus(...params);
            setStudyStatusByPriorityAndDocument(data);
        } catch (error) {
            console.error('Error fetching study status by priority and document:', error);
        }
    }, [currentuser]);

    const fetchReportsByWeek = useCallback(async (startDate, endDate, radiologyGroupName) => {
        try {
            const params = currentuser ? [startDate, endDate, radiologyGroupName] : [startDate, endDate];
            const data = await fetchReportsByWeekAnlyt(...params);
            setReportsByWeek(data);
        } catch (error) {
            console.error('Error fetching reports by week analysis:', error);
        }
    }, [currentuser]);

    const fetchReportsByUserAndModal = useCallback(async (startDate, endDate, radiologyGroupName) => {
        const params = currentuser ? [startDate, endDate, radiologyGroupName] : [startDate, endDate];
        try {
            const data = await fetchReportsByUserAndModality(...params);
            setReportsByUserAndModal(data);
        } catch (error) {
            console.error('Error fetching reports by user and modality:', error);
        }
    }, [currentuser]);

    const fetchUserWeeklyPerform = useCallback(async (username, startDate, endDate) => {
        try {
            const data = await fetchUserWeeklyPerformance(username, startDate, endDate);
            setUserWeeklyPerform(data);
        } catch (error) {
            console.error('Error fetching user weekly performance:', error);
        }
    }, []);

    const fetchInstituteWeeklyPerform = useCallback(async (institutionName, startDate, endDate) => {
        try {
            const data = await fetchInstituteWeeklyPerformanceData(institutionName, startDate, endDate);
            setInstituteWeeklyPerform(data);
        } catch (error) {
            console.error('Error fetching institution weekly performance:', error);
        }
    }, []);

    const fetchModalityWiseAverageTATs = useCallback(async (startDate, endDate, radiologyGroupName) => {
        try {
            const params = currentuser ? [startDate, endDate, radiologyGroupName] : [startDate, endDate];
            const data = await fetchModalityWiseAverageTAT(...params);
            setModalityWiseAverageTATs(data);
        } catch (error) {
            console.error('Error fetching modality wise average TAT:', error);
        }
    }, [currentuser]);

    const value = useMemo(
        () => ({
            studyCount,
            reportsByPriority,
            reportsByModality,
            reportsByDocumentStatus,
            approvedReportsByModality,
            reportsByDate,
            reportsByModalityAndInst,
            studyStatusByPriorityAndDocument,
            reportsByWeek,
            instituteWeeklyPerform,
            reportsByUserAndModal,
            userWeeklyPerform,
            modalityWiseAverageTATs,
            radiologistData,
            fetchStudyCount,
            fetchRadiologistActiveListData,
            fetchReportsByPriority,
            fetchReportsByModality,
            fetchReportsByDocumentStatus,
            fetchApprovedReportsByModality,
            fetchReportsByDate,
            fetchReportsByModalityAndInst,
            fetchStudyStatusByPriorityAndDocument,
            fetchReportsByWeek,
            fetchReportsByUserAndModal,
            fetchUserWeeklyPerform,
            fetchInstituteWeeklyPerform,
            fetchModalityWiseAverageTATs,
            startDate,
            setStartDate,
            endDate,
            setEndDate,
        }),
        [
            radiologistData,
            studyCount,
            reportsByPriority,
            reportsByModality,
            reportsByDocumentStatus,
            approvedReportsByModality,
            reportsByDate,
            reportsByModalityAndInst,
            studyStatusByPriorityAndDocument,
            reportsByWeek,
            reportsByUserAndModal,
            instituteWeeklyPerform,
            userWeeklyPerform,
            modalityWiseAverageTATs,
            startDate,
            endDate,
        ]
    );

    return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};

export const useAnalytics = () => useContext(AnalyticsContext);
