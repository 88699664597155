import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Select from '../Select';
import Typography from '../Typography';
import Button from '../Button';

import { Formik, Form, Field } from 'formik';
import Label from '../Label';
import { useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';


const RadiologyPatientInformationSetting = ({ onCancel, createRadiologyGroupSetting, updateRadiologyGroupSetting, patientInformationSetting, fetchRadiologyGroupSetting, setRadiologyGroupSetting, radiologyGroupList  }) => {
    const { show } = useSnackbar();
    const { t } = useTranslation('UserPreferencesModal');
    const fieldData = patientInformationSetting ? JSON.parse(patientInformationSetting.field[0]) : null;

    const initialValues = {
        groupName: fieldData?.radiologyGroupNamePatientInformation || '',
        information: fieldData?.information || '',
    };

    const [selectedGroupPatientInformation, setSelectedGroupPatientInformation] = useState(fieldData?.information || null);
    const [selectedGroupName, setSelectedGroupName] = useState(fieldData?.radiologyGroupNamePatientInformation || null);

    const onSubmitHandler = async (values, { setSubmitting }) => {
        try {
            if (patientInformationSetting) {


                const data = {
                    field: [
                        JSON.stringify({
                            radiologyGroupNamePatientInformation: selectedGroupName,
                            information: selectedGroupPatientInformation,
                        })
                    ],
                };
                const response = await updateRadiologyGroupSetting(patientInformationSetting.id, data);
                if (response.status) {
                    fetchRadiologyGroupSetting()
                        .then(data => setRadiologyGroupSetting(data))
                        .catch(error => console.error('Error fetching radiology groups setting :', error));
                    show({
                        title: "Success!",
                        message: "Successfully Update Radiology Group Setting",
                        type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
                        position: "topRight"
                    });
                    onCancel()
                }
            } else {
                const data = {
                    name: 'RadiologyGroupPatientInformationSetting',
                    field: [
                        JSON.stringify({
                            radiologyGroupNamePatientInformation: selectedGroupName,
                            information: selectedGroupPatientInformation,
                        })
                    ],
                };
                const response = await createRadiologyGroupSetting(data);
                if (response.status) {
                    fetchRadiologyGroupSetting()
                        .then(data => setRadiologyGroupSetting(data))
                        .catch(error => console.error('Error fetching radiology groups setting :', error));
                    show({
                        title: "Success!",
                        message: "Successfully Saved Radiology Group Setting ",
                        type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
                        position: "topRight"
                    });
                    onCancel()
                }
            }
            setSubmitting(false);
        } catch (error) {
            console.log(error.message);
        }
    };

  const optionGroup = radiologyGroupList.map(group => ({
    label: group.name,
    value: group.name
  }));

    const optionPatientInformation = [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
    ];

    const handleSelectionPatientInformationChange = (selected) => {
        setSelectedGroupPatientInformation(selected.value);
    };
    const handleSelectionGroupNameChange = (selected) => {
        setSelectedGroupName(selected.value);
    };


    const Section = ({ title, children }) => (
        <>
            <div className="mb-2 border-b-2 border-black">
                <Typography
                    variant="inherit"
                    color="primaryLight"
                    className="flex pb-2 text-[16px] font-semibold !leading-[1.2]"
                >
                    {title}
                </Typography>
            </div>
            <div className="mt-4 mb-8">{children}</div>
        </>
    );

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmitHandler}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <Section title={t(' Radiology Group Patient Information Setting')}>
                            <div className='flex items-end'>
                                <div className="grid grid-cols-2 gap-4">
                                    <div className='w-full'>
                                        <Label className="my-2 block">Radiology Group Name</Label>
                                        <Field
                                            className="mr-5"
                                            name="groupName"
                                            placeholder={'Select Radiology Group Name'}
                                            onChange={handleSelectionGroupNameChange}
                                            as={Select}
                                            options={optionGroup}
                                            value={[selectedGroupName]}
                                        />
                                    </div>
                                    <div className='w-72 '>
                                        <Label className="my-2 block">Information</Label>
                                        <Field
                                            as={Select}
                                            placeholder={'Select...'}
                                            options={optionPatientInformation}
                                            className="mr-5"
                                            name="information"
                                            value={[selectedGroupPatientInformation]}
                                            onChange={handleSelectionPatientInformationChange}
                                        />
                                    </div>
                                </div>
                                <Button disabled={isSubmitting}>
                                    Save
                                </Button>
                            </div>
                        </Section>
                    </Form>
                )}
            </Formik>

        </>
    );
};

export default RadiologyPatientInformationSetting;
