// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-tel-input .selected-flag{
    background-color: black;
}
.react-tel-input .selected-flag:hover{
    background-color: rgb(58 63 153);
}
.react-tel-input .flag-dropdown {
    border-color: rgb(58 63 153);
}
.react-tel-input .flag-dropdown.open .selected-flag {
    background-color: black;
}
.react-tel-input .country-list {
    background-color: black;
    background-color: 'black' !important;
}
.react-tel-input .country-list .country:hover {
    background-color: rgb(58 63 153) !important;
}
.react-tel-input .country-list .country.highlight {
    background-color: rgb(58 63 153) !important;
}
.downloadbutton span{
    /* min-width: 99px; */
    position: relative;
  }
.buttonloader::after {
    content: '';
    display: block;
    width: 1.4em;
    height: 1.4em;
    position: absolute;
    right: 0;
    top: calc(50% - 0.75em);
    border: 0.15em solid transparent;
    border-right-color: white;
    border-radius: 50%;
    -webkit-animation: button-anim 0.7s linear infinite;
            animation: button-anim 0.7s linear infinite;
    opacity: 1;
  }
@-webkit-keyframes button-anim {
    from {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
@keyframes button-anim {
    from {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }`, "",{"version":3,"sources":["webpack://./../../ui/src/components/ShareModel/shareModel.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;AACA;IACI,gCAAgC;AACpC;AACA;IACI,4BAA4B;AAChC;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,uBAAuB;IAGvB,oCAAoC;AAFxC;AAIA;IACI,2CAA2C;AAC/C;AACA;IACI,2CAA2C;AAC/C;AAEA;IACI,qBAAqB;IACrB,kBAAkB;EACpB;AACA;IACE,WAAW;IACX,cAAc;IACd,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,QAAQ;IACR,uBAAuB;IACvB,gCAAgC;IAChC,yBAAyB;IACzB,kBAAkB;IAClB,mDAA2C;YAA3C,2CAA2C;IAC3C,UAAU;EACZ;AACA;IACE;MACE,4BAAoB;cAApB,oBAAoB;IACtB;IACA;MACE,iCAAyB;cAAzB,yBAAyB;IAC3B;EACF;AAPA;IACE;MACE,4BAAoB;cAApB,oBAAoB;IACtB;IACA;MACE,iCAAyB;cAAzB,yBAAyB;IAC3B;EACF","sourcesContent":[".react-tel-input .selected-flag{\n    background-color: black;\n}\n.react-tel-input .selected-flag:hover{\n    background-color: rgb(58 63 153);\n}\n.react-tel-input .flag-dropdown {\n    border-color: rgb(58 63 153);\n}\n.react-tel-input .flag-dropdown.open .selected-flag {\n    background-color: black;\n}\n.react-tel-input .country-list {\n    background-color: black;\n}\n.react-tel-input .country-list {\n    background-color: 'black' !important;\n}\n.react-tel-input .country-list .country:hover {\n    background-color: rgb(58 63 153) !important;\n}\n.react-tel-input .country-list .country.highlight {\n    background-color: rgb(58 63 153) !important;\n}\n\n.downloadbutton span{\n    /* min-width: 99px; */\n    position: relative;\n  }\n  .buttonloader::after {\n    content: '';\n    display: block;\n    width: 1.4em;\n    height: 1.4em;\n    position: absolute;\n    right: 0;\n    top: calc(50% - 0.75em);\n    border: 0.15em solid transparent;\n    border-right-color: white;\n    border-radius: 50%;\n    animation: button-anim 0.7s linear infinite;\n    opacity: 1;\n  }\n  @keyframes button-anim {\n    from {\n      transform: rotate(0);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
