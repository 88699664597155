// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CUSTOM TELERAPP SCROLLBAR */
.telerapp-scrollbar {
  scrollbar-color: #173239 transparent;
  scrollbar-gutter: stable;
}
.study-min-height {
  min-height: 450px;
}
.study-container {
  height: 100%;
  /* Occupy full height of the parent */
}
.study-scroll {
  min-height: 450px;
  /* Minimum height */
  max-height: 600px;
  /* Maximum height to allow scrolling */
  overflow-y: auto;
  /* Enable vertical scroll */
  overflow-x: hidden;
  /* Hide horizontal scroll */
}
.telerapp-scrollbar:hover {
  overflow-y: auto;
}
.telerapp-scrollbar::-webkit-scrollbar {
  width: 8px;
}
.telerapp-scrollbar::-webkit-scrollbar-track {
  border-radius: 0.25rem;
}
.telerapp-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(9 68 179 / var(--tw-bg-opacity));
  background-color: #173239;
}
.telerapp-scrollbar::-webkit-scrollbar-thumb:window-inactive {
  --tw-bg-opacity: 1;
  background-color: rgb(9 68 179 / var(--tw-bg-opacity));
  background-color: #173239;
}
/* INVISIBLE SCROLLBAR */
.invisible-scrollbar {
  scrollbar-width: none;
}
.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}
.invisible-scrollbar::-webkit-scrollbar-track {
  display: none;
}
.invisible-scrollbar::-webkit-scrollbar-thumb {
  display: none;
}
.invisible-scrollbar::-webkit-scrollbar-thumb:window-inactive {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/assets/styles/styles.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;EACE,oCAAoC;EACpC,wBAAwB;AAC1B;AAEA;EACE,iBAAiB;AACnB;AAEA;EACE,YAAY;EACZ,qCAAqC;AACvC;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,iBAAiB;EACjB,sCAAsC;EACtC,gBAAgB;EAChB,2BAA2B;EAC3B,kBAAkB;EAClB,2BAA2B;AAC7B;AAEA;EACE,gBAAgB;AAClB;AAEA;EACE,UAAU;AACZ;AAGE;EAAA;AAAc;AAId;EAAA,sBAAc;EACd,kBAAsB;EAAtB,sDAAsB;EACtB;AAFc;AAMd;EAAA,kBAAsB;EAAtB,sDAAsB;EACtB;AADsB;AAIxB,wBAAwB;AACxB;EACE,qBAAqB;AACvB;AAGE;EAAA;AAAa;AAIb;EAAA;AAAa;AAIb;EAAA;AAAa;AAIb;EAAA;AAAa","sourcesContent":["/* CUSTOM TELERAPP SCROLLBAR */\n.telerapp-scrollbar {\n  scrollbar-color: #173239 transparent;\n  scrollbar-gutter: stable;\n}\n\n.study-min-height {\n  min-height: 450px;\n}\n\n.study-container {\n  height: 100%;\n  /* Occupy full height of the parent */\n}\n\n.study-scroll {\n  min-height: 450px;\n  /* Minimum height */\n  max-height: 600px;\n  /* Maximum height to allow scrolling */\n  overflow-y: auto;\n  /* Enable vertical scroll */\n  overflow-x: hidden;\n  /* Hide horizontal scroll */\n}\n\n.telerapp-scrollbar:hover {\n  overflow-y: auto;\n}\n\n.telerapp-scrollbar::-webkit-scrollbar {\n  width: 8px;\n}\n\n.telerapp-scrollbar::-webkit-scrollbar-track {\n  @apply rounded;\n}\n\n.telerapp-scrollbar::-webkit-scrollbar-thumb {\n  @apply rounded;\n  @apply bg-primary-main;\n  background-color: #173239;\n}\n\n.telerapp-scrollbar::-webkit-scrollbar-thumb:window-inactive {\n  @apply bg-primary-main;\n  background-color: #173239;\n}\n\n/* INVISIBLE SCROLLBAR */\n.invisible-scrollbar {\n  scrollbar-width: none;\n}\n\n.invisible-scrollbar::-webkit-scrollbar {\n  @apply hidden;\n}\n\n.invisible-scrollbar::-webkit-scrollbar-track {\n  @apply hidden;\n}\n\n.invisible-scrollbar::-webkit-scrollbar-thumb {\n  @apply hidden;\n}\n\n.invisible-scrollbar::-webkit-scrollbar-thumb:window-inactive {\n  @apply hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
