export interface Model {
    value: string;
    label: string;
}

export const getModels = (): Model[] => {
    const modelUrls = [
        process.env.REACT_APP_MODEL_1_URL,
        process.env.REACT_APP_MODEL_2_URL,
        process.env.REACT_APP_MODEL_3_URL,
        process.env.REACT_APP_MODEL_4_URL,
        process.env.REACT_APP_MODEL_5_URL
    ];

    const modelLabels = [
        // 'pneumonia1',
        process.env.REACT_APP_MODEL_1_LABEL,
        process.env.REACT_APP_MODEL_2_LABEL,
        process.env.REACT_APP_MODEL_3_LABEL,
        process.env.REACT_APP_MODEL_4_LABEL,
        process.env.REACT_APP_MODEL_5_LABEL
    ];

    return [
        // { value: '', label: 'Select Model' },
        ...modelUrls.map((url, index) => ({
            value: url || '',
            label: modelLabels[index] || `Model ${index + 1}`
        }))
    ];
};

export const filterModels = (models: Model[], searchQuery: string): Model[] =>
    models.filter(model =>
        model.label.toLowerCase().includes(searchQuery?.toLowerCase())
    );
