import React, { ReactNode, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import { hotkeys } from '@ohif/core';
import { useReportEditor, useUser, useModal } from '../../contextProviders';

import NavBar from '../NavBar';
import Svg from '../Svg';
import Icon from '../Icon';
import IconButton from '../IconButton';
import Dropdown from '../Dropdown';
import { BiSolidDashboard } from "react-icons/bi";
import { FaClipboardList, FaUserCog, FaUserTie, FaUserMd, FaPowerOff } from "react-icons/fa";
import { HiUserGroup, HiUsers } from "react-icons/hi2";
import { FaChalkboardUser, FaUsersGear, FaXRay } from "react-icons/fa6";

import UploadModal from '../AiIntegration/AiTool';
import { SendtoML } from '../AiIntegration/SendtoML';
import { getModels, filterModels } from '../AiIntegration/modelUtils';
import { IoMdSettings } from 'react-icons/io';
import WorklistModel from '../WorklistModel';
import { IoInformationCircle } from 'react-icons/io5';
import AboutModal from '../AboutModal';
import UserPreferences from '../UserPreferences';
import { useAppConfig } from '@state';
import i18n from '@ohif/i18n';
import filtersMeta from '../../../../app/src/routes/WorkList/filtersMeta';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';


function Header({
  children,
  isReturnEnabled,
  onClickReturnButton,
  isSticky,
  WhiteLabeling,
  loggedInUserDetails,
  isReportEnabled,
  sortedStudies,
  servicesManager,
  hotkeysManager,
  ...props
}): ReactNode {
  const { hotkeyDefinitions, hotkeyDefaults } = hotkeysManager;
  const { t } = useTranslation('Header');
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { setToggleDisplayReportEditor } = useReportEditor();
  const [appConfig] = useAppConfig();
  const { show: showModal, hide } = useModal();
  const [showModelMenu, setShowModelMenu] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef(null);
  const { availableLanguages, defaultLanguage, currentLanguage } = i18n;
  const {
    userToken,
    token,
    setToken,
  } = useUser();

  const versionNumber = process.env.VERSION_NUMBER;
  const commitHash = process.env.COMMIT_HASH;

  const location = useLocation();
  const isWorklist = location.pathname !== '/viewer';


  useEffect(() => {
    const getToken = async () => {
      try {
        const data = {
          token: loggedInUserDetails.access_token,
        };
        const response = await userToken(data);
        setToken(response);
      } catch (error) {
        console.log(error);
      }
    };
    getToken();
  }, []);

  // TODO: this should be passed in as a prop instead and the react-router-dom
  // dependency should be dropped
  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      onClickReturnButton();
      setToggleDisplayReportEditor((show: boolean) => !show);
    }
  };

  const toggleDisplayReportEditorView = () =>
    setToggleDisplayReportEditor((show: boolean) => !show);

  const permissions = loggedInUserDetails?.profile?.permission;
  const hasAssignPermission =
    permissions?.includes('Access Dashboard') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const hasAddRadiologistPermission =
    permissions?.includes('Add Radiologist') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const hasCreateRadiologyGroupPermission =
    permissions?.includes('Create Radiology Group') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const hasCreateRadiologyGroupAdminPermission =
    permissions?.includes('Add Radiology Group Admin') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const hasAddTelerappManagerPermission =
    permissions?.includes('Add Telerapp Manager') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const hasAddTechnologistPermission =
    permissions?.includes('Add Radiology Group Technologist') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const hasQaUserPermission =
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const hasPhysicianPermission =
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const hasAllUsersPermission =
    permissions?.includes('All Users Tab Access') ||
    loggedInUserDetails?.profile?.roleType?.includes('RadiologyGroupAdmin') ||
    token?.realm_access?.roles?.includes('TelerappManager') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const hasDeputyAdminPermission = token?.realm_access?.roles?.includes('super-admin');

  const hasUserPermission =
    permissions?.includes('Add Telerapp Manager') ||
    permissions?.includes('All Users Tab Access') ||
    permissions?.includes('Add Radiology Group Technologist') ||
    permissions?.includes('Create Radiology Group') ||
    permissions?.includes('Add Radiologist') ||
    permissions?.includes('Access Dashboard') ||
    loggedInUserDetails?.profile?.roleType?.includes('RadiologyGroupAdmin') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);

  const toggleUserDropdown = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
  };

  const menuItems = [
    hasAssignPermission && {
      label: 'Dashboard',
      link: '/dashboard/connectivity-status',
      icon: <BiSolidDashboard />,
    },
    {
      label: 'WorkList',
      link: '/',
      icon: <FaClipboardList />,
    },
    hasUserPermission && {
      label: 'Users',
      icon: <FaUserCog />,
      submenu: [
        hasAllUsersPermission && {
          label: 'All Users',
          link: '/users/all-users',
          icon: <HiUsers />
        },
        hasDeputyAdminPermission && {
          label: 'Deputy Admin',
          link: '/users/deputy-admin',
          icon: <FaUserCog />,
        },
        hasAddTelerappManagerPermission && {
          label: 'Telerapp Manager',
          link: '/users/telerapp-manager',
          icon: <FaUsersGear />,
        },
        hasAddRadiologistPermission && {
          label: 'Radiologist',
          link: '/users/radiologist-users',
          icon: <FaXRay />,
        },
        hasQaUserPermission && {
          label: 'QA User',
          link: '/users/qa-user',
          icon: <FaUserTie />,
        },
        hasCreateRadiologyGroupPermission && {
          label: 'Radiology Group',
          link: '/users/radiology-group-user',
          icon: <HiUserGroup />,
        },
        hasCreateRadiologyGroupAdminPermission && {
          label: 'Radiology Group Admin',
          link: '/users/radiology-group-admin',
          icon: <FaUsersGear />,
        },
        hasAddTechnologistPermission && {
          label: 'Technologist',
          link: '/users/technologist-user',
          icon: <FaChalkboardUser />,
        },
        hasPhysicianPermission && {
          label: 'Physician',
          link: '/users/physician',
          icon: <FaUserMd />,
        },
      ].filter(Boolean), // Filter out any falsy values
    },
  ].filter(Boolean);

  const userItems = [
    {
      label: 'Worklist Options',
      icon: <FaClipboardList />,
      link: '/worklist-option'
    },
    {
      label: 'Settings',
      icon: <IoMdSettings />,
      // onClick: () => {
      //   setIsOpen(false);
      //   navigate('/setting/my-account');
      // },
      link: '/setting/my-account'
    },
  ].filter(Boolean)


  const settingItems = [
    {
      label: 'About',
      icon: <IoInformationCircle />,
      onClick: () => {
        setIsOpen(false);
        showModal({
          content: AboutModal,
          title: t('AboutModal:About Telerapp Viewer'),
          contentProps: { versionNumber, commitHash },
        });
      },
    },
    {
      label: 'Preferences',
      icon: <FaUserCog />,
      onClick: () => {
        setIsOpen(false);
        showModal({
          title: t('UserPreferencesModal:User preferences'),
          content: UserPreferences,
          contentProps: {
            hotkeyDefaults: hotkeysManager.getValidHotkeyDefinitions(hotkeyDefaults),
            hotkeyDefinitions,
            onCancel: hide,
            currentLanguage: currentLanguage(),
            availableLanguages,
            defaultLanguage,
            onSubmit: state => {
              if (state.language.value !== currentLanguage().value) {
                i18n.changeLanguage(state.language.value);
              }
              hotkeysManager.setHotkeys(state.hotkeyDefinitions);
              hide();
            },
            onReset: () => hotkeysManager.restoreDefaultBindings(),
            hotkeysModule: hotkeys,
          },
        });
      },
    },
  ].filter(Boolean);

  if (appConfig.oidc) {
    settingItems.push({
      // icon: 'power-off',
      icon: <FaPowerOff />,
      label: 'Logout',
      onClick: () => {
        navigate(`/logout?redirect_uri=${encodeURIComponent(window.location.href)}`);
      },
    });
  }

  const renderSubmenu = (submenu) => (
    <ul className="bg-primary-dark text-white">
      {submenu.map((item) => (
        <li
          key={item.label}
          className="bg-primary-dark hover:border-secondary-light hover:bg-secondary-main cursor-pointer p-2 transition duration-300 flex items-center gap-2 group"
          onClick={() => handleMenuItemClick(item.link)}
        >
          <span className='text-blue-400 bg-[#183153] p-1 text-2xl duration-300 group-hover:bg-blue-400 group-hover:text-white transition-all rounded-sm'>
            {item.icon}
          </span>
          {item.label}
        </li>
      ))}
    </ul>
  );

  const handleMenuItemClick = (link, onClick) => {
    if (onClick) {
      onClick(); // Trigger modal for Worklist Option
    } else if (link) {
      navigate(link); // Handle regular link navigation
    }
  };

  const handleModelMenuClick = () => {
    setShowModelMenu(!showModelMenu);
  };

  const handleModelSelection = (model: { value: string; label: string }) => {
    setShowModelMenu(false);
    openUploadModal(model.value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const openUploadModal = (modelUrl: string) => {
    if (modelUrl) {
      showModal({
        content: UploadModal,
        contentProps: {
          onSend: SendtoML,
          hide: () => setShowModelMenu(false),
          modelUrl1: modelUrl,
          send: true,
        },
        title: 'AI Predictions',
        isOpen: true,
      });
    }
  };

  const models = getModels();
  const filteredModels = filterModels(models, searchQuery);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowModelMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <NavBar
      className="relative justify-between border-b-4 border-black"
      isSticky={isSticky}
    >
      <div className="flex flex-1 justify-between">
        <div className="flex items-center">
          {/* // TODO: Should preserve filter/sort
              // Either injected service? Or context (like react router's `useLocation`?) */}
          <div
            className={classNames(
              'mr-3 inline-flex items-center',
              isReturnEnabled && 'cursor-pointer'
            )}
            data-cy="return-to-work-list"
          >
            {isReturnEnabled && (
              <Icon
                onClick={onClickReturn}
                name="chevron-left"
                className="text-primary-active w-8"
              />
            )}
            <div className="ml-4 flex items-center">
              <svg
                className="mr-4 block h-4 w-4 cursor-pointer border-white fill-current text-white"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setIsOpen(!isOpen)}
              >
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
              </svg>

              <h6 className="m-0 text-xl leading-tight text-white">
                Hello, {loggedInUserDetails?.profile?.name || 'User'}
              </h6>
            </div>
          </div>
        </div>
        <div className="flex items-center">{children}</div>
        {isWorklist && (
          <Icon
            name="quantum-logo1"
            className="h-10 w-28 text-white ml-auto mr-1"
          />
        )}
        <div className="flex items-center">
          {isReportEnabled && (
            <div
              className="relative"
              style={{ marginTop: '10px', marginRight: '10px' }}
            >
              <IconButton
                id={'submit-report'}
                variant="text"
                color="inherit"
                size="initial"
                className="text-primary-active"
                onClick={
                  loggedInUserDetails?.profile?.roleType !== 'Physician' &&
                    loggedInUserDetails?.profile?.roleType !== 'Technologist' &&
                    loggedInUserDetails?.profile?.roleType !== 'RadiologyGroupAdmin'
                    ? toggleDisplayReportEditorView
                    : loggedInUserDetails?.profile?.roleType === 'RadiologyGroupAdmin' &&
                      permissions?.includes('Edit Report')
                      ? toggleDisplayReportEditorView
                      : null
                }
                disabled={
                  loggedInUserDetails?.profile?.roleType === 'Physician' ||
                  loggedInUserDetails?.profile?.roleType === 'Technologist' ||
                  (loggedInUserDetails?.profile?.roleType === 'RadiologyGroupAdmin' &&
                    !permissions?.includes('Edit Report'))
                }
              >
                <Icon name="tab-patient-info" />
              </IconButton>
            </div>
          )}
          {isReportEnabled && (
            <div className="relative" style={{ marginTop: '10px', marginRight: '10px' }} ref={dropdownRef}>
              <IconButton
                id={'run-script-button'}
                variant="text"
                color="inherit"
                size="initial"
                className="text-primary-active"
                onClick={
                  loggedInUserDetails?.profile?.roleType !== 'RadiologyGroupAdmin'
                    ? handleModelMenuClick
                    : loggedInUserDetails?.profile?.roleType === 'RadiologyGroupAdmin' &&
                      permissions?.includes('Ai Assistance')
                      ? handleModelMenuClick
                      : null
                }
                disabled={
                  (loggedInUserDetails?.profile?.roleType === 'RadiologyGroupAdmin' &&
                    !permissions?.includes('Ai Assistance'))
                }
              >
                <Icon name="ai-robot" />
              </IconButton>
              {showModelMenu && (
                <div
                  className="absolute right-0 mt-2 w-48 shadow-lg rounded-lg"
                  style={{ backgroundColor: '#041c4a' }}
                >
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="p-2 w-full"
                    style={{
                      backgroundColor: '#041c4a',
                      color: '#caccd1',
                      borderRadius: '5px',
                      outline: 'none',
                    }}
                  />
                  <ul
                    className="list-none p-2 m-0 max-h-[30px] overflow-y-auto"
                    style={{
                      maxHeight: '130px',
                      overflowY: 'auto',
                      backgroundColor: '#041c4a',
                      borderRadius: '5px',
                      scrollbarColor: '#caccd1 #041c4a',
                      scrollbarWidth: 'thin',
                    }}
                  >
                    {filteredModels.map((model) => (
                      <li
                        key={model.value}
                        onClick={() => handleModelSelection(model)}
                        className="cursor-pointer p-2"
                        style={{
                          color: '#caccd1',
                          backgroundColor: '#041c4a',
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = '#090c29';
                          e.currentTarget.style.color = '#255fb2';
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = '#041c4a';
                          e.currentTarget.style.color = '#caccd1';
                        }}
                      >
                        {model.label}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <ul
        className={classNames(
          'bg-primary-dark absolute top-12 w-60 text-white transition duration-300 flex flex-col justify-between overflow-y-auto',
          {
            'left-0': isOpen,
          },
          {
            'left-[-1000px]': !isOpen,
          }
        )}
        style={{
          height: 'calc(100vh - 48px)', // Full height minus header height
          scrollbarColor: '#173239 transparent',
        }}
      >
        <div className="flex-grow">
          {menuItems.map((menuItem) => (
            <li
              key={menuItem.label}
              className="bg-primary-dark border-b border-b-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer p-2 transition duration-300 flex items-center gap-2 group justify-between"
              onClick={() => {
                if (menuItem.label === 'Users') {
                  toggleUserDropdown(); // Toggle dropdown on click
                } else {
                  handleMenuItemClick(menuItem.link, menuItem.onClick); // Handle both link and modal
                }
              }}
            >
              <div className="flex items-center gap-2">
                <span
                  className="text-blue-400 bg-[#183153] p-1 text-2xl duration-300 group-hover:bg-blue-400 group-hover:text-white transition-all rounded-sm"
                  style={{
                    boxShadow:
                      'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
                  }}
                >
                  {menuItem.icon}
                </span>
                {menuItem.label}
              </div>

              {/* Conditionally render the dropdown icon if there is a submenu */}
              {menuItem.submenu && (
                <span className="text-blue-400 group-hover:text-white transition duration-300">
                  {isUserDropdownOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                </span>
              )}
            </li>
          ))}

          {isUserDropdownOpen && renderSubmenu(menuItems.find((item) => item.label === 'Users').submenu)}

          {userItems.map((menuItem) => (
            <li
              key={menuItem.label}
              className={`bg-primary-dark border-b border-b-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer p-2 transition duration-300 flex items-center gap-2 group justify-between ${(menuItem.label === 'Worklist Options' && isUserDropdownOpen === true) ? 'border-t border-t-secondary-light' : ''
                }`}
              onClick={() => {
                if (menuItem.label === 'Users') {
                  toggleUserDropdown();
                } else {
                  handleMenuItemClick(menuItem.link, menuItem.onClick);
                }
              }}
            >
              <div className="flex items-center gap-2">
                <span
                  className="text-blue-400 bg-[#183153] p-1 text-2xl duration-300 group-hover:bg-blue-400 group-hover:text-white transition-all rounded-sm"
                  style={{
                    boxShadow:
                      'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
                  }}
                >
                  {menuItem.icon}
                </span>
                {menuItem.label}
              </div>

              {/* Conditionally render the dropdown icon if there is a submenu */}
              {/* {menuItem.submenu && (
                <span className="text-blue-400 group-hover:text-white transition duration-300">
                  {isUserDropdownOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                </span>
              )} */}
            </li>
          ))}
        </div>

        <div>
          {settingItems.map((settingItem) => (
            <li
              key={settingItem.label}
              className={`bg-primary-dark border-b border-b-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer p-2 transition duration-300 flex items-center gap-2 groupRadiology Group TAT Setting ${settingItem.label === 'About' ? 'border-t border-t-secondary-light mt-6' : ''
                }`}
              onClick={settingItem.onClick}
            >
              <span
                className="text-blue-400 bg-[#183153] p-1 text-2xl duration-300 group-hover:bg-blue-400 group-hover:text-white transition-all rounded-sm"
                style={{
                  boxShadow:
                    'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
                }}
              >
                {settingItem.icon}
              </span>
              {settingItem.label}
            </li>
          ))}
        </div>
      </ul>

    </NavBar>
  );
}

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isReturnEnabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  onClickReturnButton: PropTypes.func,
  WhiteLabeling: PropTypes.object,
  loggedInUserDetails: PropTypes.object,
};

Header.defaultProps = {
  isReturnEnabled: true,
  isSticky: false,
};

export default Header;
