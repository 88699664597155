import React, { useState } from 'react';
import { useLogViewer } from '../../contextProviders/LogViewerProvider'; // Adjust the import path as needed
import Typography from '../Typography';
import LegacyButtonGroup from '../LegacyButtonGroup';
import LegacyButton from '../LegacyButton';
import { useUser } from '../../contextProviders';

const LogList2 = ({ onSelectLog, searchTerm }) => {
    const { logs } = useLogViewer();
    const [currentPage, setCurrentPage] = useState(1);
    const logsPerPage = 50; // Display 50 logs per page

    const {
        token
    } = useUser();

    let logEntries = [];

    // Helper function to format the log date and time in India/Mumbai time zone
    const formatDateTime = (timestamp) => {
        const dateTime = new Date(timestamp);

        const options = {
            timeZone: 'Asia/Kolkata',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        };

        const formattedDateTime = new Intl.DateTimeFormat('en-IN', options).formatToParts(dateTime);
        const date = `${formattedDateTime[4].value}-${formattedDateTime[2].value}-${formattedDateTime[0].value}`;
        const time = `${formattedDateTime[6].value}:${formattedDateTime[8].value}:${formattedDateTime[10].value} ${formattedDateTime[12].value}`;
        return { date, time };
    };

    // Updated helper function to extract the relevant log details from the log
    const extractLogDetails = (log) => {
        const { StudyInstanceUID, PatientID, AET, InstitutionName, Modality, timestamp } = log;
        const dateTime = formatDateTime(timestamp);

        return {
            StudyInstanceUID: StudyInstanceUID || 'N/A',
            patientId: PatientID || 'N/A',
            AET: AET || 'N/A',
            institution: InstitutionName || 'N/A',
            modality: Modality || 'N/A',
            ...dateTime,
        };
    };

    // Parse log content based on whether it's a date range or a single log response
    const parseLogs = (logContent) => {
        const logLines = logContent.split('\n').filter((entry) => entry.trim() !== '');
        return logLines.map((line) => {
            try {
                const log = JSON.parse(line);
                return extractLogDetails(log);
            } catch (error) {
                console.error('Failed to parse log line:', line, error);
                return null;
            }
        }).filter(entry => entry !== null);
    };

    // Handle both single date and date range responses
    if (logs?.logs) {
        logs.logs.forEach((log) => {
            logEntries = [...logEntries, ...parseLogs(log.logContent)];
        });
    } else if (logs?.logContent) {
        logEntries = parseLogs(logs.logContent);
    }

    // Filter logs based on the search term
    const filteredLogs = logEntries
        .filter(log => Object.values(log).some(value => String(value).toLowerCase().includes(searchTerm.toLowerCase())))
        .reverse(); // Reverse to show the latest logs first

    const filteredLogsByInstitution = filteredLogs.filter((i) =>
        (i?.institution || '').replace('-', '').replace(' ', '') ===
        (token?.radiologyGroup || '').replace(' ', '').replace('-', '')
    );

    // Pagination
    const totalPages = Math.ceil(filteredLogs.length / logsPerPage);
    const currentLogs = filteredLogs.slice((currentPage - 1) * logsPerPage, currentPage * logsPerPage);

    const handlePageChange = (direction) => {
        if (direction === 'next' && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        } else if (direction === 'prev' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    if (!currentLogs.length) {
        return <p>No logs available.</p>;
    }

    return (
        <div>

            <div className="max-h-[500px] overflow-y-auto telerapp-scrollbar"
                style={{
                    overflowX: 'auto',
                    maxHeight: 'calc(100vh - 290px)',
                    //   scrollbarColor: '#173239 transparent',
                }}>
                <table className="container m-auto mt-2 w-full table-auto text-white">
                    <thead className="bg-[#d1d1d1] dark:bg-primary-dark dark:border-primary-main border-secondary-dark border sticky top-0 right-0 left-0">
                        <tr className=' max-sm:text-base '>
                            <th className="p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black">Date</th>
                            <th className="p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black">Time</th>
                            <th className="p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black">Study UID</th>
                            <th className="p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black">Patient ID</th>
                            <th className="p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black">AET</th>
                            <th className="p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black">Institution</th>
                            <th className="p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black">Modality</th>
                        </tr>
                    </thead>
                    <tbody className="mt-1">
                        {currentLogs && currentLogs.length > 0 ? (
                            currentLogs.map((log, index) => (
                                <tr
                                    key={index}
                                    className="bg-secondary-light dark:bg-secondary-dark dark:border-primary-main border-secondary-dark hover:border-secondary-dark dark:hover:bg-[#414141] hover:bg-primary-light cursor-pointer border text-center transition duration-300 max-sm:text-sm"
                                    onClick={() => onSelectLog(log)}
                                >
                                    <td className="p-2 dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black">{log.date}</td>
                                    <td className="p-2 dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black">{log.time}</td>
                                    <td className="p-2 dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black">{log.StudyInstanceUID}</td>
                                    <td className="p-2 dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black">{log.patientId}</td>
                                    <td className="p-2 dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black">{log.AET}</td>
                                    <td className="p-2 dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black">{log.institution}</td>
                                    <td className="p-2 dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black">{log.modality}</td>
                                </tr>
                            ))
                        ) : (
                            <div className=' h-full flex justify-center items-center bg-secondary-light dark:bg-secondary-dark border-secondary-dark border hover:border-secondary-dark dark:text-white text-black p-4 mt-2  max-sm:text-[16px] max-[375px]:text-[13px]'>No logs found</div>
                        )}
                    </tbody>
                </table>
            </div>

            {/* Total rows and pagination */}
            <div className="flex justify-between items-center mt-4">
                {/* Total number of rows at the bottom left */}
                <Typography className="text-base dark:text-white text-black opacity-60 max-sm:text-sm">
                    Results: {filteredLogs.length}
                </Typography>

                {/* Pagination at the bottom right */}
                <div className="flex items-center">
                    <Typography className="dark:text-white text-black mr-4 text-base opacity-60 max-sm:text-sm">
                        Page {currentPage} of {totalPages}
                    </Typography>
                    <LegacyButtonGroup>
                        <LegacyButton
                            size="initial"
                            className="dark:text-white text-black px-4 py-2 text-base max-sm:text-sm"
                            color="translucent"
                            border="primary"
                            variant="outlined"
                            onClick={() => handlePageChange('prev')}
                            disabled={currentPage <= 1} // Disable on the first page
                        >
                            {'< Back'}
                        </LegacyButton>
                        <LegacyButton
                            size="initial"
                            className="px-4 py-2 text-base max-sm:text-sm dark:text-white text-black"
                            color="translucent"
                            border="primary"
                            variant="outlined"
                            onClick={() => handlePageChange('next')}
                            disabled={currentPage >= totalPages} // Disable on the last page
                        >
                            {'Next >'}
                        </LegacyButton>
                    </LegacyButtonGroup>
                </div>
            </div>

        </div>
    );
};

export default LogList2;
