import React, { useEffect, useState } from 'react';
import Button from '../Button';
import { useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import moment from 'moment';
import { ImExit } from "react-icons/im";

const RadiologyGroupMember = ({ user, token, groupId, viewRadiologyGroupMember, setRadiologyGroupMemberList, deleteRadiologyGroupMember, hide }) => {
    const { show } = useSnackbar();
    const [membersList, setmembersList] = useState([])
    const permissions = user?.profile?.permission

    const hasManagerPermission =
        permissions?.includes('Create Radiology Group') ||
        permissions?.includes('Add Radiologist') ||
        permissions?.includes('Add Radiology Group Admin') ||
        token?.realm_access?.roles.includes('super-admin') ||
        token?.realm_access?.roles.includes('deputy-admin');

    const tableHeaders = {
        username: 'Name',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email ID',
        createdTimestamp: 'Added',
        ...(hasManagerPermission && { leave: 'Leave' })
    };

    const keys = Object.keys(tableHeaders);
    const values = Object.values(tableHeaders);

    useEffect(() => {
        viewRadiologyGroupMember(user.access_token, groupId)
            .then((data) => {
                setRadiologyGroupMemberList(data)
                setmembersList(data)
            })
            .catch(error => console.error('Error fetching radiology groups member:', error));
    }, [])

    const handleLeave = async (userData) => {
        try {

            const response = await deleteRadiologyGroupMember(userData.id, groupId, user.access_token)
            if (response.status === 204) {
                await viewRadiologyGroupMember(user.access_token, groupId)
                    .then(data => setRadiologyGroupMemberList(data))
                    .catch(error => console.error('Error fetching users:', error));
                hide()
                show({
                    title: "Success!",
                    message: `${userData.username} Remove for Group Successfully`,
                    type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
                    position: "topRight"
                });
            }
        } catch (error) {
            console.log(error);

        }
    }


    return (
        <section className="w-full">
            <div
                className=" container m-auto"
            >
                {
                    membersList.length <= 0 ? (
                        <span className='flex justify-center dark:text-white text-black'>No Members Exiting</span>
                    ) : (
                        <table className=" overflow-y-auto w-full table-auto text-white">
                            <thead className="bg-[#d1d1d1] dark:bg-primary-dark dark:border-primary-main border-secondary-dark border sticky -top-3 right-0 left-0">
                                <tr>
                                    {values.map(tableHead => (
                                        <th
                                            className="p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black"
                                            key={'tableHead'}
                                        >
                                            {tableHead}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="mt-1 ">
                                {!membersList ? <></> : (
                                    membersList && membersList?.map(instance => (
                                        <tr className="bg-secondary-light dark:bg-secondary-dark dark:border-primary-main border-secondary-dark hover:border-secondary-dark dark:hover:bg-[#414141] hover:bg-primary-light cursor-pointer border text-center transition duration-300 max-sm:text-sm">
                                            {keys.map((key) => (
                                                <td className="p-2 dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black" key={key}>
                                                    {hasManagerPermission && key === "leave" ? (
                                                        <div className='cursor-pointer flex justify-center items-center' onClick={() => handleLeave(instance)}>
                                                            <ImExit className=' text-xl hover:opacity-10 transition-all' />
                                                        </div>
                                                    ) : key === "createdTimestamp" ? (
                                                        <span className='cursor-pointer'>
                                                            {moment(instance.createdTimestamp).format('MM/DD/YYYY hh:mm:ss A')}
                                                        </span>
                                                    ) : (
                                                        instance[key]
                                                    )

                                                    }
                                                </td>
                                            ))
                                            }
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    )
                }
            </div>
        </section>
    );
};

export default RadiologyGroupMember;
