import React, { ReactNode, createContext, useContext,useReducer, useMemo,useState } from 'react';

interface ImageViewerProviderProps {
  StudyInstanceUIDs: string[];
  children: ReactNode;
}

export const ImageViewerContext = createContext(null);
export const useImageViewer = () => useContext(ImageViewerContext);

export function ImageViewerProvider({ StudyInstanceUIDs, children }: ImageViewerProviderProps) {
 const [isThumbnail, setIsThumbnail] = useState(false);
  const value = useMemo(() => {
    return { StudyInstanceUIDs,isThumbnail, setIsThumbnail };
  }, [StudyInstanceUIDs,isThumbnail, setIsThumbnail]);

  return <ImageViewerContext.Provider value={value}>{children}</ImageViewerContext.Provider>;
}
