
export const createRadiologyGroup = (radiologistUserData,accessTokens) => {
  const response =  fetch(`${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/groups`,{
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessTokens}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(radiologistUserData)
  })
  return response
}

export const fetchRadiologyGroup = async (accessTokens) => {
  return await fetch(`${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/groups`,{
    headers: {
      Authorization: `Bearer ${accessTokens}`

    }})
    .then((response) => response.json())
}

export const fetchSingleRadiologyGroup = async (accessTokens,ids) => {
  const promises = ids.map(id => {
    return fetch(`${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/groups/${id}`, {
      headers: {
        Authorization: `Bearer ${accessTokens}`
      }
    }).then(response => response.json());
  });

  return Promise.all(promises);
}

export const deleteRadiologyGroup = async (id, accessTokens) => {
  const response =  fetch(`${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/groups/${id}`,{
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessTokens}`,
      'Content-Type': 'application/json'
    }})
  return response
}

export const updateRadiologyGroup = (id,radiologistUserData,accessTokens) => {
  const response =  fetch(`${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/groups/${id}`,{
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessTokens}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(radiologistUserData)
  })
  return response
}

export const addRadiologyGroupMember = (userId,groupId,accessTokens) => {
  const response =  fetch(`${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/users/${userId}/groups/${groupId}`,{
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessTokens}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({})
  })
  return response
}

export const deleteRadiologyGroupMember = (userId,groupId,accessTokens) => {
  const response =  fetch(`${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/users/${userId}/groups/${groupId}`,{
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessTokens}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({})
  })
  return response
}

export const viewRadiologyGroupMember = async (accessTokens, groupId) => {
  return await fetch(`${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/groups/${groupId}/members`, {
    headers: {
      Authorization: `Bearer ${accessTokens}`

    }
  })
    .then((response) => response.json())
}

export const createRadiologyGroupSetting = async (data) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/radiology-group-setting`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  return response
};

export const fetchRadiologyGroupSetting = () => {
  return fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/radiology-group-setting`)
    .then((response) => response.json())
}

export const updateRadiologyGroupSetting = async (id, data) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/radiology-group-setting/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  return response
};

export const uploadImage = async (file, removeimageName) => {
  const formData = new FormData();
  formData.append('files', file);
  formData.append('removeimageName', removeimageName);

  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/upload-images`, {
      method: 'POST',
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Failed to upload image: ${error}`);
  }
};
