import React, { useEffect, useState } from 'react';
import Typography from '../Typography';
import Button from '../Button';
import './ConnectivityStatus.css';
import { useUser, Dashboard, InputDateRange, LegacyButton, Icon, useRadiologyGroup } from '@ohif/ui';
import moment from 'moment';
import { GoDotFill } from 'react-icons/go';

const ConnectivityStatus = ({ servicesManager, extensionManager, hotkeysManager, data: studies }) => {
  const { usersList, fetchSessionsRadiologistUser } = useUser();
  const { radiologyGroupList } = useRadiologyGroup();
  const { userAuthenticationService } = servicesManager.services;
  const user = userAuthenticationService.getUser();

  const [activeList, setActiveList] = useState([]);

  const [session, setSession] = useState([]);
  const [radiologistUserLists, setRadiologistUserLists] = useState(radiologyGroupList);
  const [selectedDateRange, setSelectedDateRange] = useState({ startDate: null, endDate: null });
  const [isFiltering, setIsFiltering] = useState(false);
  const [lastActiveTimes, setLastActiveTimes] = useState({});
  const [searchQuery, setSearchQuery] = useState('');


  const tableHeaders = {
    liveStatus: 'Live Status',
    groupName: 'Group Name',
    lastActive: 'Last Active Time'
  };

  const keys = Object.keys(tableHeaders);
  const values = Object.values(tableHeaders);

  const radiologistUser = usersList?.map(data => data.id);

  useEffect(() => {
    fetchSessionsRadiologistUser(user.access_token, radiologistUser)
      .then(data => setSession(data))
      .catch(error => console.error('Error fetching radiology user session:', error));
  }, [user.accessTokens, usersList]);

  useEffect(() => {
    if (!session || !usersList) return;

    const updatedList = usersList.map(userData => {
      const relevantSessions = session.map(sessionData =>
        sessionData && sessionData?.find(data => data.userId === userData.id)
      ).filter(Boolean); // Filter out undefined values

      let lastActiveTime = null;
      if (relevantSessions.length > 0) {
        lastActiveTime = relevantSessions[0].lastAccess;
      }

      if (relevantSessions.length > 0) {
        return {
          ...userData,
          lastActiveTime: moment(lastActiveTime).format('MMM-DD-YYYY hh:mm:ss A')
        };
      } else {
        return null;
      }
    }).filter(Boolean);

    setActiveList(updatedList);
  }, [session]);
  const handleDateRangeFieldChange = ({ startDate, endDate }) => {
    setSelectedDateRange({ startDate, endDate });
  };

  const handleClearFilters = () => {
    setSelectedDateRange({ startDate: null, endDate: null });
    setRadiologistUserLists(radiologyGroupList);
    setIsFiltering(false);
  };

  const handleSearch = () => {
    const startTimestamp = moment(selectedDateRange.startDate).format('MM/DD/YYYY');
    const endTimestamp = moment(selectedDateRange.endDate).format('MM/DD/YYYY');
    const filteredUsers = radiologistUserLists.filter(user => {
      const userTimestamp = moment(user.createdTimestamp).format('MM/DD/YYYY');

      return userTimestamp >= startTimestamp && userTimestamp <= endTimestamp;
    });
    setIsFiltering(true);
    setRadiologistUserLists(filteredUsers);
  };


  useEffect(() => {
    const newLastActiveTimes = {};

    radiologistUserLists.forEach(instance => {
      const lastActive = activeList
        .filter(sessionData => sessionData?.attributes?.radiologyGroup?.toString() === instance.name)
        .map(sessionData => sessionData?.lastActiveTime)
        .filter(time => time !== null);

      if (lastActive.length > 0) {
        newLastActiveTimes[instance.name] = lastActive.reduce((latest, current) => current > latest ? current : latest);
      } else {
        newLastActiveTimes[instance.name] = null;
      }
    });

    setLastActiveTimes(newLastActiveTimes);
  }, [radiologistUserLists, activeList]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredConnectivityLists = radiologistUserLists.filter(user =>
    user?.name && user?.name.toLowerCase().includes(searchQuery.toLowerCase())
  );


  return (
    <section
      className="relative w-full text-white"
      style={{}}
    >
      <Dashboard
        servicesManager={servicesManager}
        extensionManager={extensionManager}
        hotkeysManager={hotkeysManager}
        sortedStudies={studies}
      />
      <div className="absolute top-16 left-60 p-3 w-10/12">
        <div className="container relative mx-auto flex w-full flex-col pt-5">
          <div className="mb-5 flex flex-row justify-between">
            <div className="flex min-w-[1px] shrink flex-row items-center gap-6">
              <Typography
                variant="h6"
                className="text-white"
                component={'h6'}
              >
                Connectivity Status
              </Typography>
            </div>
            <div className="flex h-[34px] flex-row items-center">
              {isFiltering && (
                <LegacyButton
                  rounded="full"
                  variant="outlined"
                  color="primaryActive"
                  border="primaryActive"
                  className="mx-8"
                  startIcon={<Icon name="cancel" />}
                  onClick={handleClearFilters}
                >
                  ClearFilters
                </LegacyButton>
              )}
              <input
                type="text"
                className="custom-input search-box mt-2 mr-3"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <InputDateRange
                value={selectedDateRange}
                onChange={handleDateRangeFieldChange}
              />
              <Button
                className="ml-5 mt-2.5 py-0.5 px-8"
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>
          </div>
          <div className="max-h-[500px] overflow-y-auto"
            style={{
              overflowX: 'auto',
              scrollbarColor: '#173239 transparent',
            }}>
            <table className="container m-auto mt-2 w-full table-auto text-white">
              <thead className="bg-primary-dark border-secondary-light border sticky top-0 z-2"
                style={{ backgroundColor: '#041c4a' }}>
                <tr>
                  {values.map(tableHead => (
                    <th
                      className="p-2"
                      key={tableHead}
                    >
                      {tableHead}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="mt-1 ">
                {filteredConnectivityLists &&
                  filteredConnectivityLists.map(instance => {
                    const isSessionExist = activeList.some(sessionData =>
                      sessionData?.attributes?.radiologyGroup?.toString() === instance.name
                    );

                    const formattedLastActive = lastActiveTimes[instance.name] || 'N/A';

                    return (
                      <tr className="bg-primary-dark border-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer border text-center transition duration-300">
                        {keys.map(key => (
                          <td
                            className="p-2"
                            key={key}
                          >
                            {key === 'liveStatus' ? (
                              <span className="flex justify-center items-center">
                                <GoDotFill
                                  fontSize={37}
                                  style={{ color: isSessionExist ? 'lime' : 'red' }}
                                />
                              </span>
                            ) : key === 'groupName' ? (
                              <span className="cursor-pointer">
                                {instance?.name}
                              </span>
                            ) : key === 'lastActive' ? (
                              <span className="cursor-pointer">
                                {formattedLastActive}
                              </span>
                            ) : (
                              instance[key]
                            )}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConnectivityStatus;
