import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from '../Icon';

const baseLabelClassName = 'flex flex-col flex-1 text-white text-lg pl-1 select-none';
const spanClassName = 'flex flex-row items-center cursor-pointer focus:outline-none';
const sortIconMap = {
  descending: 'sorting-active-up',
  ascending: 'sorting-active-down',
  none: 'sorting',
};

const InputLabelWrapper = ({
  name,
  label,
  isSortable,
  sortDirection,
  onLabelClick,
  className,
  children,
  selectedRows,
  setSelectedRows,
  filterData
}) => {

  const headerCheckboxRef = useRef<HTMLInputElement>(null);

  const onClickHandler = e => {
    if (!isSortable) {
      return;
    }

    onLabelClick(e);
  };


  // Handle the change for the header checkbox
  const handleHeaderCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      // Select all rows
      setSelectedRows(filterData.map(row => row.studyInstanceUid));
    } else {
      // Deselect all rows
      setSelectedRows([]);
    }
  };

  // useEffect(() => {
  //   if (headerCheckboxRef.current) {
  //     headerCheckboxRef.current.indeterminate = selectedRows.length > 0 && selectedRows.length < filterData.length;
  //   }
  // }, [selectedRows, filterData.length]);

  useEffect(() => {
    if (headerCheckboxRef.current) {
      headerCheckboxRef.current.indeterminate = selectedRows.length > 0 && selectedRows.length < filterData.length;
    }
    // If filterData becomes empty, ensure selectedRows is cleared to avoid stale data
    if (filterData?.length === 0 && selectedRows?.length > 0) {
      setSelectedRows([]);
    }
    // Ensure selectedRows is in sync with filterData
    if (selectedRows?.length > 0 && selectedRows?.length > filterData?.length) {
      setSelectedRows(selectedRows.filter(uid => filterData.some(row => row.studyInstanceUid === uid)));
    }
  }, [selectedRows, filterData, setSelectedRows]);


  return (
    <label className={classnames(baseLabelClassName, className, { 'justify-center': name === 'checkBox' },
      { 'items-center': name === 'checkBox' })}>
      <span
        role="button"
        className={spanClassName}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        tabIndex="0"
      >
        {name === 'checkBox' ? (
          <>
            <input
              type="checkbox"
              // checked={selectedRows.length === filterData.length}
              checked={selectedRows.length === filterData.length && filterData.length > 0}
              ref={headerCheckboxRef}
              onChange={handleHeaderCheckboxChange}
              className='checkbox cursor-pointer'
            />
            {label}
          </>
        ) : (
          label
        )}
        {/* {label} */}
        {isSortable && (
          <Icon
            name={sortIconMap[sortDirection]}
            className={classnames(
              'mx-2 w-2',
              sortDirection !== 'none' ? 'text-primary-light' : 'text-primary-main'
            )}
          />
        )}
      </span>
      <span>{children}</span>
    </label>
  );
};

InputLabelWrapper.defaultProps = {
  className: '',
};

InputLabelWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  isSortable: PropTypes.bool.isRequired,
  sortDirection: PropTypes.oneOf(['ascending', 'descending', 'none']).isRequired,
  onLabelClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default InputLabelWrapper;
