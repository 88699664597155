import React, { useEffect, useState } from 'react';
import { Button, Input, Label } from '@ohif/ui';
import './ReportTemplate.css';
import CustomTemplates from './CustomTemplates';

const CreateReportTemplate = ({
  handleClick,
  data,
  handleSubmit,
  error,
  dataTemplate,
  checkedAttributesItems,
  setCheckedAttributesItems,
  templateName,
  setTemplateName,
  templates,
  setEditorData,
  selectCustomTemplate,
  setSelectCustomTemplate,
  imageCapture,
  setImageCapture,
  modality,
  setModality,
  bodyparts,
  setBodyparts,
  templategroup,
  setTemplategroup,
}) => {
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const handleNameClick = async name => {
    const updatedCheckedItems = {
      ...checkedAttributesItems,
      [name]: !checkedAttributesItems[name],
    };
    setCheckedAttributesItems(updatedCheckedItems);
  };

  const handleSelectAllChange = e => {
    const checked = e.target.checked;
    const updatedCheckedItems = {};

    data.forEach(item => {
      updatedCheckedItems[item.name] = checked;
    });

    setCheckedAttributesItems(updatedCheckedItems);
    setSelectAllChecked(checked);
  };

  const handleSelectAllClick = () => {
    const updatedCheckedItems = {};
    const newSelectAllChecked = !selectAllChecked;

    data.forEach(item => {
      updatedCheckedItems[item.name] = newSelectAllChecked;
    });

    setCheckedAttributesItems(updatedCheckedItems);
    setSelectAllChecked(newSelectAllChecked);
  };

  useEffect(() => {
    if (dataTemplate) {
      const attributesObj = JSON.parse(dataTemplate?.templateAttributes[0]);
      setCheckedAttributesItems(attributesObj);
      setTemplateName(dataTemplate.name);
      setEditorData(dataTemplate?.customTemplate);
      setModality(dataTemplate.modality || '');
      setBodyparts(dataTemplate.bodyparts || '');
      setTemplategroup(dataTemplate.templategroup || '');
    } else {
      setCheckedAttributesItems({});
      setTemplateName('');
      setEditorData('');
      setModality('');
      setBodyparts('');
      setTemplategroup('');
    }
  }, [dataTemplate]);


  useEffect(() => {
    if (!dataTemplate) {
      const initialCheckedItems = {};
      data.forEach(item => {
        initialCheckedItems[item.name] = item.isChecked;
      });
      setCheckedAttributesItems(initialCheckedItems);
    }
  }, []);

  return (
    <div className="container mx-auto min-h-[550px] max-h-[550px] overflow-y-auto overflow-x-auto telerapp-scrollbar"
      style={{
        overflowX: 'auto',
        maxHeight: 'calc(100vh - 66px)',
        // scrollbarColor: '#173239 transparent',
      }}>
      <div className="my-2 w-full p-3">
        {/* First Row - Template Name and Modality */}
        <div className="flex justify-between max-lg:flex-col gap-4">
          <div className="flex justify-between w-full max-sm:flex-col sm:gap-4">
            <div className="sm:w-10/12 w-full">
              <Label className="my-2 block max-sm:text-[16px] dark:text-white text-black">Template Name</Label>
              <Input
                className=" shadow transition duration-300 appearance-none border dark:border-primary-main border-secondary-dark focus:border-inputfield-focus focus:outline-none disabled:border-inputfield-disabled rounded w-full py-2 px-3 text-sm dark:text-white text-black dark:placeholder-inputfield-dark placeholder-inputfield-light leading-tight dark:bg-secondary-dark bg-secondary-light"
                type="text"
                name="name"
                value={templateName}
                onChange={e => setTemplateName(e.target.value)}
                placeholder="Please enter template name"
              />
            </div>

            <div className="sm:w-6/12 w-full ">
              <Label className="my-2 block max-sm:text-[16px] dark:text-white text-black">Modality</Label>
              <Input
                className=" shadow transition duration-300 appearance-none border dark:border-primary-main border-secondary-dark focus:border-inputfield-focus focus:outline-none disabled:border-inputfield-disabled rounded w-full py-2 px-3 text-sm dark:text-white text-black dark:placeholder-inputfield-dark placeholder-inputfield-light leading-tight dark:bg-secondary-dark bg-secondary-light"
                type="text"
                value={modality}
                onChange={e => setModality(e.target.value)}
              />
            </div>
          </div>
          {/* </div> */}

          {/* Second Row - Body Part and Template Group */}
          {/* <div className="flex justify-between space-x-4 mt-4"> */}
          <div  className='flex justify-between w-full max-sm:flex-col sm:gap-4'>
            <div className="sm:w-6/12 w-full">
              <Label className="my-2 block max-sm:text-[16px] dark:text-white text-black">Body Parts</Label>
              <Input
                className="shadow transition duration-300 appearance-none border dark:border-primary-main border-secondary-dark focus:border-inputfield-focus focus:outline-none disabled:border-inputfield-disabled rounded w-full py-2 px-3 text-sm dark:text-white text-black dark:placeholder-inputfield-dark placeholder-inputfield-light leading-tight dark:bg-secondary-dark bg-secondary-light"
                type="text"
                value={bodyparts}
                onChange={e => setBodyparts(e.target.value)}
              />
            </div>

            <div className="sm:w-6/12 w-full">
              <Label className="my-2 block max-sm:text-[16px] dark:text-white text-black">Template Group</Label>
              <Input
                className="shadow transition duration-300 appearance-none border dark:border-primary-main border-secondary-dark focus:border-inputfield-focus focus:outline-none disabled:border-inputfield-disabled rounded w-full py-2 px-3 text-sm dark:text-white text-black dark:placeholder-inputfield-dark placeholder-inputfield-light leading-tight dark:bg-secondary-dark bg-secondary-light"
                type="text"
                value={templategroup}
                onChange={e => setTemplategroup(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* Error message */}
        { error &&
          <Label className="my-2 ml-2 mb-5 block h-3 pl-3 text-red-500">
            {error}
          </Label>
        }
      </div>


      <div className="ml-3.5 grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 items-center gap-4">
        <div
          className="template-checkbox dark:bg-secondary-dark bg-secondary-light "
          onClick={handleSelectAllClick}
        >
          <input
            type="checkbox"
            checked={selectAllChecked}
            onChange={handleSelectAllChange}
            className="cursor-pointer sm:w-[10px] sm:h-[10px]"
          />
          <span className='dark:text-white text-black' onClick={handleSelectAllClick}>Select All</span>
        </div>
      </div>

      <div className="ml-3.5 grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 items-center gap-4">
        {data.map((data, index) => (
          <div
            className="template-checkbox dark:bg-secondary-dark bg-secondary-light"
            key={index}
            onClick={() => handleNameClick(data.name)}
          >
            <input
              type="checkbox"
              checked={checkedAttributesItems[data.name] || false}
              onChange={() => handleNameClick(data.name)}
              className="cursor-pointer sm:w-[10px] sm:h-[10px]"
            />
            <span className='dark:text-white text-black' onClick={() => handleNameClick(data.name)}>{data.label}</span>
          </div>
        ))}
        <div
          className="template-checkbox dark:bg-secondary-dark bg-secondary-light"
          onClick={() => setImageCapture(!imageCapture)}
        >
          <input
            type="checkbox"
            checked={imageCapture}
            onChange={() => setImageCapture(!imageCapture)}
            className="cursor-pointer sm:w-[10px] sm:h-[10px]"
          />
          <span className='dark:text-white text-black' onClick={() => setImageCapture(!imageCapture)}>Image Capture</span>
        </div>
      </div>
      {!dataTemplate?.customTemplate && (
        <>
          <div
            className="template-checkbox mt-5 ml-3.5 w-3/6 dark:bg-secondary-dark bg-secondary-light"
            onClick={() => setSelectCustomTemplate(!selectCustomTemplate)}
          >
            <input
              type="checkbox"
              checked={selectCustomTemplate}
              onChange={() => setSelectCustomTemplate(!selectCustomTemplate)}
              className="cursor-pointer sm:w-[10px] sm:h-[10px]"
            />
            <span className='dark:text-white text-black' onClick={() => setSelectCustomTemplate(!selectCustomTemplate)}>
              {' '}
              Add template content
            </span>
          </div>
        </>
      )}

      {dataTemplate?.customTemplate && (
        <CustomTemplates
          templates={templates}
          setEditorData={setEditorData}
          dataTemplate={dataTemplate?.customTemplate}
        />
      )}

      {selectCustomTemplate && (
        <CustomTemplates
          templates={templates}
          setEditorData={setEditorData}
          dataTemplate={dataTemplate?.customTemplate}
        />
      )}

      <div className="flex">
        <Button
          className="mt-8 max-sm:text-[16px]"
          onClick={handleSubmit}
        >
          {dataTemplate ? 'Update Report Template' : 'Create Report Template'}
        </Button>

        <Button
          className="mt-8 ml-2 max-sm:text-[16px]"
          onClick={() => {
            handleClick(false);
            setSelectCustomTemplate(false);
          }}
        >
          {'Cancel'}
        </Button>
      </div>
    </div>

  );
};

export default CreateReportTemplate;
