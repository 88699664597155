import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Import the specific model interface components
import ChestAiInterface from './ChestAiInterface';
import SpineAiInterface from './SpineAiInterface';
import BreastCancerAiInterface from './BreastCancerAiInterface';
import BrainStrokeAiInterface from './BrainStrokeAiInterface';
import BoneFractureAiInterface from './BoneFractureAiInterface';

const AiTool: React.FC<{
    onSend: (modelUrl1: string) => Promise<any>;
    modelUrl1: string;
    send: boolean;
    apiData?: any;
}> = ({ onSend, modelUrl1, send, apiData }) => {
    const [loading, setLoading] = useState(false);
    const [fetchedData, setFetchedData] = useState<any | null>(null);
    const [model, setModel] = useState<string | undefined>(apiData?.model);

    // Fetch the data when send is true and modelUrl1 is available
    const handleSend = async () => {
        if (modelUrl1 && send) {
            setLoading(true);
            setFetchedData(null); // Clear previous data before fetching new data
            try {
                const result = await onSend(modelUrl1);
                // console.log(result)
                setFetchedData(result.data); // Set fetched data to state
                setModel(result.model)
            } catch (error) {
                console.error('Error sending to ML model:', error);
            } finally {
                setLoading(false);
            }
        }
    };


    // Update model whenever apiData or fetchedData changes
    useEffect(() => {
        if (apiData?.model) {
            setModel(apiData.model);
        } else if (fetchedData?.model) {
            setModel(fetchedData.model);
        }
    }, [apiData, fetchedData]);

    useEffect(() => {
        if (send) {
            handleSend(); // Trigger the data fetching when send is true or modelUrl1 changes
        }
    }, [send, modelUrl1]);

    // If fetching is in progress, show a loading state
    if (loading) {
        return <div>Loading...</div>;
    }

    // If no API data and nothing is fetched yet, show a fallback message
    if (!apiData && !fetchedData) {
        return <div>No AI results found on the database</div>;
    }

    switch (model) {
        case 'modelCXR':
            return <ChestAiInterface fetchedData={apiData?.data || fetchedData} />;
        case 'modelBreastCancer':
            return <BreastCancerAiInterface fetchedData={apiData?.data || fetchedData} />;
        case 'modelSpineAI':
            return <SpineAiInterface fetchedData={apiData?.data || fetchedData} />;
        case 'modelBrainStroke':
            return <BrainStrokeAiInterface fetchedData={apiData?.data || fetchedData} />;
        case 'modelBoneFracture':
            return <BoneFractureAiInterface fetchedData={apiData?.data || fetchedData} />;
        default:
            return <div>Unexpected model type or no model found.</div>;
    }
};

AiTool.propTypes = {
    onSend: PropTypes.func.isRequired,
    modelUrl1: PropTypes.string.isRequired,
    send: PropTypes.bool.isRequired,
    apiData: PropTypes.object,
};

export default AiTool;
