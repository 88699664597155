import React, { useEffect, useState } from 'react';
import { Button, Input, Label } from '@ohif/ui';
import './ReportTemplate.css';
import CustomTemplates from './CustomTemplates';

const CreateReportTemplate = ({
  handleClick,
  data,
  handleSubmit,
  error,
  dataTemplate,
  checkedAttributesItems,
  setCheckedAttributesItems,
  templateName,
  setTemplateName,
  templates,
  setEditorData,
  selectCustomTemplate,
  setSelectCustomTemplate,
  imageCapture,
  setImageCapture,
  modality,
  setModality,
  bodyparts,
  setBodyparts,
  templategroup,
  setTemplategroup,
}) => {
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const handleNameClick = async name => {
    const updatedCheckedItems = {
      ...checkedAttributesItems,
      [name]: !checkedAttributesItems[name],
    };
    setCheckedAttributesItems(updatedCheckedItems);
  };

  const handleSelectAllChange = e => {
    const checked = e.target.checked;
    const updatedCheckedItems = {};

    data.forEach(item => {
      updatedCheckedItems[item.name] = checked;
    });

    setCheckedAttributesItems(updatedCheckedItems);
    setSelectAllChecked(checked);
  };

  const handleSelectAllClick = () => {
    const updatedCheckedItems = {};
    const newSelectAllChecked = !selectAllChecked;

    data.forEach(item => {
      updatedCheckedItems[item.name] = newSelectAllChecked;
    });

    setCheckedAttributesItems(updatedCheckedItems);
    setSelectAllChecked(newSelectAllChecked);
  };

  useEffect(() => {
    if (dataTemplate) {
      const attributesObj = JSON.parse(dataTemplate?.templateAttributes[0]);
      setCheckedAttributesItems(attributesObj);
      setTemplateName(dataTemplate.name);
      setEditorData(dataTemplate?.customTemplate);
      setModality(dataTemplate.modality || '');
      setBodyparts(dataTemplate.bodyparts || '');
      setTemplategroup(dataTemplate.templategroup || '');
    } else {
      setCheckedAttributesItems({});
      setTemplateName('');
      setEditorData('');
      setModality('');
      setBodyparts('');
      setTemplategroup('');
    }
  }, [dataTemplate]);


  useEffect(() => {
    if (!dataTemplate) {
      const initialCheckedItems = {};
      data.forEach(item => {
        initialCheckedItems[item.name] = item.isChecked;
      });
      setCheckedAttributesItems(initialCheckedItems);
    }
  }, []);

  return (
    <div className="min-h-[550px] max-h-[550px] overflow-y-auto overflow-x-auto mr-2"
      style={{
        scrollbarColor: '#173239 transparent',
      }}>
      <div className="my-2 w-11/12 p-3">
        {/* First Row - Template Name and Modality */}
        <div className="flex justify-between space-x-4">
          <div className="w-10/12">
            <Label className="my-2 block">Template Name</Label>
            <Input
              className="border-primary-main bg-black"
              type="text"
              name="name"
              value={templateName}
              onChange={e => setTemplateName(e.target.value)}
              placeholder="Please enter template name"
            />
          </div>

          <div className="w-6/12">
            <Label className="my-2 block">Modality</Label>
            <Input
              className="border-primary-main bg-black"
              type="text"
              value={modality}
              onChange={e => setModality(e.target.value)}
            />
          </div>
          {/* </div> */}

          {/* Second Row - Body Part and Template Group */}
          {/* <div className="flex justify-between space-x-4 mt-4"> */}
          <div className="w-6/12">
            <Label className="my-2 block">Body Parts</Label>
            <Input
              className="border-primary-main bg-black"
              type="text"
              value={bodyparts}
              onChange={e => setBodyparts(e.target.value)}
            />
          </div>

          <div className="w-6/12">
            <Label className="my-2 block">Template Group</Label>
            <Input
              className="border-primary-main bg-black"
              type="text"
              value={templategroup}
              onChange={e => setTemplategroup(e.target.value)}
            />
          </div>
        </div>

        {/* Error message */}
        <Label className="my-2 ml-2 mb-5 block h-3 pl-3 text-red-500">
          {error}
        </Label>
      </div>


      <div className="ml-3.5 grid grid-cols-4 items-center gap-4">
        <div
          className="template-checkbox"
          onClick={handleSelectAllClick}
        >
          <input
            type="checkbox"
            checked={selectAllChecked}
            onChange={handleSelectAllChange}
            className="cursor-pointer"
          />
          <span onClick={handleSelectAllClick}>Select All</span>
        </div>
      </div>

      <div className="ml-3.5 grid grid-cols-4	 items-center gap-4">
        {data.map((data, index) => (
          <div
            className="template-checkbox"
            key={index}
            onClick={() => handleNameClick(data.name)}
          >
            <input
              type="checkbox"
              checked={checkedAttributesItems[data.name] || false}
              onChange={() => handleNameClick(data.name)}
              className="cursor-pointer"
            />
            <span onClick={() => handleNameClick(data.name)}>{data.label}</span>
          </div>
        ))}
        <div
          className="template-checkbox"
          onClick={() => setImageCapture(!imageCapture)}
        >
          <input
            type="checkbox"
            checked={imageCapture}
            onChange={() => setImageCapture(!imageCapture)}
            className="cursor-pointer"
          />
          <span onClick={() => setImageCapture(!imageCapture)}>Image Capture</span>
        </div>
      </div>
      {!dataTemplate?.customTemplate && (
        <>
          <div
            className="template-checkbox mt-5 ml-3.5 w-3/6"
            onClick={() => setSelectCustomTemplate(!selectCustomTemplate)}
          >
            <input
              type="checkbox"
              checked={selectCustomTemplate}
              onChange={() => setSelectCustomTemplate(!selectCustomTemplate)}
              className="cursor-pointer"
            />
            <span onClick={() => setSelectCustomTemplate(!selectCustomTemplate)}>
              {' '}
              Add template content
            </span>
          </div>
        </>
      )}

      {dataTemplate?.customTemplate && (
        <CustomTemplates
          templates={templates}
          setEditorData={setEditorData}
          dataTemplate={dataTemplate?.customTemplate}
        />
      )}

      {selectCustomTemplate && (
        <CustomTemplates
          templates={templates}
          setEditorData={setEditorData}
          dataTemplate={dataTemplate?.customTemplate}
        />
      )}

      <div className="flex">
        <Button
          className="mt-8"
          onClick={handleSubmit}
        >
          {dataTemplate ? 'Update Report Template' : 'Create Report Template'}
        </Button>

        <Button
          className="mt-8 ml-2"
          onClick={() => {
            handleClick(false);
            setSelectCustomTemplate(false);
          }}
        >
          {'Cancel'}
        </Button>
      </div>
    </div>

  );
};

export default CreateReportTemplate;
