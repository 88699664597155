import React, { useEffect,useRef  } from 'react';

const PermissionDropdown = ({
  showDropdown,
  setShowDropdown,
  showAiSubmenu,
  setShowAiSubmenu,
  selectedPermissions,
  setSelectedPermissions,
  handleSelectPermission,
  handleSelectAiModule,
  options,
  defaultPermission,
}) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Close the dropdown if clicked outside
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
        setShowAiSubmenu(false); // Also close the submenu
      }
    };

    // Add the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowDropdown, setShowAiSubmenu]);

  return (
    <div
      className="dropdown"
      ref={dropdownRef}
    >
      <button
        type="button"
        onClick={() => setShowDropdown(!showDropdown)}
        className="dropdown-button"
      >
        {selectedPermissions.length === 0
          ? 'Select Permissions'
          : selectedPermissions?.map(option => {
              return (
                <>
                  {option}
                  {', '}
                </>
              );
            })}
      </button>
      {showDropdown && (
        <div className="dropdown-menu">
          {options?.map(permission => (
            <div
              key={permission.value}
              className="dropdown-item"
            >
              <label>
                <input
                  type="checkbox"
                  checked={
                    selectedPermissions.includes(permission.value) ||
                    (permission.value === 'ai-assistance' &&
                      permission.subOptions.every(opt => selectedPermissions.includes(opt.value)))
                  }
                  onChange={() => handleSelectPermission(permission)}
                />
                {permission.label}
              </label>
              {permission.value === 'ai-assistance' && showAiSubmenu && (
                <div className="ai-submenu">
                  {permission.subOptions.map(module => (
                    <div
                      key={module.value}
                      className="submenu-item"
                    >
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedPermissions.includes(module.value)}
                          onChange={() => handleSelectAiModule(module)}
                        />
                        {module?.label}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PermissionDropdown;
