import React, { useContext, useEffect, useState } from 'react';
import { Button, Header, Select, SettingsLeftPanel, Typography } from '@ohif/ui';
import { useDisplaySettings, useUser } from '../../contextProviders';
import { useNavigate } from 'react-router-dom';
import { useAppConfig } from '@state';
import { ThemeContext } from '../ThemeWrapper/ThemeWrapper';

const DisplaySetting = ({
  servicesManager,
  extensionManager,
  hotkeysManager,
  // theme,
  fontSize,
  tat,
  alert,
  timeZone,
  daylightSaving,
  data: studies,
}) => {
  const { userAuthenticationService } = servicesManager.services;
  const user = userAuthenticationService.getUser();
  const { theme, toggleTheme } = useContext(ThemeContext);
  const { usersList } = useUser();
  const userId = usersList?.find((i) => i.email === user.profile.email)
  const { createDisplaySettingsOptions, displaySettingsOptionsLists, setWorklistAutoRefreshTime, updateDisplaySettingsOptions, setDisplaySettingsOptionsLists } = useDisplaySettings();
  const filterDisplaySettings = displaySettingsOptionsLists?.find((i) => i?.user_Id === userId?.id)
  const navigate = useNavigate();
  const [appConfig] = useAppConfig();

  const {
    token,
    userToken,
    setToken,
  } = useUser();

  useEffect(() => {
    const getToken = async () => {
      try {
        const data = {
          token: user.access_token,
        };
        const response = await userToken(data);
        setToken(response);
      } catch (error) {
        console.log(error);
      }
    };
    getToken();
  }, []);

  const onClickReturnButton = () => {
    const { pathname } = location;
    const dataSourceIdx = pathname.indexOf('/', 1);
    const query = new URLSearchParams(window.location.search);
    const configUrl = query.get('configUrl');

    const dataSourceName = pathname.substring(dataSourceIdx + 1);
    const existingDataSource = extensionManager.getDataSources(dataSourceName);

    const searchQuery = new URLSearchParams();
    if (dataSourceIdx !== -1 && existingDataSource) {
      searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    }

    if (configUrl) {
      searchQuery.append('configUrl', configUrl);
    }

    navigate({
      pathname: '/',
      search: decodeURIComponent(searchQuery.toString()),
    });
  };

  useEffect(() => {
    setWorklistAutoRefreshTime(Number(filterDisplaySettings?.worklist_auto_refresh_time) * 60 * 1000)
  }, [displaySettingsOptionsLists])

  const [state, setState] = useState({
    theme : theme ? {value : theme, label :theme  === "light" ? "Light"  : theme  === "dark"  ? "Dark" : "" } :  { value: 'light', label: 'Light' },
    fontSize,
    tat,
    alert,
    timeZone,
    daylightSaving,
    worklistAutoRefresh: filterDisplaySettings?.worklist_auto_refresh_time && { value: filterDisplaySettings?.worklist_auto_refresh_time, label: `${filterDisplaySettings?.worklist_auto_refresh_time} min` },
  });

  const themesOptions = [
    { value: 'light', label: 'Light' },
    { value: 'dark', label: 'Dark' },
  ];

  const fontSizesOptions = [
    { value: 'large', label: 'Large' },
    { value: 'medium', label: 'Medium' },
    { value: 'small', label: 'Small' },
  ];
  const dayLightOptions = [
    { value: 'on', label: 'On' },
    { value: 'off', label: 'Off' },
  ];

  const worklistAutoRefreshOptions = [
    { value: '1', label: '1 min' },
    { value: '2', label: '2 min' },
    { value: '5', label: '5 min' },
    { value: '10', label: '10 min' },
  ];


  const onValueChange = async (name, value) => {
    toggleTheme(value.value)
    setState(state => ({ ...state, [name]: value }));
  };

  const onClickSubmit = async () => {
    if (!filterDisplaySettings?.id) {
      await createDisplaySettingsOptions({ worklist_auto_refresh_time: state.worklistAutoRefresh.value, user_Id: userId?.id }, setDisplaySettingsOptionsLists)
    } else {
      await updateDisplaySettingsOptions({ id: filterDisplaySettings.id, worklist_auto_refresh_time: state.worklistAutoRefresh.value, }, setDisplaySettingsOptionsLists)

    }
  };


  const Section = ({ title, children }) => (
    <>
      <div className="mb-2 border-b-2 border-b-secondary-dark dark:border-b-primary-main">
        <Typography
          component={'p'}
          variant="inherit"
          color="primaryLight"
          className="flex pb-2 text-[16px] font-semibold !leading-[1.2] dark:text-white text-black"
        >
          {title}
        </Typography>
      </div>
      <div>{children}</div>
    </>
  );
  return (
    <section className="relative w-full dark:text-white text-black">
      <Header
        servicesManager={servicesManager}
        hotkeysManager={hotkeysManager}
        sortedStudies={studies}
        isReturnEnabled={!!appConfig.showStudyList}
        onClickReturnButton={onClickReturnButton}
        WhiteLabeling={appConfig.whiteLabeling}
        loggedInUserDetails={user}
        loggedInUserTokenDetails={token}
        headerOpen={true}
      />
      {/* <div className="absolute top-16 left-[260px]"> */}
      <div className='max-sm:px-3'>
        <div className='container relative mx-auto flex flex-col pt-5'>
          {' '}
          <Section title={'Display settings'}>
            <div className="mt-2 grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2 w-full">
              <div className="flex w-full items-center justify-between">
                <div className="w-full">
                  <Typography
                    variant="subtitle"
                    className="mr-5 h-full dark:text-white text-black"
                    component={'p'}
                  >
                    Theme
                  </Typography>
                </div>
                <div className="w-full">
                  <Select
                    id={'theme'}
                    isClearable={false}
                    onChange={value => onValueChange('theme', value)}
                    options={themesOptions}
                    value={state.theme}
                  />
                </div>
              </div>

              <div className="flex w-full items-center justify-between ">
                <div className="w-full">
                  <Typography
                    component={'p'}
                    variant="subtitle"
                    className="mr-5 h-full dark:text-white text-black"
                  >
                    {'Font Size'}
                  </Typography>
                </div>
                <div className="w-full">
                  <Select
                    id="font-size"
                    isClearable={false}
                    onChange={value => onValueChange('fontSize', value)}
                    options={fontSizesOptions}
                    value={state.fontSize}
                  />
                </div>
              </div>
            </div>

            <div className="mt-2 grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2 w-full">
              <div className=" flex w-full items-center justify-between">
                <div className="w-full">
                  <Typography
                    component={'p'}
                    variant="subtitle"
                    className="mr-5 h-full dark:text-white text-black"
                  >
                    TAT
                  </Typography>
                </div>
                <div className="w-full">
                  <Select
                    id="tat"
                    isClearable={false}
                    onChange={value => onValueChange('theme', value)}
                    options={themesOptions}
                    value={state.theme}
                  />
                </div>
              </div>
              <div className="flex w-full items-center justify-between ">
                <div className="w-full">
                  <Typography
                    component={'p'}
                    variant="subtitle"
                    className="mr-5 h-full dark:text-white text-black"
                  >
                    Email/WhatsApp alert
                  </Typography>
                </div>
                <div className="w-full">
                  <label className="inline-flex cursor-pointer items-center">
                    <input
                      type="checkbox"
                      value={state.alert}
                      onClick={() =>
                        setState(prevState => ({ ...prevState, alert: !prevState.alert }))
                      }
                      className="peer sr-only"
                    />
                    <div className="after:start-[2px] peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                  </label>
                </div>
              </div>
            </div>

            <div className="mt-2 grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2 w-full">
              <div className=" flex w-full items-center justify-between">
                <div className="w-full">
                  <Typography
                    component={'p'}
                    variant="subtitle"
                    className="mr-5 h-full dark:text-white text-black"
                  >
                    {'Time Zone'}
                  </Typography>
                </div>
                <div className="w-full">
                  <Select
                    id="timezone"
                    isClearable={false}
                    onChange={value => onValueChange('timezone', value)}
                    options={themesOptions}
                    value={state.timeZone}
                  />
                </div>
              </div>

              <div className=" flex w-full items-center justify-between ">
                <div className="w-full">
                  <Typography
                    component={'p'}
                    variant="subtitle"
                    className="mr-5 h-full dark:text-white text-black"
                  >
                    {'Daylight Saving'}
                  </Typography>
                </div>
                <div className="w-full">
                  <label className="inline-flex cursor-pointer items-center">
                    <input
                      type="checkbox"
                      value={state.daylightSaving}
                      onClick={() =>
                        setState(prevState => ({ ...prevState, daylightSaving: !prevState.alert }))
                      }
                      className="peer sr-only"
                    />
                    <div className="after:start-[2px] peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                  </label>
                </div>
              </div>

            </div>
            <div className="mt-2 grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2 w-full">
              <div className=" flex w-full items-center justify-between">
                <div className="w-full">
                  <Typography
                    component={'p'}
                    variant="subtitle"
                    className="mr-5 h-full dark:text-white text-black"
                  >
                    {'Worklist Auto Refresh'}
                  </Typography>
                </div>
                <div className="w-full">
                  <Select
                    id="worklistAutoRefresh"
                    isClearable={false}
                    onChange={value => onValueChange('worklistAutoRefresh', value)}
                    options={worklistAutoRefreshOptions}
                    value={state.worklistAutoRefresh}
                  />
                </div>
              </div>
            </div>
            <Button onClick={onClickSubmit} className=' mt-3'>Submit</Button>
          </Section>
        </div>
      </div>
    </section>
  );
};

export default DisplaySetting;
