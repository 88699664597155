import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const flex = 'flex flex-row justify-between items-center';
const theme = 'bg-indigo-dark dark:text-white text-black';

const ListMenu = ({ items = [], renderer, onClick = () => {} }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const [isVisible, setIsVisible] = useState(window.innerWidth > 1400);

  useEffect(() => {
    const handleResize = () => {
      setIsVisible(window.innerWidth > 1400);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const ListItem = ({ item, index, isSelected }) => {
    const onClickHandler = () => {
      setSelectedIndex(index);
      onClick({ item, selectedIndex: index });
      if (item.onClick) {
        item.onClick({ ...item, index, isSelected });
      }
    };

    return (
      <div
        className={classnames(flex, theme, {
          'cursor-pointer': !item.disabled,
          'ohif-disabled': item.disabled,
        })}
        onClick={onClickHandler}
        data-cy={item.id}
      >
        {renderer && renderer({ ...item, index, isSelected })}
      </div>
    );
  };

  return (
    <div
      className={`dark:bg-secondary-dark bg-secondary-light flex flex-col gap-[4px] overflow-auto rounded-md p-1 ${isVisible ? 'fixed' : ''}`}
      style={{ maxHeight: 'calc(100vh - 5rem)' }}
    >
      {items.map((item, index) => {
        return (
          <ListItem
            key={`ListItem${index}`}
            index={index}
            isSelected={selectedIndex === index}
            item={item}
          />
        );
      })}
    </div>
  );
};

ListMenu.propTypes = {
  items: PropTypes.array.isRequired,
  renderer: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

export default ListMenu;
