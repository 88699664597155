import React from 'react';
import { useUserAuthentication } from '@ohif/ui';
import { decodeToken } from 'react-jwt';

const AccessDenied = ({ message = `Sorry, you don\'t have access to this page.` }) => {
  return (
    <div className="absolute flex h-full w-full items-center justify-center text-white">
      <div>
        <h4>{message}</h4>
        <a
          href="/"
          className="ml-16 text-sky-400"
          style={{ color: 'cornflowerblue' }}
        >
          Go back to homepage
        </a>
      </div>
    </div>
  );
};

export const PrivateRoute = ({ children, handleUnauthenticated, accessRoles, path }) => {
  const [{ user, enabled }] = useUserAuthentication();

  const myDecodedToken: any = decodeToken(user?.access_token);

  const userRoles = myDecodedToken?.realm_access?.roles || [];
  const userPermission = myDecodedToken?.permission || [];
  const userRoleType = myDecodedToken?.roleType || []

  const hasRequiredRole = accessRoles?.some(role => userRoles?.includes(role) || userPermission?.includes(role) || userRoleType?.includes(role));

  const hasDashBoardPermisson = path === '/dashboard/connectivity-status' && userPermission?.includes('Access Dashboard') && userRoleType?.includes('RadiologyGroupAdmin')

  if (enabled && !user) {
    return handleUnauthenticated();
  }

  if ((accessRoles !== undefined && !hasRequiredRole) || hasDashBoardPermisson) {
    return <AccessDenied />;
  } else {
    return children;
  }
};

export default PrivateRoute;

// import { useUserAuthentication } from '@ohif/ui';

// export const PrivateRoute = ({ children, handleUnauthenticated }) => {
//   const [{ user, enabled }] = useUserAuthentication();

//   if (enabled && !user) {
//     return handleUnauthenticated();
//   }

//   return children;
// };

// export default PrivateRoute;
