import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import LegacyButton from '../LegacyButton';
import Icon from '../Icon';
import Typography from '../Typography';
import InputGroup from '../InputGroup';
import Select from '../Select';

const StudyListFilter = ({
  filtersMeta,
  filterValues,
  onChange,
  clearFilters,
  isFiltering,
  numOfStudies,
  onUploadClick,
  getDataSourceConfigurationComponent,
  handleFilterStudies,
  displaySetList,
  otherData,
  isCriticalData,
  selectedRows,
  patientReportsDetails, handleStudiesDelete,
  user,
  token,
}) => {
  const [selectedWorklistFilter, setSelectedWorklistFilter] = useState(displaySetList[1].value)
  const { t } = useTranslation('StudyList');
  const { sortBy, sortDirection } = filterValues;
  const filterSorting = { sortBy, sortDirection };
  const setFilterSorting = sortingValues => {
    onChange({
      ...filterValues,
      ...sortingValues,
    });
  };

  // useEffect(() => {
  //   handleFilterStudies(selectedWorklistFilter)
  // },[selectedWorklistFilter, handleFilterStudies])

  // const handleFilterChange = (e) => {
  //   setSelectedWorklistFilter(e.value);
  // }
  // const isSortingEnabled = numOfStudies > 0 && numOfStudies <= 100;

  return (
    <React.Fragment>
      <div>
        <div className="postion__header_design bg-black">
          <div className=" relative mx-auto flex flex-col px-5 pt-2">
            <div>
              <div>
                {/* <Typography
                  variant="h6"
                  className="text-white"
                >
                  {t('WorkList')}
                </Typography> */}
                {/* <Select
                  id="group_name"
                  placeholder={'Select Filter'}
                  isClearable={false}
                  onChange={handleFilterChange}
                  options={displaySetList}
                  value={[selectedWorklistFilter]}
                  className="mr-5 w-[200px]"
                /> */}
                <InputGroup
                  inputMeta={filtersMeta}
                  values={filterValues}
                  onValuesChange={onChange}
                  sorting={filterSorting}
                  onSortingChange={setFilterSorting}
                  handleFilterStudies={handleFilterStudies}
                  displaySetList={displaySetList}
                  isFiltering={isFiltering}
                  clearFilters={clearFilters}
                  otherData={otherData}
                  isCriticalData={isCriticalData}
                  patientReportsDetails={patientReportsDetails}
                  handleStudiesDelete={handleStudiesDelete}
                  selectedRows={selectedRows}
                  user={user}
                  token={token}

                />
                {getDataSourceConfigurationComponent && getDataSourceConfigurationComponent()}
                {onUploadClick && (
                  <div
                    className="text-primary-active flex cursor-pointer items-center gap-2 self-center text-lg font-semibold"
                    onClick={onUploadClick}
                  >
                    <Icon name="icon-upload"></Icon>
                    <span>{t('Upload')}</span>
                  </div>
                )}
              </div>
              {/* <div className="flex h-[34px] flex-row items-center"> */}
              {/* TODO revisit the completely rounded style of button used for clearing the study list filter - for now use LegacyButton*/}
              {/* {isFiltering && (
                  <LegacyButton
                    rounded="full"
                    variant="outlined"
                    color="primaryActive"
                    border="primaryActive"
                    className="mx-8"
                    startIcon={<Icon name="cancel" />}
                    onClick={clearFilters}
                  >
                    {t('ClearFilters')}
                  </LegacyButton>
                )} */}
              {/* <Typography
                  variant="h6"
                  className="text-primary-light"
                >
                  {`${t('Number of studies')}: `}
                </Typography>
                <Typography
                  variant="h6"
                  className="mr-2"
                  data-cy={'num-studies'}
                >
                  {numOfStudies}
                </Typography> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="sticky -top-1 z-10 mx-auto border-b-4 border-black w-full px-5 scroll_width_header_body">
        <div className="bg-primary-dark pt-3 pb-3 " style={{width: 'fit-content !important'}}> */}
      {/* <InputGroup
            inputMeta={filtersMeta}
            values={filterValues}
            onValuesChange={onChange}
            sorting={filterSorting}
            onSortingChange={setFilterSorting}
          /> */}
      {/* </div>
      </div> */}
    </React.Fragment>
  );
};

StudyListFilter.propTypes = {
  filtersMeta: PropTypes.arrayOf(
    PropTypes.shape({
      /** Identifier used to map a field to it's value in `filterValues` */
      name: PropTypes.string.isRequired,
      /** Friendly label for filter field */
      displayName: PropTypes.string.isRequired,
      /** One of the supported filter field input types */
      inputType: PropTypes.oneOf(['Text', 'MultiSelect', 'DateRange', 'None']).isRequired,
      isSortable: PropTypes.bool.isRequired,
      /** Size of filter field in a 12-grid system */
      gridCol: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]).isRequired,
      /** Options for a "MultiSelect" inputType */
      option: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  filterValues: PropTypes.object.isRequired,
  numOfStudies: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  isFiltering: PropTypes.bool.isRequired,
  onUploadClick: PropTypes.func,
  getDataSourceConfigurationComponent: PropTypes.func,
};

export default StudyListFilter;
