import React, { useState } from 'react';
import Label from '../Label';
import Input from '../Input';
import Button from '../Button';
import Icon from '../Icon';
import './Physician.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import Select from '../Select';

const PhysicianCreateModel = ({
  user,
  editPhysicianUserData,
  createUser,
  updateUser,
  hide,
  radiologyGroupList,
  addRadiologyGroupMember,
  deleteRadiologyGroupMember,
  fetchUsers,
  setUsersList,
}) => {
  const { show } = useSnackbar();

  const initialValues = {
    firstName: editPhysicianUserData?.firstName || '',
    lastName: editPhysicianUserData?.lastName || '',
    username: editPhysicianUserData?.username || '',
    email: editPhysicianUserData?.email || '',
    password: editPhysicianUserData?.password || '',
    confirmPassword: editPhysicianUserData?.password || '',
    mobileNumber: editPhysicianUserData?.attributes?.mobileNumber || '',
    radiologyGroup: editPhysicianUserData?.attributes?.radiologyGroup || [],
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    username: Yup.string().required('User Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: !editPhysicianUserData ? Yup.string().required('Password is required') : Yup.string(),
    confirmPassword: !editPhysicianUserData
      ? Yup.string()
          .required('Confirm Password is required')
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
      : Yup.string(),
    mobileNumber: Yup.number().required('Mobile Number is required'),
    radiologyGroup: Yup.array().required('Select at least one Radiology Group is required'),
  });

  const options = radiologyGroupList.map(group => ({
    label: group.name,
    value: group.name,
  }));

  const initialSelectedOptions = editPhysicianUserData?.attributes?.radiologyGroup || [];
  const [selectedOptions, setSelectedOptions] = useState(
    initialSelectedOptions.flatMap(option => option.split(', '))
  );
  const [passwordeye, setpasswordeye] = useState(false);
  const [confirmPassword, setConfirmPasswordeye] = useState(false);
  const [error, setError] = useState(null);

  const handleSelectionChange = selected => {
    setSelectedOptions(selected);
  };

  const handleSubmitPhysicianUser = async (values, { setSubmitting }) => {
    try {
      if (editPhysicianUserData) {
        const physicianUserDetail = {
          username: values.username,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          attributes: {
            radiologyGroup: selectedOptions.label || selectedOptions[0],
            roleType: ['Physician'],
            mobileNumber: values.mobileNumber,
          },
        };
        const response = await updateUser(
          editPhysicianUserData.id,
          physicianUserDetail,
          user.access_token
        );
        if (response.status === 204) {
          await fetchUsers(user.access_token)
            .then(data => setUsersList(data))
            .catch(error => console.error('Error fetching users:', error));
          show({
            title: 'Success!',
            message: 'Successfully Physician User Update',
            type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
            position: 'topRight',
          });
          hide();
        }
        const findGroup = radiologyGroupList.find(
          group => group.name === initialValues.radiologyGroup[0]
        );
        const findCurrentGroup = radiologyGroupList.find(
          group => group.name === selectedOptions.label
        );

        if (findCurrentGroup && findGroup.id !== findCurrentGroup.id) {
          addRadiologyGroupMember(editPhysicianUserData.id, findCurrentGroup.id, user.access_token);
          deleteRadiologyGroupMember(editPhysicianUserData.id, findGroup.id, user.access_token);
        }
      } else {
        const data = {
          username: values.username,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          attributes: {
            radiologyGroup: selectedOptions.label,
            roleType: ['Physician'],
            mobileNumber: values.mobileNumber,
          },
          credentials: [{ type: 'password', temporary: true, value: values.password }],
          enabled: true,
          emailVerified: true,
        };
        const response = await createUser(data, user.access_token);
        if (response.status === 201) {
          await fetchUsers(user.access_token)
            .then(async data => {
              setUsersList(data);
              const findeUser = data.find(items => items.email === values.email);
              const findGroup = radiologyGroupList.find(
                group => group.name === selectedOptions.label
              );

              await addRadiologyGroupMember(findeUser.id, findGroup.id, user.access_token);
            })
            .catch(error => console.error('Error fetching users:', error));
          show({
            title: 'Success!',
            message: 'Successfully Physician User Created',
            type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
            position: 'topRight',
          });
          hide();
        } else {
          const errorMessage = await response.json();
          setError(errorMessage.errorMessage);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <section className="w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitPhysicianUser}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label className="my-2 block">First Name</Label>
                <Field
                  type="text"
                  name="firstName"
                  as={Input}
                  placeholder="Please enter your first name"
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block">Last Name</Label>
                <Field
                  type="text"
                  name="lastName"
                  as={Input}
                  placeholder="Please enter your last name"
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block">User Name</Label>
                <Field
                  type="text"
                  name="username"
                  as={Input}
                  placeholder="Please enter your user name"
                  disabled={editPhysicianUserData ? true : false}
                />
                <ErrorMessage
                  name="username"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block">Mobile Number</Label>
                <Field
                  type="text"
                  name="mobileNumber"
                  as={Input}
                  placeholder="Please enter your mobile number"
                />
                <ErrorMessage
                  name="mobileNumber"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block">Email</Label>
                <Field
                  type="text"
                  name="email"
                  as={Input}
                  placeholder="Please enter your email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="passworld_box">
                <Label className="my-2 block">Password</Label>
                <Field
                  type={passwordeye ? 'text' : 'password'}
                  name="password"
                  as={Input}
                  placeholder="Please enter your password"
                />
                <Icon
                  onClick={() => setpasswordeye(!passwordeye)}
                  name={passwordeye ? 'eye-visible' : 'eye-hidden'}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="passworld_box">
                <Label className="my-2 block">Confirm Password</Label>
                <Field
                  type={confirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  as={Input}
                  placeholder="Please enter your confirm password"
                />
                <Icon
                  onClick={() => setConfirmPasswordeye(!confirmPassword)}
                  name={confirmPassword ? 'eye-visible' : 'eye-hidden'}
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block"> Radiology Group</Label>
                <Field
                  name="radiologyGroup"
                  as={Select}
                  placeholder="Select Radiology Group"
                  options={options}
                  value={selectedOptions || editPhysicianUserData?.attributes?.radiologyGroup}
                  onChange={handleSelectionChange}
                />

                <ErrorMessage
                  name="radiologyGroup"
                  component="div"
                  className="text-red-600"
                />
              </div>
            </div>
            <div className="flex">
              <Button
                className="mt-8"
                disabled={isSubmitting}
              >
                {editPhysicianUserData ? 'Update Physician User' : 'Create Physician User'}
              </Button>
              <Label className="my-2 mt-9 block h-3 pl-3 text-red-600	">{error}</Label>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default PhysicianCreateModel;
