export const fetchDisplaySettingsOptions = async () => {
    return await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/display_settings`)
        .then((response) => response.json())
}

export const createDisplaySettingsOptions = async (refreshData, setDisplaySettingsOptionsLists) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/display_settings`, {
            method: 'POST',
            body: JSON.stringify(refreshData),
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error(`Failed to create document: ${response.statusText}`);
        }

        const data = await response.json();
        await fetchDisplaySettingsOptions()
            .then((data) => setDisplaySettingsOptionsLists(data))
            .catch((error) =>
                console.error('Error fetching document upload details:', error)
            );
        return data;
    } catch (error) {
        console.error('Error creating document:', error);
        throw error;
    }
};


export const updateDisplaySettingsOptions = async (displaySettingsData, setDisplaySettingsOptionsLists) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/display_settings/${displaySettingsData.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ worklist_auto_refresh_time: displaySettingsData.worklist_auto_refresh_time })
        })
        if (!response.ok) {
            throw new Error(`Failed to create document: ${response.statusText}`);
        }
        const data = await response.json();
        await fetchDisplaySettingsOptions()
            .then((data) => setDisplaySettingsOptionsLists(data))
            .catch((error) =>
                console.error('Error fetching document upload details:', error)
            );
        return data;
    } catch (error) {
        console.error('Error creating document:', error);
        throw error;
    }
}
