import React, { useEffect } from 'react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import './ReportTemplate.css'

const PreviewTemplate = ({ templates }) => {
  useEffect(() => {
    const editorElement = document.querySelector('#editor') as HTMLElement;
    if (!editorElement || !templates) return;
    DecoupledEditor.create(editorElement, {

      language: 'en',
    }
)
      .then(editor => {
        editor.setData(templates);
      })
      .catch(error => {
        console.error(error);
      });
  }, [templates]);
  return (
    <>
      <div className="pointer-events-none">
        <div id="toolbar-container"></div>
        <div id="editor" className='bg-white text-black'></div>
      </div>
    </>
  );
};

export default PreviewTemplate;
