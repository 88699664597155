import React, { useEffect, useState } from 'react';
import { Dashboard, Header, useUser } from '@ohif/ui';
import Reports from './Reports';
import Users from './Users';
import Institutions from './Institutions';
import './style.css';
import StudyStatus from './StudyStatus';
import { useNavigate } from 'react-router-dom';
import { useAppConfig } from '@state';

const Analytics = ({ servicesManager, extensionManager, hotkeysManager, data: studies }) => {
    const [activeTab, setActiveTab] = useState('Reports');

    const { userAuthenticationService } = servicesManager.services;
    const user = userAuthenticationService.getUser();

    const navigate = useNavigate();
    const [appConfig] = useAppConfig();

    const {
        token,
        userToken,
        setToken,
    } = useUser();

    useEffect(() => {
        const getToken = async () => {
            try {
                const data = {
                    token: user.access_token,
                };
                const response = await userToken(data);
                setToken(response);
            } catch (error) {
                console.log(error);
            }
        };
        getToken();
    }, []);

    const onClickReturnButton = () => {
        const { pathname } = location;
        const dataSourceIdx = pathname.indexOf('/', 1);
        const query = new URLSearchParams(window.location.search);
        const configUrl = query.get('configUrl');

        const dataSourceName = pathname.substring(dataSourceIdx + 1);
        const existingDataSource = extensionManager.getDataSources(dataSourceName);

        const searchQuery = new URLSearchParams();
        if (dataSourceIdx !== -1 && existingDataSource) {
            searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
        }

        if (configUrl) {
            searchQuery.append('configUrl', configUrl);
        }

        navigate({
            pathname: '/',
            search: decodeURIComponent(searchQuery.toString()),
        });
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'Reports':
                return <Reports servicesManager={servicesManager}
                    extensionManager={extensionManager}
                    hotkeysManager={hotkeysManager} />;
            case 'Users':
                return <Users servicesManager={servicesManager}
                    extensionManager={extensionManager}
                    hotkeysManager={hotkeysManager} />;
            case 'Institutions':
                return <Institutions servicesManager={servicesManager}
                    extensionManager={extensionManager}
                    hotkeysManager={hotkeysManager} />;
            case 'StudyStatus':
                return (
                    <StudyStatus
                        servicesManager={servicesManager}
                        extensionManager={extensionManager}
                        hotkeysManager={hotkeysManager}
                    />
                );
            default:
                return <Reports servicesManager={servicesManager}
                    extensionManager={extensionManager}
                    hotkeysManager={hotkeysManager} />;
        }
    };

    return (
        <section className="relative w-full text-white">
            <Header
                servicesManager={servicesManager}
                hotkeysManager={hotkeysManager}
                sortedStudies={studies}
                isReturnEnabled={!!appConfig.showStudyList}
                onClickReturnButton={onClickReturnButton}
                WhiteLabeling={appConfig.whiteLabeling}
                loggedInUserDetails={user}
                loggedInUserTokenDetails={token}
                headerOpen={true}
            />
            {/* <div className="absolute top-16 left-[250px] w-10/12"> */}
            <div className='max-sm:px-3'>
                <div className="tabs" style={{ display: 'flex', justifyContent: 'center' }} >
                <button
                        className={`!text-secondary-dark dark:!text-secondary-light tab-button hover:opacity-80 sm:text-sm text-[10px] ${activeTab === 'Reports' ? 'active' : ''}`}
                        onClick={() => setActiveTab('Reports')}
                    >
                        Reports
                    </button>
                    <button
                        className={`!text-secondary-dark dark:!text-secondary-light tab-button hover:opacity-80 sm:text-sm text-[10px] ${activeTab === 'Users' ? 'active' : ''}`}
                        onClick={() => setActiveTab('Users')}
                    >
                        Users
                    </button>
                    <button
                        className={`!text-secondary-dark dark:!text-secondary-light tab-button hover:opacity-80 sm:text-sm text-[10px] ${activeTab === 'Institutions' ? 'active' : ''}`}
                        onClick={() => setActiveTab('Institutions')}
                    >
                        Institutions
                    </button>
                    <button
                        className={`!text-secondary-dark dark:!text-secondary-light tab-button hover:opacity-80 sm:text-sm text-[10px] ${activeTab === 'StudyStatus' ? 'active' : ''}`}
                        onClick={() => setActiveTab('StudyStatus')}
                    >
                        Study status
                    </button>
                </div>
                {renderContent()}
            </div>
        </section>
    );
};

export default Analytics;
