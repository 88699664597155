import React, { useEffect } from 'react';
import { Button, Header, SettingsLeftPanel, Typography, useUser } from '@ohif/ui';
import axios from 'axios';
import { useAppConfig } from '@state';
import { useNavigate } from 'react-router-dom';

const MyAccount = ({ servicesManager, extensionManager, hotkeysManager, data: studies }) => {
  const { userAuthenticationService } = servicesManager.services;
  const [appConfig] = useAppConfig();
  const navigate = useNavigate();
  const {
    token,
    userToken,
    setToken,
  } = useUser();

  const user = userAuthenticationService.getUser();
  useEffect(() => {
    const getToken = async () => {
      try {
        const data = {
          token: user.access_token,
        };
        const response = await userToken(data);
        setToken(response);
      } catch (error) {
        console.log(error);
      }
    };
    getToken();
  }, []);

  const onClickReturnButton = () => {
    const { pathname } = location;
    const dataSourceIdx = pathname.indexOf('/', 1);
    const query = new URLSearchParams(window.location.search);
    const configUrl = query.get('configUrl');

    const dataSourceName = pathname.substring(dataSourceIdx + 1);
    const existingDataSource = extensionManager.getDataSources(dataSourceName);

    const searchQuery = new URLSearchParams();
    if (dataSourceIdx !== -1 && existingDataSource) {
      searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    }

    if (configUrl) {
      searchQuery.append('configUrl', configUrl);
    }

    navigate({
      pathname: '/',
      search: decodeURIComponent(searchQuery.toString()),
    });
  };

  const handleChangePassword = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/users/${user.profile.sub}/reset-password`,
        { temporary: true, value: 'temporaryPassword' },
        {
          headers: {
            Authorization: `${user.token_type} ${user.access_token}`,
          },
        }
      );
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const Section = ({ title, children }) => (
    <>
      <div className="mb-2 border-b-2 border-b-secondary-dark dark:border-b-primary-main">
        <Typography
          component={'p'}
          variant="inherit"
          color="primaryLight"
          className="flex pb-2 text-[16px] font-semibold !leading-[1.2] dark:text-white text-black"
        >
          {title}
        </Typography>
      </div>
      <div className="mt-4 mb-8">{children}</div>
    </>
  );
  return (
    <section className="relative w-full dark:text-white text-black">
      <Header
        // servicesManager={servicesManager}
        hotkeysManager={hotkeysManager}
        // sortedStudies={studies}
        // isReturnEnabled={!!appConfig.showStudyList}
        onClickReturnButton={onClickReturnButton}
        // WhiteLabeling={appConfig.whiteLabeling}
        loggedInUserDetails={user}
        loggedInUserTokenDetails={token}
        headerOpen={true}
      />
      {/* <div className="absolute top-16 left-[260px]"> */}
      <div className='max-sm:px-3'>
        <div className='container relative mx-auto flex flex-col pt-5'>
          {' '}
          <Section title={'My account'}>
            <div className="flex">
              <Typography
                component={'p'}
                variant="subtitle"
                className="mr-5 h-full cursor-pointer"
                onClick={handleChangePassword}
              >
                <Button> Change Password</Button>
              </Typography>
            </div>
          </Section>
        </div>
      </div>
    </section>
  );
};

export default MyAccount;
