import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { createRadiologyGroup, fetchRadiologyGroup, deleteRadiologyGroup, updateRadiologyGroup, fetchSingleRadiologyGroup, addRadiologyGroupMember, deleteRadiologyGroupMember, viewRadiologyGroupMember, createRadiologyGroupSetting, fetchRadiologyGroupSetting, updateRadiologyGroupSetting, uploadImage } from '../requestHandler/radiologyGroupRequest';
import { UserProvider } from './UserProvider';

export const RadiologyGroupContext = createContext(null);


export const RadiologyGroupContextProvider = ({ children, service, extension }) => {
    const [radiologyGroupList, setRadiologyGroupList] = useState([]);
    const [singleRadiologyGroupList, setSingleRadiologyGroupList] = useState([]);
    const [radiologyGroupMemberLsit, setRadiologyGroupMemberList] = useState([]);
    const [radiologyGroupSetting, setRadiologyGroupSetting] = useState('');
    const [groupIds, setGroupIds] = useState([]);

    const accessToken = sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_KEYCLOAK_AUTHORIZATION_URL}`);
    const userData = JSON.parse(accessToken);
    const accessTokens = userData?.access_token;

    useEffect(() => {
        const filteredGroupIds = radiologyGroupList && radiologyGroupList
            .filter(group => !group.error)
            .map(group => group.id);

            setGroupIds(filteredGroupIds);
    }, [radiologyGroupList, fetchRadiologyGroup]);

    useEffect(() => {

        if (accessTokens) {
            const fetchData = async () => {
                try {
                    // Fetch radiology groups
                    const groupsData = await fetchRadiologyGroup(accessTokens);
                    setRadiologyGroupList(groupsData);

                    // Fetch settings
                    const settingsData = await fetchRadiologyGroupSetting();
                    setRadiologyGroupSetting(settingsData);

                    // Only fetch single radiology groups after groupIds are set
                    if (groupsData.length > 0) {
                        const filteredGroupIds = groupsData
                            .filter(group => !group.error)
                            .map(group => group.id);
                        const singleGroupData = await fetchSingleRadiologyGroup(accessTokens, filteredGroupIds);
                        setSingleRadiologyGroupList(singleGroupData);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };

            fetchData();
        }
    }, [accessTokens]);


    const value = useMemo(
        () => ({
            radiologyGroupList,
            createRadiologyGroup,
            fetchRadiologyGroup,
            deleteRadiologyGroup,
            updateRadiologyGroup,
            fetchSingleRadiologyGroup,
            setRadiologyGroupList,
            singleRadiologyGroupList,
            setGroupIds,
            groupIds,
            setSingleRadiologyGroupList,
            addRadiologyGroupMember,
            deleteRadiologyGroupMember,
            viewRadiologyGroupMember,
            setRadiologyGroupMemberList,
            createRadiologyGroupSetting,
            updateRadiologyGroupSetting,
            radiologyGroupSetting,
            fetchRadiologyGroupSetting,
            setRadiologyGroupSetting,
            radiologyGroupMemberLsit,
            uploadImage
        }),
        [radiologyGroupList, singleRadiologyGroupList, radiologyGroupSetting, radiologyGroupMemberLsit]
    );

    return <RadiologyGroupContext.Provider value={value}>  <UserProvider>
{children}   </UserProvider>
</RadiologyGroupContext.Provider>;
};

export const useRadiologyGroup = () => useContext(RadiologyGroupContext);
