// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch-button input:checked ~ .switch-button-outer {
  --tw-border-opacity: 1;
  border-color: rgba(212, 212, 212, var(--tw-border-opacity))
}
.switch-button input:checked ~ .switch-button-dot {
  --tw-bg-opacity: 1;
  background-color: rgba(212, 212, 212, var(--tw-bg-opacity));
  -webkit-transform: translateX(12px);
          transform: translateX(12px)
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/SwitchButton/switchButton.css"],"names":[],"mappings":"AACE;EAAA,sBAA2B;EAA3B;AAA2B;AAG3B;EAAA,kBAAuB;EAAvB,2DAAuB;EACvB,mCAA2B;UAA3B;AADuB","sourcesContent":[".switch-button input:checked ~ .switch-button-outer {\n  @apply border-primary-light;\n}\n.switch-button input:checked ~ .switch-button-dot {\n  @apply bg-primary-light;\n  transform: translateX(12px);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
