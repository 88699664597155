// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="file"]::-webkit-file-upload-button {
    border: 2px solid #5acce6;
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
    background-color: #5acce6;
    -webkit-transition: 1s;
    transition: 1s;
  }
  
  input[type="file"]::file-selector-button {
    border: 2px solid #5acce6;
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
    background-color: #5acce6;
    -webkit-transition: 1s;
    transition: 1s;
  }
  
  input[type="file"]::-webkit-file-upload-button:hover {
    background-color: #6c5ce7;
    border: 2px solid #6c5ce7;
  }
  
  input[type="file"]::file-selector-button:hover {
    background-color: #6c5ce7;
    border: 2px solid #6c5ce7;
  }`, "",{"version":3,"sources":["webpack://./../../ui/src/components/ReportSetting/ReportSetting.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,oBAAoB;IACpB,oBAAoB;IACpB,yBAAyB;IACzB,sBAAc;IAAd,cAAc;EAChB;;EANF;IACI,yBAAyB;IACzB,oBAAoB;IACpB,oBAAoB;IACpB,yBAAyB;IACzB,sBAAc;IAAd,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,yBAAyB;EAC3B;;EAHA;IACE,yBAAyB;IACzB,yBAAyB;EAC3B","sourcesContent":["input[type=\"file\"]::file-selector-button {\n    border: 2px solid #5acce6;\n    padding: 0.2em 0.4em;\n    border-radius: 0.2em;\n    background-color: #5acce6;\n    transition: 1s;\n  }\n  \n  input[type=\"file\"]::file-selector-button:hover {\n    background-color: #6c5ce7;\n    border: 2px solid #6c5ce7;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
