import React, { useRef, useState } from 'react';
import Label from '../Label';
import Input from '../Input';
import Button from '../Button';
import Icon from '../Icon';
// import './Physician.css'
import InputMultiSelect from '../InputMultiSelect';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useModal, useReportEditor, useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import Select from '../Select';
import CheckBox from '../CheckBox';



const RadiologyGroupAdminCreateModel = ({
  createUser,
  updateUser,
  fetchUsers,
  setUsersList,
  user,
  editradiologistUserData,
  hidden,
  radiologyGroupList,
  addRadiologyGroupMember,
  deleteRadiologyGroupMember,
  uploadImage,
  defaultTemplates
}) => {
  const { show: showSnackbar } = useSnackbar();

  const initialValues = {
    username: editradiologistUserData?.username || '',
    firstName: editradiologistUserData?.firstName || '',
    lastName: editradiologistUserData?.lastName || '',
    mobileNumber: editradiologistUserData?.attributes?.mobileNumber || '',
    // username: editradiologistUserData?.username || '',
    email: editradiologistUserData?.email || '',
    password: editradiologistUserData?.password || '',
    confirmPassword: editradiologistUserData?.password || '',
    title: editradiologistUserData?.attributes?.title[0] || '',
    radiologyGroup: editradiologistUserData?.attributes?.radiologyGroup || [],
    permission: editradiologistUserData?.attributes?.permission || '',
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('User Name is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    mobileNumber: Yup.number().required('Mobile Number is required'),
    password: !editradiologistUserData
      ? Yup.string().required('Password is required')
      : Yup.string(),
    confirmPassword: !editradiologistUserData
      ? Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
      : Yup.string(),
    title: Yup.string().required('Title is required'),
    radiologyGroup: Yup.array().required('Radiology Group Admin is required'),
    // radiologyGroup: Yup.array().min(1).required('Select at least one Radiology Group is required'),
  });

  const options = [
    { label: 'Assign', value: 'Assign' },
    { label: 'Upload Study', value: 'Upload Study' },
    { label: 'Delete Study', value: 'Delete Study' },
    { label: 'Export Worklist', value: 'Export Worklist' },
    { label: 'Access Dashboard', value: 'Access Dashboard' },
    { label: 'Create Invoice', value: 'Create Invoice' },
    { label: 'Share Report', value: 'Share Report' },
    { label: 'Edit Report', value: 'Edit Report' },
    { label: 'Ai Assistance', value: 'Ai Assistance' },
  ];

  const optionsRadiologyGroup = [
    { label: 'Default', value: 'Default' },
    ...radiologyGroupList.map(group => ({
      label: group.name,
      value: group.name,
    }))
  ];

  const initialSelectedRadiologyGroupOptions = editradiologistUserData?.attributes?.radiologyGroup || [];
  const initialSelectedOptions = editradiologistUserData?.attributes?.permission || [];

  const [selectedRadiologyGroupOptions, setSelectedRadiologyGroupOptions] = useState(
    initialSelectedRadiologyGroupOptions.flatMap(option => option)
  );
  const [selectedOptions, setSelectedOptions] = useState(
    initialSelectedOptions.flatMap(option => option.split(', '))
  );

  const [passwordeye, setpasswordeye] = useState(false);
  const [confirmPassword, setConfirmPasswordeye] = useState(false);
  const [error, setError] = useState(null);

  const handleSelectionChangeRadiologyGroup = selected => {
    setSelectedRadiologyGroupOptions(selected);
  };

  const handleSelectionChange = selected => {
    setSelectedOptions(selected);
  };

  const handleSubmit = async (values, { setSubmitting }) => {

    try {
      if (editradiologistUserData) {
        const data = {
          username: values.username,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          attributes: {
            radiologyGroup: selectedRadiologyGroupOptions.label || selectedRadiologyGroupOptions[0],
            roleType: ['RadiologyGroupAdmin'],
            mobileNumber: values.mobileNumber,
            title: values.title,
            permission: selectedOptions,
            templates: ['Default']
          },
        };
        const response = await updateUser(editradiologistUserData.id, data, user.access_token);

        if (response.status === 204) {
          await fetchUsers(user.access_token)
            .then(data => setUsersList(data))
            .catch(error => console.error('Error fetching users:', error));
          showSnackbar({
            title: 'Success!',
            message: 'Successfully Radiology Group Admin Update',
            type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
            position: 'topRight',
          });
        }
        const findGroup = radiologyGroupList.find(
          group => group.name === initialValues.radiologyGroup[0]
        );
        const findCurrentGroup = radiologyGroupList.find(
          group => group.name === selectedRadiologyGroupOptions.label
        );

        if (findCurrentGroup && findGroup.id !== findCurrentGroup.id) {
          addRadiologyGroupMember(
            editradiologistUserData.id,
            findCurrentGroup.id,
            user.access_token
          );
          deleteRadiologyGroupMember(editradiologistUserData.id, findGroup.id, user.access_token);
        }
        hidden();
      } else {
        const data = {
          username: values.username,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          attributes: {
            radiologyGroup: selectedRadiologyGroupOptions.label,
            roleType: ['RadiologyGroupAdmin'],
            mobileNumber: values.mobileNumber,
            registrationNo: values.registrationNo,
            title: values.title,
            permission: selectedOptions,
            templates: ['Default']
          },
          credentials: [{ type: 'password', temporary: true, value: values.password }],
          enabled: true,
          emailVerified: true,
        };
        const response = await createUser(data, user.access_token);
        if (response.status === 201) {
          await fetchUsers(user.access_token)
            .then(async data => {
              setUsersList(data);
              const findeUser = data.find(items => items.email === values.email);
              const findGroup = radiologyGroupList.find(
                group => group.name === selectedRadiologyGroupOptions.label
              );

              await addRadiologyGroupMember(findeUser.id, findGroup.id, user.access_token);
            })
            .catch(error => console.error('Error fetching users:', error));
          showSnackbar({
            title: 'Success!',
            message: 'Successfully Radiology Group Admin Created',
            type: SnackbarTypes.SUCCESS,
            position: 'topRight',
          });
          hidden();
        } else {
          const errorMessage = await response.json();
          setError(errorMessage.errorMessage);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };


  return (
    <section className="w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label className="my-2 block" text="">First Name</Label>
                <Field
                  type="text"
                  name="firstName"
                  as={Input}
                  placeholder="Please enter your first name"
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text ="">Last Name</Label>
                <Field
                  type="text"
                  name="lastName"
                  as={Input}
                  placeholder="Please enter your last name"
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text="">User Name</Label>
                <Field
                  type="text"
                  name="username"
                  as={Input}
                  placeholder="Please enter your user name"
                  disabled={editradiologistUserData ? true : false}
                />
                <ErrorMessage
                  name="username"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text="">Mobile Number</Label>
                <Field
                  type="text"
                  name="mobileNumber"
                  as={Input}
                  placeholder="Please enter your mobile number"
                />
                <ErrorMessage
                  name="mobileNumber"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text="">Email</Label>
                <Field
                  type="text"
                  name="email"
                  as={Input}
                  placeholder="Please enter your email name"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="passworld_box">
                <Label className="my-2 block" text="">Password</Label>
                <Field
                  type={passwordeye ? 'text' : 'password'}
                  name="password"
                  as={Input}
                  placeholder="Please enter your password"
                />
                <Icon
                  onClick={() => setpasswordeye(!passwordeye)}
                  name={passwordeye ? 'eye-visible' : 'eye-hidden'}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="passworld_box">
                <Label className="my-2 block" text="">Confirm Password</Label>
                <Field
                  type={confirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  as={Input}
                  placeholder="Please enter your Confirm Password "
                />
                <Icon
                  onClick={() => setConfirmPasswordeye(!confirmPassword)}
                  name={confirmPassword ? 'eye-visible' : 'eye-hidden'}
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text="">Title</Label>
                <Field
                  type="text"
                  name="title"
                  as={Input}
                  placeholder="Please enter your title"
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block" text=""> Radiology Group</Label>
                <Field
                  name="radiologyGroup"
                  as={Select}
                  placeholder="Select Radiology Group"
                  options={optionsRadiologyGroup}
                  value={
                    selectedRadiologyGroupOptions
                  }
                  onChange={handleSelectionChangeRadiologyGroup}
                />
                <ErrorMessage
                  name="radiologyGroup"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block" text={''}>Permission</Label>
                <Field
                  name="permission"
                  as={InputMultiSelect}
                  options={options}
                  value={selectedOptions}
                  onChange={handleSelectionChange}
                />

                <ErrorMessage
                  name="permission"
                  component="div"
                  className="text-red-600"
                />
              </div>
            </div>
            <Button
              className="mt-8"
              disabled={isSubmitting}
            >
              {editradiologistUserData ? 'Update Radiology Group Admin' : 'Create Radiology Group Admin'}
            </Button>

            <div className=' text-center'>
              <Label className="my-2 mt-2 block h-3 pl-3 text-red-600	" text=''>{error}</Label>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default RadiologyGroupAdminCreateModel;
