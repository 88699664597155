// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.tab-button {
    padding: 10px 20px;
    border: none;
    background-color: transparent;
    color: #5acce6;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    position: relative;
}

.tab-button:hover {
    /* opacity: 80; */
}

.tab-button.active {
    /* color: #fff; */
    border-bottom: 2px solid #5acce6;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/Analytics/style.css"],"names":[],"mappings":"AAAA;IACI,oBAAa;IAAb,oBAAa;IAAb,aAAa;IACb,wBAAuB;QAAvB,qBAAuB;YAAvB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,6BAA6B;IAC7B,cAAc;IACd,eAAe;IACf,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,gCAAgC;AACpC","sourcesContent":[".tabs {\n    display: flex;\n    justify-content: center;\n}\n\n.tab-button {\n    padding: 10px 20px;\n    border: none;\n    background-color: transparent;\n    color: #5acce6;\n    cursor: pointer;\n    font-size: 16px;\n    font-weight: bold;\n    position: relative;\n}\n\n.tab-button:hover {\n    /* opacity: 80; */\n}\n\n.tab-button.active {\n    /* color: #fff; */\n    border-bottom: 2px solid #5acce6;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
