import React, { useContext, useEffect, useState } from 'react';
import { useAnalytics, useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, AreaChart, Area } from 'recharts';
import InputDateRange from '../InputDateRange';
import Label from '../Label';
import moment from 'moment';
import { ThemeContext } from '../ThemeWrapper/ThemeWrapper';

const Users = ({ servicesManager, extensionManager, hotkeysManager }) => {
    const { studyCount, fetchReportsByUserAndModal, fetchUserWeeklyPerform, reportsByUserAndModal, userWeeklyPerform, setStartDate, setEndDate, startDate, endDate } = useAnalytics();
    const { userAuthenticationService } = servicesManager.services;
    const user = userAuthenticationService.getUser();

    const currentuser = user?.profile?.roleType?.includes('RadiologyGroupAdmin')
    const radiologyGroupName = user?.profile?.radiologyGroup;

    const { show } = useSnackbar();
    const [username, setUsername] = useState(null);
    const [error, setError] = useState(null);
    const [studyFilterHeight, setStudyFilterHeight] = useState(false);
    // const [selectedDateRange, setSelectedDateRange] = useState<any>({ startDate, endDate });
    const [selectedDateRange, setSelectedDateRange] = useState<any>({
        startDate,
        endDate,
        ...(currentuser && { radiologyGroupName })
    });
    let totalRVUSum = 0;
    const isMobileLayout = window.innerWidth < 640;
    const { theme } = useContext(ThemeContext);

    const fetchData = (startDate, endDate, radiologyGroupName) => {
        const reportsByPriorityParams = currentuser ? [startDate, endDate, radiologyGroupName] : [startDate, endDate];
        Promise.all([
            fetchReportsByUserAndModal(...reportsByPriorityParams),
        ]).catch(error => setError(error));
    };

    const getLast7Days = () => {
        const today = new Date();
        const priorDate = new Date().setDate(today.getDate() - 7);

        return {
            startDate: new Date(priorDate).toISOString().split('T')[0],
            endDate: today.toISOString().split('T')[0],
        };
    };

    const handleDateRangeFieldChange = ({ startDate, endDate }) => {
        const start_date = moment(startDate).format('YYYY-MM-DD');
        const end_date = moment(endDate).format('YYYY-MM-DD');
        setSelectedDateRange({ start_date, end_date });
        setStartDate(start_date);
        setEndDate(end_date);
    };

    useEffect(() => {
        const { startDate, endDate } = getLast7Days();
        setSelectedDateRange({ startDate, endDate })
        setStartDate(startDate);
        setEndDate(endDate);
        fetchData(startDate, endDate, radiologyGroupName);
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            fetchData(startDate, endDate, radiologyGroupName);
            setSelectedDateRange({ startDate, endDate });
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (username && startDate && endDate) {
            fetchUserWeeklyPerform(username, startDate, endDate);
        }
    }, [username, startDate, endDate]);

    useEffect(() => {
        if (error) {
            show({ message: error.message, type: SnackbarTypes.ERROR });
        }
    }, [error, show]);

    const handleRowClick = (user) => {
        setUsername(user);
    };

    // Define types for your data
    interface ModalityCounts {
        [modality: string]: number;
    }

    interface UserModalities {
        [user: string]: ModalityCounts;
    }

    interface TransformedRow {
        [key: string]: number | string;
        total: number;
        totalRVU: number;
    }

    interface TransformResult {
        transformedData: TransformedRow[];
        allModalities: string[];
    }

    // Transform function for users
    const transformTableDataForUserAndModal = (data: UserModalities): TransformResult => {
        const allModalities = new Set<string>();

        const transformedData = Object.entries(data).map(([user, modality]) => {
            const total = Object.values(modality).reduce((sum, count) => sum + count, 0);

            const totalRVU =
                (modality['CR/DX'] || 0) * 0.3 +
                (modality['US'] || 0) * 0.7 +
                (modality['CT'] || 0) * 1.5 +
                (modality['CT\\PT'] || 0) * 1.5 +
                (modality['DOC\\US'] || 0) * 1 +
                (modality['MR'] || 0) * 1.4 +
                (modality['MG'] || 0) * 0.7;
            totalRVUSum += totalRVU;

            Object.keys(modality).forEach(modality => allModalities.add(modality));

            return {
                user,
                ...modality,
                total,
                totalRVU: parseFloat(totalRVU.toFixed(2)), // Ensure totalRVU is a number, then round to 2 decimal places
            };
        });

        return { transformedData, allModalities: Array.from(allModalities) };
    };

    // Transform function for line chart data
    interface LineChartRawData {
        date: string;
        modality?: string;
        count: string;
    }

    const transformLineChartData = (rawData: LineChartRawData[]): { date: string; modality: string; count: number }[] => {
        return rawData.map(item => ({
            date: new Date(item.date).toLocaleDateString(), // Format date if needed
            modality: item.modality || 'Unknown', // Handle null or undefined modalities
            count: parseInt(item.count, 10), // Convert count to number
        }));
    };

    const { transformedData: userAndModalData, allModalities: userAndModalModalities } = reportsByUserAndModal
        ? transformTableDataForUserAndModal(reportsByUserAndModal)
        : { transformedData: [], allModalities: [] };

    const lineChartData = transformLineChartData(userWeeklyPerform);

    const CustomTooltip = ({ payload, label }) => {
        if (payload.length > 0) {
            return (
                <div className="custom-tooltip dark:text-white text-black">
                    <p className="label">{`Date: ${label}`}</p>
                    <p className="value">{`Value: ${payload[0].value}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <div className=" container mx-auto min-h-[500px] max-h-[550px] overflow-y-auto overflow-x-auto telerapp-scrollbar"
        style={{
            maxHeight: 'calc(100vh - 115px)',
            // scrollbarColor: '#173239 transparent',
        }}>
            <div className=' flex items-center lg:justify-between justify-start my-5 gap-5 max-lg:flex-col'>
                <div className=' flex items-center gap-3 w-full'>
                    <Label className="mt-2 max-sm:text-[16px] max-[375px]:text-[13px] dark:text-white text-black" text={''}>Date Range : </Label>
                    <InputDateRange
                        id={'report-analutic'}
                        value={selectedDateRange}
                        onChange={handleDateRangeFieldChange}
                        setStudyFilterHeight={setStudyFilterHeight}
                    />
                </div>
                <div className=' w-full lg:text-end text-start'>
                    <p
                        className="inline-flex items-center rounded border dark:border-primary-main border-secondary-dark bg-secondary-light dark:bg-secondary-dark py-2 px-4 font-semibold dark:text-white text-black transition-all opacity-80 mr-3 max-sm:text-[16px] max-[375px]:text-[13px]"
                    >
                        Study Count: {studyCount?.count || 0}
                    </p>
                </div>
            </div>

            <p className=' mb-5 max-sm:text-[16px] max-[375px]:text-[13px] dark:text-white text-black'><strong className='  dark:text-[#5acce6] text-black'>Total RVU :</strong> {totalRVUSum.toFixed(2)} [ MR = 1.4, CR/DX = 0.3, US = 0.7, MG = 0.7, CT = 1.5, CT\PT = 1.5, DOC\US = 1 ]</p>

            <div className={` mb-5 ${userAndModalData.length === 0 ? 'h-48 ' : ''}  mr-3`} >
                <p className=' dark:text-[#5acce6] text-black max-sm:text-[16px] max-[375px]:text-[13px]'>Study by User and Modality</p>
                <div className="max-h-[500px] overflow-y-auto telerapp-scrollbar"
                    style={{
                    overflowX: 'auto',
                    maxHeight: 'calc(100vh - 230px)',
                    // scrollbarColor: '#173239 transparent',
                    }}>
                    {userAndModalData.length > 0 ? (
                        <table className="container m-auto mt-2 w-full table-auto text-white">
                            <thead className="bg-[#d1d1d1] dark:bg-primary-dark dark:border-primary-main border-secondary-dark border sticky top-[-10px] z-2">
                                <tr className=' max-sm:text-base '>
                                    <th className=" p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black">User</th>
                                    {userAndModalModalities.map(modality => (
                                        <th key={String(modality)} className=" p-2  dark:border-primary-main border-secondary-dark border dark:text-white text-black">{modality}</th>
                                    ))}
                                    <th className=" p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black">Total</th>
                                    <th className=" p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black">Total RVU</th>
                                </tr>
                            </thead>
                            <tbody className="mt-1 ">
                                {userAndModalData.map((row, index) => (
                                    <tr key={index} className="bg-secondary-light dark:bg-secondary-dark dark:border-primary-main border-secondary-dark hover:border-secondary-dark dark:hover:bg-[#414141] hover:bg-primary-light cursor-pointer border text-center transition duration-300 max-sm:text-sm">
                                        <td
                                            className=" p-2 cursor-pointer dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black"
                                            onClick={() => handleRowClick(row.user)}
                                        >
                                            {row.user}
                                        </td>
                                        {userAndModalModalities.map(modality => (
                                            <td key={String(modality)} className=" p-2  dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black">{row[modality] || 0}</td>
                                        ))}
                                        <td className=" p-2  dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black">{row.total}</td>
                                        <td className=" p-2  dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black">{row.totalRVU}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className=' h-full flex justify-center items-center bg-secondary-light dark:bg-secondary-dark border-secondary-dark dark:border-primary-main border p-4 mt-2  max-sm:text-[16px] max-[375px]:text-[13px] text-black dark:text-white'>Data not available.</div>
                    )}
                </div>
            </div>
            {username && (
                <div className=' flex-1 bg-secondary-light dark:bg-secondary-dark border-secondary-dark dark:border-primary-main border p-4 rounded-md lg:mr-3'>
                    <p className=' dark:text-[#5acce6] text-black max-sm:text-[16px] max-[375px]:text-[13px]'>Date wise User performance</p>
                    <ResponsiveContainer width="100%" height={400}>
                        {lineChartData.length > 0 ? (
                            <AreaChart data={lineChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" stroke='#3a3f99 ' />
                                <XAxis dataKey="date" stroke={theme === 'dark' ? "#fff" : "#000"} fontSize={isMobileLayout ? '12px' : '16px'} />
                                <YAxis stroke={theme === 'dark' ? "#fff" : "#000"} allowDecimals={false} domain={[0, 'auto']} fontSize={isMobileLayout ? '12px' : '16px'} />
                                <Tooltip content={CustomTooltip} />
                                <Line type="monotone" dataKey="count" stroke="#5acce6" />
                                <Area type="monotone" dataKey="count" stroke="#5acce6" fill="#FFBB28" />
                            </AreaChart>
                        ) : (
                            <div className=' bg-secondary-light dark:bg-secondary-dark dark:text-white text-black h-full flex justify-center items-center  max-sm:text-[16px] max-[375px]:text-[13px]'>Data not available.</div>
                        )}
                    </ResponsiveContainer>
                </div>
            )}
        </div>
    );
};

export default Users;
