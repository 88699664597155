import React, { useState } from 'react';
import { AiOutlineClose } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { SiGooglekeep } from "react-icons/si";
import { MdOutlineCancel } from "react-icons/md";
import { FaLightbulb } from "react-icons/fa6";


const AnonymizeStudyModel = ({
    hidden,
    anonymizeStudies,
    setAllStudyList,
    anonymizeStudiesDeleteOrigin,
    setIsPostData,
    selectedRows,
    setIsDeleteData
}) => {


    const [error, setError] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadingAnonymize, setUploadingAnonymize] = useState(false);
    const [success, setSuccess] = useState(null);

    // anonymized studies

    const handleStudiesAnonymize = async () => {
        setUploading(true);
        try {

            const studyAnonymize = await anonymizeStudies(selectedRows, setAllStudyList)

            if (studyAnonymize.error) {
                setError("error while study anonymized study..")
            } else {
                setSuccess('Study anonymized Successfully.....');
                setIsPostData(selectedRows)
                setUploading(false);
                setTimeout(() => {
                    hidden()
                }, 2000);
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            }

        } catch (error) {
            console.error('Error anonymized Study:', error);
            setError('Failed to anonymized Study');
            setUploading(false);
        }
    }

    const handleStudiesAnonymizeToDeleteOriginal = async () => {
        setUploadingAnonymize(true);
        try {
            const studyAnonymizeToDeleteOriginal = await anonymizeStudiesDeleteOrigin(selectedRows, setAllStudyList)

            if (studyAnonymizeToDeleteOriginal.error) {
                setError("error while anonymized study..")
            } else {
                setSuccess('Study anonymized and delete original  Successfully.....');
                setIsPostData(selectedRows)
                setIsDeleteData(selectedRows)
                setUploadingAnonymize(false);
                setTimeout(() => {
                    hidden()
                }, 2000);
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            }
            // setIsPostData()
        } catch (error) {
            console.error('Error anonymized Study:', error);
            setError('Failed to anonymized Study');
            setUploadingAnonymize(false);
        }
    }


    const handleAnonymizedCancel = () => {
        hidden()
    }

    return (
        <section className="w-full">
            <div className='flex justify-end cursor-pointer'>
                <AiOutlineClose className='mt-3 dark:text-white text-black' onClick={handleAnonymizedCancel} />
            </div>
            <div className='p-10 text-center'>
                <h1 className="text-2xl  font-bold dark:text-white text-black py-4">Please Confirm</h1>
                <p className='py-2 dark:text-white text-black'>You are about to create anonymized copy of the studies selected.</p>
                <p className='dark:text-white text-black'>Do You want to keep original study copy?</p>
                <p className='text-red-600 py-2'>If you click "Delete" then original studies will be removed from your worklist</p>
                <div className='flex justify-center mt-9'>
                    <button className="dark:border-primary-main border-secondary-dark border bg-primary-light dark:bg-primary-dark dark:text-white text-black font-semibold hover:opacity-80 py-2 px-10 rounded inline-flex items-center transition-all" onClick={handleStudiesAnonymizeToDeleteOriginal}>
                        <RiDeleteBin6Line className="fill-current w-5 h-5 mr-2" />
                        {uploadingAnonymize ? 'Loading' : 'Delete'}

                    </button>
                    <button className="inline-flex items-center rounded dark:border-primary-main border-secondary-dark border bg-primary-light dark:bg-primary-dark py-2 px-10 font-semibold dark:text-white text-black transition-all hover:opacity-80 mx-6" onClick={handleStudiesAnonymize}>
                        {/* <SiGooglekeep className="fill-current w-5 h-5 mr-2" /> */}
                        <FaLightbulb className="fill-current w-5 h-5 mr-2" />
                        {uploading ? 'Loading' : 'Keep'}
                    </button>
                    <button className="dark:border-primary-main border-secondary-dark border bg-primary-light dark:bg-primary-dark dark:text-white text-black font-semibold hover:opacity-80 py-2 px-10 rounded inline-flex items-center transition-all" onClick={handleAnonymizedCancel}>
                        <MdOutlineCancel className="fill-current w-5 h-5 mr-2" />
                        Cancel
                    </button>
                </div>
                {error && <div className="border border-red-400 text-red-700 px-4 py-3 rounded relative mt-5 text-center" role="alert">
                    <span className="block sm:inline">{error}</span>
                </div>}
                {success && <div className="border border-green-400 text-green-700 px-4 py-3 rounded relative mt-5 text-center" role="alert">
                    <span className="block sm:inline">{success}</span>
                </div>}
            </div>
        </section>
    );
};

export default AnonymizeStudyModel;
