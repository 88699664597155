export const SendtoML = async (modelUrl: string) => {
    const page_url = window.location.href;
    const uuid_value = extractUuidFromUrl(page_url);

    if (uuid_value) {
        try {
            const response = await fetch(modelUrl, {  // Use modelUrl directly
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ uuid: uuid_value }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            return result;
        } catch (error) {
            console.error('Error:', error);
            return { results: [] }; // Return an empty result on error
        }
    } else {
        console.error('UUID not found in the URL.');
        return { results: [] }; // Return an empty result if UUID is not found
    }
};

const extractUuidFromUrl = (url: string) => {
    const query = new URL(url).search;
    const params = new URLSearchParams(query);
    return params.get('UUID');
};
