import React, { useEffect, useState } from 'react';
import Typography from '../Typography';
import RadiologistUsersCreateModel from './RadiologistUsersCreateModel';
import Swal from 'sweetalert2';
import { useAppConfig } from '@state';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import {
  Header,
  useModal,
  useRadiologyGroup,
  useUser,
  useReportEditor,
} from '@ohif/ui';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { FaEdit } from 'react-icons/fa';

const RadiologistUsers = ({ servicesManager, extensionManager, hotkeysManager }) => {
  const {
    usersList,
    createUser,
    updateUser,
    fetchUsers,
    setUsersList,
    deleteUser,
    token,
    userToken,
    setToken,
  } = useUser();

  const { radiologyGroupList, addRadiologyGroupMember, deleteRadiologyGroupMember, uploadImage } =
    useRadiologyGroup();

  const { defaultReportTemplates } = useReportEditor();

  const { userAuthenticationService } = servicesManager.services;
  const user = userAuthenticationService.getUser();
  const radiologyGroupName = user?.profile?.radiologyGroup;
  const { show, hide } = useModal();
  const permissions = user?.profile?.permission

  const hasManagerPermission =
    permissions?.includes('Add Radiologist') ||
    token?.realm_access?.roles.includes('super-admin') ||
    token?.realm_access?.roles.includes('deputy-admin');

  const [radiologistUserLists, setRadiologistUserLists] = useState([]);
  const TelerappUser = token?.realm_access?.roles.includes('super-admin') || token?.realm_access?.roles.includes('deputy-admin') || user?.profile?.roleType === 'TelerappManager' || user?.profile?.roleType === 'DeputyAdmin';

  useEffect(() => {
    const getToken = async () => {
      try {
        const data = {
          token: user.access_token,
        };
        const response = await userToken(data);
        setToken(response);
      } catch (error) {
        console.log(error);
      }
    };
    getToken();
  }, []);

  const tableHeaders = {
    firstName: 'First Name',
    lastName: 'Last Name',
    mobileNumber: 'Mobile Number',
    email: 'Email ID',
    title: 'Username',
    registration: 'Registration No',
    qualification: 'Qualification',
    uploadSignature: 'Upload Signature Image',
    radiologyGroup: 'Radiology Group',
    createdTimestamp: 'Added',
    update: 'Update',
    delete: 'Delete',
  };

  const keys = Object.keys(tableHeaders);
  const values = Object.values(tableHeaders);

  const [appConfig] = useAppConfig();
  const navigate = useNavigate();
  const location = useLocation();

  const onClickReturnButton = () => {
    const { pathname } = location;
    const dataSourceIdx = pathname.indexOf('/', 1);
    const query = new URLSearchParams(window.location.search);
    const configUrl = query.get('configUrl');

    const dataSourceName = pathname.substring(dataSourceIdx + 1);
    const existingDataSource = extensionManager.getDataSources(dataSourceName);

    const searchQuery = new URLSearchParams();
    if (dataSourceIdx !== -1 && existingDataSource) {
      searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    }

    if (configUrl) {
      searchQuery.append('configUrl', configUrl);
    }

    navigate({
      pathname: '/',
      search: decodeURIComponent(searchQuery.toString()),
    });
  };

  const handleDelete = async id => {
    Swal.fire({
      // title: 'Are you sure?',
      text: 'Are you sure to delete this user!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then(async result => {
      if (result.isConfirmed) {
        // If the user confirms deletion, proceed with the deletion
        await deleteUser(id, user.access_token)
          .then(async res => {
            Swal.fire({
              title: 'Deleted',
              text: 'User has been Delete Successfully',
              icon: 'success',
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
            });
            if (res.status === 204) {
              await fetchUsers(user.access_token)
                .then(data => setUsersList(data))
                .catch(error => console.error('Error fetching users:', error));
            }
          })
          .catch(error => {
            console.error('Error deleting user:', error);
            Swal.fire({
              title: 'Error!',
              text: 'Failed to delete user. Please try again later.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          // title: 'Cancelled',
          text: 'User Delete Cancel.',
          icon: 'info',
          confirmButtonText: 'OK',
        });
      }
    });
  };

  const handlerWarning = async () => {
    Swal.fire({
      title: 'Authorization Required',
      text: 'Only super-admin, deputy-admin, and telerapp-manager roles are authorized to create and update radiologist user.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      background: '#f2f2f2',

    }).then(async result => {
      if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: 'Action Cancelled',
          text: 'The deletion of the user has been cancelled.',
          icon: 'info',
          confirmButtonText: 'Understood',
          background: '#e0f7fa',
        });
      }
    });
  };

  useEffect(() => {
    if (usersList.length > 0) {
      const filteredUsers = usersList?.filter(ruser => {
        console.log(ruser)
        const isRadiologist = ruser?.attributes?.roleType?.includes('Radiologist');
        if (TelerappUser) {
          return isRadiologist;
        } else {
          return (
            isRadiologist &&
            (ruser?.attributes?.radiologyGroup?.includes(radiologyGroupName) ||
              ruser?.attributes?.radiologyGroup?.includes('Default'))
          );
        }
      });

      setRadiologistUserLists(filteredUsers);
    }
  }, [usersList, TelerappUser, radiologyGroupName]);

  const modelShow = radiologistUserData => {
    show({
      content: RadiologistUsersCreateModel,
      title: `RadiologistUsersCreateModel: ${radiologistUserData.username ? 'Update Radiologist User' : 'Create New Radiologist User'}`,
      contentProps: {
        user,
        hidden: hide,
        editradiologistUserData: radiologistUserData.username ? radiologistUserData : '',
        radiologyGroupList,
        addRadiologyGroupMember,
        deleteRadiologyGroupMember,
        createUser,
        updateUser,
        fetchUsers,
        setUsersList,
        uploadImage,
        defaultReportTemplates,
      },
    });
  };

  return (
    <section className="">
      <Header
        isReturnEnabled={!!appConfig.showStudyList}
        onClickReturnButton={onClickReturnButton}
        WhiteLabeling={appConfig.whiteLabeling}
        loggedInUserDetails={user}
        loggedInUserTokenDetails={token}
        hotkeysManager={hotkeysManager}
      />
      {/* <ToastContainer/>  */}
      <div className="container relative mx-auto flex flex-col pt-5">
        <div className="mb-5 flex flex-row justify-between">
          <div className="flex min-w-[1px] shrink flex-row items-center gap-6">
            <Typography
              variant="h6"
              className="text-white"
              component={'h6'}
            >
              Radiologist Users
            </Typography>
          </div>
          <div className="flex h-[34px] flex-row items-center">
            <Typography
              variant="h6"
              className="text-primary-light cursor-pointer"
              component={'h6'}
              onClick={hasManagerPermission ? modelShow : handlerWarning}
            >
              Add Radiologist
            </Typography>
          </div>
        </div>
      </div>
      <table className="container m-auto mt-2 table-auto text-white">
        <thead className="bg-primary-dark border-secondary-light border" style={{ backgroundColor: '#041c4a' }}>
          <tr>
            {values.map(tableHead => (
              <th
                className="p-2"
                key={'tableHead'}
              >
                {tableHead}
              </th>
            ))}
          </tr>
        </thead>
        {radiologistUserLists && radiologistUserLists.length <= 0 ? (
          <span className="absolute  flex h-96 w-5/12 items-center justify-end">
            No Radiologist Users Exiting
          </span>
        ) : (
          <tbody className="mt-1 ">
            {radiologistUserLists &&
              radiologistUserLists?.map(instance => (
                <tr className="bg-primary-dark border-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer border text-center transition duration-300">
                  {instance?.attributes?.roleType?.join(', ') === 'Radiologist' &&
                    keys.map(key => (
                      <td
                        className="p-2"
                        key={key}
                      >
                        {key === 'delete' ? (
                          <div
                            className="flex cursor-pointer items-center justify-center text-xl text-[#ff0000] transition-all hover:text-red-400"
                            onClick={() => hasManagerPermission ? handleDelete(instance.id) : handlerWarning()}
                          >
                            <RiDeleteBin6Fill />
                          </div>
                        ) : key === 'update' ? (
                          <div
                            className="flex cursor-pointer items-center justify-center text-xl transition-all hover:text-[#63b3ed]"
                            onClick={() => hasManagerPermission ? modelShow(instance) : handlerWarning()}
                          >
                            <FaEdit />
                          </div>
                        ) : key === 'qualification' ? (
                          <span className="cursor-pointer">
                            {instance?.attributes?.qualification?.join(', ')}
                          </span>
                        ) : key === 'title' ? (
                          <span className="cursor-pointer">
                            {instance?.attributes?.title?.join(', ')}
                          </span>
                        ) : key === 'uploadSignature' ? (
                          <span>
                            <Link
                              target="_blank"
                              className=" text-green-600"
                              to={`${instance?.attributes?.uploadSignature}`}
                            >
                              Preview
                            </Link>
                          </span>
                        ) : key === 'registration' ? (
                          <span className="cursor-pointer">
                            {instance?.attributes?.registrationNo?.join(', ')}
                          </span>
                        ) : key === 'radiologyGroup' ? (
                          <span className="cursor-pointer">
                            {instance?.attributes?.radiologyGroup?.join(', ')}
                          </span>
                        ) : key === 'mobileNumber' ? (
                          <span className="cursor-pointer">
                            {instance?.attributes?.mobileNumber?.join(', ')}
                          </span>
                        ) : key === 'createdTimestamp' ? (
                          <span className="cursor-pointer">
                            {moment(instance.createdTimestamp).format('MM/DD/YYYY hh:mm:ss A')}
                          </span>
                        ) : (
                          instance[key]
                        )}
                      </td>
                    ))}
                </tr>
              ))}
          </tbody>
        )}
      </table>
    </section>
  );
};

export default RadiologistUsers;
