import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

type Grade = {
    CentralCanalStenosis: number;
    ForaminalStenosisLeft: number;
    ForaminalStenosisRight: number;
    Herniation: number;
    LowerEndplateDefect: number;
    LowerMarrow: number;
    Narrowing: number;
    Pfirrmann: number;
    Spondylolisthesis: number;
    UpperEndplateDefect: number;
    UpperMarrow: number;
};

type ResponseData = {
    grades: Grade[];
    image?: string;
    results?: { prediction: string; error?: string }[];
};

const AiTool: React.FC<{
    onSend: (modelUrl1: string) => Promise<ResponseData>;
    hide: () => void;
    modelUrl1: string;
    send: boolean;
}> = ({ onSend, modelUrl1, send }) => {
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState('');
    const [grades, setGrades] = useState<Grade[]>([]);
    const [image, setImage] = useState<string | null>(null);

    const handleSend = async () => {
        setResults('');
        setGrades([]);
        setImage(null);
        setLoading(true);

        try {
            const data = await onSend(modelUrl1);

            // Handle the grading and image display
            if (data && data.grades) {
                setGrades(data.grades);
            }
            if (data && data.image) {
                setImage(data.image); // Set the image if it exists
            }

            // Handle result predictions
            if (data && Array.isArray(data.results)) {
                const totalResults = data.results.length;

                const resultsText = data.results
                    .map((result, index) => {
                        if (result.error) {
                            return `Instance ${index} : Error - ${result.error}`;
                        }
                        return `Instance ${index} : "${result.prediction}"`;
                    })
                    .join('\n') + '\n\n' +
                    (totalResults > 0
                        ? Object.entries(
                            data.results
                                .filter(result => !result.error)
                                .reduce((acc, result) => {
                                    acc[result.prediction] = (acc[result.prediction] || 0) + 1;
                                    return acc;
                                }, {} as { [key: string]: number })
                        )
                            .map(([prediction, count]) => {
                                const percentage = ((count / totalResults) * 100).toFixed(2);
                                return `"${prediction}" : ${percentage}%`;
                            })
                            .join('\n')
                        : 'No valid results to calculate percentages.');

                setResults(resultsText);
            } else {
                setResults('Unexpected response format');
            }
        } catch (error) {
            setResults(`Error fetching result: ${error.message || error}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (send) {
            handleSend();
        }
    }, [send]);

    const handleCopy = () => {
        navigator.clipboard.writeText(results);
        alert('Results copied to clipboard');
    };

    const handleCopyImage = () => {
        if (image) {
            const img = document.querySelector('img');
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.download = 'result_image.png';
            link.href = dataURL;
            link.click();
        } else {
            alert('No image to copy');
        }
    };

    const handleCopyGrades = () => {
        if (grades.length > 0) {
            const gradesText = grades.map((grade, index) => {
                return `Instance ${index}:\n` +
                    `Central Canal Stenosis: ${grade.CentralCanalStenosis}\n` +
                    `Foraminal Stenosis Left: ${grade.ForaminalStenosisLeft}\n` +
                    `Foraminal Stenosis Right: ${grade.ForaminalStenosisRight}\n` +
                    `Herniation: ${grade.Herniation}\n` +
                    `Lower Endplate Defect: ${grade.LowerEndplateDefect}\n` +
                    `Lower Marrow: ${grade.LowerMarrow}\n` +
                    `Narrowing: ${grade.Narrowing}\n` +
                    `Pfirrmann: ${grade.Pfirrmann}\n` +
                    `Spondylolisthesis: ${grade.Spondylolisthesis}\n` +
                    `Upper Endplate Defect: ${grade.UpperEndplateDefect}\n` +
                    `Upper Marrow: ${grade.UpperMarrow}\n\n`;
            }).join('');
            navigator.clipboard.writeText(gradesText);
            alert('Grades copied to clipboard');
        } else {
            alert('No grades to copy');
        }
    };

    return (
        <div className="modal-content">
            {/* Display the image if available */}
            {image && (
                <div style={{ marginBottom: '20px' }}>
                    <img
                        src={`data:image/png;base64,${image}`}
                        alt="Result Image"
                        style={{ width: '100%', maxHeight: '550px', objectFit: 'contain' }}
                    />
                </div>
            )}

            {/* Display the grades in a table if grades are available */}
            {grades.length > 0 && (
                <div style={{ marginBottom: '20px' }}>
                    <h3>Grading Results</h3>
                    <table border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th>Central Canal Stenosis</th>
                                <th>Foraminal Stenosis Left</th>
                                <th>Foraminal Stenosis Right</th>
                                <th>Herniation</th>
                                <th>Lower Endplate Defect</th>
                                <th>Lower Marrow</th>
                                <th>Narrowing</th>
                                <th>Pfirrmann</th>
                                <th>Spondylolisthesis</th>
                                <th>Upper Endplate Defect</th>
                                <th>Upper Marrow</th>
                            </tr>
                        </thead>
                        <tbody>
                            {grades.map((grade, index) => (
                                <tr key={index}>
                                    <td>{grade.CentralCanalStenosis}</td>
                                    <td>{grade.ForaminalStenosisLeft}</td>
                                    <td>{grade.ForaminalStenosisRight}</td>
                                    <td>{grade.Herniation}</td>
                                    <td>{grade.LowerEndplateDefect}</td>
                                    <td>{grade.LowerMarrow}</td>
                                    <td>{grade.Narrowing}</td>
                                    <td>{grade.Pfirrmann}</td>
                                    <td>{grade.Spondylolisthesis}</td>
                                    <td>{grade.UpperEndplateDefect}</td>
                                    <td>{grade.UpperMarrow}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Only show the textarea if there are no grades */}
            {grades.length === 0 && (
                <textarea
                    value={loading ? 'Loading...' : results}
                    rows={10}
                    cols={50}
                    onChange={(e) => console.log(e.target.value)}
                    style={{ width: '100%', marginBottom: '10px', background: 'transparent' }}
                />
            )}

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button onClick={handleCopy} className="btn-copy" disabled={results === ''}>
                    Copy Results
                </button>
                <button onClick={handleCopyImage} className="btn-copy">
                    Copy Image
                </button>
                <button onClick={handleCopyGrades} className="btn-copy">
                    Copy Grades
                </button>
            </div>
        </div>
    );
};

AiTool.propTypes = {
    onSend: PropTypes.func.isRequired,
    hide: PropTypes.func.isRequired,
};

export default AiTool;
