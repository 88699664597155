import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { fetchEventsPagination } from '../../requestHandler';
import Typography from '../Typography';


const EventLogs = ({ servicesManager }) => {
    const [eventLogs, setEventLogs] = useState([]);
    const [loading, setLoading] = useState(false);

    const { userAuthenticationService } = servicesManager.services;
    const user = userAuthenticationService.getUser();

    const fetchAndSetEvents = useCallback(async () => {
        setLoading(true);
        try {
            const data = await fetchEventsPagination(user.access_token); // Adjusted to fetch all events
            setEventLogs(data); // No pagination, just set the events data directly
        } catch (error) {
            console.error('Error fetching events:', error);
        } finally {
            setLoading(false);
        }
    }, [user.access_token]);

    useEffect(() => {
        fetchAndSetEvents();
    }, [fetchAndSetEvents]);


    return (
        <section>
            <div
                className="overflow-y-auto container m-auto mt-2"
                style={{
                    maxHeight: '480px', // Adjust height as needed
                    overflowX: 'auto',
                    scrollbarColor: '#173239 transparent',
                }}
            >
                {loading ? (
                    <div className="text-center">Loading...</div>
                ) : (
                    <>
                        <table className="w-full table-auto text-white">
                            <thead className="bg-primary-dark border-secondary-light border sticky top-0 right-0 left-0" style={{ backgroundColor: '#041c4a' }}>
                                <tr>
                                    <th className="p-2">Event Type</th>
                                    <th className="p-2">Username</th>
                                    <th className="p-2">IP Address</th>
                                    <th className="p-2">Timestamp</th>
                                </tr>
                            </thead>
                            <tbody className="mt-1">
                                {eventLogs.length > 0 ? (
                                    eventLogs.map((log, index) => (
                                        <tr key={index} className="bg-primary-dark border-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer border text-center transition duration-300">
                                            <td className="p-2">{log.type}</td>
                                            <td className="p-2">{log.username}</td>
                                            <td className="p-2">{log.ipAddress}</td>
                                            <td className="p-2">{moment(log.timestamp).format('MM/DD/YYYY hh:mm:ss A')}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={4} className="py-20 text-center">No events found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
            <div className="flex justify-between items-center mt-4">
                <Typography className="text-base opacity-60">
                    Total Rows: {eventLogs.length}
                </Typography>

            </div>
        </section>
    );
};

export default EventLogs;
