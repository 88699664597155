import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Button, Input, Label, Typography, useReportEditor, useSnackbar, useUser } from '@ohif/ui';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './shareModel.css'
import Handlebars from 'handlebars';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import Swal from 'sweetalert2';
// import DecoupledEditor from "../../../../ckeditor5-41.4.2-o8usa4rff71"


const ShareModel = ({ hide, studyInstanceUid, handleSharePatientData, patientReportsDetails, sendReportData, sendReportDataToWhastapp, user, usersList, reportSettingDetails, setIsLoading, token, patientId, accession }) => {

    const tableHeaders = {
        checkBox: '',
        username: 'Username',
        email: 'Email',
        mobileNumber: 'Mobile Number',

    };

    const keys = Object.keys(tableHeaders);
    const values = Object.values(tableHeaders);

    const physicianUserLists = token?.roleType?.includes('RadiologyGroupAdmin') && usersList ?
        usersList?.filter(
            user =>
                user.attributes &&
                user.attributes.roleType &&
                user.attributes.roleType.includes('Physician') &&
                user?.attributes?.radiologyGroup.includes(token?.radiologyGroup)
        ) :
        usersList &&
        usersList?.filter(
            user =>
                user.attributes &&
                user.attributes.roleType &&
                user.attributes.roleType.includes('Physician')
        );

    const findPatientData = patientReportsDetails?.find(item => item.study_UIDs === studyInstanceUid);

    const [patientReportDetail, setPatientReportDetail] = useState(null);
    const [template, setTemplate] = useState(null);
    const [editorData, setEditorData] = React.useState(null);

    const reportSetting = reportSettingDetails?.find(
        item => item.group_name === user?.profile?.radiologyGroup
    );

    const patientDatas = patientReportsDetails?.find(item => item.study_UIDs === studyInstanceUid);

    const reportSubmit_time =
        patientDatas?.report_submit_time && new Date(patientDatas?.report_submit_time);

    let formattedTime;
    if (reportSubmit_time) {
        formattedTime = `
        ${reportSubmit_time?.toLocaleDateString('en-US', { month: 'long' })}
        ${reportSubmit_time?.getDate()},
        ${reportSubmit_time?.getFullYear()}
        ${reportSubmit_time?.getHours()}:${('0' + reportSubmit_time?.getMinutes())?.slice(-2)}:${('0' + reportSubmit_time?.getSeconds())?.slice(-2)} GMT${reportSubmit_time?.getTimezoneOffset() > 0 ? '-' : '+'}${('0' + Math.abs(reportSubmit_time?.getTimezoneOffset() / 60))?.slice(-2)}:${('0' + Math.abs(reportSubmit_time?.getTimezoneOffset() % 60)).slice(-2)} (India Standard Time)`;
    }

    const assignUserFind = findPatientData?.assign?.map((item) => JSON.parse(item));
    const findAssignUserName = assignUserFind?.map(item => item.assign_name);
    const assignUserDataFind = usersList?.find((item) => {
        // Check if the firstName exists in findAssignUserName
        return findAssignUserName?.includes(item.username);
    });



    useEffect(() => {
        const fetchPatientData = async () => {
            const patient = await patientReportsDetails.find(
                item => item.study_UIDs === studyInstanceUid
            );
            setPatientReportDetail(patient);
            setTemplate(Handlebars.compile(patient?.reportdetails || '')(patient));
        };

        fetchPatientData();
    }, [studyInstanceUid, patientReportsDetails]);

    useEffect(() => {
        const editorElement: any = document.querySelector('#editor');
        if (!editorElement || !template) {
            return;
        }
        DecoupledEditor.create(editorElement, {
            toolbar: {
                items: [
                    'heading', '|',
                    'bold', 'italic', 'underline',
                    'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor',
                    '|', 'bulletedList', 'numberedList', 'insertTable',
                    '|', 'undo', 'redo',
                ],
            },
            language: 'en',
        }).then(editor => {
            const toolbarContainer = document.querySelector('#toolbar-container');
            if (toolbarContainer) {
                toolbarContainer.appendChild(editor.ui.view.toolbar.element);
            }
            if (patientReportDetail?.isDrafted) {
                editor.setData(template + '<p>Addendum begin &lt; &gt; Addendum end</p>');
            } else {
                editor.setData(template);
            }
            editor.model.document.on('change:data', (event, data) => {
                const newData = editor.getData();
                setEditorData(newData);
            });
        }).catch(error => {
            console.error(error);
        });
    }, [template, patientReportDetail]);


    const { show } = useSnackbar();
    const initialValue = (data) => {
        return {
            patient_phoneNuber: data?.phone_number || '',
            countryCode: data?.countryCode || '',
            patient_email: data?.patient_email || ''
        }
    }

    const [formData, setFormData] = useState({
        patient_phoneNuber: '',
        // countryCode: '',
        patient_email: ''
    })
    const [selectedUsers, setSelectedUsers] = useState([]);

    // const handleCheckboxChange = (email, phone, isChecked) => {
    //     setSelectedUsers(prev => {
    //         if (isChecked) {
    //             // Add the {email, phone} object if checked
    //             return [...prev, { email, phone }];
    //         } else {
    //             // Remove the object with the matching email and phone if unchecked
    //             return prev.filter(user => user.email !== email || user.phone !== phone);
    //         }
    //     });
    // };

    const handleCheckboxChange = (email, phone, isChecked) => {
        setSelectedUsers(prevSelectedUsers => {
            if (isChecked) {
                // Check if the user is already in the list to prevent duplicates
                const isAlreadySelected = prevSelectedUsers.some(user => user.email === email && user.phone === phone);
                if (!isAlreadySelected) {
                    return [...prevSelectedUsers, { email, phone }];
                }
                return prevSelectedUsers; // Return unchanged if already in the list
            } else {
                // Remove the user from the list if unchecked
                return prevSelectedUsers.filter(user => !(user.email === email && user.phone === phone));
            }
        });
    };


    useEffect(() => {
        if (findPatientData !== undefined) {
            setFormData({
                patient_phoneNuber: findPatientData?.phone_number,
                // countryCode: findPatientData?.countryCode,
                patient_email: findPatientData?.patient_email
            })
        } else {
            setFormData({
                patient_phoneNuber: '',
                // countryCode: '',
                patient_email: ''
            })
        }
    }, [findPatientData])

    const setFieldValue = (e, value, name) => {

        if (name === 'patient_phoneNumber') {
            let dialCode = value?.dialCode || '';
            let phoneNumber = e;

            if (phoneNumber.startsWith(dialCode)) {
                // Remove the dial code from the phone number
                phoneNumber = phoneNumber.substring(dialCode.length);
            }

            setFormData({
                ...formData,
                // countryCode: dialCode,
                patient_phoneNuber: `+${dialCode}${phoneNumber}`,
            });
        }
    }

    const onsubmit = (values) => {
        handleSharePatientData(studyInstanceUid, formData, patientId, accession);
        if (findPatientData !== undefined) {
            show({
                title: "Success!",
                message: "Successfully Update Patient Details",
                type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
                position: "topRight"
            });
        } else {
            show({
                title: "Success!",
                message: "Successfully Add Patient Details",
                type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
                position: "topRight"
            });
        }
        hide();
    }


    const handlerShareDocumentToEmail = async () => {
        setIsLoading(true);

        try {
            // Modify the editor data before generating the PDF
            const modifiedEditorData = editorData ? editorData : patientReportDetail.reportdetails;

            const headerStyle = `
            height: 100%;
            width: 98%;
            z-index: 1;
            padding-right: 10px;
            height: ${reportSetting.height}px;
            `;


            const watermarkStyle = `
                position: fixed;
                width: 100%;
                height: auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0.6;
                margin-top: 50px;
                margin-bottom: 50px;
                z-index: -1;
            `;


            const watermarkTextStyle = `
                font-size: 80px;
                color: #999;
            `;


            // signature image style
            const signatureStyle = `
                width: 200px;
                height: 100px;
                color : red ;
            `;


            // report template style
            const reportDataStyle = `
                margin-top: ${reportSetting?.top}px;
                margin-left: ${reportSetting?.left}px;
                margin-right: ${reportSetting?.right}px;
                margin-bottom: ${reportSetting?.bottom}px;
                font-family: ${reportSetting?.font_style};
                font-size: ${reportSetting?.font_size}px !important;
                line-height: ${reportSetting?.line_spacing};
            `;


            // Doctore details footer
            const firstName = assignUserDataFind ? `${assignUserDataFind?.username}` : '';
            const qualification = assignUserDataFind?.attributes.qualification !== undefined ? assignUserDataFind?.attributes.qualification : '';
            const registrationNo = assignUserDataFind && assignUserDataFind?.attributes && assignUserDataFind?.attributes.registrationNo ? assignUserDataFind.attributes.registrationNo : '';
            const formattedTimes = formattedTime === undefined ? '' : formattedTime;
            const disclaimerDetails = reportSetting && reportSetting.disclaimer_details ? reportSetting.disclaimer_details : '';


            const output = `
                ${firstName}<br/>
                ${qualification}<br/>
                ${registrationNo ? `Reg.No. :- ${registrationNo}` : ''}<br/>
                ${formattedTimes}<br/>
                ${disclaimerDetails ? `Disclaimer :- ${disclaimerDetails}` : ''}
            `;




            const pageHeaderSpace = `
          height: ${reportSetting.height}px;
          `;


            const pageFooterSpace = `
          height: ${reportSetting.height}px;
          `;


            const pageFooter = `
            position: fixed;
            bottom: 0;
            width: 100%;`;


            const pageHeader = `
            position: fixed;
            top: 0mm;
            width: 100%;`;


            const page = `
              page-break-after: always;
            `;


            const pageStyle = `
            @page {
              margin:   30mm
            }

            @media print {
              thead {display: table-header-group;}
              tfoot {display: table-footer-group;}

              button {display: none;}

              body {margin: 0;}
          }`;


            let modifiedEditor = '';
            // Construct modified editor content
            if (reportSetting?.multiple_header_and_footer === true) {
                modifiedEditor = `
                <div style="${pageStyle}">
                  <div class="page-header" style="${pageHeader}">
                    ${reportSetting?.include_header && reportSetting?.header_image
                        ? `
                    <img src="${reportSetting?.header_image}"  alt="Header" style="${headerStyle}" />
                    ` : ''}

                  </div >

                  <div class="page-footer" style="${pageFooter}">
                  ${reportSetting?.include_footer && reportSetting?.footer_image ? `
                  <img src="${reportSetting?.footer_image}" alt="Footer" style="${headerStyle}" />
                  ` : ''}
                  </div>

                  <table>

                  <thead>
                    <tr>
                      <td>
                        <!--place holder for the fixed-position header-->
                        <div style="${pageHeaderSpace}" class="page-header-space"></div>
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>

                        <div style="${page}">
                          <div style="${reportDataStyle}">
                            ${modifiedEditorData}
                            <div style="margin-top: 10px">
                              ${reportSetting?.signature && assignUserDataFind?.attributes?.uploadSignature && assignUserDataFind ? `<img src="${assignUserDataFind?.attributes?.uploadSignature}" alt="signature" style="${signatureStyle}" />` : ''} <br/>
                              ${output}
                            </div >
                          </div>

                        </div>
                      </td>
                    </tr>
                  </tbody>

                  <tfoot>
                    <tr>
                      <td>

                        <div class="page-footer-space" style="${pageFooterSpace}"></div>
                      </td>
                    </tr>
                  </tfoot>

                  </table>
                  ${reportSetting?.include_watermark && reportSetting?.watermark_image
                        ? `
                  <div style="${watermarkStyle}" class="watermark">
                    <span style="${watermarkTextStyle}" class="watermark-text"><img src="${reportSetting?.watermark_image}" alt="watermark" width="100%" height="auto" /> </span>
                  </div>
                  `
                        : ''
                    }
                        </div>

        `;
            } else {
                modifiedEditor = `
            <div>
              ${reportSetting?.include_header && reportSetting?.header_image
                        ? `
              <img src="${reportSetting?.header_image}" alt="Header" style="${headerStyle}" />
            `
                        : ''
                    }
              <div >
                <div >
                  <!-- Editor content -->
                  <div style="${reportDataStyle}">
                    ${modifiedEditorData}
                    <div style="margin-top: 10px">
                     ${reportSetting?.signature && assignUserDataFind?.attributes?.uploadSignature && assignUserDataFind ? `<img src="${assignUserDataFind?.attributes?.uploadSignature}" alt="signature" style="${signatureStyle}" />` : ''} <br/>
                      ${output}
                    </div >
                  </div>
                </div>
              </div>
              ${reportSetting?.include_footer && reportSetting?.footer_image
                        ? `
              <img src="${reportSetting?.footer_image}" alt="Footer" style="${headerStyle}" />
              `
                        : ''
                    }
            </div >
            ${reportSetting?.include_watermark && reportSetting?.watermark_image
                        ? `
              <div style="${watermarkStyle}" class="watermark">
                <span style="${watermarkTextStyle}" class="watermark-text"><img src="${reportSetting?.watermark_image}" alt="watermark" width="100%" height="auto" /> </span>
              </div>
            `
                        : ''
                    }
      `;
            }


            if (findPatientData?.document_status === 'Approved') {
                // const editorContent = document.querySelector('#editor')?.innerHTML;

                // const promises = (formData.patient_email
                //     ? [...selectedUsers, formData.patient_email]
                //     : selectedUsers
                // ).map(email => {
                //     const sendData = {
                //         to: email,
                //         document: modifiedEditor,
                //         patient_name: patientReportDetail.patient_name.toLowerCase(),
                //     };
                //     console.log(sendData, "sendData");

                //     return sendReportData(sendData); // Assume `sendReportData` sends the email
                // });
                const email = selectedUsers.map(user => user.email);

                const promises = (formData.patient_email
                    ? [...email, formData.patient_email]
                    : email
                ).map(email => {
                    const sendData = {
                        to: [email],
                        document: modifiedEditor,
                        patient_name: patientReportDetail.patient_name.toLowerCase()
                    };
                    return sendReportData(sendData)
                })
                const response = await Promise.all(promises);

                hide();
                // const response = await sendReportData(sendData)
                if (response[0].status === 200) {
                    show({
                        title: "Success!",
                        message: response[0]?.message,
                        type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
                        position: "topRight"
                    });
                } else {
                    show({
                        title: "Error!",
                        message: `Failed Document Send To ${formData.patient_email}`,
                        type: SnackbarTypes.ERROR, // Assuming you have a SUCCESS type defined in your SnackbarTypes
                        position: "topRight"
                    });
                }
            } else {
                Swal.fire({
                    text: `Sorry, patient report not approved. Share only approved reports.`,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                }).then(result => {
                    if (result.isConfirmed) {
                        show({
                            title: "Error!",
                            message: `First patient report Approved then share report`,
                            type: SnackbarTypes.ERROR, // Assuming you have a SUCCESS type defined in your SnackbarTypes
                            position: "topRight"
                        });
                    }
                });
                hide();
            }
        } catch (error) {
            console.error("Share document to email faile :- ", error)
        } finally {
            setIsLoading(false);
        }
    }

    const handlerShareDocumentToWhatsapp = async () => {
        setIsLoading(true);

        try {
            // Modify the editor data before generating the PDF
            const modifiedEditorData = editorData ? editorData : patientReportDetail.reportdetails;

            const headerStyle = `
             height: 100%;
             width: 98%;
             z-index: 1;
             padding-right: 10px;
             height: ${reportSetting.height}px;
             `;

            const watermarkStyle = `
                 position: fixed;
                 width: 100%;
                 height: auto;
                 top: 50%;
                 left: 50%;
                 transform: translate(-50%, -50%);
                 opacity: 0.6;
                 margin-top: 50px;
                 margin-bottom: 50px;
                 z-index: -1;
             `;

            const watermarkTextStyle = `
                 font-size: 80px;
                 color: #999;
             `;

            // signature image style
            const signatureStyle = `
                 width: 200px;
                 height: 100px;
                 color : red ;
             `;

            // report template style
            const reportDataStyle = `
                 margin-top: ${reportSetting?.top}px;
                 margin-left: ${reportSetting?.left}px;
                 margin-right: ${reportSetting?.right}px;
                 margin-bottom: ${reportSetting?.bottom}px;
                 font-family: ${reportSetting?.font_style};
                 font-size: ${reportSetting?.font_size}px !important;
                 line-height: ${reportSetting?.line_spacing};
             `;

            // Doctore details footer
            const firstName = assignUserDataFind ? `${assignUserDataFind?.username}` : '';
            const qualification = assignUserDataFind?.attributes.qualification !== undefined ? assignUserDataFind?.attributes.qualification : '';
            const registrationNo = assignUserDataFind && assignUserDataFind?.attributes && assignUserDataFind?.attributes.registrationNo ? assignUserDataFind.attributes.registrationNo : '';
            const formattedTimes = formattedTime === undefined ? '' : formattedTime;
            const disclaimerDetails = reportSetting && reportSetting.disclaimer_details ? reportSetting.disclaimer_details : '';

            const output = `
                 ${firstName}<br/>
                 ${qualification}<br/>
                 ${registrationNo ? `Reg.No. :- ${registrationNo}` : ''}<br/>
                 ${formattedTimes}<br/>
                 ${disclaimerDetails ? `Disclaimer :- ${disclaimerDetails}` : ''}
             `;


            const pageHeaderSpace = `
           height: ${reportSetting.height}px;
           `;

            const pageFooterSpace = `
           height: ${reportSetting.height}px;
           `;

            const pageFooter = `
             position: fixed;
             bottom: 0;
             width: 100%;`;

            const pageHeader = `
             position: fixed;
             top: 0mm;
             width: 100%;`;

            const page = `
               page-break-after: always;
             `;

            const pageStyle = `
             @page {
               margin:   30mm
             }

             @media print {
               thead {display: table-header-group;}
               tfoot {display: table-footer-group;}

               button {display: none;}

               body {margin: 0;}
           }`;

            let modifiedEditor = '';
            // Construct modified editor content
            if (reportSetting?.multiple_header_and_footer === true) {
                modifiedEditor = `
                 <div style="${pageStyle}">
                   <div class="page-header" style="${pageHeader}">
                     ${reportSetting?.include_header && reportSetting?.header_image
                        ? `
                     <img src="${reportSetting?.header_image}"  alt="Header" style="${headerStyle}" />
                     ` : ''}

                    </div >

                    <div class="page-footer" style="${pageFooter}">
                    ${reportSetting?.include_footer && reportSetting?.footer_image ? `
                    <img src="${reportSetting?.footer_image}" alt="Footer" style="${headerStyle}" />
                    ` : ''}
                    </div>

                    <table>

                    <thead>
                        <tr>
                        <td>
                            <!--place holder for the fixed-position header-->
                            <div style="${pageHeaderSpace}" class="page-header-space"></div>
                        </td>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                        <td>

                            <div style="${page}">
                            <div style="${reportDataStyle}">
                                ${modifiedEditorData}
                                <div style="margin-top: 10px">
                                ${reportSetting?.signature && assignUserDataFind?.attributes?.uploadSignature && assignUserDataFind ? `<img src="${assignUserDataFind?.attributes?.uploadSignature}" alt="signature" style="${signatureStyle}" />` : ''} <br/>
                                ${output}
                                </div >
                            </div>

                            </div>
                        </td>
                        </tr>
                    </tbody>

                    <tfoot>
                        <tr>
                        <td>

                            <div class="page-footer-space" style="${pageFooterSpace}"></div>
                        </td>
                        </tr>
                    </tfoot>

                    </table>
                    ${reportSetting?.include_watermark && reportSetting?.watermark_image
                        ? `
                    <div style="${watermarkStyle}" class="watermark">
                        <span style="${watermarkTextStyle}" class="watermark-text"><img src="${reportSetting?.watermark_image}" alt="watermark" width="100%" height="auto" /> </span>
                    </div>
                   `
                        : ''
                    }
                         </div>

                    `;
            } else {
                modifiedEditor = `
                    <div>
                    ${reportSetting?.include_header && reportSetting?.header_image
                        ? `
                    <img src="${reportSetting?.header_image}" alt="Header" style="${headerStyle}" />
                    `
                        : ''
                    }
                    <div >
                        <div >
                        <!-- Editor content -->
                        <div style="${reportDataStyle}">
                            ${modifiedEditorData}
                            <div style="margin-top: 10px">
                            ${reportSetting?.signature && assignUserDataFind?.attributes?.uploadSignature && assignUserDataFind ? `<img src="${assignUserDataFind?.attributes?.uploadSignature}" alt="signature" style="${signatureStyle}" />` : ''} <br/>
                            ${output}
                            </div >
                        </div>
                        </div>
                    </div>
                    ${reportSetting?.include_footer && reportSetting?.footer_image
                        ? `
                    <img src="${reportSetting?.footer_image}" alt="Footer" style="${headerStyle}" />
                    `
                        : ''
                    }
                    </div >
                    ${reportSetting?.include_watermark && reportSetting?.watermark_image
                        ? `
                    <div style="${watermarkStyle}" class="watermark">
                        <span style="${watermarkTextStyle}" class="watermark-text"><img src="${reportSetting?.watermark_image}" alt="watermark" width="100%" height="auto" /> </span>
                    </div>
                    `
                        : ''
                    }
                    `;
            }
            if (findPatientData?.document_status === 'Approved') {

                // const sendData = {
                //     number: formData.patient_phoneNuber,
                //     code: formData.countryCode,
                //     document: modifiedEditor,
                //     patient_name: patientReportDetail.patient_name.toLowerCase()
                // }
                const phoneNumbers = selectedUsers.map(user => user.phone[0]);

                (formData.patient_phoneNuber
                    ? [...phoneNumbers, formData.patient_phoneNuber]
                    : phoneNumbers
                )


                const promises = (formData.patient_phoneNuber
                    ? [...phoneNumbers, formData.patient_phoneNuber]
                    : phoneNumbers
                ).map(phone => {
                    const sendData = {
                        number: [phone],
                        document: modifiedEditor,
                        patient_name: patientReportDetail.patient_name.toLowerCase()
                    };
                    return sendReportDataToWhastapp(sendData)
                })


                // const sendData = {
                //     number: phoneNumber,
                //     code: formData.countryCode,
                //     document: modifiedEditor,
                //     patient_name: patientReportDetail.patient_name.toLowerCase()
                // };

                hide()

                const response = await Promise.all(promises);

                if (response[0].status === 200) {
                    show({
                        title: "Success!",
                        message: response[0]?.message,
                        type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
                        position: "topRight"
                    });
                } else {
                    show({
                        title: "Error!",
                        message: `Failed Document Send To ${formData.patient_email}`,
                        type: SnackbarTypes.ERROR, // Assuming you have a SUCCESS type defined in your SnackbarTypes
                        position: "topRight"
                    });
                }
            } else {
                Swal.fire({
                    text: `Sorry, patient report not approved. Share only approved reports.`,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                }).then(result => {
                    if (result.isConfirmed) {
                        show({
                            title: "Error!",
                            message: `First patient report Approved then share report`,
                            type: SnackbarTypes.ERROR, // Assuming you have a SUCCESS type defined in your SnackbarTypes
                            position: "topRight"
                        });
                    }
                });
                hide();
            }
        } catch (error) {
            console.error("Share document to whatsapp faile :- ", error)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <section className="w-full ">
            {/* <Formik
                initialValues={initialValue(findPatientData)}
                onSubmit={onsubmit}
            >
                {({ isSubmitting, handleChange, values }) => (
                    <Form>
                        <div className=' flex gap-5 items-end my-2'>
                            <div className=' w-full'>
                                <Label className='' text=''>Patient Phone Number</Label>
                                <PhoneInput
                                    country={"in"}
                                    value={`${values.countryCode} ${values.patient_phoneNuber}`}
                                    onChange={(e, phone) => setFieldValue(e, phone, "patient_phoneNumber")}
                                    inputStyle={{ width: '100%', marginTop: '0.5rem', fontSize: '1.125rem', color: 'white', backgroundColor: 'black', borderColor: `rgb(58 63 153)` }}
                                    containerStyle={{ width: '100%' }}
                                />
                            </div>
                            <div className=' flex items-center downloadbutton'>
                                <button type="button" className=' box-content inline-flex flex-row items-center justify-center gap-[5px] justify center px-[10px] outline-none rounded leading-[1.2] font-sans text-center whitespace-nowrap font-semibold bg-primary-main text-white transition duration-300 ease-in-out focus:outline-none hover:bg-customblue-80 active:bg-customblue-40 h-[32px] text-[14px] min-w-[32px]' onClick={handlerShareDocumentToWhatsapp}>Share Document</button>
                            </div>
                        </div>
                        <div className=' flex gap-5 items-end my-2'>
                            <div className=' w-full'>
                                <Label className='' text=''>Patient Email</Label>
                                <Field
                                    type='email'
                                    className=" mt-2 w-full text-lg"
                                    name="patient_email"
                                    placeholder={'Enter Patient Email'}
                                    onChange={(e) => setFormData({ ...formData, patient_email: e.target.value })}
                                    as={Input}
                                    value={formData.patient_email}
                                />
                            </div>
                            <div>
                                <button type="button" className=' box-content inline-flex flex-row items-center justify-center gap-[5px] justify center px-[10px] outline-none rounded leading-[1.2] font-sans text-center whitespace-nowrap font-semibold bg-primary-main text-white transition duration-300 ease-in-out focus:outline-none hover:bg-customblue-80 active:bg-customblue-40 h-[32px] text-[14px] min-w-[32px]' onClick={handlerShareDocumentToEmail}>Share</button>
                            </div>
                        </div>
                        <div className=' mt-5'>
                            <Button>{(findPatientData?.phone_number || findPatientData?.patient_email) ? 'Update' : 'Save'}</Button>
                        </div>
                    </Form>

                )}
            </Formik>
            <div id="editor" className=' hidden' ></div> */}
            <div className='max-sm:px-3'>
                <div className="container relative mx-auto flex flex-col pt-5">
                    <div className="mb-5 flex flex-row justify-between">
                        <div className="flex min-w-[1px] shrink flex-row items-center gap-6">
                            <Typography
                                variant="h6"
                                className="dark:text-white text-black max-sm:text-[16px]"
                                component={'h6'}
                            >Users
                            </Typography>
                        </div>

                    </div>
                </div>
                <div
                    className=" overflow-y-auto container m-auto mt-2 telerapp-scrollbar"
                    style={{
                        maxHeight: 'calc(100vh - 201px)', // Full height minus header height
                        // scrollbarColor: '#173239 transparent',
                    }}
                >
                    <table className="w-full table-auto text-white">
                        <thead className="bg-[#d1d1d1] dark:bg-primary-dark dark:border-primary-main border-secondary-dark border sticky top-0 right-0 left-0" >
                            <tr className=' max-sm:text-base '>
                                {values.map(tableHead => (
                                    <th
                                        className="p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black"
                                        key={'tableHead'}
                                    >
                                        {tableHead}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        {physicianUserLists && physicianUserLists.length <= 0 ? (
                            <span className="absolute  flex h-96 w-5/12 items-center justify-end dark:text-white text-black">
                                No Physician Users Exiting
                            </span>
                        ) : (
                            <tbody className="mt-1 ">
                                {physicianUserLists &&
                                    physicianUserLists?.map(instance => (
                                        <tr className="bg-secondary-light dark:bg-secondary-dark dark:border-primary-main border-secondary-dark hover:border-secondary-dark dark:hover:bg-[#414141] hover:bg-primary-light cursor-pointer border text-center transition duration-300 max-sm:text-sm" >
                                            {keys.map(key => (
                                                <td
                                                    className="p-2 dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black"
                                                    key={key}
                                                >
                                                    {key === 'checkBox' ? (
                                                        <span className="flex justify-center items-center">
                                                            <span className="custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="cursor-pointer"
                                                                    onChange={(e) => handleCheckboxChange(instance?.email, instance?.attributes?.mobileNumber, e.target.checked)}
                                                                />
                                                            </span>
                                                        </span>
                                                    ) : key === 'mobileNumber' ? (
                                                        <span
                                                            className="cursor-pointer"
                                                        >
                                                            {instance?.attributes?.mobileNumber}
                                                        </span>
                                                    ) : (
                                                        instance[key]
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                            </tbody>
                        )}
                    </table>
                </div>
                <Formik
                    initialValues={initialValue(findPatientData)}
                    onSubmit={onsubmit}
                >
                    {({ isSubmitting, handleChange, values }) => (
                        <Form>
                            <div className=' flex gap-5 items-end my-2'>
                                <div className=' w-full'>
                                    <Label className='' text=''>Patient Phone Number</Label>
                                    <PhoneInput
                                        country={"in"}
                                        value={`${values.countryCode} ${values.patient_phoneNuber}`}
                                        onChange={(e, phone) => setFieldValue(e, phone, "patient_phoneNumber")}
                                        inputStyle={{ width: '100%', marginTop: '0.5rem', fontSize: '1.125rem', color: 'white', backgroundColor: 'black', borderColor: `rgb(58 63 153)` }}
                                        containerStyle={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                            <div className=' flex gap-5 items-end my-2'>
                                <div className=' w-full'>
                                    <Label className='' text=''>Patient Email</Label>
                                    <Field
                                        type='email'
                                        className=" mt-2 w-full text-lg"
                                        name="patient_email"
                                        placeholder={'Enter Patient Email'}
                                        onChange={(e) => setFormData({ ...formData, patient_email: e.target.value })}
                                        as={Input}
                                        value={formData.patient_email}
                                    />
                                </div>
                            </div>
                            <div className=' mt-5'>
                                <Button>{(findPatientData?.phone_number || findPatientData?.patient_email) ? 'Update' : 'Save'}</Button>
                            </div>
                        </Form>

                    )}
                </Formik>
            </div>
            <div className='flex justify-end gap-2 mt-5'>
                <button onClick={handlerShareDocumentToEmail} className="dark:border-primary-main border-secondary-dark border bg-primary-light dark:bg-primary-dark hover:opacity-80 dark:text-white text-black font-bold py-2 px-4 rounded inline-flex items-center transition-all">
                    <span>Share to Email</span>
                </button>
                <button onClick={handlerShareDocumentToWhatsapp} className="dark:border-primary-main border-secondary-dark border bg-primary-light dark:bg-primary-dark hover:opacity-80 dark:text-white text-black font-bold py-2 px-4 rounded inline-flex items-center transition-all">
                    <span>Share to Phone Number</span>
                </button>


            </div>
        </section>
    )
}

export default ShareModel;
