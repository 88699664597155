import React, { useEffect, useState } from 'react';
import { Dashboard } from '@ohif/ui';
import LogList from './LogList';
import LogList2 from './LogList2';
import * as XLSX from 'xlsx'; // Import XLSX for handling Excel export
import { useLogViewer } from '../../contextProviders/LogViewerProvider'; // Adjust the import path as needed
import './LogViewer.css';
import { BsSearch } from 'react-icons/bs';
import { GrPowerReset } from 'react-icons/gr';
import { FaFileDownload } from "react-icons/fa";
import Typography from '../Typography';
import Button from '../Button';
import EventLogs from './EventLogs';


const LogViewer = ({ servicesManager, extensionManager, hotkeysManager, data: studies }) => {
    const { logs, setSelectedDate, setSelectedLogFileName, setSelectedDateRange } = useLogViewer(); // Ensure 'logs' is destructured from context
    const [selectedLog, setSelectedLog] = useState(null);
    const [fileName, setFileName] = useState('StudyLogs'); // Default file name (can be changed by the dropdown)
    const [searchTerm, setSearchTerm] = useState(''); // State for search term

    // Initialize the dates to today
    const today = new Date().toISOString().split('T')[0];
    const [date, setDate] = useState(today);
    const [endDate, setEndDate] = useState(today);

    useEffect(() => {
        handleFetchLogs(); // Fetch logs for default values on component mount
    }, []);

    const handleDateChange = (e) => {
        setDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    const handleFileNameChange = (e) => {
        setFileName(e.target.value); // Update selected file name from dropdown
    };

    const handleFetchLogs = () => {
        if (date && !endDate) {
            setSelectedDate(date); // Set the selected date if no end date is provided
            setSelectedLogFileName(fileName); // Set the selected log file name
        } else if (date && endDate) {
            setSelectedDateRange({ startDate: date, endDate }); // Set the selected date range if both dates are provided
            setSelectedLogFileName(fileName); // Set the selected log file name
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value); // Update search term
    };

    const handleReset = () => {
        // Reset states to default
        setDate(today);
        setEndDate(today);
        setSearchTerm('');
        setFileName('StudyLogs');
        handleFetchLogs(); // Fetch logs with reset values
    };

    // Handle Export functionality
    const handleExport = () => {
        if (!logs) {
            alert('No logs available to export.');
            return;
        }

        let logEntries = [];

        // Helper function to format the log date and time in India/Mumbai time zone
        const formatDateTime = (timestamp) => {
            const dateTime = new Date(timestamp);

            const options = {
                timeZone: 'Asia/Kolkata',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true,
            };

            const formattedDateTime = new Intl.DateTimeFormat('en-IN', options).formatToParts(dateTime);
            const date = `${formattedDateTime[4].value}-${formattedDateTime[2].value}-${formattedDateTime[0].value}`;
            const time = `${formattedDateTime[6].value}:${formattedDateTime[8].value}:${formattedDateTime[10].value} ${formattedDateTime[12].value}`;
            return { date, time };
        };

        // Parse logs based on the selected fileName
        const parseLogs = (logContent, type) => {
            const logLines = logContent.split('\n').filter((entry) => entry.trim() !== '');
            return logLines.map((line) => {
                try {
                    const log = JSON.parse(line);
                    if (type === 'StudyLogs') {
                        const { StudyInstanceUID, PatientID, AET, InstitutionName, Modality, timestamp } = log;
                        const dateTime = formatDateTime(timestamp);
                        return {
                            Date: dateTime.date,
                            Time: dateTime.time,
                            'Study UID': StudyInstanceUID || 'N/A',
                            'Patient ID': PatientID || 'N/A',
                            AET: AET || 'N/A',
                            Institution: InstitutionName || 'N/A',
                            Modality: Modality || 'N/A',
                        };
                    } else {
                        const { accession, patientID, action, userName, timestamp, UID } = log;
                        const dateTime = formatDateTime(timestamp);
                        return {
                            Date: dateTime.date,
                            Time: dateTime.time,
                            UID: UID || 'N/A',
                            Accession: accession || 'N/A',
                            'Patient ID': patientID || 'N/A',
                            Activity: action || 'Activity not found',
                            User: userName || 'Unknown User',
                        };
                    }
                } catch (error) {
                    console.error('Failed to parse log line:', line, error);
                    return null;
                }
            }).filter(entry => entry !== null);
        };

        // Gather log entries based on the selected fileName
        if (fileName === 'StudyLogs') {
            if (logs?.logs) {
                logs.logs.forEach((log) => {
                    logEntries = [...logEntries, ...parseLogs(log.logContent, 'StudyLogs')];
                });
            } else if (logs?.logContent) {
                logEntries = parseLogs(logs.logContent, 'StudyLogs');
            }
        } else {
            if (logs?.logs) {
                logs.logs.forEach((log) => {
                    logEntries = [...logEntries, ...parseLogs(log.logContent, 'OtherLogs')];
                });
            } else if (logs?.logContent) {
                logEntries = parseLogs(logs.logContent, 'OtherLogs');
            }
        }

        if (logEntries.length === 0) {
            alert('No log entries found to export.');
            return;
        }

        // Create a worksheet from the log entries
        const worksheet = XLSX.utils.json_to_sheet(logEntries);

        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Logs');

        // Generate a buffer
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Create a Blob from the buffer
        const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });

        // Create a download link and trigger the download
        const url = window.URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${fileName}_${new Date().toISOString()}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    return (
        <section className="relative w-full text-white">
            <Dashboard
                servicesManager={servicesManager}
                extensionManager={extensionManager}
                hotkeysManager={hotkeysManager}
                sortedStudies={studies}
            />
            <div className="absolute top-16 p-3 left-60 right-0">
                <div className="container mx-auto flex flex-col pt-5">
                    <div className="flex flex-row justify-between items-center w-full">
                        <div className="flex min-w-[1px] shrink mr-3 flex-row items-center gap-6">
                            <Typography variant="h6" className="text-white" component={'h6'}>
                                Audit Logs
                            </Typography>
                        </div>
                        <div className="flex flex-row items-center space-x-2">
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    title="Search on logs data"
                                    className="custom-input search-box"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                            </div>

                            <div className="custom-select-wrapper">
                                <select
                                    value={fileName}
                                    onChange={handleFileNameChange}
                                    className="custom-select"
                                    title='Log options'
                                    required
                                >
                                    <option value="" disabled>Select a Category</option>
                                    <option value="StudyLogs">Study Logs</option>
                                    <option value="AddendumLogs">Addendum Logs</option>
                                    <option value="ApproveLogs">Approve Logs</option>
                                    <option value="AssignLogs">Assign Logs</option>
                                    <option value="UnassignLogs">Unassign Logs</option>
                                    <option value="HistoryLogs">History Logs</option>
                                    {/* <option value="StatusLogs">Status Logs</option> */}
                                    <option value="ShareLogs">Share Logs</option>
                                    <option value="PriorityLogs">Priority Logs</option>
                                    <option value="ErrorLogs">Error Logs</option>
                                    <option value="DeleteLogs">Delete Logs</option>
                                    <option value="SubmitLogs">Submit Logs</option>
                                    <option value="DraftLogs">Draft Logs</option>
                                    <option value="CriticalLogs">Critical Logs</option>
                                    <option value="LogInLogs">LogIn Logs</option>
                                </select>
                                <span className="custom-arrow">⮟</span>
                            </div>

                            <input
                                type="date"
                                value={date}
                                onChange={handleDateChange}
                                className="custom-input1"
                                title='Start date'
                                required
                            />

                            <input
                                type="date"
                                value={endDate}
                                onChange={handleEndDateChange}
                                className="custom-input1"
                                title='End date'
                            />

                            <Button className="ml-5 py-0.5 px-8" onClick={handleFetchLogs}>
                                <BsSearch className="h-4 w-4 text-[#cacbcc] dark:text-[#cacbcc]" />
                            </Button>

                            <Button className="ml-3 py-0.5 px-8" onClick={handleReset}>
                                <GrPowerReset className="h-4 w-4 text-[#cacbcc] dark:text-[#cacbcc]" />
                            </Button>

                            <Button className="ml-3 py-0.5 px-8" onClick={handleExport}>
                                <FaFileDownload className="h-4 w-4 text-[#cacbcc] dark:text-[#cacbcc]" />
                            </Button>
                        </div>

                    </div>

                    <div className="mt-4">
                        {fileName === 'LogInLogs' ? (
                            <EventLogs servicesManager={servicesManager} />
                        ) : fileName === 'StudyLogs' ? (
                            <LogList2 onSelectLog={setSelectedLog} searchTerm={searchTerm} />
                        ) : (
                            <LogList onSelectLog={setSelectedLog} searchTerm={searchTerm} />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LogViewer;
