import React from 'react';

interface StrokeAiInterfaceProps {
    fetchedData?: {
        results: Array<{
            confidence_score: number;
            predicted_class: number;
        }>;
    };
}

const StrokeAiInterface: React.FC<StrokeAiInterfaceProps> = ({ fetchedData }) => {
    if (!fetchedData) return null;

    const { results = [] } = fetchedData || {};

    // Check if any predicted_class: 1 is present
    const hasStrokeWarning = results.some((result) => result.predicted_class === 1);

    // Find the highest confidence_score for predicted_class: 1
    const highestRiskScore = results
        .filter((result) => result.predicted_class === 1)
        .reduce((max, result) => Math.max(max, result.confidence_score), 0);

    // Convert the highest score to a percentage (scale 0-100)
    const riskPercentage = highestRiskScore > 0 ? (highestRiskScore * 100).toFixed(0) : "0";

    return (
        <div className="stroke-ai-interface" style={{ padding: "20px" }}>
            {/* Alert Message Section */}
            {hasStrokeWarning && (
                <div
                    style={{
                        padding: "10px",
                        backgroundColor: "#ffcccc",
                        color: "#b00000",
                        border: "1px solid #b00000",
                        borderRadius: "5px",
                        marginBottom: "20px",
                    }}
                >
                    <strong>Alert!</strong> Stroke detected.
                </div>
            )}

            {/* Risk Scale Section */}
            <div style={{ marginBottom: "20px", textAlign: "center" }}>
                <h3>Risk Based on Presence of Abnormalities</h3>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                    <div style={{ flex: 1, maxWidth: "400px", position: "relative" }}>
                        {/* Progress Bar */}
                        <div
                            style={{
                                position: "relative",
                                height: "15px",
                                backgroundColor: "#e0e0e0",
                                borderRadius: "10px",
                                overflow: "hidden",
                            }}
                        >
                            <div
                                style={{
                                    width: `${riskPercentage}%`,
                                    backgroundColor:
                                        highestRiskScore > 0.75
                                            ? "#FF6F61"
                                            : highestRiskScore > 0.49
                                                ? "#FFC107"
                                                : "#4CAF50",
                                    height: "100%",
                                }}
                            />
                        </div>

                        {/* Labels */}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                fontSize: "12px",
                                marginTop: "5px",
                            }}
                        >
                            <span>Low</span>
                            <span>Intermediate</span>
                            <span>Elevated</span>
                        </div>
                    </div>

                    {/* Highest Region Score */}
                    <div style={{ marginLeft: "20px", fontSize: "24px", fontWeight: "bold" }}>
                        {riskPercentage}%
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StrokeAiInterface;
