import React from "react";

interface BoneFractureAiInterfaceProps {
    fetchedData?: {
        image: string;
        message: string;
        predictions: { [key: string]: number } | string; // Allow predictions to be an object or string
    };
}

const BoneFractureAiInterface: React.FC<BoneFractureAiInterfaceProps> = ({ fetchedData }) => {
    if (!fetchedData) return null; // Return nothing if no fetchedData

    const { image, message, predictions } = fetchedData;

    // Parse predictions string into an object, if predictions is a string
    const parsedPredictions =
        typeof predictions === "string"
            ? predictions.split("\n").reduce<{ [key: string]: number }>((acc, line) => {
                const [key, value] = line.split(":").map((item) => item.trim());
                if (key && value) {
                    const numericValue = parseFloat(value.replace(/[\[\]]/g, ""));
                    if (!isNaN(numericValue)) {
                        acc[key] = numericValue;
                    }
                }
                return acc;
            }, {})
            : predictions; // Use predictions as-is if it's already an object

    // Exclude "text" key from predictions
    const filteredPredictions = Object.entries(parsedPredictions || {}).reduce<{
        [key: string]: number;
    }>((acc, [key, value]) => {
        if (key.toLowerCase() !== "text") {
            acc[key] = value;
        }
        return acc;
    }, {});

    // Extract predicted fracture types (i.e., class names) and their scores
    const predictedClasses = Object.entries(filteredPredictions || {})
        .filter(([_, score]) => score > 0) // Only include classes with a non-zero score
        .map(([className, score]) => ({
            className,
            score: (score * 100).toFixed(0), // Convert score to a percentage
        }));

    // Create a message string based on predicted fracture types
    const predictedMessage =
        predictedClasses.length > 0
            ? predictedClasses.map(
                ({ className, score }) => `${className.charAt(0).toUpperCase() + className.slice(1)} is present%`    //`${className} detected possibility ${score}%`
            ).join(", ")
            : "No fractures detected";

    // Function to handle image download
    const handleDownloadImage = () => {
        if (image) {
            const imageSrc = `data:image/png;base64,${image}`;

            // Create a hidden link element
            const link = document.createElement("a");
            link.href = imageSrc;
            link.download = "result_image.png"; // Name of the file to download

            // Trigger the download by simulating a click on the link
            link.click();

            alert("Image download initiated!");
        } else {
            alert("No image to download");
        }
    };

    return (
        <div className="bone-fracture-ai-interface" style={{ padding: "20px" }}>
            {/* Display Image Section */}
            {image && (
                <div style={{ marginBottom: "20px", position: "relative" }}>
                    <img
                        src={`data:image/png;base64,${image}`}
                        alt="Bone Fracture AI Result"
                        style={{ width: "100%", maxHeight: "500px", objectFit: "contain" }}
                    />
                    <button
                        title="Download image"
                        onClick={handleDownloadImage}
                        style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            padding: "5px 10px",
                            backgroundColor: "#808080",
                            color: "#FFFFFF",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontWeight: "bold",
                        }}
                    >
                        ⇩
                    </button>
                </div>
            )}

            {/* Display Message Section */}
            {message && (
                <div style={{ marginBottom: "20px" }}>
                    <strong>{message}</strong>
                </div>
            )}

            {/* Display Predicted Fractures Section */}
            {predictedMessage && (
                <div style={{ marginBottom: "20px", fontSize: "16px" }}>
                    <strong>{predictedMessage}</strong>
                </div>
            )}

            {/* Display Predictions Section */}
            {/* {filteredPredictions && Object.keys(filteredPredictions).length > 0 ? (
                <div>
                    <strong>Predictions:</strong>
                    <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                        {predictedClasses.map(({ className, score }, index) => (
                            <li key={index} style={{ marginBottom: "10px" }}>
                                <strong>{className}:</strong> {score}%
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <p>No predictions available.</p>
            )} */}
        </div>
    );
};

export default BoneFractureAiInterface;
