import React, { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import {
    fetchStudyCount,
    fetchRadiologistActiveListData,
    fetchReportsByPriorityAndDateRange,
    fetchReportsByModalityAndDateRange,
    fetchReportsByDocumentStatusAndDateRange,
    fetchApprovedReportsByModalityAndDateRange,
    fetchReportsByDateAnalysis,
    fetchReportsByModalityAndInstitute,
    fetchStudyStatusByPriorityAndDocumentStatus,
    fetchReportsByWeekAnlyt,
    fetchReportsByUserAndModality,
    fetchUserWeeklyPerformance,
    fetchModalityWiseAverageTAT,
    fetchInstituteWeeklyPerformanceData
} from '../requestHandler';

export const AnalyticsContext = createContext(null);

export const AnalyticsProvider = ({ children }) => {
    const [studyCount, setStudyCount] = useState(0);
    const [radiologistData, setRadiologistData] = useState()
    const [reportsByPriority, setReportsByPriority] = useState([]);
    const [reportsByModality, setReportsByModality] = useState([]);
    const [reportsByDocumentStatus, setReportsByDocumentStatus] = useState([]);
    const [approvedReportsByModality, setApprovedReportsByModality] = useState([]);
    const [reportsByDate, setReportsByDate] = useState([]);
    const [reportsByModalityAndInst, setReportsByModalityAndInst] = useState([]);
    const [studyStatusByPriorityAndDocument, setStudyStatusByPriorityAndDocument] = useState([]);
    const [reportsByWeek, setReportsByWeek] = useState([]);
    const [reportsByUserAndModal, setReportsByUserAndModal] = useState([]);
    const [userWeeklyPerform, setUserWeeklyPerform] = useState([]);
    const [modalityWiseAverageTATs, setModalityWiseAverageTATs] = useState([]);
    const [instituteWeeklyPerform, setInstituteWeeklyPerform] = useState([]);


    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const username = "";
    const instituteName = "";

    useEffect(() => {
        fetchStudyCount()
            .then(data => setStudyCount(data))
            .catch(error => console.error('Error fetching study count:', error));
    }, []);

    useEffect(() => {
        fetchRadiologistActiveListData()
            .then(data => setRadiologistData(data))
            .catch(error => console.error('Error fetching study count:', error));
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            fetchReportsByPriorityAndDateRange(startDate, endDate);
            fetchReportsByPriorityAndDateRange(startDate, endDate);
            fetchReportsByModalityAndDateRange(startDate, endDate)
            fetchReportsByDocumentStatusAndDateRange(startDate, endDate)
            fetchApprovedReportsByModalityAndDateRange(startDate, endDate)
            fetchReportsByDateAnalysis(startDate, endDate)
            fetchReportsByModalityAndInstitute(startDate, endDate)
            fetchStudyStatusByPriorityAndDocumentStatus(startDate, endDate)
            fetchReportsByWeekAnlyt(startDate, endDate)
            fetchReportsByUserAndModality(startDate, endDate)
            username && fetchUserWeeklyPerformance(username, startDate, endDate)
            fetchModalityWiseAverageTAT(startDate, endDate)
            instituteName && fetchInstituteWeeklyPerformanceData(instituteName, startDate, endDate)
        }
    }, [startDate, endDate, username, instituteName]);

    const fetchReportsByPriority = useCallback(async (startDate, endDate) => {
        try {
            const data = await fetchReportsByPriorityAndDateRange(startDate, endDate);
            setReportsByPriority(data);
        } catch (error) {
            console.error('Error fetching reports by priority:', error);
        }
    }, []);

    const fetchReportsByModality = useCallback(async (startDate, endDate) => {
        try {
            const data = await fetchReportsByModalityAndDateRange(startDate, endDate);
            setReportsByModality(data);
        } catch (error) {
            console.error('Error fetching reports by modality:', error);
        }
    }, []);

    const fetchReportsByDocumentStatus = useCallback(async (startDate, endDate) => {
        try {
            const data = await fetchReportsByDocumentStatusAndDateRange(startDate, endDate);
            setReportsByDocumentStatus(data);
        } catch (error) {
            console.error('Error fetching reports by document status:', error);
        }
    }, []);

    const fetchApprovedReportsByModality = useCallback(async (startDate, endDate) => {
        try {
            const data = await fetchApprovedReportsByModalityAndDateRange(startDate, endDate);
            setApprovedReportsByModality(data);
        } catch (error) {
            console.error('Error fetching approved reports by modality:', error);
        }
    }, []);

    const fetchReportsByDate = useCallback(async (startDate, endDate) => {
        try {
            const data = await fetchReportsByDateAnalysis(startDate, endDate);
            setReportsByDate(data);
        } catch (error) {
            console.error('Error fetching reports by date analysis:', error);
        }
    }, []);

    const fetchReportsByModalityAndInst = useCallback(async (startDate, endDate) => {
        try {
            const data = await fetchReportsByModalityAndInstitute(startDate, endDate);
            setReportsByModalityAndInst(data);
        } catch (error) {
            console.error('Error fetching reports by modality and institute:', error);
        }
    }, []);

    const fetchStudyStatusByPriorityAndDocument = useCallback(async (startDate, endDate) => {
        try {
            const data = await fetchStudyStatusByPriorityAndDocumentStatus(startDate, endDate);
            setStudyStatusByPriorityAndDocument(data);
        } catch (error) {
            console.error('Error fetching study status by priority and document:', error);
        }
    }, []);

    const fetchReportsByWeek = useCallback(async (startDate, endDate) => {
        try {
            const data = await fetchReportsByWeekAnlyt(startDate, endDate);
            setReportsByWeek(data);
        } catch (error) {
            console.error('Error fetching reports by week analysis:', error);
        }
    }, []);

    const fetchReportsByUserAndModal = useCallback(async (startDate, endDate) => {
        try {
            const data = await fetchReportsByUserAndModality(startDate, endDate);
            setReportsByUserAndModal(data);
        } catch (error) {
            console.error('Error fetching reports by user and modality:', error);
        }
    }, []);

    const fetchUserWeeklyPerform = useCallback(async (username, startDate, endDate) => {
        try {
            const data = await fetchUserWeeklyPerformance(username, startDate, endDate);
            setUserWeeklyPerform(data);
        } catch (error) {
            console.error('Error fetching user weekly performance:', error);
        }
    }, []);

    const fetchInstituteWeeklyPerform = useCallback(async (institutionName, startDate, endDate) => {
        try {
            const data = await fetchInstituteWeeklyPerformanceData(institutionName, startDate, endDate);
            setInstituteWeeklyPerform(data);
        } catch (error) {
            console.error('Error fetching institution weekly performance:', error);
        }
    }, []);

    const fetchModalityWiseAverageTATs = useCallback(async (startDate, endDate) => {
        try {
            const data = await fetchModalityWiseAverageTAT(startDate, endDate);
            setModalityWiseAverageTATs(data);
        } catch (error) {
            console.error('Error fetching modality wise average TAT:', error);
        }
    }, []);

    const value = useMemo(
        () => ({
            studyCount,
            reportsByPriority,
            reportsByModality,
            reportsByDocumentStatus,
            approvedReportsByModality,
            reportsByDate,
            reportsByModalityAndInst,
            studyStatusByPriorityAndDocument,
            reportsByWeek,
            instituteWeeklyPerform,
            reportsByUserAndModal,
            userWeeklyPerform,
            modalityWiseAverageTATs,
            radiologistData,
            fetchStudyCount,
            fetchRadiologistActiveListData,
            fetchReportsByPriority,
            fetchReportsByModality,
            fetchReportsByDocumentStatus,
            fetchApprovedReportsByModality,
            fetchReportsByDate,
            fetchReportsByModalityAndInst,
            fetchStudyStatusByPriorityAndDocument,
            fetchReportsByWeek,
            fetchReportsByUserAndModal,
            fetchUserWeeklyPerform,
            fetchInstituteWeeklyPerform,
            fetchModalityWiseAverageTATs,
            startDate,
            setStartDate,
            endDate,
            setEndDate,
        }),
        [
            radiologistData,
            studyCount,
            reportsByPriority,
            reportsByModality,
            reportsByDocumentStatus,
            approvedReportsByModality,
            reportsByDate,
            reportsByModalityAndInst,
            studyStatusByPriorityAndDocument,
            reportsByWeek,
            reportsByUserAndModal,
            instituteWeeklyPerform,
            userWeeklyPerform,
            modalityWiseAverageTATs,
            startDate,
            endDate,
        ]
    );

    return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};

export const useAnalytics = () => useContext(AnalyticsContext);
