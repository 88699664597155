import React, { useEffect, useState } from 'react';
import Label from '../Label';
import Input from '../Input';
import Button from '../Button';
import Icon from '../Icon';
import './Physician.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import Select from '../Select';
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  GetState,
  GetCity
} from "react-country-state-city";
import PhoneInput from 'react-phone-input-2';

const PhysicianCreateModel = ({
  user,
  editPhysicianUserData,
  createUser,
  updateUser,
  hide,
  radiologyGroupList,
  addRadiologyGroupMember,
  deleteRadiologyGroupMember,
  fetchUsers,
  setUsersList,
  countriesList,
}) => {
  const { show } = useSnackbar();

  const initialValues = {
    firstName: editPhysicianUserData?.firstName || '',
    lastName: editPhysicianUserData?.lastName || '',
    username: editPhysicianUserData?.username || '',
    email: editPhysicianUserData?.email || '',
    password: editPhysicianUserData?.password || '',
    confirmPassword: editPhysicianUserData?.password || '',
    // mobileNumber: editPhysicianUserData?.attributes?.mobileNumber || '',
    radiologyGroup: editPhysicianUserData?.attributes?.radiologyGroup || [],
    street: editPhysicianUserData?.attributes?.userStreet || '',
    district: editPhysicianUserData?.attributes?.userDistrict || '',
    cityName: (editPhysicianUserData?.attributes?.userCityid || [0]).map(Number) || '',
    stateName: (editPhysicianUserData?.attributes?.userStateid || [0]).map(Number) || '',
    countryName: (editPhysicianUserData?.attributes?.userCountryid || [0]).map(Number) || '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    username: Yup.string().required('User Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: !editPhysicianUserData ? Yup.string().required('Password is required') : Yup.string(),
    confirmPassword: !editPhysicianUserData
      ? Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
      : Yup.string(),
    // mobileNumber: Yup.number().required('Mobile Number is required'),
    radiologyGroup: Yup.array().required('Select at least one Radiology Group is required'),
    // street: Yup.string().required('street is required'),
    // district: Yup.string().required('district is required'),
  });

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    const countryid = (editPhysicianUserData?.attributes?.userCountryid || [0]).map(Number);
    const stateid = (editPhysicianUserData?.attributes?.userStateid || [0]).map(Number);

    GetState(countryid[0]).then((result) => {
      setStateList(result);
    });
    GetCity(countryid[0], stateid[0]).then((result) => {
      setCityList(result);
    });
  }, [])

  const options = radiologyGroupList.map(group => ({
    label: group.name,
    value: group.name,
  }));

  const initialSelectedOptions = editPhysicianUserData?.attributes?.radiologyGroup || [];
  const initialSelectedCountry = (editPhysicianUserData?.attributes?.userCountryid || [0]).map(Number);
  const initialSelectedState = (editPhysicianUserData?.attributes?.userStateid || [0]).map(Number);
  const initialSelectedCities = (editPhysicianUserData?.attributes?.userCityid || [0]).map(Number);
  const [selectedOptions, setSelectedOptions] = useState(
    initialSelectedOptions.flatMap(option => option.split(', '))
  );
  const [passwordeye, setpasswordeye] = useState(false);
  const [confirmPassword, setConfirmPasswordeye] = useState(false);
  const [error, setError] = useState(null);
  const [country, setCountry] = useState(initialSelectedCountry);
  const [state, setstate] = useState(initialSelectedState);
  const [city, setcity] = useState(initialSelectedCities);

  const [phoneNuber, setPhoneNuber] = useState(editPhysicianUserData?.attributes?.mobileNumber[0] || '')
  const handleSelectionChange = selected => {
    setSelectedOptions(selected);
  };

  const handleSubmitPhysicianUser = async (values, { setSubmitting }) => {
    try {
      if (editPhysicianUserData) {
        const physicianUserDetail = {
          username: values.username,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          attributes: {
            radiologyGroup: selectedOptions.label || selectedOptions[0],
            roleType: ['Physician'],
            mobileNumber: phoneNuber,
            userStreet: values.street === initialValues.street ? values.street : [values.street],
            userDistrict: values.district === initialValues.district ? values.district : [values.district],
            userCityid: city[0]?.id ? [city[0]?.id] : [city[0]],
            userStateid: state[0]?.id ? [state[0]?.id] : [state[0]],
            userCountryid: country[0]?.id ? [country[0]?.id] : [country[0]],
            userCityName: city[0]?.name ? [city[0]?.name] : editPhysicianUserData?.attributes?.userCityName,
            userStateName: state[0]?.name ? [state[0]?.name] : editPhysicianUserData?.attributes?.userStateName,
            userCountryName: country[0]?.name ? [country[0]?.name] : editPhysicianUserData?.attributes?.userCountryName,

          },
        };
        const response = await updateUser(
          editPhysicianUserData.id,
          physicianUserDetail,
          user.access_token
        );
        if (response.status === 204) {
          await fetchUsers(user.access_token)
            .then(data => setUsersList(data))
            .catch(error => console.error('Error fetching users:', error));
          show({
            title: 'Success!',
            message: 'Successfully Physician User Update',
            type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
            position: 'topRight',
          });
          hide();
        }
        const findGroup = radiologyGroupList.find(
          group => group.name === initialValues.radiologyGroup[0]
        );
        const findCurrentGroup = radiologyGroupList.find(
          group => group.name === selectedOptions.label
        );

        if (findCurrentGroup && findGroup.id !== findCurrentGroup.id) {
          addRadiologyGroupMember(editPhysicianUserData.id, findCurrentGroup.id, user.access_token);
          deleteRadiologyGroupMember(editPhysicianUserData.id, findGroup.id, user.access_token);
        }
      } else {
        const data = {
          username: values.username,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          attributes: {
            radiologyGroup: selectedOptions.label,
            roleType: ['Physician'],
            mobileNumber: phoneNuber,
            userStreet: values.street === initialValues.street ? values.street : [values.street],
            userDistrict: values.district === initialValues.district ? values.district : [values.district],
            userCityid: city[0]?.id && [city[0]?.id],
            userStateid: state[0]?.id && [state[0]?.id],
            userCountryid: country[0]?.id && [country[0]?.id],
            userCityName: city[0]?.name && [city[0]?.name],
            userStateName: state[0]?.name && [state[0]?.name],
            userCountryName: country[0]?.name && [country[0]?.name],

          },
          credentials: [{ type: 'password', temporary: true, value: values.password }],
          enabled: true,
          emailVerified: true,
        };
        const response = await createUser(data, user.access_token);
        if (response.status === 201) {
          await fetchUsers(user.access_token)
            .then(async data => {
              setUsersList(data);
              const findeUser = data.find(items => items.email === values.email);
              const findGroup = radiologyGroupList.find(
                group => group.name === selectedOptions.label
              );

              await addRadiologyGroupMember(findeUser.id, findGroup.id, user.access_token);
            })
            .catch(error => console.error('Error fetching users:', error));
          show({
            title: 'Success!',
            message: 'Successfully Physician User Created',
            type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
            position: 'topRight',
          });
          hide();
        } else {
          const errorMessage = await response.json();
          setError(errorMessage.errorMessage);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const setFieldValue = (e, value, name) => {

    if (name === 'mobileNumber') {
      let dialCode = value?.dialCode || '';
      let phoneNumber = e;
      let moblieNumber

      if (phoneNumber.startsWith(dialCode)) {
        // Remove the dial code from the phone number
        phoneNumber = phoneNumber.substring(dialCode.length);
        moblieNumber = `+${dialCode}${phoneNumber}`
      }

      setPhoneNuber(moblieNumber);
    }
  }

  return (
    <section className="w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitPhysicianUser}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2">
              <div>
                <Label className="my-2 block">First Name</Label>
                <Field
                  type="text"
                  name="firstName"
                  as={Input}
                  placeholder="Please enter your first name"
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block">Last Name</Label>
                <Field
                  type="text"
                  name="lastName"
                  as={Input}
                  placeholder="Please enter your last name"
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block">User Name</Label>
                <Field
                  type="text"
                  name="username"
                  as={Input}
                  placeholder="Please enter your user name"
                  disabled={editPhysicianUserData ? true : false}
                />
                <ErrorMessage
                  name="username"
                  component="div"
                  className="text-red-600"
                />
              </div>
              {/* <div>
                <Label className="my-2 block">Mobile Number</Label>
                <Field
                  type="text"
                  name="mobileNumber"
                  as={Input}
                  placeholder="Please enter your mobile number"
                />
                <ErrorMessage
                  name="mobileNumber"
                  component="div"
                  className="text-red-600"
                />
              </div> */}
              <div className=' w-full'>
                <Label className='my-2 block' text=''>Mobile Number</Label>
                <PhoneInput
                  country={"us"}
                  value={editPhysicianUserData?.attributes?.mobileNumber[0]}
                  onChange={(e, phone) => setFieldValue(e, phone, "mobileNumber")}
                  inputStyle={{ width: '100%', marginTop: '0.5rem', fontSize: '1.125rem', color: 'white', backgroundColor: 'black', borderColor: `rgb(58 63 153)` }}
                  containerStyle={{ width: '100%' }}
                />
              </div>
              <div>
                <Label className="my-2 block">Email</Label>
                <Field
                  type="text"
                  name="email"
                  as={Input}
                  placeholder="Please enter your email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="passworld_box">
                <Label className="my-2 block">Password</Label>
                <Field
                  type={passwordeye ? 'text' : 'password'}
                  name="password"
                  as={Input}
                  placeholder="Please enter your password"
                />
                <Icon
                  onClick={() => setpasswordeye(!passwordeye)}
                  name={passwordeye ? 'eye-visible' : 'eye-hidden'}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="passworld_box">
                <Label className="my-2 block">Confirm Password</Label>
                <Field
                  type={confirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  as={Input}
                  placeholder="Please enter your confirm password"
                />
                <Icon
                  onClick={() => setConfirmPasswordeye(!confirmPassword)}
                  name={confirmPassword ? 'eye-visible' : 'eye-hidden'}
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block"> Radiology Group</Label>
                <Field
                  name="radiologyGroup"
                  as={Select}
                  placeholder="Select Radiology Group"
                  options={options}
                  value={selectedOptions || editPhysicianUserData?.attributes?.radiologyGroup}
                  onChange={handleSelectionChange}
                />

                <ErrorMessage
                  name="radiologyGroup"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block" text=""> Country</Label>
                <CountrySelect
                  onChange={(e) => {
                    setCountry([e]);
                  }}
                  defaultValue={country[0]?.id ? country[0] : countriesList.find((item) => item.id === initialSelectedCountry[0])}
                  placeHolder="Select Country"
                />
                <ErrorMessage
                  name="countryName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block" text=""> State</Label>
                <StateSelect
                  countryid={country[0]?.id ? country[0].id : country[0]}
                  onChange={(e) => {
                    setstate([e]);
                  }}
                  defaultValue={state[0].id ? state[0] : stateList?.find((item) => item.id === initialSelectedState[0])}
                  placeHolder="Select State"
                />
                <ErrorMessage
                  name="stateName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block" text=""> City</Label>
                <CitySelect
                  stateid={state[0].id ? state[0].id : state[0]}
                  countryid={country[0]?.id ? country[0].id : country[0]}
                  onChange={(e) => {
                    setcity([e])
                  }}
                  defaultValue={city[0].id ? city[0] : cityList?.find((item) => item.id === initialSelectedCities[0])}
                  placeHolder="Select City"
                />
                <ErrorMessage
                  name="stateName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text={''}>District</Label>
                <Field type="text" name="district" as={Input} placeholder="Please enter your district" />
                <ErrorMessage name="district" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block" text={''}>Street</Label>
                <Field type="text" name="street" as={Input} placeholder="Please enter your street" />
                <ErrorMessage name="street" component="div" className="text-red-600" />
              </div>
            </div>
            <div className="flex">
              <Button
                className="mt-8"
                disabled={isSubmitting}
              >
                {editPhysicianUserData ? 'Update Physician User' : 'Create Physician User'}
              </Button>
              <Label className="my-2 mt-9 block h-3 pl-3 text-red-600	">{error}</Label>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default PhysicianCreateModel;
