import React from 'react';
import Button from '../Button';


const ReportStatusModal = ({ hide, studyInstanceUid, handleSelectReportsStatusChanges, patientId, accession }) => {
  const handleButtonClick = (reportStatus) => {

    handleSelectReportsStatusChanges({ reportStatus, studyInstanceUid, patientId, accession });
    hide();
  };

  return (
    <div>
      <div className='flex justify-center mb-4'>Change the Report Status</div>
      <div className='flex justify-center mb-4 gap-3'>
        <Button disabled onClick={() => handleButtonClick('Draft')}>Draft</Button>
        <Button disabled onClick={() => handleButtonClick('Final')}>Final</Button>
        <Button onClick={() => handleButtonClick('Approved')}>Approve</Button>
      </div>
    </div>
  );
};


export default ReportStatusModal;
