import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import {
  createUser,
  fetchUsers,
  fetchUsersPagination,
  deleteUser,
  updateUserPassword,
  userToken,
  updateUser,
  createWorklistAttributes,
  fetchWorklistAttributes,
  updateWorklistAttributes,
  fetchStudyList,
  deleteStudies,
  uploadStudy,
  anonymizeStudies,
  anonymizeStudiesDeleteOrigin,
  fetchSessionsRadiologistUser,
} from '../requestHandler/userRequest';

export const UserContext = createContext(null);

export const UserProvider = ({ children, service, extension }) => {
  // const [usersList, setUsersList] = useState('');
  const [token, setToken] = useState('');
  const [worklistAttributesList, setWorklistAttributesList] = useState('');
  const [allStudyList, setAllStudyList] = useState([]);
  const [isDeleteData, setIsDeleteData] = useState();
  const [isPostData, setIsPostData] = useState();
  const [usersList, setUsersList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultPerPage] = useState(10)

  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState([]);


  const accessToken = sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_KEYCLOAK_AUTHORIZATION_URL}`
  );
  const userData = JSON.parse(accessToken);
  const accessTokens = userData?.access_token;

  const fetchPaginatedUsers = async (page, searchQuery) => {
    try {
      const response = await fetchUsersPagination(accessTokens, page, resultsPerPage, searchQuery);
      setResults(response);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    if(accessToken){
      fetchUsers(accessTokens)
      .then(data => setUsersList(data))
      .catch(error => console.error('Error fetching users:', error));
    }
  }, [accessTokens]);

  // useEffect(() => {
  //   if (searchQuery) { // Only fetch if there's a search query
  //     fetchSearchUsers(accessTokens, searchQuery)
  //       .then(data => setResults(data)) // Set the results based on the fetched data
  //       .catch(error => console.error('Error fetching Search users:', error));
  //   } else {
  //     setResults([]); // Reset results if the search query is empty
  //   }
  // }, [searchQuery]);

  useEffect(() => {
    if (accessTokens) {
      fetchPaginatedUsers(currentPage, searchQuery);
    }
  }, [currentPage, resultsPerPage, searchQuery, accessTokens]);

  useEffect(() => {
    fetchWorklistAttributes()
      .then(data => setWorklistAttributesList(data))
      .catch(error => console.error('Error fetching users:', error));
  }, [accessTokens]);

  useEffect(() => {
    fetchStudyList()
      .then(data => setAllStudyList(data))
      .catch(error => console.error('Error fetching study list:', error));
  }, []);

  const value = useMemo(
    () => ({
      usersList,
      currentPage,
      setCurrentPage,
      resultsPerPage,
      setResultPerPage,
      searchQuery,
      setSearchQuery,
      results,
      deleteUser,
      updateUserPassword,
      createUser,
      setUsersList,
      setResults,
      fetchUsers,
      fetchPaginatedUsers,
      userToken,
      token,
      setToken,
      createWorklistAttributes,
      fetchWorklistAttributes,
      worklistAttributesList,
      updateWorklistAttributes,
      setWorklistAttributesList,
      updateUser,
      allStudyList,
      setAllStudyList,
      deleteStudies,
      uploadStudy,
      anonymizeStudies,
      anonymizeStudiesDeleteOrigin,
      fetchSessionsRadiologistUser,
      isDeleteData,
      setIsDeleteData,
      setIsPostData,
      isPostData
    }),
    [usersList, token, worklistAttributesList, allStudyList, results]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);
