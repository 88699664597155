// Define keyword groups and their corresponding backend URLs
const modelKeywords = {
    modelCXR: ['CXR', 'CR', 'DX', 'X-ray', 'x-ray', 'Xray', 'xray', 'Chest'],
    modelBreastCancer: ['MG', 'CR', 'DX', 'Mammogram', 'Mammo', 'Tomo', 'breast'],
    modelSpineAI: ['MR', 'cervical', 'thoracic', 'lumbar', 'Spine'],
    modelBrainStroke: ['CT', 'brain', 'stroke', 'ischemic', 'embolism', 'thrombosis', 'arterial dissection', 'cerebral hypoxia'],
    modelBoneFracture: [
        'CR', 'DX', 'hand', 'shoulder', 'elbow', 'knee', 'wrist', 'finger', 'fracture', 'bone', 'toe',
        'leg', 'arm', 'tibia', 'fibula', 'femur', 'ankle', 'rib', 'msk', 'musculoskeletal', 'radius',
        'ulna', 'thumb', 'elbow', 'humerus'
    ],
};


export const SendtoML = async (modality, description, uuid) => {
    console.log(`test`, modality, description)
    if (!uuid) {
        console.error('UUID is missing');
        return null;
    }

    // Map models to their corresponding URLs
    const modelUrls = {
        modelBreastCancer: process.env.REACT_APP_MODEL_1_URL,
        modelSpineAI: process.env.REACT_APP_MODEL_2_URL,
        modelBoneFracture: process.env.REACT_APP_MODEL_3_URL,
        modelBrainStroke: process.env.REACT_APP_MODEL_4_URL,
        modelCXR: process.env.REACT_APP_MODEL_5_URL,
    };

    // Determine which model URL to use based on the most keyword matches
    let selectedModel = null;
    let maxMatches = 0;

    // Loop through all the model keywords and count the number of matches
    Object.keys(modelKeywords).forEach(modelKey => {
        const keywords = modelKeywords[modelKey];
        let matchCount = 0;

        // Count how many keywords match between modalities/description
        keywords.forEach(keyword => {
            if ((modality || '').toLowerCase().includes(keyword.toLowerCase()) ||
                (description || '').toLowerCase().includes(keyword.toLowerCase())) {
                matchCount++;
            }
        });

        // Select the model only if at least 2 keywords match
        if (matchCount >= 2 && matchCount > maxMatches) {
            maxMatches = matchCount;
            selectedModel = modelUrls[modelKey];
        }
    });

    // Default to a generic model if no keywords matched
    const modelUrl = selectedModel || `${process.env.REACT_APP_DEFAULT_MODEL_URL}`;

    try {
        const response = await fetch(modelUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ uuid }), // Sending UUID in the request body
        });

        if (!response.ok) {
            throw new Error(`Failed to send to AI: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error in SendtoML:', error);
        throw error;
    }
};


export const saveAiResultsToBackend = async (result, uuid, studyInstanceUid, modality, description) => {
    try {

        // Map models to their corresponding backend URLs
        const backendUrls = {
            modelCXR: `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/chestm1`,
            modelBreastCancer: `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/breastcancerm1`,
            modelSpineAI: `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/spinem1`,
            modelBrainStroke: `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/brainstrokem1`,
            modelBoneFracture: `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/bonefracturem1`,
        };

        // Determine which backend URL to use based on the most keyword matches
        let selectedBackend = null;
        let maxMatches = 0;

        // Loop through all the model keywords and count the number of matches
        Object.keys(modelKeywords).forEach(modelKey => {
            const keywords = modelKeywords[modelKey];
            let matchCount = 0;

            // Count how many keywords match between modalities/description
            keywords.forEach(keyword => {
                if ((modality || '').toLowerCase().includes(keyword.toLowerCase()) ||
                    (description || '').toLowerCase().includes(keyword.toLowerCase())) {
                    matchCount++;
                }
            });

            // Select the backend only if at least 2 keywords match
            if (matchCount >= 2 && matchCount > maxMatches) {
                maxMatches = matchCount;
                selectedBackend = backendUrls[modelKey];
            }
        });

        // Default to a generic backend if no keywords matched
        const backendUrl = selectedBackend || `${process.env.REACT_APP_DEFAULT_MODEL_URL}`;

        // Perform the API request to save the AI results
        const response = await fetch(backendUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                uuid,
                studyinstanceuid: studyInstanceUid,
                response: result,
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to save AI results: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error in saveAiResultsToBackend:', error);
        throw error;
    }
};
