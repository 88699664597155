import React, { useState } from 'react';
import Label from '../Label';
import Input from '../Input';
import Button from '../Button';
import Icon from '../Icon';
import InputMultiSelect from '../InputMultiSelect';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';

const RadiologistGroupModel = ({ user, data, createRadiologyGroup, fetchRadiologyGroup, updateRadiologyGroup, setRadiologyGroupList, fetchSingleRadiologyGroup, singleRadiologyGroupList, setSingleRadiologyGroupList, groupIds, radiologyGroupList, hide }) => {
  const { show } = useSnackbar();

  const initialValues = {
    radiologyGroupName: data.name || '',
    email: data?.attributes?.groupEmail || '',
    mobileNumber: data?.attributes?.groupMobileNumber || '',
    aeTitle: data?.attributes?.groupAETitle || '',
    ip: data?.attributes?.groupIP || '',
    portNumber: data?.attributes?.groupPortNumber || '',
    address: data?.attributes?.groupAddress || '',
  };


  // const validationSchema = Yup.object().shape({
  //   radiologyGroupName: Yup.string().required('Radiology group name is required'),
  //   email: Yup.string().email('Invalid email').required('Email is required'),
  //   mobileNumber: Yup.number().required('Mobile number is required'),
  //   aeTitle: Yup.array().required('AE Title is required'),
  //   ip: Yup.array().required('IP address is required'),
  //   portNumber: Yup.array().required('Port number is required'),
  //   address: Yup.array().required('Address is required'),
  // });

  const [passwordeye, setpasswordeye] = useState(false);
  const [confirmPassword, setConfirmPasswordeye] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {

      if (data) {

        const dataDetails = {
          name: values.radiologyGroupName,
          attributes: {
            groupEmail: values.email === initialValues.email ? values.email : [values.email],
            groupMobileNumber:
              values.mobileNumber === initialValues.mobileNumber
                ? values.mobileNumber
                : [values.mobileNumber],
            groupAETitle:
              values.aeTitle === initialValues.aeTitle ? values.aeTitle : [values.aeTitle],
            groupAddress:
              values.address === initialValues.address ? values.address : [values.address],
            groupPortNumber:
              values.portNumber === initialValues.portNumber
                ? values.portNumber
                : [values.portNumber],
            groupIP: values.ip === initialValues.ip ? values.ip : [values.ip],
          },

        }
        const response = await updateRadiologyGroup(data.id, dataDetails, user.access_token)
        if (response.status === 204) {
          await fetchSingleRadiologyGroup(user.access_token, groupIds)
            .then(data => setSingleRadiologyGroupList(data))
            .catch(error => console.error('Error fetching users:', error));

          await fetchRadiologyGroup(user.access_token)
            .then(data => setRadiologyGroupList(data))
            .catch(error => console.error('Error fetching users:', error));
          show({
            title: "Success!",
            message: "Successfully Radiology Group Update",
            type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
            position: "topRight"
          });
        }
        hide()
      } else {
        const dataDetails = {
          name: values.radiologyGroupName,
          attributes: {
            groupEmail: values.email === initialValues.email ? values.email : [values.email],
            groupMobileNumber:
              values.mobileNumber === initialValues.mobileNumber
                ? values.mobileNumber
                : [values.mobileNumber],
            groupAETitle:
              values.aeTitle === initialValues.aeTitle ? values.aeTitle : [values.aeTitle],
            groupAddress:
              values.address === initialValues.address ? values.address : [values.address],
            groupPortNumber:
              values.portNumber === initialValues.portNumber
                ? values.portNumber
                : [values.portNumber],
            groupIP: values.ip === initialValues.ip ? values.ip : [values.ip],
          },
        }
        const response = await createRadiologyGroup(dataDetails, user.access_token)
        if (response.status === 201) {
          let filteredGroupIds
          await fetchRadiologyGroup(user.access_token)
            .then((data) => {
              filteredGroupIds = data
                .filter(group => !group.error)
                .map(group => group.id);
              setRadiologyGroupList(data)
            })
            .catch(error => console.error('Error fetching users:', error));

          await fetchSingleRadiologyGroup(user.access_token, filteredGroupIds)
            .then(data => setSingleRadiologyGroupList(data))
            .catch(error => console.error('Error fetching users:', error));
          show({
            title: "Success!",
            message: "Successfully Radiology Group Created",
            type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
            position: "topRight"
          });
        }
        hide()
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };


  return (
    <section className="w-full">

      <Formik initialValues={initialValues}  onSubmit={handleSubmit} >
        {({ isSubmitting }) => (
          <Form>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label className="my-2 block" text={''}>Radiology Group Name</Label>
                <Field type="text" name="radiologyGroupName" as={Input} placeholder="Please enter your radiology group name" />
                <ErrorMessage name="radiologyGroupName" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block" text={''}>Email</Label>
                <Field type="text" name="email" as={Input} placeholder="Please enter email" />
                <ErrorMessage name="email" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block" text={''}>Mobile Number</Label>
                <Field type="text" name="mobileNumber" as={Input} placeholder="Please enter mobile number" />
                <ErrorMessage name="mobileNumber" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block" text={''}>AE Title</Label>
                <Field type="text" name="aeTitle" as={Input} placeholder="Please enter your AE title" />
                <ErrorMessage name="aeTitle" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block" text={''}>IP</Label>
                <Field type="text" name="ip" as={Input} placeholder="Please enter your IP" />
                <ErrorMessage name="ip" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block" text={''}>Port Number</Label>
                <Field type="text" name="portNumber" as={Input} placeholder="Please enter your port number" />
                <ErrorMessage name="portNumber" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block" text={''}>Address</Label>
                <Field type="text" name="address" as={Input} placeholder="Please enter your address" />
                <ErrorMessage name="address" component="div" className="text-red-600" />
              </div>
            </div>
            <div className="flex">
              <Button className="mt-8" disabled={isSubmitting}>
                {data ? 'Update Radiology  Group' : 'Create Radiology Group'}
              </Button>
              <Label className="my-2 block h-3 text-red-600 mt-9 pl-3	" text={''}>{error}</Label>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default RadiologistGroupModel;
