import React, { useEffect, useState } from 'react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import './ReportTemplate.css';
import Button from '../Button';


const CustomTemplates = ({ templates, setEditorData, dataTemplate }) => {
  useEffect(() => {
    const editorElement = document.querySelector('#editor') as HTMLElement;
    if (!editorElement || !templates) return;
    DecoupledEditor.create(editorElement, {
      toolbar: {
        items: [
          'heading',
          '|',
          'alignment',
          'bold',
          'italic',
          'underline',
          'fontFamily',
          'fontSize',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'bulletedList',
          'numberedList',
          'insertTable',
          '|',
          'undo',
          'redo',
        ],
      },
      language: 'en',
    })
      .then(editor => {
        const toolbarContainer = document.querySelector('#toolbar-container');
        if (toolbarContainer) {
          toolbarContainer.appendChild(editor.ui.view.toolbar.element);
        }

        if (dataTemplate) {
          editor.setData(dataTemplate);
        }
        editor.model.document.on('change:data', (event, data) => {
          setEditorData(editor.getData());
        });
      })
      .catch(error => {
        console.error(error);
      });
  }, [templates]);

  return (
    <>
      <div className="mt-4">
        <div id="toolbar-container"></div>
        <div
          id="editor"
          className="bg-white text-black"
        ></div>
      </div>
    </>
  );
};

export default CustomTemplates;
