import React, { useEffect, useRef, useState, useCallback } from 'react';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Handlebars from 'handlebars';
import moment from 'moment';
import { useSearchParams } from '@ohif/app/src/hooks';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { useDocumentEditor, useModal, useReportEditor, useSnackbar, useUser } from '../../contextProviders';
import './ReportEditor.css';
import Select from '../Select';
import Button from '../Button';
import Icon from '../Icon';
import AddAttachmentModel from '../AttachMent';
import studyAndModality from './study_modality_list';
import html2canvas from 'html2canvas';
import '@ckeditor/ckeditor5-build-decoupled-document/build/ckeditor.js';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import debounce from 'lodash/debounce';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { FaMicrophone, FaMicrophoneSlash, FaNotesMedical } from "react-icons/fa";
import { HiMiniDocumentPlus } from 'react-icons/hi2';
import { GrDocumentUpdate } from "react-icons/gr";
import Tooltip from '../Tooltip';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import { useNavigate } from 'react-router-dom';


interface Props {
  templateValue: string;
  availableReportTemplates: any;
  onChangeHandler: (data: string) => void;
  servicesManager: any;
  reportSettingDetails: any;
  radiologistUserList: any;
  ViewportGridComp: any;
  commandsManager: any;
  viewportComponents: any;
}

const ReportEditor: React.FC<Props> = props => {
  const [patientData, setPatientData] = React.useState(null);
  const [patientReportDetail, setPatientReportDetail] = React.useState(null);
  const [editorData, setEditorData] = React.useState(null);
  const [template, setTemplate] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [editorData1, setEditorData1] = useState('');
  const [imageDataUrl, setImageDataUrl] = useState('');
  const [isMic, setIsMic] = useState(false);
  const query = useSearchParams();
  const params = useLocation();
  const { show, hide } = useModal();
  const [enableListening, setEnableListening] = useState('')
  const { show: display } = useSnackbar();


  const commands = [
    {
      command: 'delete',
      callback: () => {
        if (window.confirm('Are you sure you want to delete all report data?')) {
          setSaveReports('');
          SpeechRecognition.stopListening();
          resetTranscript();
          localStorage.clear();
          setIsAutoListening(false);
        } else {
          SpeechRecognition.stopListening();
          resetTranscript();
        }
      },
    },
    {
      command: 'next line',
      callback: () => {
        stopListening();
        if (isAutoListening) {
          setTimeout(startListening, 500);
        }
      },
    },
    {
      command: 'title *',
      callback: title => {
        let upperCaseText = title.toUpperCase();
        const newValue =
          saveReports +
          `<h1 class="ql-align-center"><strong><u>${upperCaseText}</u></strong></h1><br/>`;

        console.log(newValue, 'newValuenewValue');

        setSaveReports(newValue);
        // localStorage.setItem(`test_transcript`, newValue);
        stopCommand();
        if (isAutoListening) {
          setTimeout(startListening, 500);
        }
      },
    },

    {
      command: 'history',
      callback: () => {
        stopListening();
        const newValue = saveReports + '<br/><h2><strong>CLINICAL HISTORY: </strong></h2>';
        setSaveReports(newValue);
        localStorage.setItem(`test_transcript`, newValue);
        if (isAutoListening) {
          setTimeout(startListening, 500);
        }
      },
    },

    {
      command: 'technique',
      callback: () => {
        stopListening();
        const newValue = saveReports + '<br/><h2><strong>TECHNIQUE: </strong></h2>';
        setSaveReports(newValue);
        localStorage.setItem(`test_transcript`, newValue);
        if (isAutoListening) {
          setTimeout(startListening, 500);
        }
      },
    },

    {
      command: 'findings',
      callback: () => {
        stopListening();
        const newValue = saveReports + '<br/><h2><strong>FINDINGS: </strong></h2>';
        setSaveReports(newValue);
        localStorage.setItem(`test_transcript`, newValue);
        if (isAutoListening) {
          setTimeout(startListening, 500);
        }
      },
    },

    {
      command: 'impression',
      callback: () => {
        stopListening();
        const newValue = saveReports + '<br/><h2><strong>IMPRESSION: </strong></h2>';
        setSaveReports(newValue);
        localStorage.setItem(`test_transcript`, newValue);
        if (isAutoListening) {
          setTimeout(startListening, 500);
        }
      },
    },

    {
      command: 'advice',
      callback: () => {
        stopListening();
        const newValue = saveReports + '<br/><h2><strong>ADVICE: </strong></h2>';
        setSaveReports(newValue);
        localStorage.setItem(`test_transcript`, newValue);
        if (isAutoListening) {
          setTimeout(startListening, 500);
        }
      },
    },

    {
      command: 'underline *',
      callback: underline => {
        const newValue = saveReports + `<u>${underline}</u>. `;
        setSaveReports(newValue);
        localStorage.setItem(`test_transcript`, newValue);
        stopCommand();
        if (isAutoListening) {
          setTimeout(startListening, 500);
        }
      },
    },
    {
      command: 'bold face *',
      callback: point => {
        const newValue = saveReports + `<strong>${point}</strong>. `;
        setSaveReports(newValue);
        localStorage.setItem(`test_transcript`, newValue);
        stopCommand();
        if (isAutoListening) {
          setTimeout(startListening, 500);
        }
      },
    },
  ];

  const [saveReports, setSaveReports] = useState('');
  const [isAutoListening, setIsAutoListening] = useState(false);
  const [concurrentTemplate, setConcurrentTemplate] = useState(template);
  const [lastTranscriptLength, setLastTranscriptLength] = useState(0);

  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition({ commands });

  const transcriptRef = useRef(transcript);

  const navigate = useNavigate();

  useEffect(() => {
    if (editorData1?.editing?.view && !enableListening) {
      setConcurrentTemplate(editorData1.getData());
      setEnableListening('listening')
    }
  }, [listening, enableListening]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const startListening = () => {
    resetTranscript();
    setEnableListening('')
    SpeechRecognition.startListening({ continuous: true });
  };

  const stopListening = () => {
    SpeechRecognition.stopListening();
    setLastTranscriptLength(0);
    // resetTranscript();
  };

  const stopCommand = () => {
    SpeechRecognition.stopListening();
    resetTranscript();
  };

  if (listening && transcript.length > lastTranscriptLength) {
    transcriptRef.current = transcript;

    // Apply your modifications to the transcript

    let modifiedTranscript = transcript.replace(/( stop| full stop| period\s*)+/g, '. ');
    modifiedTranscript = modifiedTranscript.replace(/(next\s*line\s*)+/g, '');
    modifiedTranscript = modifiedTranscript.replace(/(comma\s*)+/g, ', ');
    modifiedTranscript = modifiedTranscript.replace(/(hyphen\s*)+/g, '-');
    modifiedTranscript = modifiedTranscript.replace(/( underscore\s*)+/g, '_');
    modifiedTranscript = modifiedTranscript.replace(/(colon\s*)+/g, ': ');
    modifiedTranscript = modifiedTranscript.replace(/(semicolon\s*)+/g, '; ');
    modifiedTranscript = modifiedTranscript.replace(/(question mark\s*)+/g, '? ');
    modifiedTranscript = modifiedTranscript.replace(/(plus\s*)+/g, '+');
    modifiedTranscript = modifiedTranscript.replace(/(minus\s*)+/g, '-');
    modifiedTranscript = modifiedTranscript.replace(/(multiply\s*)+/g, '*');
    modifiedTranscript = modifiedTranscript.replace(/(divide\s*)+/g, '/');
    modifiedTranscript = modifiedTranscript.charAt(0).toUpperCase() + modifiedTranscript.slice(1);

    // Append the new modified transcript to the previously saved transcript
    const finalTranscript = modifiedTranscript ? modifiedTranscript : saveReports

    // Update the saveReports with the combined transcript
    setSaveReports(finalTranscript);

    // Update lastTranscriptLength to keep track of the length of the transcript processed

    setLastTranscriptLength(transcript.length);

    // Save the final transcript to localStorage

    localStorage.setItem('test_transcript', finalTranscript);

  }


  const {
    defaultReportTemplates,
    reportEditorTemplate,
    setReportEditorTemplate,
    setToggleDisplayReportEditor,
    getCurrentPatient,
    createPatientReports,
    patientReportsDetails,
    generateReportPdf,
    updatePatientReports,
    setPatientReportsDetails,
  } = useReportEditor();

  const {
    documentUploadDetails,
    createDocument,
    updateDocument,
    deleteDocumentUrl,
    setDocumentUploadDetails,
  } = useDocumentEditor();

  const { usersList, allStudyList, token, setToken, userToken } = useUser();

  const { t } = useTranslation();

  useEffect(() => {
    const getToken = async () => {
      try {
        const data = {
          token: user.access_token,
        };
        const response = await userToken(data);
        setToken(response);
      } catch (error) {
        console.log(error);
      }
    };
    getToken();
  }, []);

  const studyInstanceUid = params.pathname.includes('report-editor')
    ? params.pathname.split('report-editor/:')[1]
    : params?.search?.slice(params?.search?.indexOf('StudyInstanceUIDs=') + 'StudyInstanceUIDs='.length).split('&')[0];

  const isNewTab = params.pathname.includes('report-editor');

  const fetchPatientData = async () => {
    const data = await getCurrentPatient(params, query, studyInstanceUid);

    const patient = await patientReportsDetails?.find(
      items => items.patient_id === data[0]?.patientId
    );

    const priorityData = patientReportsDetails.find(data => data.study_UIDs === studyInstanceUid);
    const studyList = allStudyList.find(
      data => data.MainDicomTags.StudyInstanceUID === studyInstanceUid
    );

    setPatientReportDetail(patient);

    if (Array.isArray(data) && data.length) {
      const age = data[0]?.age || data[0]?.patientName?.match(/\d/g)?.join('');
      const [name] = data[0].patientName.split(age);
      let sex;

      if (data[0].patientSex.toLowerCase() === 'm') {
        sex = 'Male';
      } else {
        sex = 'Female';
      }
      const studyDate =
        data[0].date &&
        moment(data[0].date, ['YYYYMMDD', 'YYYY.MM.DD'], true).isValid() &&
        moment(data[0].date, ['YYYYMMDD', 'YYYY.MM.DD']).format(
          t('Common:localDateFormat', 'MMM-DD-YYYY')
        );
      const studyTime =
        data[0].time &&
        moment(data[0].time, ['HH', 'HHmm', 'HHmmss', 'HHmmss.SSS']).isValid() &&
        moment(data[0].time, ['HH', 'HHmm', 'HHmmss', 'HHmmss.SSS']).format(
          t('Common:localTimeFormat', 'hh:mm A')
        );

      if (patient?.reportdetails !== null && patient?.reportdetails !== undefined) {
        setPatientData(patient);
      } else {
        setPatientData({
          patient_name: name,
          patient_age: age,
          patient_gender: sex,
          patient_accession: data[0].accession,
          patient_id: data[0].patientId,
          patient_modality: data[0].modalities,
          study: data[0].description,
          study_date: studyDate,
          study_time: studyTime,
          ref_physician: data[0].referringPhysician,
          ref_doctor: data[0].referringPhysician,
          accession_number: studyList.MainDicomTags.AccessionNumber,
          uid: data[0].studyInstanceUid,
          document_status: data[0].document_status,
          priority: priorityData?.study_priority || 'Routine',
          institution_name: studyList.MainDicomTags.InstitutionName,
          study_description: studyList.MainDicomTags.StudyDescription,
          patient_dob: moment(studyList?.PatientMainDicomTags?.PatientBirthDate).format(
            'MM/DD/YYYY'
          ),
          clinical_history: priorityData?.clinical_history,
          image_perview: imageDataUrl,
        });
      }
    }
  };

  React.useEffect(() => {
    fetchPatientData();
  }, []);

  const {
    availableReportTemplates = defaultReportTemplates,
    templateValue = reportEditorTemplate,
    onChangeHandler = setReportEditorTemplate,
    servicesManager,
    reportSettingDetails,
    radiologistUserList,
    ViewportGridComp,
    commandsManager,
    viewportComponents,
  } = props;

  const { userAuthenticationService, viewportGridService } = servicesManager.services;
  const { activeViewportId } = viewportGridService?.getState();

  const user = userAuthenticationService?.getUser();

  const username = user?.profile?.name;

  const loginUserData = usersList?.filter(data => data.id === user.profile.sub);

  const loginUseremplateName = loginUserData?.map(data => data?.attributes?.templates).flat();

  const loginUserTemplateOption = availableReportTemplates?.filter(data =>
    loginUseremplateName.some(dat => dat === data.name)
  );

  //permission
  const isAttachment = user?.profile?.roleType?.includes('Radiologist') ||
    user?.profile?.roleType?.includes('QaUsers') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const [selectedTemplateOptions, setSelectedTemplateOptions] = useState<any>();
  // filterData = priorityStudiesFilter.length > 0 ? priorityStudiesFilter : filterStudies;
  const templateOptions = loginUseremplateName.includes('Default')
    ? availableReportTemplates
    : loginUserTemplateOption;

  const [displayTemplateOptions, setDisplayTemplateOptions] = useState(() => {
    return templateOptions?.map(({ id, name, templates, isCapture }) => {
      return {
        id,
        label: name,
        value: templates,
        isCapture: isCapture,
      };
    });
  });

  // Set Default Reports
  useEffect(() => {
    // const fetchData = async () => {
    //   try {
    //     const studies = allStudyList.find(
    //       data => data.MainDicomTags.StudyInstanceUID === studyInstanceUid
    //     );
    //     const studyDescription = studies?.MainDicomTags?.StudyDescription;
    //     const modality = studies?.Modality;

    //     const patientTemplate = displayTemplateOptions?.find(
    //       option =>
    //         option.label === studyDescription &&
    //         studyAndModality.some(
    //           study => study.studyDescription === studyDescription && study.modality === modality
    //         )
    //     );
    //     // const defaultTemplate = displayTemplateOptions?.find(
    //     //   (option) => option.label === 'Default'
    //     // );
    //     // const defaultTemplate = displayTemplateOptions?.find(
    //     //   (option) => option.label
    //     // );
    //     const defaultTemplate = displayTemplateOptions[0];

    //     if (patientTemplate) {
    //       setSelectedTemplateOptions(patientTemplate);
    //     } else {
    //       setSelectedTemplateOptions(defaultTemplate);
    //     }
    //   } catch (error) {
    //     console.error('Failed to fetch data:', error);
    //   }
    // };

    // fetchData();

    const defaultTemplate = displayTemplateOptions?.find(option => option.label === 'Default Template');
    setSelectedTemplateOptions(defaultTemplate);
  }, [allStudyList]);

  const onSelectChange = selected => {
    setSelectedTemplateOptions(selected);
    setImageDataUrl('');
    setSaveReports('')
    stopListening()
  };

  const handleNewWindowOpen = () => {
    // closing the report editor pop-up
    setToggleDisplayReportEditor((show: boolean) => !show);
    // window features
    const strWindowFeatures = 'location=yes,height=800,width=1400,scrollbars=yes,status=yes';

    // opening the new window with custom features
    window.open(
      `${window.location.origin}/report-editor/:${studyInstanceUid}`,
      '_blank',
      strWindowFeatures
    );
  };

  const patientFind = patientReportsDetails?.find(item => item.study_UIDs === studyInstanceUid);
  const assignUserFind = patientFind?.assign?.map(item => JSON.parse(item));

  const assignUserDetail = assignUserFind?.find(
    item => item.assign_name === user?.profile?.preferred_username
  );

  const permissions = user?.profile?.permission

  const isPhysicianOrTechnologist = user?.profile?.roleType === 'Physician' || user?.profile?.roleType === 'Technologist';
  const canEditReport = permissions?.includes('Edit Report');
  const isQaUser = token?.realm_access?.roles.includes('qa-user');
  const isSuperAndDeputyAdmin = token?.realm_access?.roles.includes('super-admin') || token?.realm_access?.roles.includes('deputy-admin')
  const isApproved = patientReportDetail?.document_status === 'Approved'

  // attachment
  const handleAttachmentChange = async (studyInstanceUid, attachmentData) => {
    const data = documentUploadDetails?.find(item => item.study_UIDs === studyInstanceUid);

    if (!data) {
      await createDocument(studyInstanceUid, attachmentData, setDocumentUploadDetails);
    } else {
      await updateDocument(data.id, attachmentData, setDocumentUploadDetails);
    }
  };

  const handleAttachmentRemove = async (attachment, instance, studyInstanceUid) => {
    const updateDocumnet = attachment?.filter(item => item.attachment !== instance);
    const data = documentUploadDetails?.find(item => item.study_UIDs === studyInstanceUid);

    const pattern = /\d+-([^/]+)$/;
    // const pattern = /\/(\d+-([\w-]+\.pdf))$/;

    const removeDocumentName = instance.match(pattern);

    const resData = {
      ...data,
      updateData: updateDocumnet && updateDocumnet?.length > 0 ? updateDocumnet : null,
      removeDocument: removeDocumentName[0].replaceAll('/', ''),
    };

    await deleteDocumentUrl(data.id, resData, setDocumentUploadDetails);
  };

  const handleAttachment = (studyInstanceUid, patientName) => {
    show({
      content: AddAttachmentModel,
      title: t('ReportStatus:Attachment'),
      contentProps: {
        hide,
        studyInstanceUid,
        handleAttachmentChange,
        handleAttachmentRemove,
        documentUploadDetails,
        patientName
      },
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    // Function to handle submit after confirmation
    const handleConfirmation = () => {
      const oldData = patientReportsDetails?.find(item => item.study_UIDs === studyInstanceUid);
      const currentTime = new Date();

      const actionlog = 'SubmitLogs';

      const resData = {
        ...patientData,
        reportdetails: editorData,
        study_UIDs: studyInstanceUid,
        study_priority: patientReportDetail?.study_priority || null,
        isDrafted: false,
        document_status: 'Read',
        report_submit_time: currentTime,
        assign: assignUserDetail !== undefined ? [assignUserDetail] : null,
        radiologyGroup: user?.profile?.radiologyGroup,
        created_by: user?.profile?.preferred_username,
      };

      if (!oldData) {
        createPatientReports(resData, setPatientReportsDetails, username, actionlog).then(res => {
          if (res.status === 200) {
            toast.success('Your report has been successfully submitted');
            setTimeout(() => {
              navigate({ pathname: '/' });
            }, 1500);
          }
        });
      } else {
        updatePatientReports(oldData.id, resData, setPatientReportsDetails, username, actionlog).then(res => {
          if (res.status === 200) {
            toast.success('Your report has been successfully updated');
            setTimeout(() => {
              navigate({ pathname: '/' });
            }, 1500);
          }
        });
      }
    };

    // Show SweetAlert Confirmation Dialog
    Swal.fire({
      text: `Are you sure, you want to submit Report? Because after submit you can't edit it`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, submit it!',
    }).then(result => {
      if (result.isConfirmed) {
        handleConfirmation();
      }
    });
  };

  const handleDraft = event => {
    event.preventDefault();

    const oldData = patientReportsDetails?.find(item => item.study_UIDs === studyInstanceUid);

    const actionlog = 'DraftLogs'

    const resData = {
      ...patientData,
      reportdetails: editorData,
      study_UIDs: studyInstanceUid,
      study_priority: patientReportDetail?.study_priority || null,
      isDrafted: true,
      document_status: 'Read',
      assign: assignUserDetail !== undefined ? [assignUserDetail] : null,
      radiologyGroup: user?.profile?.radiologyGroup,
      created_by: user?.profile?.preferred_username,
    };

    if (!oldData) {
      createPatientReports(resData, setPatientReportsDetails, username, actionlog).then(res => {
        if (res.status === 200) {
          toast.success('Your report was saved as draft successfully');
        }
      });
    } else {
      updatePatientReports(oldData.id, resData, setPatientReportsDetails, username, actionlog).then(res => {
        if (res.status === 200) {
          toast.success('Your report has been successfully updated');
        }
      });
    }
  };

  const handlerCriticalToggle = async (studyInstanceUid, isCriticalSet, patientId, accession) => {
    const data = patientReportsDetails.find(item => item.study_UIDs === studyInstanceUid);

    const actionlog = 'CriticalLogs'

    if (!data) {
      const newData = {
        study_UIDs: studyInstanceUid,
        critical: isCriticalSet,
        patient_id: patientId,
        patient_accession: accession
      };
      await createPatientReports(newData, setPatientReportsDetails, username, actionlog);
    } else {

      const updatedData = {
        ...data,
        critical: isCriticalSet,
      };
      await updatePatientReports(data.id, updatedData, setPatientReportsDetails, username, actionlog);
    }
  };

  const handleClick = (studyInstanceUid, patientId, accession) => {
    console.log(patientId, accession)
    const data = patientReportsDetails.find(item => item.study_UIDs === studyInstanceUid);
    const isCritical = data ? !data.critical : true;
    if (isCritical === true) {
      display({
        title: 'Success!',
        message: 'Critical status added successfully',
        type: SnackbarTypes.SUCCESS,
        position: 'topRight',
      });
    } else {
      display({
        title: 'Success!',
        message: 'Critical status removed successfully',
        type: SnackbarTypes.SUCCESS,
        position: 'topRight',
      });
    }

    handlerCriticalToggle(studyInstanceUid, isCritical, patientId, accession);
  };


  // const patientFind = patientReportsDetails?.find(item => item.study_UIDs === studyInstanceUid);
  // const assignUserFind = patientFind?.assign?.map((item) => JSON.parse(item));



  const findAssignUserName = [patientFind?.firstAssignUser];

  const assignUserDataFind = radiologistUserList?.find(item => {
    return findAssignUserName?.includes(item.username);
  });

  const reportSetting = reportSettingDetails?.find(
    item => item.group_name === user?.profile?.radiologyGroup
  );

  const patientDatas = patientReportsDetails?.find(item => item.study_UIDs === studyInstanceUid);

  const reportSubmit_time =
    patientDatas?.report_submit_time && new Date(patientDatas?.report_submit_time);

  let formattedTime;
  if (reportSubmit_time) {
    formattedTime = `
    ${reportSubmit_time?.toLocaleDateString('en-US', { month: 'long' })}
    ${reportSubmit_time?.getDate()},
    ${reportSubmit_time?.getFullYear()}
    ${reportSubmit_time?.getHours()}:${('0' + reportSubmit_time?.getMinutes())?.slice(-2)}:${('0' + reportSubmit_time?.getSeconds())?.slice(-2)} GMT${reportSubmit_time?.getTimezoneOffset() > 0 ? '-' : '+'}${('0' + Math.abs(reportSubmit_time?.getTimezoneOffset() / 60))?.slice(-2)}:${('0' + Math.abs(reportSubmit_time?.getTimezoneOffset() % 60)).slice(-2)} (IST)`;
  }

  const handleDownloadPdf = async () => {
    try {
      setIsLoading(true);

      const editorDatas = editorData ? editorData : patientReportDetail?.reportdetails;
      const parser = new DOMParser();
      const doc = parser.parseFromString(editorDatas, 'text/html');
      let modifiedEditorData = doc.body.innerHTML;

      if (reportSetting?.remove_defualt_report) {
        // Create a temporary DOM element to manipulate the HTML string
        const parser = new DOMParser();
        const doc = parser.parseFromString(modifiedEditorData, 'text/html');

        // Remove the patient details table
        const table = doc.querySelector('figure.table');
        if (table) {
          table.remove();
        }

        // Serialize the document back to a string
        modifiedEditorData = doc.body.innerHTML;
      }

      const headerStyle = `
        width: 98%;
        z-index: 1;
        padding-right: 10px;
        height: ${reportSetting?.height}px;
        `;

      const watermarkStyle = `
            position: fixed;
            width: 100%;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.6;
            margin-top: 50px;
            margin-bottom: 50px;
            z-index: -1;
        `;

      const watermarkTextStyle = `
            font-size: 80px;
            color: #999;
        `;

      // signature image style
      const signatureStyle = `
            width: 200px;
            height: 100px;
        `;

      // report template style
      const reportDataStyle = `
             margin-top: ${reportSetting?.top}px;
             margin-left: ${reportSetting?.left}px;
             margin-right: ${reportSetting?.right}px;
             margin-bottom: ${reportSetting?.bottom}px;
            font-family: ${reportSetting?.font_style};
            font-size: ${reportSetting?.font_size}px !important;
            line-height: ${reportSetting?.line_spacing};
        `;

      // Doctore details footer
      const firstName = assignUserDataFind ? `${assignUserDataFind?.username}` : '';
      const qualification =
        assignUserDataFind?.attributes.qualification !== undefined
          ? assignUserDataFind?.attributes.qualification
          : '';
      const registrationNo =
        assignUserDataFind &&
          assignUserDataFind?.attributes &&
          assignUserDataFind?.attributes.registrationNo
          ? assignUserDataFind.attributes.registrationNo
          : '';
      const formattedTimes = formattedTime === undefined ? '' : formattedTime;
      const disclaimerDetails =
        reportSetting && reportSetting.disclaimer_details ? reportSetting.disclaimer_details : '';

      const output = `
            ${firstName}<br/>
            ${qualification}<br/>
            ${registrationNo ? `Reg.No. :- ${registrationNo}` : ''}<br/>
            ${formattedTimes}<br/>
            ${disclaimerDetails ? `Disclaimer :- ${disclaimerDetails}` : ''}
        `;

      let pageHeaderSpace;
      if (reportSetting?.patient_details_in_header) {
        pageHeaderSpace = `
          height: 274px;
        `;
      } else {
        pageHeaderSpace = `
          height: 150px;
        `;
      }

      const pageFooterSpace = `
      height: ${reportSetting?.height}px;
      `;

      const pageFooter = `
        position: fixed;
        bottom: 0;
        width: 100%;

      `;

      const pageHeader = `
        position: fixed;
        top: 0mm;
        width: 100%;


      `;

      const page = `
          page-break-after: auto;
        `;

      const pageStyle = `

        @media print {
          thead {display: table-header-group;}
          tfoot {display: table-footer-group;}

          button {display: none;}

          body {margin: 0;}
      }`;

      let modifiedEditor = '';
      // Construct modified editor content
      if (reportSetting?.multiple_header_and_footer === true) {
        modifiedEditor = `
            <div style="${pageStyle}">
              <div class="page-header" style="${pageHeader}">
                ${reportSetting?.include_header && reportSetting?.header_image
            ? `
                <img src="${reportSetting?.header_image}"  alt="Header" style="${headerStyle}" />
                `
            : ''
          }


                 ${reportSetting?.patient_details_in_header
            ? `
                      <div>
                      <figure class="table">
                        <table style="margin: 0; border-collapse: collapse;">
                          <tbody>
                            <tr>
                              <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Patient Name:</strong></td>
                              <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.patient_name}</td>
                              <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Patient Id:</strong></td>
                              <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.patient_id}</td>
                            </tr>
                            <tr>
                              <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Patient Gender:</strong></td>
                              <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.patient_gender}</td>
                              <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Patient Age:</strong></td>
                              <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.patient_age}</td>
                            </tr>
                            <tr>
                              <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Patient Modality:</strong></td>
                              <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.patient_modality}</td>
                              <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Accession Number:</strong></td>
                              <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.patient_accession}</td>
                            </tr>
                            <tr>
                              <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Study Date:</strong></td>
                              <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.study_date}</td>
                              <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Study Time:</strong></td>
                              <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.study_time}</td>
                            </tr>
                            <tr>
                              <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Ref Doctor:</strong></td>
                              <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.ref_doctor}</td>
                              <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Study:</strong></td>
                              <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.study}</td>
                            </tr>
                          </tbody>
                        </table>
                      </figure>
                    </div>

                      `
            : ``
          }

              </div >

              <div class="page-footer" style="${pageFooter}">
              ${reportSetting?.include_footer && reportSetting?.footer_image
            ? `
              <img src="${reportSetting?.footer_image}" alt="Footer" style="${headerStyle}" />
              `
            : ''
          }
              </div>

              <table>

              <thead>
                <tr>
                  <td>
                    <!--place holder for the fixed-position header-->
                    <div style="${pageHeaderSpace}" class="page-header-space"></div>
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <div style="${page}">
                      <div style="${reportDataStyle}">
                        ${modifiedEditorData}
                        <div style="margin-top: 10px;line-height:1 !important;">
                          ${reportSetting?.signature && assignUserDataFind?.attributes?.uploadSignature && assignUserDataFind ? `<img src="${assignUserDataFind?.attributes?.uploadSignature}" alt="signature" style="${signatureStyle}" />` : ''} <br/>
                          ${output}
                        </div >
                        </div>

                    </div>
                  </td>
                </tr>
              </tbody>

              <tfoot>
                <tr>
                  <td>

                    <div class="page-footer-space" style="${pageFooterSpace}"></div>
                  </td>
                </tr>
              </tfoot>

              </table>
              ${reportSetting?.include_watermark && reportSetting?.watermark_image
            ? `
              <div style="${watermarkStyle}" class="watermark">
                <span style="${watermarkTextStyle}" class="watermark-text"><img src="${reportSetting?.watermark_image}" alt="watermark" width="100%" height="auto" /> </span>
              </div>
              `
            : ''
          }
          </div>

    `;
      } else {
        modifiedEditor = `
        <div>
          ${reportSetting?.include_header && reportSetting?.header_image
            ? `
          <img src="${reportSetting?.header_image}" alt="Header" style="${headerStyle}" />
        `
            : ''
          }
          <div class="page-header" style="${pageHeader}">
          <div style="${headerStyle}" ></div>
           ${reportSetting?.patient_details_in_header
            ? `
                <div>
                <figure class="table">
                  <table style="margin: 0; border-collapse: collapse;">
                    <tbody>
                      <tr>
                        <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Patient Name:</strong></td>
                        <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.patient_name}</td>
                        <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Patient Id:</strong></td>
                        <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.patient_id}</td>
                      </tr>
                      <tr>
                        <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Patient Gender:</strong></td>
                        <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.patient_gender}</td>
                        <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Patient Age:</strong></td>
                        <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.patient_age}</td>
                      </tr>
                      <tr>
                        <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Patient Modality:</strong></td>
                        <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.patient_modality}</td>
                        <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Accession Number:</strong></td>
                        <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.patient_accession}</td>
                      </tr>
                      <tr>
                        <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Study Date:</strong></td>
                        <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.study_date}</td>
                        <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Study Time:</strong></td>
                        <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.study_time}</td>
                      </tr>
                      <tr>
                        <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Ref Doctor:</strong></td>
                        <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.ref_doctor}</td>
                        <td style="width:17.7931%; border: 1px solid black; padding: 0;"><strong>Study:</strong></td>
                        <td style="width:33.5161%; border: 1px solid black; padding: 0;">${patientData?.study}</td>
                      </tr>
                    </tbody>
                  </table>
                </figure>
              </div>

                `
            : ``
          }

        </div >

        <table>

        <thead>
            <tr>
              <td>
                <!--place holder for the fixed-position header-->
                <div style="${pageHeaderSpace}" class="page-header-space"></div>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <div style="${page}">
                  <div style="${reportDataStyle}">
                    ${modifiedEditorData}
                    <div style="margin-top: 10px">
                      ${reportSetting?.signature && assignUserDataFind?.attributes?.uploadSignature && assignUserDataFind ? `<img src="${assignUserDataFind?.attributes?.uploadSignature}" alt="signature" style="${signatureStyle}" />` : ''} <br/>
                      ${output}
                    </div >
                    </div>

                </div>
              </td>
            </tr>
          </tbody>

        </table>

          ${reportSetting?.include_footer && reportSetting?.footer_image
            ? `
          <img src="${reportSetting?.footer_image}" alt="Footer" style="${headerStyle}" />
          `
            : ''
          }
        </div >
        ${reportSetting?.include_watermark && reportSetting?.watermark_image
            ? `
          <div style="${watermarkStyle}" class="watermark">
            <span style="${watermarkTextStyle}" class="watermark-text"><img src="${reportSetting?.watermark_image}" alt="watermark" width="100%" height="auto" /> </span>
          </div>
        `
            : ''
          }
  `;
      }

      // Generate the PDF with the modified content
      generateReportPdf(modifiedEditor, setIsLoading, patientData?.patient_name?.toLowerCase());
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  const handleCaptureImage = async () => {
    const divForDownloadViewport: any = document.querySelector(
      `div[data-viewport-uid="${activeViewportId}"]`
    );
    html2canvas(divForDownloadViewport).then(canvas => {
      const scale = 0.5;
      const resizedCanvas = document.createElement('canvas');
      const ctx = resizedCanvas.getContext('2d');
      resizedCanvas.width = canvas.width * scale;
      resizedCanvas.height = canvas.height * scale;
      ctx.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);
      const quality = 0.7;
      const imageDataUrl = resizedCanvas.toDataURL('image/jpeg', quality);
      setImageDataUrl(imageDataUrl);
    });
  };

  const highlightText = content => {
    const containsMale = /\b(Male|male)\b/.test(content);
    const containsFemale = /\b(Female|female)\b/.test(content);

    const femaleKeywords = [
      'Ovary',
      'Ovarii',
      'Fallopian tube',
      'Uterus',
      'cervix',
      'vagina',
      'Epoophoron',
      'Garner’s duct',
      'Paroophoron',
      "Skene's glands",
      "Bartholin's gland",
      'Labia minora',
      'Clitoris',
      'Clitoridis',
      'Clitoral',
      'Canal of Nuck',
      'Vulva',
      'Breast',
    ];

    const maleKeywords = [
      'Testicle',
      'Testis',
      'Prostatic utricle',
      'Epididymis',
      'Vas deferens',
      'Seminal vesicle',
      'Paradidymis',
      'Prostate',
      'Bulbourethral gland',
      'Scrotum',
      'Penile raphe',
      'Penis',
      'Penile',
      'Processus vaginalis',
      'testes',
      'Ducts deferens',
      'Ejaculatory ducts',
    ];

    const highlightWords = (content, wordsToHighlight) => {
      wordsToHighlight.forEach(keyword => {
        const regex = new RegExp(`\\b${keyword}\\b`, 'gi');
        content = content.replace(
          regex,
          match => `<span style="background-color:yellow;">${match}</span>`
        );
      });
      return content;
    };

    const removeHighlight = content => {
      return content.replace(/<span style="background-color:yellow;">(.*?)<\/span>/gi, '$1');
    };

    // Remove existing highlights
    content = removeHighlight(content);

    // Apply new highlights
    if (containsFemale) {
      content = highlightWords(content, maleKeywords);
    }

    if (containsMale) {
      content = highlightWords(content, femaleKeywords);
    }
    return content;
  };

  const lastDocumentVersionRef = useRef(null);
  const lastHighlightedContentRef = useRef('');

  const debouncedHandleChange = useCallback(
    debounce(editor => {
      const newVersion = editor.model.document.version;
      if (lastDocumentVersionRef.current === newVersion) {
        return;
      }
      lastDocumentVersionRef.current = newVersion;

      const editorContent = editor.getData();
      const highlightedContent = highlightText(editorContent);

      if (
        editorContent !== highlightedContent &&
        highlightedContent !== lastHighlightedContentRef.current
      ) {
        lastHighlightedContentRef.current = highlightedContent;

        // Save the current selection
        const selection = editor.model.document.selection;
        const ranges = Array.from(selection.getRanges()).map(range =>
          editor.model.createRange(
            editor.model.createPositionAt((range as any).start.parent, (range as any).start.offset),
            editor.model.createPositionAt((range as any).end.parent, (range as any).end.offset)
          )
        );

        // Update the model without resetting the content
        editor.model.change(writer => {
          const root = editor.model.document.getRoot();
          const viewFragment = editor.data.processor.toView(highlightedContent);
          const modelFragment = editor.data.toModel(viewFragment);

          // Clear existing content
          writer.remove(
            writer.createRange(
              writer.createPositionAt(root, 0),
              writer.createPositionAt(root, root.childCount)
            )
          );

          // Insert new content
          writer.insert(modelFragment, root, 0);

          // Restore the selection
          writer.setSelection(ranges);
        });
      }
    }, 800),
    []
  );

  useEffect(() => {
    if (selectedTemplateOptions && selectedTemplateOptions.value && patientData) {
      const compiledTemplate = Handlebars.compile(patientReportDetail?.reportdetails ? patientReportDetail?.reportdetails : selectedTemplateOptions?.value
      );
      const templateData = compiledTemplate(patientData);
      setEditorData(templateData);
      setTemplate(templateData);
    }
  }, [selectedTemplateOptions, patientData]);

  // Custom plugin to set default font size
  function DefaultFontSizePlugin(editor) {
    // editor.model.schema.extend('$text', { allowAttributes: 'fontSize' });
    // editor.conversion.attributeToElement({
    //   model: 'fontSize',
    //   view: (modelAttributeValue, { writer }) => {
    //     const span = writer.createAttributeElement('span', {
    //       style: `font-size:${modelAttributeValue}`,
    //     });
    //     writer.setCustomProperty('fontSize', true, span);
    //     return span;
    //   },
    // });

    editor.model.document.on('change:data', (event, data) => {
      editor.model.change(writer => {
        const selection = editor.model.document.selection;
        const ranges = selection.getRanges();

        for (const range of ranges) {
          if (!range.isCollapsed) {
            writer.setAttribute('fontSize', '12px', range);
          }
        }
      });
    });
  }
  useEffect(() => {
    const editorElement: any = document.querySelector('#editor');
    if (!editorElement || !selectedTemplateOptions || !template) {
      return;
    }

    const lineHeight = reportSetting?.line_spacing || 1.0;
    let lineHeightEditor;

    // Initialize max page heights with the usable content height

    let maxA4HeightFirst;
    let maxA4HeightOthers;

    switch (Number(lineHeight)) {
      case 0.1:
        maxA4HeightFirst = 800;
        maxA4HeightOthers = 840;

        lineHeightEditor = 1.39;
        break;
      case 0.2:
        maxA4HeightFirst = 710;
        maxA4HeightOthers = 760;

        lineHeightEditor = 1.40;
        break;

      case 0.3:
        maxA4HeightFirst = 680;
        maxA4HeightOthers = 710;

        lineHeightEditor = 1.39;
        break;

      case 0.4:
        maxA4HeightFirst = 620;
        maxA4HeightOthers = 650;

        lineHeightEditor = 1.38;
        break;

      case 0.5:
        maxA4HeightFirst = 590;
        maxA4HeightOthers = 610;

        lineHeightEditor = 1.40;
        break;

      case 0.6:
        maxA4HeightFirst = 580;
        maxA4HeightOthers = 600;

        lineHeightEditor = 1.46;
        break;

      case 0.7:
        maxA4HeightFirst = 560;
        maxA4HeightOthers = 540;

        lineHeightEditor = 1.46;
        break;

      case 0.8:
        maxA4HeightFirst = 575;
        maxA4HeightOthers = 580;

        lineHeightEditor = 1.55;
        break;

      case 0.9:
        maxA4HeightFirst = 550;
        maxA4HeightOthers = 545;

        lineHeightEditor = 1.56;
        break;

      case 1:
        maxA4HeightFirst = 500;
        maxA4HeightOthers = 480;

        lineHeightEditor = 1.5;
        break;

      case 1.1:
        maxA4HeightFirst = 490;
        maxA4HeightOthers = 450;

        lineHeightEditor = 1.5;
        break;

      case 1.2:
        maxA4HeightFirst = 480;
        maxA4HeightOthers = 430;

        lineHeightEditor = 1.5;
        break;

      case 1.3:
        maxA4HeightFirst = 440;
        maxA4HeightOthers = 400;

        lineHeightEditor = 1.5;
        break;

      case 1.4:
        maxA4HeightFirst = 435;
        maxA4HeightOthers = 400;

        lineHeightEditor = 1.5;
        break;

      case 1.5:
        maxA4HeightFirst = 425;
        maxA4HeightOthers = 390;

        lineHeightEditor = 1.5;
        break;

      case 1.6:
        maxA4HeightFirst = 415;
        maxA4HeightOthers = 375;

        lineHeightEditor = 1.5;
        break;

      case 1.7:
        maxA4HeightFirst = 390;
        maxA4HeightOthers = 350;

        lineHeightEditor = 1.5;
        break;

      case 1.8:
        maxA4HeightFirst = 385;
        maxA4HeightOthers = 345;

        lineHeightEditor = 1.5;
        break;

      case 1.9:
        maxA4HeightFirst = 375;
        maxA4HeightOthers = 340;

        lineHeightEditor = 1.5;
        break;

      case 2:
        maxA4HeightFirst = 355;
        maxA4HeightOthers = 335;

        lineHeightEditor = 1.5;
        break;

      default:
        maxA4HeightFirst = 333;
        maxA4HeightOthers = 300;

        lineHeightEditor = 1.5;
        break;

    }

    // switch (Number(lineHeight)) {
    //   case 0.1:
    //     maxA4HeightFirst = 980;
    //     maxA4HeightOthers = 1085;

    //     lineHeightEditor = 1.38;
    //     break;
    //   case 0.2:
    //     maxA4HeightFirst = 930;
    //     maxA4HeightOthers = 1010;

    //     lineHeightEditor = 1.4;
    //     break;

    //   case 0.3:
    //     maxA4HeightFirst = 865;
    //     maxA4HeightOthers = 920;

    //     lineHeightEditor = 1.39;
    //     break;

    //   case 0.4:
    //     maxA4HeightFirst = 800;
    //     maxA4HeightOthers = 880;

    //     lineHeightEditor = 1.38;
    //     break;

    //   case 0.5:
    //     maxA4HeightFirst = 770;
    //     maxA4HeightOthers = 770;

    //     lineHeightEditor = 1.40;
    //     break;

    //   case 0.6:
    //     maxA4HeightFirst = 750;
    //     maxA4HeightOthers = 780;

    //     lineHeightEditor = 1.46;
    //     break;

    //   case 0.7:
    //     maxA4HeightFirst = 705;
    //     maxA4HeightOthers = 725;

    //     lineHeightEditor = 1.46;
    //     break;

    //   case 0.8:
    //     maxA4HeightFirst = 630;
    //     maxA4HeightOthers = 690;

    //     lineHeightEditor = 1.55;
    //     break;

    //   case 0.9:
    //     maxA4HeightFirst = 690;
    //     maxA4HeightOthers = 720;

    //     lineHeightEditor = 1.56;
    //     break;

    //   case 1:
    //     maxA4HeightFirst = 640;
    //     maxA4HeightOthers = 560;

    //     lineHeightEditor = 1.5;
    //     break;

    //   case 1.1:
    //     maxA4HeightFirst = 620;
    //     maxA4HeightOthers = 530;

    //     lineHeightEditor = 1.5;
    //     break;

    //   case 1.2:
    //     maxA4HeightFirst = 600;
    //     maxA4HeightOthers = 520;

    //     lineHeightEditor = 1.5;
    //     break;

    //   case 1.3:
    //     maxA4HeightFirst = 580;
    //     maxA4HeightOthers = 500;

    //     lineHeightEditor = 1.5;
    //     break;

    //   case 1.4:
    //     maxA4HeightFirst = 560;
    //     maxA4HeightOthers = 470;

    //     lineHeightEditor = 1.5;
    //     break;

    //   case 1.5:
    //     maxA4HeightFirst = 540;
    //     maxA4HeightOthers = 450;

    //     lineHeightEditor = 1.5;
    //     break;

    //   case 1.6:
    //     maxA4HeightFirst = 540;
    //     maxA4HeightOthers = 440;

    //     lineHeightEditor = 1.5;
    //     break;

    //   case 1.7:
    //     maxA4HeightFirst = 520;
    //     maxA4HeightOthers = 420;

    //     lineHeightEditor = 1.5;
    //     break;

    //   case 1.8:
    //     maxA4HeightFirst = 520;
    //     maxA4HeightOthers = 410;

    //     lineHeightEditor = 1.5;
    //     break;

    //   case 1.9:
    //     maxA4HeightFirst = 500;
    //     maxA4HeightOthers = 400;

    //     lineHeightEditor = 1.5;
    //     break;

    //   case 2:
    //     maxA4HeightFirst = 490;
    //     maxA4HeightOthers = 380;

    //     lineHeightEditor = 1.5;
    //     break;

    //   default:
    //     maxA4HeightFirst = 333;
    //     maxA4HeightOthers = 300;

    //     lineHeightEditor = 1.5;
    //     break;

    // }
    class AddPageBreak {
      constructor(editor) {
        this.editor = editor;
      }

      init() {
        const editor = this.editor;

        editor.model.schema.register('pageBreak', {
          allowWhere: '$block',
          isObject: true,
          allowAttributes: ['class', 'style'],
        });

        editor.model.schema.register('newDiv', {
          allowWhere: '$block',
          isObject: true,
          allowAttributes: ['class', 'style'],
        });

        editor.conversion.for('editingDowncast').elementToElement({
          model: 'pageBreak',
          view: (modelElement, { writer: viewWriter }) => {
            return viewWriter.createContainerElement('div', {
              class: 'page-break',
              style: 'border-bottom: 1px dashed black;',
            });
          },
        });

        editor.conversion.for('editingDowncast').elementToElement({
          model: 'newDiv',
          view: (modelElement, { writer: viewWriter }) => {
            return viewWriter.createContainerElement('div', {
              class: 'new-div',
            });
          },
        });

        editor.conversion.for('dataDowncast').elementToElement({
          model: 'pageBreak',
          view: (modelElement, { writer: viewWriter }) => {
            return viewWriter.createContainerElement('div', {
              class: 'page-break',
              style: 'border-bottom: 1px dashed black;',
            });
          },
        });

        editor.conversion.for('dataDowncast').elementToElement({
          model: 'newDiv',
          view: (modelElement, { writer: viewWriter }) => {
            return viewWriter.createContainerElement('div', {
              class: 'new-div',
            });
          },
        });

        editor.conversion.for('upcast').elementToElement({
          view: {
            name: 'div',
            classes: 'page-break',
          },
          model: (viewElement, { writer: modelWriter }) => {
            return modelWriter.createElement('pageBreak', {
              class: 'page-break',
              style: 'border-bottom: 1px dashed black;',
            });
          },
        });

        editor.conversion.for('upcast').elementToElement({
          view: {
            name: 'div',
            classes: 'new-div',
          },
          model: (viewElement, { writer: modelWriter }) => {
            return modelWriter.createElement('newDiv', {
              class: 'new-div',
            });
          },
        });

        editor.model.document.on('change:data', () => {
          editor.model.change(writer => {
            const root = editor.model.document.getRoot();

            // Remove existing page breaks and new div elements
            for (const child of Array.from(root.getChildren())) {
              if (child.is('element', 'pageBreak') || child.is('element', 'newDiv')) {
                writer.remove(child);
              }
            }

            // Initialize height counter
            let currentHeight = 0;
            let firstPage = true; // Flag to check if it's the first page

            // Flag to determine whether to insert page break
            let shouldInsertBreak = false;

            // Loop through the children of the root to calculate height
            for (const block of root.getChildren()) {
              const viewElement = editor.editing.mapper.toViewElement(block);
              const domElement = editor.editing.view.domConverter.mapViewToDom(viewElement);

              if (domElement) {
                currentHeight += domElement.offsetHeight;

                if (firstPage) {
                  if (currentHeight > maxA4HeightFirst) {
                    shouldInsertBreak = true;
                    currentHeight = domElement.offsetHeight; // Reset height for the next page
                    firstPage = false; // Mark that the first page is processed
                  }
                } else {
                  if (currentHeight > maxA4HeightOthers) {
                    shouldInsertBreak = true;
                    currentHeight = domElement.offsetHeight; // Reset height for the next page
                  }
                }

                if (shouldInsertBreak) {
                  const pageBreakElement = writer.createElement('pageBreak', {
                    class: 'page-break',
                    style: 'border-bottom: 1px dashed black;',
                  });

                  const newDivElement = writer.createElement('newDiv', {
                    class: 'new-div',
                  });

                  writer.insert(pageBreakElement, writer.createPositionBefore(block));
                  writer.insert(newDivElement, writer.createPositionAfter(pageBreakElement));

                  shouldInsertBreak = false; // Reset flag after inserting
                }
              }
            }
          });
        });
      }
    }
    DecoupledEditor.create(editorElement, {
      fontSize: {
        options: [
          9,
          11,
          12,
          13,
          "default",
          15,
          17,
          19,
          21
        ],
        supportAllValues: true,
      },
      toolbar: {
        items: [
          'heading',
          'bold',
          'italic',
          'underline',
          'fontFamily',
          'fontSize',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'bulletedList',
          'numberedList',
          'insertTable',
          '|',
          'undo',
          'redo',
          'alignment'
        ],
      },
      language: 'en',
      // extraPlugins: [AddPageBreak],
      initialData: '<p style="font-size:12px;"></p>', // Set default font size for new content


    })
      .then(editor => {
        const toolbarContainer = document.querySelector('#toolbar-container');
        if (toolbarContainer) {
          toolbarContainer.appendChild(editor.ui.view.toolbar.element);
        }
        // Apply custom line-height to the editor content

        editor.editing.view.change(writer => {
          const editableRoot = editor.editing.view.document.getRoot();
          writer.setStyle('line-height', lineHeightEditor, editableRoot);
          writer.setStyle('font-size', "12px", editableRoot);
        });

        editor.setData(highlightText(template));

        if (patientReportDetail?.document_status === 'Addendum') {
          editor.setData(template + `<p> Addendum begin &lt; &gt; Addendum end </p>`);
        } else {
          editor.setData(template);
        }

        const editorTable = document?.querySelector('.editor_table');
        // Check document status to set read-only
        if (patientReportDetail?.document_status === "Approved") {

          editor.editing.view.document.isReadOnly = patientReportDetail?.document_status === "Approved";
          editorTable.classList.remove('editor_table'); // Remove the height class
        }
        //else{
        //   editor.editing.view.document.isReadOnly = false;
        //   editorTable.style.height = '86%';
        //   editorTable.classList.add('editor_table');
        // }


        editor.model.document.on('change:data', (event, data) => {
          const newData = editor.getData();
          debouncedHandleChange(editor);
          const modifyData = newData
            .replace(/class="text-tiny"(.*?)style="([^"]*)"/g, (match, p1, p2) => {
              return `style="font-size:.7em;${p2}"${p1}`;
            })
            .replace(/class="text-tiny"(.*?)>/g, 'style="font-size:.7em;"$1>')
            // Replace class="text-small" and merge existing styles
            .replace(/class="text-small"(.*?)style="([^"]*)"/g, (match, p1, p2) => {
              return `style="font-size:.85em;${p2}"${p1}`;
            })
            .replace(/class="text-small"(.*?)>/g, 'style="font-size:.85em;"$1>')
            // Replace class="text-big" and merge existing styles
            .replace(/class="text-big"(.*?)style="([^"]*)"/g, (match, p1, p2) => {
              return `style="font-size:1.4em;${p2}"${p1}`;
            })
            .replace(/class="text-big"(.*?)>/g, 'style="font-size:1.4em;"$1>')
            // Replace class="text-huge" and merge existing styles
            .replace(/class="text-huge"(.*?)style="([^"]*)"/g, (match, p1, p2) => {
              return `style="font-size:1.8em;${p2}"${p1}`;
            })
            .replace(/class="text-huge"(.*?)>/g, 'style="font-size:1.8em;"$1>')
            .replace(/<table>/g, '<table border="1px;" style="border-collapse: collapse;"> ')
            // .replace(/<div class="page-break" style="border-bottom:1px dashed black;">/g, '')
            .replace(/<img style="height:200px;"/g, '<img style="height:400px;"')
            .replace(/&nbsp;/g, '')
            // .replace(
            //   /<div class="new-div">/g,
            //   '<div class="page-break" style="height:0px; page-break-before: always;">'
            // );
          setEditorData(modifyData);
          onChangeHandler(modifyData);
        });
        // editor.model.document.on('change:data', () => {
        //   const currentContent = editor.getData();
        //   const highlightedContent = highlightText(currentContent);
        //   if (currentContent !== highlightedContent) {
        //     editor.setData(highlightedContent);
        //   }
        // });
        setEditorData1(editor);
      })
      .catch(error => {
        console.error(error);
      });
    if (imageDataUrl && editorData1?.editing.view) {
      editorData1?.editing?.view.change(writer => {
        const currentContent = editorData1.getData();
        const newContent =
          currentContent +
          `<img class="captured-image" src="${imageDataUrl}" alt="Captured Image" style="height:200px;"/>`;
        editorData1.setData(newContent);
      });
    } else if (saveReports && editorData1?.editing?.view) {
      editorData1?.editing?.view?.change(writer => {
        // const currentContent = editorData1?.getData();
        //  const newContent = currentContent + saveReports;
        const newContent = concurrentTemplate + saveReports;
        localStorage.setItem('test_transcript', newContent);
        editorData1.setData(newContent);
        // setSaveReports("")
      })
    } else {
      if (editorData1?.setData) {
        editorData1?.setData(template);
      }
    }
  }, [
    selectedTemplateOptions,
    patientData,
    template,
    imageDataUrl,
    reportSetting,
    patientReportDetail,
    setEditorData,
    onChangeHandler,
    setEditorData1,
    saveReports,
  ]);

  const handleMic = () => {
    setIsMic(!isMic);
  };

  const findDocument = documentUploadDetails?.find(item => item.study_UIDs === studyInstanceUid);

  return (
    <div
      className="report-editor-container report_ckeditor z-10 h-full overflow-y-auto"
      style={{ height: isNewTab ? '95vh' : '100%' }}
    >
      <div className="my-2 ml-2 flex justify-between">
        <div className=' w-2/5'>
          <Select
            id="display-set-selector"
            isClearable={false}
            onChange={onSelectChange}
            options={displayTemplateOptions}
            value={displayTemplateOptions?.find(ds => ds.id === selectedTemplateOptions?.id)}
            className="text-white"
            placeholder="Select one template"
          />
        </div>

        <div className=' flex justify-between items-center gap-3'>
          <Button
            onClick={isAttachment ? () => handleAttachment(studyInstanceUid, patientData?.patient_name) : undefined}
            className=' flex items-center'
          >
            {findDocument ? (
              <Tooltip
                content={'See Attachments'}
                position="left"
                style={{ padding: '8px', fontWeight: 'normal' }}
              >
                <HiMiniDocumentPlus className={` text-2xl `} />
              </Tooltip>
            ) : (
              <Tooltip
                content={'No Attachments'}
                position="left"
                style={{ padding: '8px', fontWeight: 'normal' }}
              >
                <GrDocumentUpdate className=" text-2xl" />
              </Tooltip>
            )}
          </Button>

          <Button >
            <Tooltip
              content={'Clinical Summary'}
              position="left"
              style={{ padding: '8px', fontWeight: 'normal' }}
            >
              <FaNotesMedical className=" text-2xl" />
            </Tooltip>
          </Button>

          {params.pathname.includes('report-editor') ? null : (
            <Button
              onClick={handleNewWindowOpen}
              endIcon={<Icon name="launch-arrow" />}
            />
          )}
        </div>
      </div>

      <div
        className={`editor_table ${patientData?.document_status === 'Approved' ? ' pointer-events-none' : ' pointer-events-auto'}`}
      >
        <div id="toolbar-container"></div>
        <div
          id="editor"
          className="h-full"
        ></div>
      </div>

      <div className="fixed bottom-[-5px] mt-2.5 flex items-center p-1 z-10">
        <button className="mic-container cursor-pointer ml-2" onClick={listening ? stopListening : startListening}>
          <div className={`mic-icon ${listening ? '' : ''}`}>
            <div className={`${listening ? 'pulse-ring' : ''}`}></div>
            {listening ? <FaMicrophone className=' text-xl text-white ' /> : <FaMicrophoneSlash className=' text-xl text-white' />}
          </div>
        </button>

        <Button
          onClick={handleSubmit}
          className="ml-3"
          disabled={
            (assignUserDetail && isPhysicianOrTechnologist) || isApproved ? true
              : ((!assignUserDetail && (canEditReport || isQaUser || isSuperAndDeputyAdmin)) || assignUserDetail || isSuperAndDeputyAdmin) ? false
                : true
          }
        >
          <Tooltip
            content={'Submit Report'}
            position='top'
            style={{ padding: '8px', fontWeight: 'normal' }}
          >
            Submit
          </Tooltip>
        </Button>
        <Button
          onClick={handleDraft}
          className="ml-3"
          disabled={
            (assignUserDetail && isPhysicianOrTechnologist) || isApproved ? true
              : ((!assignUserDetail && (canEditReport || isQaUser || isSuperAndDeputyAdmin)) || assignUserDetail || isSuperAndDeputyAdmin) ? false
                : true
          }
        >
          <Tooltip
            content={'Save as Draft'}
            position='top'
            style={{ padding: '8px', fontWeight: 'normal' }}
          >
            Draft
          </Tooltip>

        </Button>
        <Button
          onClick={() => handleClick(studyInstanceUid, patientData?.patient_id, patientData?.patient_accession)}
          className={`${!patientFind?.critical ? ' text-white' : 'bg-[#63b3ed] text-black'} ml-3`}
          disabled={
            (assignUserDetail && isPhysicianOrTechnologist) ? true
              : ((!assignUserDetail && (canEditReport || isQaUser || isSuperAndDeputyAdmin)) || assignUserDetail || isSuperAndDeputyAdmin) ? false
                : true
          }
        >
          Critical
        </Button>
        <Button
          onClick={() => setToggleDisplayReportEditor(false)}
          className='ml-3'
        >
          Discard
        </Button>
        {selectedTemplateOptions && selectedTemplateOptions?.isCapture && (
          <Button
            onClick={handleCaptureImage}
            className='ml-3'
          >
            <Tooltip
              content={'Capture Image'}
              position='top'
              style={{ padding: '8px', fontWeight: 'normal' }}
            >
              <Icon name="icon-camera-fill" />
            </Tooltip>
          </Button>
        )}
        <Button
          className="downloadbutton mx-3"
          onClick={handleDownloadPdf}
        >
          {isLoading ? (
            <span className="buttonloader"></span>
          ) : (
            <span className="flex">
              <Tooltip
                content={'Download PDF'}
                position='top'
                style={{ padding: '8px', fontWeight: 'normal' }}
              >
                <Icon name="icon-download-pdf" />
              </Tooltip>
            </span>
          )}
        </Button>
      </div>
    </div>
  );
};

export default ReportEditor;
