// Institutions.js
import React, { useEffect, useState } from 'react';
import { useAnalytics, useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import { Area, AreaChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import InputDateRange from '../InputDateRange';
import Label from '../Label';
import moment from 'moment';

const Institutions = () => {
    const { studyCount, fetchReportsByModalityAndInst, reportsByModalityAndInst, fetchInstituteWeeklyPerform, instituteWeeklyPerform, setStartDate, setEndDate, startDate, endDate } = useAnalytics();
    const { show } = useSnackbar();
    const [instituteName, setInstituteName] = useState(null);
    const [error, setError] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState<any>({ startDate, endDate });

    const handleDateRangeFieldChange = ({ startDate, endDate }) => {
        const start_date = moment(startDate).format('YYYY-MM-DD');
        const end_date = moment(endDate).format('YYYY-MM-DD');
        setSelectedDateRange({ start_date, end_date });
        setStartDate(start_date);
        setEndDate(end_date);
    };

    const fetchData = (startDate, endDate) => {
        fetchReportsByModalityAndInst(startDate, endDate)
            .catch(error => setError(error));
    };

    const getLast7Days = () => {
        const today = new Date();
        const priorDate = new Date().setDate(today.getDate() - 7);

        return {
            startDate: new Date(priorDate).toISOString().split('T')[0],
            endDate: today.toISOString().split('T')[0],
        };
    };

    const handleRowClick = (institute) => {
        setInstituteName(institute);
    };

    useEffect(() => {
        if (instituteName && startDate && endDate) {
            fetchInstituteWeeklyPerform(instituteName, startDate, endDate);
        }
    }, [instituteName, startDate, endDate]);

    useEffect(() => {
        const { startDate, endDate } = getLast7Days();
        setSelectedDateRange({ startDate, endDate })
        setStartDate(startDate);
        setEndDate(endDate);
        fetchData(startDate, endDate);
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            fetchData(startDate, endDate);
            setSelectedDateRange({ startDate, endDate });
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (error) {
            show({ message: error.message, type: SnackbarTypes.ERROR });
        }
    }, [error, show]);

    // Define types for your data
    interface ModalityCounts {
        [modality: string]: number;
    }

    interface InstitutionData {
        [institute: string]: ModalityCounts;
    }

    interface TransformedRow {
        [key: string]: number | string;
        total: number;
    }

    interface TransformResult {
        transformedData: TransformedRow[];
        allModalities: string[];
    }

    // Transform function for institutions
    const transformTableData = (data: InstitutionData): TransformResult => {
        const allModalities = new Set<string>();

        const transformedData = Object.entries(data).map(([institute, modalities]) => {
            const total = Object.values(modalities).reduce((sum, count) => sum + count, 0);
            Object.keys(modalities).forEach(modality => allModalities.add(modality));
            return {
                institute,
                ...modalities,
                total,
            };
        });

        return { transformedData, allModalities: Array.from(allModalities) };
    };

    const { transformedData: tableData, allModalities } = reportsByModalityAndInst
        ? transformTableData(reportsByModalityAndInst)
        : { transformedData: [], allModalities: [] };


    const transformChartData = (rawData: Record<string, string>) => {
        const dataEntries = Object.entries(rawData);

        return dataEntries.map(([date, value]) => ({
            date,    // the date key
            count: parseInt(value, 10)  // converting the string value to a number
        }));
    };


    const lineChartData = transformChartData(instituteWeeklyPerform);

    const CustomTooltip = ({ payload, label }) => {
        if (payload.length > 0) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`Date: ${label}`}</p>
                    <p className="value">{`Value: ${payload[0].value}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="min-h-[500px] max-h-[550px] overflow-y-auto overflow-x-auto mr-2"
            style={{
                scrollbarColor: '#173239 transparent',
            }}>
            <div className=' flex items-center justify-between gap-5 my-5'>
                <div className=' flex items-center gap-3'>
                    <Label className="mt-2" text={''}>Date Range : </Label>
                    <InputDateRange
                        id={'report-analutic'}
                        value={selectedDateRange}
                        onChange={handleDateRangeFieldChange}
                        setStudyFilterHeight={''}
                    />
                </div>
                < div style={{ textAlign: 'center' }}>
                    <p
                        className="inline-flex items-center rounded border border-[#3a3f99] bg-blue-400 py-2 px-4 font-semibold text-black transition-all opacity-80 mr-3"
                    >
                        Study Count: {studyCount?.count || 0}
                    </p>
                </div>
            </div>
            <div className={` mb-5 ${tableData.length === 0 ? 'h-48 ' : ''} mr-3`} >
                <p className=' text-[#5acce6]'>Study by Modality and Institution </p>
                {tableData.length > 0 ? (
                    <table className=" w-full bg-primary-dark  border-secondary-light border p-4 rounded-md mt-2 table-auto text-white " >
                        <thead className="bg-primary-dark border-secondary-light border" style={{ backgroundColor: '#041c4a' }}>
                            <tr>
                                <th className=" p-2" > Institute </th>
                                {
                                    allModalities.map(modality => (
                                        <th key={String(modality)} className=" p-2" > {modality} </th>
                                    ))
                                }
                                <th className=" p-2" > Total </th>
                            </tr>
                        </thead>
                        <tbody className="mt-1 ">
                            {
                                tableData.map((row, index) => (
                                    <tr key={index} className="bg-primary-dark border-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer border text-center transition duration-300" >
                                        <td className=" p-2 cursor-pointer"
                                            onClick={() => handleRowClick(row.institute)}
                                        >
                                            {row.institute}
                                        </td>
                                        {
                                            allModalities.map(modality => (
                                                <td key={String(modality)} className=" p-2" > {row[modality] || 0} </td>
                                            ))
                                        }
                                        <td className=" p-2" > {row.total} </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                ) : (
                    <div className=' h-full flex justify-center items-center bg-primary-dark  border-secondary-light border p-4 mt-2'>Data not available.</div>
                )}
            </div>
            {instituteName && (
                <div className=' flex-1 bg-primary-dark  border-secondary-light border p-4 rounded-md mr-3'>
                    <p className=' text-[#5acce6]'>Weekly Institution performance</p>
                    <ResponsiveContainer width="100%" height={400}>
                        {lineChartData.length > 0 ? (
                            <AreaChart data={lineChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" stroke='#3a3f99 ' />
                                <XAxis dataKey="date" stroke="#fff" />
                                <YAxis stroke="#fff" allowDecimals={false} domain={[0, 'auto']} />
                                <Tooltip content={CustomTooltip} />
                                <Line type="monotone" dataKey="count" stroke="#5acce6" />
                                <Area type="monotone" dataKey="count" stroke="#5acce6" fill="#FFBB28" />
                            </AreaChart>
                        ) : (
                            <div className=' h-full flex justify-center items-center'>Data not available.</div>
                        )}
                    </ResponsiveContainer>
                </div>
            )}
        </div>

    );
};

export default Institutions;
