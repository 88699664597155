import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Formik, Form, Field } from 'formik';
import { Button, SettingsLeftPanel, Typography, Select, Label, InputDateRange } from '@ohif/ui';
import {
  useRadiologyGroup,
  useReportEditor,
  useSnackbar,
  useUser,
} from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';

import { CSVDownload } from 'react-csv';
import { utils, writeFile, XLSX } from 'xlsx';

const ReportSummary = ({ servicesManager, extensionManager, hotkeysManager, data: studies }) => {
  const { show } = useSnackbar();
  const { t } = useTranslation('UserPreferencesModal');

  const { userAuthenticationService } = servicesManager.services;
  const user = userAuthenticationService.getUser();
  const { radiologyGroupList } = useRadiologyGroup();

  const { patientReportsDetails } = useReportEditor();

  const { fetchUsers } = useUser();

  const initialValues = {
    groupName: '',
    tatHours: '',
    tatMinutes: '',
  };

  const [selectedGroupName, setSelectedGroupName] = useState(null);
  const [selectedRadiologistUser, setSelectedRadiologistUser] = useState(null);
  const [selectedStudyStatus, setSelectedStudyStatus] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState({ startDate: null, endDate: null });
  const [radiologistUserList, setRadiologistUserList] = useState([]);
  const [csvData, setCsvData] = useState(null);

  const handleDateRangeFieldChange = ({ startDate, endDate }) => {
    setSelectedDateRange({ startDate, endDate });
  };


  useEffect(() => {
    fetchUsers(user.access_token)
      .then(data => setRadiologistUserList(data))
      .catch(error => console.error('Error fetching users:', error));
  }, [user.access_token]);


  const radiologistUser = radiologistUserList.length > 0 && radiologistUserList?.filter(
    user =>
      user.attributes &&
      user.attributes.roleType &&
      user.attributes.roleType.includes('Radiologist') || user?.attributes?.roleType?.includes('QaUsers')
  );


  const optionRadiologistUser = [
    { label: 'all', value: 'all' }, // Add "All" option
    ...(radiologistUser.length > 0
      ? radiologistUser.map(group => ({
          label: group.username,
          value: group.username,
        }))
      : []),
  ];

  const optionGroup =
  [ { label: 'all', value: 'all' },...radiologyGroupList.map(group => ({
    label: group.name,
    value: group.name,
  }))];

  const optionStudyStatus = [
    { label: 'all', value: 'all' },
    { label: 'pending', value: 'pending' },
    { label: 'draft', value: 'draft' },
    { label: 'final', value: 'final' },
    { label: 'addendum', value: 'addendum' },
  ];

  const handleSelectionGroupNameChange = selected => {
    setSelectedGroupName(selected.value);
    setCsvData(null);
  };
  const handleRadiologistUserChange = selected => {
    setSelectedRadiologistUser(selected.value);
    setCsvData(null);
  };
  const handleStudyStatusChange = selected => {
    setSelectedStudyStatus(selected.value);
    setCsvData(null);
  };

  const doesStatusMatch = (documentStatus, selectedStatus) => {

    switch (selectedStatus) {
      case 'draft':
        return documentStatus === 'Read';
      case 'addendum':
        return documentStatus === 'Addendum';
      case 'final':
        return documentStatus === 'Approved';
      case 'pending':
        return documentStatus === null;
      default:
        return false;
    }
  };

  const handleDownloadExcel = async () => {
    // Start with all patient reports
    let filteredData = patientReportsDetails.filter(
      data => data.radiologyGroup === selectedGroupName
    );

    if(selectedGroupName && selectedGroupName === "all"){
      filteredData = patientReportsDetails
    }

    if (filteredData && filteredData.length === 0) {
      show({
        title: 'Error!',
        message: 'No Report Exiting In Selected Radiology Group',
        type: SnackbarTypes.ERROR,
        position: 'topRight',
      });
    }

    // Filter by radiologist user if selected
    if (selectedRadiologistUser && selectedRadiologistUser !== 'all') {
      filteredData = filteredData.filter(report => report.created_by === selectedRadiologistUser);
      if (filteredData && filteredData.length === 0) {
        show({
          title: 'Error!',
          message: 'No Report Exiting In Selected radiologist User',
          type: SnackbarTypes.ERROR,
          position: 'topRight',
        });
      }
    }

    // Filter by report status if selected
    if (selectedStudyStatus && selectedStudyStatus !== 'all') {
      filteredData = filteredData.filter(report =>
        doesStatusMatch(report.document_status, selectedStudyStatus)
      );
      if (filteredData && filteredData.length === 0) {
        show({
          title: 'Error!',
          message: 'No Report Exiting In Selected Report Status ',
          type: SnackbarTypes.ERROR,
          position: 'topRight',
        });
      }
    }

    // Filter by date range if selected
    if (selectedDateRange.startDate && selectedDateRange.endDate) {
      filteredData = filteredData.filter(report => {
        const createdAt = moment(report.createdAt);
        return (
          !createdAt.isValid() ||
          createdAt.isBetween(selectedDateRange.startDate, selectedDateRange.endDate, null, '[]')
        );
      });
      if (filteredData && filteredData.length === 0) {
        show({
          title: 'Error!',
          message: 'No Report Exiting In Selected Date Range ',
          type: SnackbarTypes.ERROR,
          position: 'topRight',
        });
      }
    }

    const header = Object.keys(filteredData[0])
    const fieldsToRemove = ['reportdetails','updated_by','report_status','assign','createdAt', 'updatedAt','capture_image',];

    const filteredHeader = header.filter(field => !fieldsToRemove.includes(field));

    const xlData = [
      filteredHeader,
      ...filteredData.map(data => [
        '',
        data.patient_id,
        data.patient_name,
        data.patient_gender,
        data.patient_age,
        data.patient_accession,
        data.patient_modality,
        data.ref_doctor,
        data.study_date,
        data.study,
        data.study_UIDs,
        data.study_priority,
        data.study_time,
        data.institution_name,
        data.isDrafted,
        // data.reportdetails,
        data.created_by,
        // data.updated_by,
        // data.report_status,
        // data.assign,
        data.document_status,
        data.report_submit_time,
        data.clinical_history,
        // data.capture_image,
        data.phone_number,
        data.countryCode,
        data.patient_email,
        data.critical,
        data.radiologyGroup,
        // data.createdAt,
        // data.updatedAt,
        '',
      ]),
    ];

    // Set column widths for Excel
    const columnWidths = [
      { wch: 5 }, // Width for patientId column
      { wch: 20 }, // Width for studyInstanceUid column
      { wch: 30 }, // Width for date column
      { wch: 20 }, // Width for time column (empty)
      { wch: 25 }, // Width for accession column
      { wch: 30 }, // Width for mrn column
      { wch: 20 }, // Width for patientSex column
      { wch: 30 }, // Width for patientName column
      { wch: 20 }, // Width for instances column
      { wch: 30 }, // Width for description column
      { wch:70 }, // Width for referringPhysician column
      { wch: 20 }, // Width for institutionName column
      { wch: 25 }, // Width for modalities column
      { wch: 25 }, // Width for age column
      { wch: 15 },
      { wch: 50 },
      { wch: 25 },
      { wch: 25 },
      { wch: 15 },
      { wch: 40 },
      { wch: 20 },
      { wch: 30 },
      { wch: 30 },
      { wch: 15 },
      { wch: 25 },
      { wch: 15 },
      { wch: 30 },
      { wch: 15 },
      { wch: 25 },
      { wch: 30 },
      { wch: 30 },
    ];

    // Create Excel workbook and sheet

    const ws = utils.aoa_to_sheet(xlData);
    const headerStyle = { font: { bold: true } };
    header.forEach((header, colIndex) => {
      const cellAddress = utils.encode_cell({ r: 0, c: colIndex });
      if (!ws[cellAddress]) {
        ws[cellAddress] = { t: 's', v: header };
      }
      ws[cellAddress].s = headerStyle;
    });

    columnWidths.forEach((width, colIndex) => {
      ws['!cols'] = ws['!cols'] || [];
      ws['!cols'][colIndex] = width;
    });

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet1');

    // Save the workbook as ReportSummary.xlsx
    writeFile(wb, 'ReportSummary.xlsx');
  };

  const Section = ({ title, children }) => (
    <>
      <div className="mb-2 border-b-2 border-black">
        <Typography
          variant="inherit"
          color="primaryLight"
          className="flex pb-2 text-[16px] font-semibold !leading-[1.2]"
        >
          {title}
        </Typography>
      </div>
      <div className="mt-4 mb-8">{children}</div>
    </>
  );

  return (
    <>
      <section className="relative w-full text-white">
        <SettingsLeftPanel
          servicesManager={servicesManager}
          extensionManager={extensionManager}
          hotkeysManager={hotkeysManager}
          sortedStudies={studies}
        />
        <div className="absolute top-16 left-[260px]">
          <Formik
            initialValues={initialValues}
            // onSubmit={onSubmitHandler}
          >
            {({ isSubmitting }) => (
              <Form>
                <Section title={t('Report Summary')}>
                  <div className="flex items-end justify-center">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="w-72">
                        <Label
                          className="my-2 block"
                          text=""
                        >
                          Radiology Group{' '}
                        </Label>
                        <Field
                          className="mr-5"
                          name="groupName"
                          placeholder={'Select Radiology Group Name'}
                          onChange={handleSelectionGroupNameChange}
                          as={Select}
                          options={optionGroup}
                          value={[selectedGroupName]}
                        />
                      </div>
                      <div
                        className="w-72"
                        style={{ marginTop: '-10px' }}
                      >
                        <Label
                          className="my-2 block"
                          text=""
                        >
                          {' '}
                          Date and Time
                        </Label>
                        <Field
                          as={InputDateRange}
                          placeholder={'Select Date & Time'}
                          //   options={optionTATHours}
                          className="mr-5"
                          name="tatHours"
                          value={selectedDateRange}
                          onChange={handleDateRangeFieldChange}
                        />
                      </div>
                      <div className="w-72">
                        <Label
                          className="my-2 block"
                          text=""
                        >
                          User
                        </Label>
                        <Field
                          as={Select}
                          placeholder={'Select Radiology User'}
                          options={optionRadiologistUser}
                          className="mr-5"
                          name="tatMinutes"
                          value={[selectedRadiologistUser]}
                          onChange={handleRadiologistUserChange}
                        />
                      </div>
                      <div className="w-72">
                        <Label
                          className="my-2 block"
                          text=""
                        >
                          Report Status
                        </Label>
                        <Field
                          as={Select}
                          placeholder={'Select Report Status'}
                          options={optionStudyStatus}
                          className="mr-5"
                          name="tatMinutes"
                          value={[selectedStudyStatus]}
                          onChange={handleStudyStatusChange}
                        />
                      </div>
                      <div className="w-72">
                        <Button onClick={handleDownloadExcel}>Download as Excel</Button>
                      </div>
                    </div>
                  </div>
                </Section>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
};

export default ReportSummary;
