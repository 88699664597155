import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Label from '../Label';
import Input from '../Input';
import Button from '../Button';
import Icon from '../Icon';
import './Users.css';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';

const UserCreateModel = ({
  createUser,
  user,
  hide,
  fetchUsers,
  setUsersList,
  userData,
  updateUser,
  fetchPaginatedUsers,
  currentPage,
  searchQuery
}) => {
  const [passwordeye, setpasswordeye] = useState(false);
  const [cpassworeye, setCpassworeye] = useState(false);
  const [error, setError] = useState(null);
  const { show } = useSnackbar();

  const initialValues = {
    firstName: userData.firstName || '',
    lastName: userData.lastName || '',
    username: userData.username || '',
    email: userData.email || '',
    password: userData.password || '',
    confirmPassword: userData.password || '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    username: Yup.string().required('Username is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: !userData ? Yup.string().required('Password is required') : Yup.string(),
    confirmPassword: !userData
      ? Yup.string()
          .required('Confirm Password is required')
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
      : Yup.string(),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (userData) {
        const userDetails = {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
        };
        const response = await updateUser(userData.id, userDetails, user.access_token);
        if (response.status === 204) {
          fetchUsers(user.access_token)
            .then(data => setUsersList(data))
            .catch(error => console.error('Error fetching users:', error));
          fetchPaginatedUsers(currentPage, searchQuery)
          show({
            title: 'Success!',
            message: 'Successfully User Updated',
            type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
            position: 'topRight',
          });
          hide();
        } else {
          const errorMessage = await response.json();
          setError(errorMessage.errorMessage);
        }
      } else {
        const credentials = [
          {
            type: 'password',
            temporary: true,
            value: values.password,
          },
        ];

        const userCredentials = {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          username: values.username,
          credentials: credentials,
          enabled: true,
          emailVerified: true,
        };
        const response = await createUser(userCredentials, user.access_token);

        if (response.status === 201) {
          fetchUsers(user.access_token)
            .then(data => setUsersList(data))
            .catch(error => console.error('Error fetching users:', error));
          show({
            title: 'Success!',
            message: 'Successfully User Created',
            type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
            position: 'topRight',
          });
          hide();
        } else {
          const errorMessage = await response.json();
          setError(errorMessage.errorMessage);
        }
      }
    } catch (error) {
      setError(error.response.data.errorMessage);
    }
    setSubmitting(false);
  };

  return (
    <section className="w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2">
              <div>
                <Label className="my-2 block" text={''}>First Name</Label>
                <Field
                  type="text"
                  name="firstName"
                  as={Input}
                  placeholder="Please enter your first name"
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text={''}>Last Name</Label>
                <Field
                  type="text"
                  name="lastName"
                  as={Input}
                  placeholder="Please enter your last name"
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text={''}>Username</Label>
                <Field
                  type="text"
                  name="username"
                  as={Input}
                  placeholder="Please enter your user name"
                  disabled={userData ? true : false}
                />
                <ErrorMessage
                  name="username"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text={''}>Email</Label>
                <Field
                  type="text"
                  name="email"
                  as={Input}
                  placeholder="Please enter your first name"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text={''}>Password</Label>
                <Field
                  type="text"
                  name="password"
                  as={Input}
                  placeholder="Please enter your password"
                  disabled={userData ? true : false}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text={''}>Confirm Password</Label>
                <Field
                  type="text"
                  name="confirmPassword"
                  as={Input}
                  placeholder="Please enter your confirm password"
                  disabled={userData ? true : false}
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-red-600"
                />
              </div>
            </div>
            <div className="flex">
              <Button
                className="mt-8"
                // type="submit"
                disabled={isSubmitting}
              >
                {userData ? 'Update Physician' : 'Create Physician'}
              </Button>
              <Label className="my-2 mt-9 block h-3 pl-3 text-red-600" text={''}>{error}</Label>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default UserCreateModel;
