// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.passworld_box{
    position: relative;
}
.passworld_box svg{
    position: absolute;
    top: 48px;
    width: 25px;
    right: 10px;
    cursor: pointer;
}
.from__dropdown .customSelect__wrapper{
    margin-top: 0 !important;
}
.customSelect__clear-indicator{
    display: none !important;
}`, "",{"version":3,"sources":["webpack://./../../ui/src/components/Physician/Physician.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,WAAW;IACX,eAAe;AACnB;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,wBAAwB;AAC5B","sourcesContent":[".passworld_box{\n    position: relative;\n}\n.passworld_box svg{\n    position: absolute;\n    top: 48px;\n    width: 25px;\n    right: 10px;\n    cursor: pointer;\n}\n.from__dropdown .customSelect__wrapper{\n    margin-top: 0 !important;\n}\n.customSelect__clear-indicator{\n    display: none !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
