import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import InputLabelWrapper from '../InputLabelWrapper';
import '../../../../app/src/routes/WorkList/WorkList.css'

const WorklistLabel = ({
    inputMeta,
    filtersMeta,
    values,
    onValuesChange,
    selectedRows,
    setSelectedRows,
    displaySetList,
    filterData
}) => {
    const filteredFiltersMeta = filtersMeta.filter(meta => inputMeta[meta?.name]);
    const { sortBy, sortDirection } = values;


    const setFilterSorting = sortingValues => {
        onValuesChange({
            ...values,
            ...sortingValues,
        });
    };


    const handleFilterLabelClick = name => {
        let _sortDirection = 'descending';
        if (sortBy === name) {
            if (sortDirection === 'ascending') {
                _sortDirection = 'descending';
            } else if (sortDirection === 'descending') {
                _sortDirection = 'ascending';
            }
        }

        setFilterSorting({
            sortBy: _sortDirection !== 'none' ? name : '',
            sortDirection: _sortDirection,
        });
    };

    const renderFieldInputComponent = ({ name, displayName, inputProps, isSortable, inputType }) => {
        const _isSortable = isSortable;
        const _sortDirection = sortBy !== name ? 'none' : sortDirection;

        const onLabelClick = () => {
            handleFilterLabelClick(name);
        };


        return <InputLabelWrapper
            name={name}
            label={displayName}
            isSortable={_isSortable}
            sortDirection={_sortDirection}
            onLabelClick={onLabelClick}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            filterData={filterData}

        />
    };

    return (
        <div className="sticky top-0 z-10 mx-auto border-b-4 border-black px-5 scroll_width_header_body !w-fit">
            <div className="bg-primary-dark pt-5 pb-5 " style={{ width: 'fit-content !important' }}>
                <div className="relative m-auto flex flex-col">
                    <div className="" style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(28, 1fr)',
                    }}>
                        {/* Patient Name */}
                        {filteredFiltersMeta.length === 0 ? (
                            <div>Not Found</div> // Display "Not Found" if no results are found
                        ) : (
                            filteredFiltersMeta.filter(item => item.name !== 'general').map(inputMeta => {
                                return (
                                    <div
                                        key={inputMeta.name}
                                        className={classnames('pl-4', {
                                            'first_div': inputMeta?.name === "checkBox",
                                            'second_div': inputMeta?.displayName === "Patient Name"
                                        })}
                                        style={{
                                            width: inputMeta.name === 'document' && '209px' ||
                                                inputMeta.name === 'checkBox' && '100px' ||
                                                inputMeta.name === 'patientName' && '300px' ||
                                                inputMeta.name === 'studyDate' && '210px' ||
                                                inputMeta.name === 'description' && '245px' ||
                                                inputMeta.name === 'institutionname' && '200px' ||
                                                inputMeta.name === 'lastupdate' && '205px' ||
                                                inputMeta.name === 'instances' && '120px' ||
                                                inputMeta.name === 'share' && '100px' ||
                                                inputMeta.name === 'attachment' && '104px' ||
                                                inputMeta.name === 'clinicalhistory' && '126px' ||
                                                inputMeta.name === 'reportstatus' && '125px' ||
                                                inputMeta.name === 'modalities' && '100px' ||
                                                inputMeta.name === 'dob' && '100px' ||
                                                inputMeta.name === 'sex' && '85px' ||
                                                inputMeta.name === 'age' && '85px' ||
                                                inputMeta.name === 'tat' && '130px' ||
                                                inputMeta.name === 'studyTat' && '100px' ||
                                                inputMeta.name === 'patientId' && '130px' ||
                                                inputMeta.name === 'aet' && '85px' ||
                                                inputMeta.name === 'assign' && '120px' || '180px',
                                        }}
                                    >
                                        {renderFieldInputComponent(inputMeta)}
                                    </div>
                                );
                            })
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorklistLabel;
