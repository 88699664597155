import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon';
import Typography from '../Typography';
import { ThemeContext } from '../ThemeWrapper/ThemeWrapper';

// TODO: Add loading spinner to TELERAPP + use it here.
const EmptyStudies = ({ className, numOfStudies }) => {
  const { t } = useTranslation('StudyList');
  const { theme } = useContext(ThemeContext);
  return (
    <div className={classnames('inline-flex flex-col items-center', className)}>
      {
        theme === 'dark' ? (
          <Icon
            name="magnifierDark"
            className="mb-4"
          />
        ) : (
          <Icon
            name="magnifierLight"
            className="mb-4"
          />
        )
      }
      <Typography
        className="dark:text-white text-black"
        variant="h5"
      >
        {t(`${numOfStudies.length === 0 ? 'No studies assign' : 'No studies available'}`)}
      </Typography>
    </div>
  );
};

EmptyStudies.propTypes = {
  className: PropTypes.string,
};

export default EmptyStudies;
