import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Button, Input, Label, useReportEditor, useSnackbar, useUser } from '@ohif/ui';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './shareModel.css'
import Handlebars from 'handlebars';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import Swal from 'sweetalert2';
// import DecoupledEditor from "../../../../ckeditor5-41.4.2-o8usa4rff71"


const ShareModel = ({ hide, studyInstanceUid, handleSharePatientData, patientReportsDetails, sendReportData, sendReportDataToWhastapp, user, usersList, reportSettingDetails, setIsLoading, patientId, accession }) => {
    const findPatientData = patientReportsDetails?.find(item => item.study_UIDs === studyInstanceUid);

    const [patientReportDetail, setPatientReportDetail] = useState(null);
    const [template, setTemplate] = useState(null);
    const [editorData, setEditorData] = React.useState(null);

    const reportSetting = reportSettingDetails?.find(
        item => item.group_name === user?.profile?.radiologyGroup
    );

    const patientDatas = patientReportsDetails?.find(item => item.study_UIDs === studyInstanceUid);

    const reportSubmit_time =
        patientDatas?.report_submit_time && new Date(patientDatas?.report_submit_time);

    let formattedTime;
    if (reportSubmit_time) {
        formattedTime = `
        ${reportSubmit_time?.toLocaleDateString('en-US', { month: 'long' })}
        ${reportSubmit_time?.getDate()},
        ${reportSubmit_time?.getFullYear()}
        ${reportSubmit_time?.getHours()}:${('0' + reportSubmit_time?.getMinutes())?.slice(-2)}:${('0' + reportSubmit_time?.getSeconds())?.slice(-2)} GMT${reportSubmit_time?.getTimezoneOffset() > 0 ? '-' : '+'}${('0' + Math.abs(reportSubmit_time?.getTimezoneOffset() / 60))?.slice(-2)}:${('0' + Math.abs(reportSubmit_time?.getTimezoneOffset() % 60)).slice(-2)} (India Standard Time)`;
    }

    const assignUserFind = findPatientData?.assign?.map((item) => JSON.parse(item));
    const findAssignUserName = assignUserFind?.map(item => item.assign_name);
    const assignUserDataFind = usersList?.find((item) => {
        // Check if the firstName exists in findAssignUserName
        return findAssignUserName?.includes(item.username);
    });



    useEffect(() => {
        const fetchPatientData = async () => {
            const patient = await patientReportsDetails.find(
                item => item.study_UIDs === studyInstanceUid
            );
            setPatientReportDetail(patient);
            setTemplate(Handlebars.compile(patient?.reportdetails || '')(patient));
        };

        fetchPatientData();
    }, [studyInstanceUid, patientReportsDetails]);

    console.log(editorData, "editorDataeditorData")
    useEffect(() => {
        const editorElement: any = document.querySelector('#editor');
        if (!editorElement || !template) {
            return;
        }
        DecoupledEditor.create(editorElement, {
            toolbar: {
                items: [
                    'heading', '|',
                    'bold', 'italic', 'underline',
                    'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor',
                    '|', 'bulletedList', 'numberedList', 'insertTable',
                    '|', 'undo', 'redo',
                ],
            },
            language: 'en',
        }).then(editor => {
            const toolbarContainer = document.querySelector('#toolbar-container');
            if (toolbarContainer) {
                toolbarContainer.appendChild(editor.ui.view.toolbar.element);
            }
            if (patientReportDetail?.isDrafted) {
                editor.setData(template + '<p>Addendum begin &lt; &gt; Addendum end</p>');
            } else {
                editor.setData(template);
            }
            editor.model.document.on('change:data', (event, data) => {
                const newData = editor.getData();
                setEditorData(newData);
            });
        }).catch(error => {
            console.error(error);
        });
    }, [template, patientReportDetail]);


    const { show } = useSnackbar();
    const initialValue = (data) => {
        return {
            patient_phoneNuber: data?.phone_number || '',
            countryCode: data?.countryCode || '',
            patient_email: data?.patient_email || ''
        }
    }

    const [formData, setFormData] = useState({
        patient_phoneNuber: '',
        countryCode: '',
        patient_email: ''
    })
    console.log(formData);

    useEffect(() => {
        if (findPatientData !== undefined) {
            setFormData({
                patient_phoneNuber: findPatientData?.phone_number,
                countryCode: findPatientData?.countryCode,
                patient_email: findPatientData?.patient_email
            })
        } else {
            setFormData({
                patient_phoneNuber: '',
                countryCode: '',
                patient_email: ''
            })
        }
    }, [findPatientData])

    // const setFieldValue = (e, value, name) => {

    //     if (name === 'patient_phoneNumber') {
    //         let dialCode = value?.dialCode || '';
    //         let phoneNumber = e;

    //         if (phoneNumber.startsWith(dialCode)) {
    //             // Remove the dial code from the phone number
    //             phoneNumber = phoneNumber.substring(dialCode.length);
    //         }

    //         setFormData({
    //             ...formData,
    //             countryCode: dialCode,
    //             patient_phoneNuber: phoneNumber,
    //         });
    //     }
    // }

    const onsubmit = (values) => {
        handleSharePatientData(studyInstanceUid, formData, patientId, accession);
        if (findPatientData !== undefined) {
            show({
                title: "Success!",
                message: "Successfully Update Patient Details",
                type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
                position: "topRight"
            });
        } else {
            show({
                title: "Success!",
                message: "Successfully Add Patient Details",
                type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
                position: "topRight"
            });
        }
        hide();
    }


    const handlerShareDocumentToEmail = async () => {
        setIsLoading(true);

        try {
            // Modify the editor data before generating the PDF
            const modifiedEditorData = editorData ? editorData : patientReportDetail.reportdetails;


            console.log(modifiedEditorData, "modifiedEditorData")


            const headerStyle = `
            height: 100%;
            width: 98%;
            z-index: 1;
            padding-right: 10px;
            height: ${reportSetting.height}px;
            `;


            const watermarkStyle = `
                position: fixed;
                width: 100%;
                height: auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0.6;
                margin-top: 50px;
                margin-bottom: 50px;
                z-index: -1;
            `;


            const watermarkTextStyle = `
                font-size: 80px;
                color: #999;
            `;


            // signature image style
            const signatureStyle = `
                width: 200px;
                height: 100px;
                color : red ;
            `;


            // report template style
            const reportDataStyle = `
                margin-top: ${reportSetting?.top}px;
                margin-left: ${reportSetting?.left}px;
                margin-right: ${reportSetting?.right}px;
                margin-bottom: ${reportSetting?.bottom}px;
                font-family: ${reportSetting?.font_style};
                font-size: ${reportSetting?.font_size}px !important;
                line-height: ${reportSetting?.line_spacing};
            `;


            // Doctore details footer
            const firstName = assignUserDataFind ? `${assignUserDataFind?.username}` : '';
            const qualification = assignUserDataFind?.attributes.qualification !== undefined ? assignUserDataFind?.attributes.qualification : '';
            const registrationNo = assignUserDataFind && assignUserDataFind?.attributes && assignUserDataFind?.attributes.registrationNo ? assignUserDataFind.attributes.registrationNo : '';
            const formattedTimes = formattedTime === undefined ? '' : formattedTime;
            const disclaimerDetails = reportSetting && reportSetting.disclaimer_details ? reportSetting.disclaimer_details : '';


            const output = `
                ${firstName}<br/>
                ${qualification}<br/>
                ${registrationNo ? `Reg.No. :- ${registrationNo}` : ''}<br/>
                ${formattedTimes}<br/>
                ${disclaimerDetails ? `Disclaimer :- ${disclaimerDetails}` : ''}
            `;




            const pageHeaderSpace = `
          height: ${reportSetting.height}px;
          `;


            const pageFooterSpace = `
          height: ${reportSetting.height}px;
          `;


            const pageFooter = `
            position: fixed;
            bottom: 0;
            width: 100%;`;


            const pageHeader = `
            position: fixed;
            top: 0mm;
            width: 100%;`;


            const page = `
              page-break-after: always;
            `;


            const pageStyle = `
            @page {
              margin:   30mm
            }

            @media print {
              thead {display: table-header-group;}
              tfoot {display: table-footer-group;}

              button {display: none;}

              body {margin: 0;}
          }`;


            let modifiedEditor = '';
            // Construct modified editor content
            if (reportSetting?.multiple_header_and_footer === true) {
                modifiedEditor = `
                <div style="${pageStyle}">
                  <div class="page-header" style="${pageHeader}">
                    ${reportSetting?.include_header && reportSetting?.header_image
                        ? `
                    <img src="${reportSetting?.header_image}"  alt="Header" style="${headerStyle}" />
                    ` : ''}

                  </div >

                  <div class="page-footer" style="${pageFooter}">
                  ${reportSetting?.include_footer && reportSetting?.footer_image ? `
                  <img src="${reportSetting?.footer_image}" alt="Footer" style="${headerStyle}" />
                  ` : ''}
                  </div>

                  <table>

                  <thead>
                    <tr>
                      <td>
                        <!--place holder for the fixed-position header-->
                        <div style="${pageHeaderSpace}" class="page-header-space"></div>
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>

                        <div style="${page}">
                          <div style="${reportDataStyle}">
                            ${modifiedEditorData}
                            <div style="margin-top: 10px">
                              ${reportSetting?.signature && assignUserDataFind?.attributes?.uploadSignature && assignUserDataFind ? `<img src="${assignUserDataFind?.attributes?.uploadSignature}" alt="signature" style="${signatureStyle}" />` : ''} <br/>
                              ${output}
                            </div >
                          </div>

                        </div>
                      </td>
                    </tr>
                  </tbody>

                  <tfoot>
                    <tr>
                      <td>

                        <div class="page-footer-space" style="${pageFooterSpace}"></div>
                      </td>
                    </tr>
                  </tfoot>

                  </table>
                  ${reportSetting?.include_watermark && reportSetting?.watermark_image
                        ? `
                  <div style="${watermarkStyle}" class="watermark">
                    <span style="${watermarkTextStyle}" class="watermark-text"><img src="${reportSetting?.watermark_image}" alt="watermark" width="100%" height="auto" /> </span>
                  </div>
                  `
                        : ''
                    }
                        </div>

        `;
            } else {
                modifiedEditor = `
            <div>
              ${reportSetting?.include_header && reportSetting?.header_image
                        ? `
              <img src="${reportSetting?.header_image}" alt="Header" style="${headerStyle}" />
            `
                        : ''
                    }
              <div >
                <div >
                  <!-- Editor content -->
                  <div style="${reportDataStyle}">
                    ${modifiedEditorData}
                    <div style="margin-top: 10px">
                     ${reportSetting?.signature && assignUserDataFind?.attributes?.uploadSignature && assignUserDataFind ? `<img src="${assignUserDataFind?.attributes?.uploadSignature}" alt="signature" style="${signatureStyle}" />` : ''} <br/>
                      ${output}
                    </div >
                  </div>
                </div>
              </div>
              ${reportSetting?.include_footer && reportSetting?.footer_image
                        ? `
              <img src="${reportSetting?.footer_image}" alt="Footer" style="${headerStyle}" />
              `
                        : ''
                    }
            </div >
            ${reportSetting?.include_watermark && reportSetting?.watermark_image
                        ? `
              <div style="${watermarkStyle}" class="watermark">
                <span style="${watermarkTextStyle}" class="watermark-text"><img src="${reportSetting?.watermark_image}" alt="watermark" width="100%" height="auto" /> </span>
              </div>
            `
                        : ''
                    }
      `;
            }


            if (findPatientData?.document_status === 'Approved') {


                const editorContent = document.querySelector('#editor').innerHTML;
                const sendData = {
                    to: formData.patient_email,
                    document: modifiedEditor,
                    patient_name: patientReportDetail.patient_name.toLowerCase()
                }


                hide();
                show({
                    title: "Info!",
                    message: `Document Sendning To ${formData.patient_email} please wait`,
                    type: SnackbarTypes.INFO, // Assuming you have a SUCCESS type defined in your SnackbarTypes
                    position: "topRight"
                });


                const response = await sendReportData(sendData)
                console.log(response)


                if (response.status === 200) {
                    show({
                        title: "Success!",
                        message: response?.message,
                        type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
                        position: "topRight"
                    });
                } else {
                    show({
                        title: "Error!",
                        message: `Failed Document Send To ${formData.patient_email}`,
                        type: SnackbarTypes.ERROR, // Assuming you have a SUCCESS type defined in your SnackbarTypes
                        position: "topRight"
                    });
                }
            } else {
                Swal.fire({
                    text: `Sorry, patient report not approved. Share only approved reports.`,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                }).then(result => {
                    if (result.isConfirmed) {
                        show({
                            title: "Error!",
                            message: `First patient report Approved then share report`,
                            type: SnackbarTypes.ERROR, // Assuming you have a SUCCESS type defined in your SnackbarTypes
                            position: "topRight"
                        });
                    }
                });
                hide();
            }
        } catch (error) {
            console.error("Share document to email faile :- ", error)
        } finally {
            setIsLoading(false);
        }
    }

    // const handlerShareDocumentToWhatsapp = async () => {
    //     setIsLoading(true);

    //     try {
    //         // Modify the editor data before generating the PDF
    //         const modifiedEditorData = editorData ? editorData : patientReportDetail.reportdetails;

    //         console.log(modifiedEditorData, "modifiedEditorData")

    //         const headerStyle = `
    //          height: 100%;
    //          width: 98%;
    //          z-index: 1;
    //          padding-right: 10px;
    //          height: ${reportSetting.height}px;
    //          `;

    //         const watermarkStyle = `
    //              position: fixed;
    //              width: 100%;
    //              height: auto;
    //              top: 50%;
    //              left: 50%;
    //              transform: translate(-50%, -50%);
    //              opacity: 0.6;
    //              margin-top: 50px;
    //              margin-bottom: 50px;
    //              z-index: -1;
    //          `;

    //         const watermarkTextStyle = `
    //              font-size: 80px;
    //              color: #999;
    //          `;

    //         // signature image style
    //         const signatureStyle = `
    //              width: 200px;
    //              height: 100px;
    //              color : red ;
    //          `;

    //         // report template style
    //         const reportDataStyle = `
    //              margin-top: ${reportSetting?.top}px;
    //              margin-left: ${reportSetting?.left}px;
    //              margin-right: ${reportSetting?.right}px;
    //              margin-bottom: ${reportSetting?.bottom}px;
    //              font-family: ${reportSetting?.font_style};
    //              font-size: ${reportSetting?.font_size}px !important;
    //              line-height: ${reportSetting?.line_spacing};
    //          `;

    //         // Doctore details footer
    //         const firstName = assignUserDataFind ? `${assignUserDataFind?.username}` : '';
    //         const qualification = assignUserDataFind?.attributes.qualification !== undefined ? assignUserDataFind?.attributes.qualification : '';
    //         const registrationNo = assignUserDataFind && assignUserDataFind?.attributes && assignUserDataFind?.attributes.registrationNo ? assignUserDataFind.attributes.registrationNo : '';
    //         const formattedTimes = formattedTime === undefined ? '' : formattedTime;
    //         const disclaimerDetails = reportSetting && reportSetting.disclaimer_details ? reportSetting.disclaimer_details : '';

    //         const output = `
    //              ${firstName}<br/>
    //              ${qualification}<br/>
    //              ${registrationNo ? `Reg.No. :- ${registrationNo}` : ''}<br/>
    //              ${formattedTimes}<br/>
    //              ${disclaimerDetails ? `Disclaimer :- ${disclaimerDetails}` : ''}
    //          `;


    //         const pageHeaderSpace = `
    //        height: ${reportSetting.height}px;
    //        `;

    //         const pageFooterSpace = `
    //        height: ${reportSetting.height}px;
    //        `;

    //         const pageFooter = `
    //          position: fixed;
    //          bottom: 0;
    //          width: 100%;`;

    //         const pageHeader = `
    //          position: fixed;
    //          top: 0mm;
    //          width: 100%;`;

    //         const page = `
    //            page-break-after: always;
    //          `;

    //         const pageStyle = `
    //          @page {
    //            margin:   30mm
    //          }

    //          @media print {
    //            thead {display: table-header-group;}
    //            tfoot {display: table-footer-group;}

    //            button {display: none;}

    //            body {margin: 0;}
    //        }`;

    //         let modifiedEditor = '';
    //         // Construct modified editor content
    //         if (reportSetting?.multiple_header_and_footer === true) {
    //             modifiedEditor = `
    //              <div style="${pageStyle}">
    //                <div class="page-header" style="${pageHeader}">
    //                  ${reportSetting?.include_header && reportSetting?.header_image
    //                     ? `
    //                  <img src="${reportSetting?.header_image}"  alt="Header" style="${headerStyle}" />
    //                  ` : ''}

    //                 </div >

    //                 <div class="page-footer" style="${pageFooter}">
    //                 ${reportSetting?.include_footer && reportSetting?.footer_image ? `
    //                 <img src="${reportSetting?.footer_image}" alt="Footer" style="${headerStyle}" />
    //                 ` : ''}
    //                 </div>

    //                 <table>

    //                 <thead>
    //                     <tr>
    //                     <td>
    //                         <!--place holder for the fixed-position header-->
    //                         <div style="${pageHeaderSpace}" class="page-header-space"></div>
    //                     </td>
    //                     </tr>
    //                 </thead>

    //                 <tbody>
    //                     <tr>
    //                     <td>

    //                         <div style="${page}">
    //                         <div style="${reportDataStyle}">
    //                             ${modifiedEditorData}
    //                             <div style="margin-top: 10px">
    //                             ${reportSetting?.signature && assignUserDataFind?.attributes?.uploadSignature && assignUserDataFind ? `<img src="${assignUserDataFind?.attributes?.uploadSignature}" alt="signature" style="${signatureStyle}" />` : ''} <br/>
    //                             ${output}
    //                             </div >
    //                         </div>

    //                         </div>
    //                     </td>
    //                     </tr>
    //                 </tbody>

    //                 <tfoot>
    //                     <tr>
    //                     <td>

    //                         <div class="page-footer-space" style="${pageFooterSpace}"></div>
    //                     </td>
    //                     </tr>
    //                 </tfoot>

    //                 </table>
    //                 ${reportSetting?.include_watermark && reportSetting?.watermark_image
    //                     ? `
    //                 <div style="${watermarkStyle}" class="watermark">
    //                     <span style="${watermarkTextStyle}" class="watermark-text"><img src="${reportSetting?.watermark_image}" alt="watermark" width="100%" height="auto" /> </span>
    //                 </div>
    //                `
    //                     : ''
    //                 }
    //                      </div>

    //                 `;
    //         } else {
    //             modifiedEditor = `
    //                 <div>
    //                 ${reportSetting?.include_header && reportSetting?.header_image
    //                     ? `
    //                 <img src="${reportSetting?.header_image}" alt="Header" style="${headerStyle}" />
    //                 `
    //                     : ''
    //                 }
    //                 <div >
    //                     <div >
    //                     <!-- Editor content -->
    //                     <div style="${reportDataStyle}">
    //                         ${modifiedEditorData}
    //                         <div style="margin-top: 10px">
    //                         ${reportSetting?.signature && assignUserDataFind?.attributes?.uploadSignature && assignUserDataFind ? `<img src="${assignUserDataFind?.attributes?.uploadSignature}" alt="signature" style="${signatureStyle}" />` : ''} <br/>
    //                         ${output}
    //                         </div >
    //                     </div>
    //                     </div>
    //                 </div>
    //                 ${reportSetting?.include_footer && reportSetting?.footer_image
    //                     ? `
    //                 <img src="${reportSetting?.footer_image}" alt="Footer" style="${headerStyle}" />
    //                 `
    //                     : ''
    //                 }
    //                 </div >
    //                 ${reportSetting?.include_watermark && reportSetting?.watermark_image
    //                     ? `
    //                 <div style="${watermarkStyle}" class="watermark">
    //                     <span style="${watermarkTextStyle}" class="watermark-text"><img src="${reportSetting?.watermark_image}" alt="watermark" width="100%" height="auto" /> </span>
    //                 </div>
    //                 `
    //                     : ''
    //                 }
    //                 `;
    //         }
    //         console.log(modifiedEditor,"modifiedEditor");
    //         if (findPatientData?.document_status === 'Approved') {

    //             const editorContent = document.querySelector('#editor').innerHTML;
    //             const sendData = {
    //                 number: formData.patient_phoneNuber,
    //                 code: formData.countryCode,
    //                 document: modifiedEditor,
    //                 patient_name: patientReportDetail.patient_name.toLowerCase()
    //             }
    //             console.log(sendData, 'sendData')
    //             hide();
    //             show({
    //                 title: "Info!",
    //                 message: `Document Sendning To ${formData.countryCode}${formData.patient_phoneNuber} please wait`,
    //                 type: SnackbarTypes.INFO, // Assuming you have a SUCCESS type defined in your SnackbarTypes
    //                 position: "topRight"
    //             });

    //             const response = await sendReportDataToWhastapp(sendData)
    //             console.log(response);

    //             if (response.status === 200) {
    //                 show({
    //                     title: "Success!",
    //                     message: response?.message,
    //                     type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
    //                     position: "topRight"
    //                 });
    //             } else {
    //                 show({
    //                     title: "Error!",
    //                     message: `Failed Document Send To ${formData.patient_email}`,
    //                     type: SnackbarTypes.ERROR, // Assuming you have a SUCCESS type defined in your SnackbarTypes
    //                     position: "topRight"
    //                 });
    //             }
    //         } else {
    //             Swal.fire({
    //                 text: `Sorry, patient report not approved. Share only approved reports.`,
    //                 icon: 'info',
    //                 showCancelButton: true,
    //                 confirmButtonColor: '#3085d6',
    //                 cancelButtonColor: '#d33',
    //             }).then(result => {
    //                 if (result.isConfirmed) {
    //                     show({
    //                         title: "Error!",
    //                         message: `First patient report Approved then share report`,
    //                         type: SnackbarTypes.ERROR, // Assuming you have a SUCCESS type defined in your SnackbarTypes
    //                         position: "topRight"
    //                     });
    //                 }
    //             });
    //             hide();
    //         }
    //     } catch (error) {
    //         console.error("Share document to whatsapp faile :- ", error)
    //     } finally {
    //         setIsLoading(false);
    //     }
    // }

    return (
        <section className="w-full ">
            <Formik
                initialValues={initialValue(findPatientData)}
                onSubmit={onsubmit}
            >
                {({ isSubmitting, handleChange, values }) => (
                    <Form>
                        {/* <div className=' flex gap-5 items-end my-2'>
                            <div className=' w-full'>
                                <Label className='' text=''>Patient Phone Number</Label>
                                <PhoneInput
                                    country={"in"}
                                    value={`${values.countryCode} ${values.patient_phoneNuber}`}
                                    onChange={(e, phone) => setFieldValue(e, phone, "patient_phoneNumber")}
                                    inputStyle={{ width: '100%', marginTop: '0.5rem', fontSize: '1.125rem', color: 'white', backgroundColor: 'black', borderColor: `rgb(58 63 153)` }}
                                    containerStyle={{ width: '100%' }}
                                />
                            </div>
                            <div className=' flex items-center downloadbutton'>
                                <button type="button" className=' box-content inline-flex flex-row items-center justify-center gap-[5px] justify center px-[10px] outline-none rounded leading-[1.2] font-sans text-center whitespace-nowrap font-semibold bg-primary-main text-white transition duration-300 ease-in-out focus:outline-none hover:bg-customblue-80 active:bg-customblue-40 h-[32px] text-[14px] min-w-[32px]' onClick={handlerShareDocumentToWhatsapp}>Share Document</button>
                            </div>
                        </div> */}
                        <div className=' flex gap-5 items-end my-2'>
                            <div className=' w-full'>
                                <Label className='' text=''>Patient Email</Label>
                                <Field
                                    type='email'
                                    className=" mt-2 w-full text-lg"
                                    name="patient_email"
                                    placeholder={'Enter Patient Email'}
                                    onChange={(e) => setFormData({ ...formData, patient_email: e.target.value })}
                                    as={Input}
                                    value={formData.patient_email}
                                />
                            </div>
                            <div>
                                <button type="button" className=' box-content inline-flex flex-row items-center justify-center gap-[5px] justify center px-[10px] outline-none rounded leading-[1.2] font-sans text-center whitespace-nowrap font-semibold bg-primary-main text-white transition duration-300 ease-in-out focus:outline-none hover:bg-customblue-80 active:bg-customblue-40 h-[32px] text-[14px] min-w-[32px]' onClick={handlerShareDocumentToEmail}>Share</button>
                            </div>
                        </div>
                        <div className=' mt-5'>
                            <Button>{(findPatientData?.phone_number || findPatientData?.patient_email) ? 'Update' : 'Save'}</Button>
                        </div>
                    </Form>

                )}
            </Formik>
            <div id="editor" className=' hidden' ></div>
        </section>
    )
}

export default ShareModel;
