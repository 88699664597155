export const fetchLogs = async (date, fileName) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/logs/${date}/${fileName}`);
    const data = await response.json();
    return data;
};

export const fetchDateRangeLogs = async (startDate, endDate, fileName) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/logs/${startDate}/${endDate}/${fileName}`);
    const data = await response.json();
    return data;
};


// requestHandler.js

// Fetch all events without pagination
// export const fetchEvents = async (accessTokens) => {
//     try {
//         const response = await fetch(`${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/events`, {
//             headers: {
//                 Authorization: `Bearer ${accessTokens}`
//             }
//         });
//         if (!response.ok) {
//             throw new Error(`Error fetching events: ${response.statusText}`);
//         }
//         return await response.json();
//     } catch (error) {
//         console.error('Error in fetchEvents:', error);
//         throw error;
//     }
// };

export const fetchEventsPagination = async (accessTokens, dateFrom = new Date().toISOString().split('T')[0]) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/events?dateFrom=${dateFrom}`,
            {
                headers: {
                    Authorization: `Bearer ${accessTokens}`
                }
            }
        );

        if (!response.ok) {
            throw new Error(`Error fetching paginated events: ${response.statusText}`);
        }

        // Parse the response data
        const data = await response.json();

        // Check if the data is in the expected array format
        if (!Array.isArray(data)) {
            console.error('Unexpected response format:', data);
            return [];
        }

        // Filter out events with type 'CODE_TO_TOKEN' and IP address '0:0:0:0:0:0:0:1'
        // Map 'LOGOUT_ERROR' type to 'LOGOUT' in the result
        return data
            .filter(event => event.type !== 'CODE_TO_TOKEN' && event.ipAddress !== '0:0:0:0:0:0:0:1')
            .map(event => ({
                type: event.type === 'LOGOUT_ERROR' ? 'LOGOUT' : event.type, // Rename LOGOUT_ERROR to LOGOUT
                username: event.details?.username,
                ipAddress: event.ipAddress,
                timestamp: event.time
            }));
    } catch (error) {
        console.error('Error in fetchEventsPagination:', error);
        throw error;
    }
};
