import React from 'react';
import { Button, SettingsLeftPanel, Typography } from '@ohif/ui';
import axios from 'axios';

const MyAccount = ({ servicesManager, extensionManager, hotkeysManager, data: studies }) => {
  const { userAuthenticationService } = servicesManager.services;

  const user = userAuthenticationService.getUser();
  const handleChangePassword = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/users/${user.profile.sub}/reset-password`,
        { temporary: true, value: 'temporaryPassword' },
        {
          headers: {
            Authorization: `${user.token_type} ${user.access_token}`,
          },
        }
      );
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const Section = ({ title, children }) => (
    <>
      <div className="mb-2 border-b-2 border-black">
        <Typography
          component={'p'}
          variant="inherit"
          color="primaryLight"
          className="flex pb-2 text-[16px] font-semibold !leading-[1.2]"
        >
          {title}
        </Typography>
      </div>
      <div className="mt-4 mb-8">{children}</div>
    </>
  );
  return (
    <section className="relative w-full text-white">
      <SettingsLeftPanel
        servicesManager={servicesManager}
        extensionManager={extensionManager}
        hotkeysManager={hotkeysManager}
        sortedStudies={studies}
      />
      <div className="absolute top-16 left-[260px]">
        {' '}
        <Section title={'My account'}>
          <div className="flex">
            <Typography
              component={'p'}
              variant="subtitle"
              className="mr-5 h-full cursor-pointer"
              onClick={handleChangePassword}
            >
              <Button> Change Password</Button>
            </Typography>
          </div>
        </Section>
      </div>
    </section>
  );
};

export default MyAccount;
