import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function SignoutCallbackComponent({ userManager }) {
  const navigate = useNavigate();

  useEffect(() => {
    const onRedirectSuccess = (/* user */) => {
      const { pathname, search = '' } = JSON.parse(sessionStorage.getItem('ohif-redirect-to'));
  
      navigate(`${pathname}?${search}`);
    };
  
    const onRedirectError = error => {
      throw new Error(error);
    };
  
    // Debounced function to navigate
    const debouncedNavigate = debounce(() => {
      userManager
        .signoutRedirectCallback()
        .then(user => onRedirectSuccess(user))
        .catch(error => onRedirectError(error));
    }, 500); // Adjust debounce delay as needed
  
    debouncedNavigate();

    // Clean up function
    // return () => {
    //   clearTimeout(debouncedNavigate);
    // };
  }, [userManager, navigate]);

  return null;
}

SignoutCallbackComponent.propTypes = {
  userManager: PropTypes.object.isRequired,
};

// Debounce utility function
function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export default SignoutCallbackComponent;
