import React, { useRef, useState } from 'react';

const UploadStudyModel = ({
    hidden,
    uploadStudy,
    setAllStudyList,
    setIsPostData
}) => {

    const [files, setFiles] = useState([]);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [uploading, setUploading] = useState(false);
    const fileInputRef = useRef(null);

    // const MAX_FILE_SIZE = 6 * 1024 * 1024; // 6MB in bytes
    const MAX_FILE_SIZE = 2 * 1024 * 1024 * 1024; // 2GB in bytes

    const handleUploadStudyChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const largeFiles = selectedFiles.filter(file => file.size > MAX_FILE_SIZE);

        if (largeFiles.length > 0) {
            setError('Selected file(s) exceed the 2GB size limit.');
            setFiles([]); // Clear previous files if any are too large
        } else {
            setError(null);
            setFiles(selectedFiles); // Add files to state if all are valid
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const droppedFiles = Array.from(event.dataTransfer.files);
        const largeFiles = droppedFiles.filter(file => file.size > MAX_FILE_SIZE);

        if (largeFiles.length > 0) {
            setError('Dropped file(s) exceed the 2GB size limit.');
            setFiles([]); // Clear previous files if any are too large
        } else {
            setError(null);
            setFiles(droppedFiles); // Add valid dropped files to state
        }
    };

    const handleRemoveFiles = () => {
        setFiles([]);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const filesName = files.length > 0 ? files.map(file => file.name).join(', ') : [];

    const handleStudyUpload = async () => {
        setUploading(true);
        try {
            const totalFiles = files.length;
            for (let i = 0; i < totalFiles; i++) {
                const file = files[i];
                const formData = new FormData();
                formData.append('file', file);
                const imageUpload = await uploadStudy(formData, setAllStudyList);

                if (imageUpload.error) {
                    setError(`Error uploading Study: ${imageUpload.error}`);
                    return;
                }
            }
            setSuccess('Study Uploaded Successfully.....');
            setIsPostData(files);

            // Hide the loader and reset progress after successful upload
            setUploading(false);
            setTimeout(() => {
                hidden();
            }, 2000);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (error) {
            console.error('Error uploading Study:', error);
            setError('Failed to upload Study');
            setUploading(false);
        }
    };


    return (
        <section className="w-full">
            <div
                className="bg-gray-50 text-center px-4 rounded flex flex-col items-center justify-center cursor-pointer border border-dashed border-gray-600 mx-auto font-[sans-serif] relative"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                <div className="pt-5">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-10 mb-2 fill-current inline-block" viewBox="0 0 32 32">
                        <path
                            d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                            data-original="#000000" />
                        <path
                            d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                            data-original="#000000" />
                    </svg>
                    <h4 className="text-lg font-semibold text-gray-600">Drag and drop files here</h4>
                    <p className='text-base font-semibold text-gray-600 text-center pt-2'> {filesName}</p>
                </div>
                <p className='text-lg font-semibold text-gray-600 py-3'>or</p>

                <div className="pb-5">
                    <input type="file" id='uploadFile1' className="hidden" onChange={handleUploadStudyChange} multiple ref={fileInputRef} />
                    <label htmlFor="uploadFile1"
                        className="bg-transparent hover:bg-blue-400 text-[#63b3ed] font-semibold hover:text-black py-2 px-4 border border-[#3a3f99] hover:border-transparent rounded inline-flex items-center transition-all" >Select files to upload</label>
                </div>
            </div>
            <div className='flex justify-between mt-5'>
                <button className="bg-blue-400 hover:opacity-80 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center transition-all" onClick={handleRemoveFiles}>
                    <span>Clear</span>
                </button>
                {/* <button className="bg-blue-400 hover:opacity-80 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center transition-all" onClick={handleStudyUpload} disabled={uploading || files.length === 0}>
                    <span>{uploading ? 'Uploading...' : 'Add to Storage'}</span>
                </button> */}
                <button
                    className={`bg-blue-400 ${files.length === 0 ? '' : 'hover:opacity-80'}  text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center transition-all ${files.length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={handleStudyUpload}
                    disabled={uploading || files.length === 0}
                >
                    <span>{uploading ? 'Uploading...' : 'Add'}</span>
                </button>
            </div>
            {error && <div className="border border-red-400 text-red-700 px-4 py-3 rounded relative mt-5 text-center" role="alert">
                <span className="block sm:inline">{error}</span>
            </div>}
            {success && <div className="border border-green-400 text-green-700 px-4 py-3 rounded relative mt-5 text-center" role="alert">
                <span className="block sm:inline">{success}</span>
            </div>}
        </section>
    );
};

export default UploadStudyModel;
